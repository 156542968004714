import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import _ from "lodash";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

import BackButton from "../common/backButtion";
import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";

import { getCategories, removeCategory } from "../../services/categoryService";
import CategoriesTable from "./categoriesTable";
import authService from "../../services/auth.service";
import rbacService from "../../services/rbacService";

class Categories extends Component {
  constructor(props) {
    super(props);
    this.titleRef = React.createRef();
  }
  state = {
    categories: [],
    selectedCategory: null,
    isLoading: false,
    currentPage: 1,
    pageSize: 10,
    sortColumn: { path: "title", order: "asc" },
    currentUser: null,
    rbac: null,
  };

  async componentDidMount() {
    this.setState({ isLoading: true });

    const localCategories = await getCategories();
    const cu = authService.getCurrentUser();
    const lrbac = new rbacService(cu);

    this.setState({ categories: localCategories, 
                    isLoading: false,
                    currentUser: cu,
                    rbac: lrbac });
  }

  handleCategorySelect = (category) => {
    if(category !== undefined) {
      this.setState({ selectedCategory: category, currentPage: 1 });
    }
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleDelete = async (category) => {
    const deleted = await removeCategory(category.id);
    if (deleted.status === 200) {
      const fedbks = this.state.categories.filter(
        (fb) => fb.id !== category.id
      );
      this.setState({ categories: fedbks });
    }
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const { selectedCategory, pageSize, currentPage, sortColumn, categories } =
      this.state;

    const categoryFiltered =
      selectedCategory && selectedCategory.id
        ? categories.filter((v) => v.id === selectedCategory.id)
        : categories;

    const sorted = _.orderBy(
      categoryFiltered,
      [sortColumn.path],
      [sortColumn.order]
    );

    const topics_tmp = paginate(sorted, currentPage, pageSize);

    return { totalCount: categoryFiltered.length, data: topics_tmp };
  };

  changePageSize = (e) => {
    this.setState({ pageSize: e.value, currentPage: 1 });
  };

  render() {
    const { length: count } = this.state.categories;
    const { pageSize, currentPage, sortColumn } = this.state;

    if (this.state.isLoading)
      return (
        <div className="text-center p-3">
          {" "}
          <span className="spinner-border spinner-border-lg align-center"></span>{" "}
        </div>
      );

    if (count === 0) return <p>There are no Categories in the database</p>;

    const selectOptions = [
      { value: 5, label: 5 },
      { value: 10, label: 10 },
      { value: 15, lable: 15 },
      { value: 20, label: 20 },
      { value: 25, label: 25 },
      { value: 50, label: 50 },
    ];

    const { totalCount, data: categories } = this.getPagedData();
    const tableCaption =
      "Showing " + totalCount + " categories in the database.";

    return (
      <div className="container shadow border border-1 border-light rounded-3 pt-2 pb-2 mt-2">
        <div className="d-flex flex-row">
          <div className="col-2 ">
            <BackButton />
            {this.state.rbac.hasElevatedPrivs() ?
              ( <Link to="/categoryForm/new">
                  <button className="btn btn-primary btn-sm ms-2">Add Topic</button>
                </Link>
              ) : null  
            }
          </div>
          <div className="col-10">
            <div className="typeahead form-floating ">
              <div className="d-flex justify-content-start">
                <button
                  className="btn btn-sm btn-secondary me-1"
                  onClick={() => {
                    this.titleRef.current.clear();
                    this.setState({ selectedCategory: {} });
                  }}
                >
                  Clear
                </button>
                <Typeahead
                  id="topicFilter"
                  ref={this.titleRef}
                  labelKey={(option) => `${option.title}`}
                  placeholder="Filter by Title"
                  minLength={2}
                  size="sm"
                  options={this.state.categories}
                  onChange={(selected) => {
                    this.handleCategorySelect(selected[0]);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <h2 className="display-6 mt-2">Topics</h2>
        <div className="row">
          <div className="d-inline-flex pe-2">
            <CategoriesTable
              categories={categories}
              sortColumn={sortColumn}
              onDelete={this.handleDelete}
              onSort={this.handleSort}
              caption={tableCaption}
            />
          </div>
          <div className="d-inline-flex pe-2">
            <Pagination
              itemsCount={totalCount}
              pageSize={this.state.pageSize}
              currentPage={this.state.currentPage}
              onPageChange={this.handlePageChange}
            />
            <Select
              className="ms-2"
              id="pgSize"
              options={selectOptions}
              onChange={this.changePageSize.bind(this)}
              placeholder="Number of Items / Page"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Categories;
