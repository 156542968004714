import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import BackButton from "../common/backButtion";
import authService from "../../services/auth.service";
import rbacService from "../../services/rbacService";

import { getEnum2, getPocOrders, removePocOrder } from "../../services/pocOrderService";
import PocOrdersTable from "./pocOrdersTable";

class PocUserOrders extends Component {
  constructor(props) {
    super(props);
    this.pocOrderRef = React.createRef();
  }
  state = {
    pocOrders: [],
    orderStatuses: [],
    isLoading: false,
    selectedpocOrder: {},
    selectedStatus: null,
    currentUser: null,
    rbac: null,
    currentPage: 1,
    pageSize: 10,
    sortColumn: { path: "name", order: "asc"},
   // showBackButton: this.props.showBackButton,
  };

  async componentDidMount() {
    this.setState({ isLoading: true });

    /*
    if(this.state.showBackButton === null) {
      this.setState({ showBackButton: true});
    }
    */
    const localOrderStatuses = await getEnum2();
    const cu = authService.getCurrentUser();
    const localrbac = new rbacService(cu);

    const localPocOrders = await getPocOrders();
    //filter open orders by reseller ID
    const ourPocOrders = localPocOrders.filter((po) => (po.resellerId === cu.resellerId));

    //console.log(localPocOrders);
    //console.log(ourPocOrders);

    this.setState({ rbac: localrbac, 
                    currentUser: cu, 
                    pocOrders: ourPocOrders, 
                    orderStatuses: localOrderStatuses,
                    isLoading: false});
  };

  /*
  filterType = (e) => {
    console.log(e.target.value);
    this.setState({selectedType: e.target.value});
  }
  */

  filterStatus = (e) => {
    console.log(e.target.value);
    this.setState({ selectedStatus: e.target.value});
  }

  handlepocOrderSelect = (pocOrder) => {
    this.setState({ selectedpocOrder: pocOrder, currentPage: 1 });
  };

  handleDelete = async (pocOrder) => {
    const deleted = await removePocOrder(pocOrder.id);
    if (deleted.status === 200) {
      const pocOrders_tmp = this.state.pocOrders.filter((i) => i.id !== pocOrder.id);
      this.setState({ pocOrders: pocOrders_tmp });
    }
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  
  getPagedData = () => {
    const { selectedpocOrder, pageSize, currentPage, sortColumn, pocOrders, selectedStatus, selectedType } =
      this.state;

    const pocOrderfiltered =
      selectedpocOrder && selectedpocOrder.id
        ? pocOrders.filter((v) => v.id === selectedpocOrder.id)
        : pocOrders;

    // handle filter by type
    let itemTypeFiltered = "";
    if(selectedType === "All Item Types") {
      itemTypeFiltered = pocOrderfiltered;
    } else {
      itemTypeFiltered = 
        selectedType ? pocOrderfiltered.filter((v) => v.type === selectedType)
        : pocOrderfiltered;
    }

    // handle filter by type
    let itemStatusFiltered = "";
    if(selectedStatus === "All Item Statuses"){
      itemStatusFiltered =  itemTypeFiltered;
    } else {
      itemStatusFiltered = 
        selectedStatus ? pocOrderfiltered.filter((v) => v.status === selectedStatus)
        : itemTypeFiltered;
    }

    const sorted = _.orderBy(
      itemStatusFiltered,
      [sortColumn.path],
      [sortColumn.order]
    );

    const pocOrders_tmp = paginate(sorted, currentPage, pageSize);

    return { totalCount: itemStatusFiltered.length, data: pocOrders_tmp };
  };

  
  changePageSize = (e) => {
    this.setState({ pageSize: e.value, currentPage: 1 });
  };

  render() {
    const { length: count } = this.state.pocOrders;
    const { pageSize, currentPage, sortColumn, orderStatuses, showBackButton } = this.state;

    
    if (this.state.isLoading)
      return (
        <div className="text-center p-3">
          {" "}
          <span className="spinner-border spinner-border-lg align-center"></span>{" "}
        </div>
      );

    if (count === 0) {
      return (
        <div className="container shadow border border-1 border-light rounded-3 pt-2 pb-2 mt-2">
          <p className="lead text-center p-2">Your Reseller has no open Proof-of-Concept Orders.</p>
        </div>
      );
    }

    const { totalCount, data: pocOrders } = this.getPagedData();
    const tableCaption = "Showing " + totalCount + " Proof-of-Concept Orders in the database.";

    const selectOptions = [
      { value: 5, label: 5 },
      { value: 10, label: 10 },
      { value: 15, label: 15 },
      { value: 20, label: 20 },
      { value: 25, label: 25 },
      { value: 50, label: 50 },
    ];

    return (
      <div className="container shadow border border-1 border-light rounded-3 pt-2 pb-2 mt-3">
      <div className="d-flex flex-row">
        <div className="col-3">
          <div className="form-group ms-2">
            <label className="text-muted ms-2" htmlFor="status">Filter by Order Status</label>
            <select
              id="status"
              className="form-select ms-2"
              onChange={this.filterStatus}
            >
              <option>{"All Item Statuses"}</option>
              {orderStatuses.map((status, i) => (
                <option key={i} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-8">
          <h2 className="display-6 mt-3 text-center">Your Open PoC Orders </h2>

        </div>
      </div>

      
      <div className="row">
        <div className="d-inline-flex pe-2">
          <PocOrdersTable
            pocOrders={pocOrders}
            sortColumn={sortColumn}
            onLike={this.handleLike}
            onDelete={this.handleDelete}
            onSort={this.handleSort}
            caption={tableCaption}
          />
        </div>
        <div className="d-inline-flex pe-2">
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
          />
          <Select
            className="ms-2"
            id="pgSize"
            options={selectOptions}
            onChange={this.changePageSize.bind(this)}
            placeholder="Number of Items / Page"
          />
        </div>
      </div>
    </div>
    );
  }
}

export default PocUserOrders;

/*
    if (count === 0) {
      return (
        <div className="container shadow border border-1 border-light rounded-3 pt-2 pb-2 mt-2">
          {showBackButton ? 
          <BackButton />
          : null }
          <p className="lead text-center p-2">Your Reseller has no open Proof-of-Concept Orders.</p>
        </div>
      );
    }
*/

/*

                <label className="text-muted mx-2" htmlFor="type">
                  <small>{"Select Item type..."}</small>
                </label>
*/

/*
return (
  <>
    <div className="container shadow border border-1 border-light rounded-3 pt-2 pb-2 mt-2">
      <BackButton />
      <center>
      <h2 className="display-6">Proof of Concept Items</h2>
      </center>
      {isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      ) : (
        pocOrders?.length > 0 && (
          <>
            <PocOrdersTable pocOrders={pocOrders} />
          </>
        )
      )}
    </div>
  </>
);
*/