import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useNavigate } from "react-router-dom";
import * as yup from "yup";

import authService from "../../services/auth.service";
import http from "../../services/httpService";

import {
  createCategory,
  getCategorybyID,
  updateCategory,
} from "../../services/categoryService";
import Input from "../common/input";
import BackButton from "../common/backButtion";
import { isDebug, logData } from "../common/helpers";

export default function CategoryForm() {
  const [fetchedCategory, setFetchedCategory] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [errMsg, setErrMsg] = useState();

  const { categoryId } = useParams();
  const navigate = useNavigate();
  const currentUser = authService.getCurrentUser();

  if (isDebug) logData(currentUser);

  const isNewCategory = categoryId.toString() === "new" ? true : false;
  const submitButtonLabel = isNewCategory ? "Create" : "Update";
  const pageTitle = isNewCategory ? "Create New Category" : "Update Category";

  if (isDebug) logData(`isNewCategory = ${isNewCategory}`);
  /*
   */
  const schema = yup.object().shape({
    title: yup.string().required().min(2),
    description: yup.string().required().min(4),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const handleUpdate = (data) => {
    data.userId = currentUser.id;

    if (isNewCategory) {
      // Creating new category entry
      const doCreate = async () => {
        const createResponse = await createCategory(data);
        if (isDebug) {
          logData(createResponse);
          logData(createResponse.status);
        }
        if (createResponse.status === 201) {
          // HTTP POST seem to return 201 on success
          setSuccessful(true);
        } else {
          setHasError(true);
          setErrMsg("Returned error code: " + createResponse.status);
        }
      };
      doCreate().catch((e) => http.handleError(e));
    } else {
      // updating an existing item
      data.id = categoryId;
      const pushUpdate = async () => {
        const updateResponse = await updateCategory(categoryId, data);
        console.log(updateResponse);
        console.log(updateResponse.status);
        if (updateResponse.status >= 200 && updateResponse.status < 300) {
          // HTTP PUT seem to return 201 on success
          setSuccessful(true);
        }
      };
      pushUpdate().catch((e) => http.handleError(e));
    }
  };

  // Runs after first render (when component mounts, sorta)
  useEffect(() => {
    setIsLoading(true);

    if (!isNewCategory) {
      const getCategoryInfo = async () => {
        const category = await getCategorybyID(categoryId);
        setFetchedCategory(category);
      };
      getCategoryInfo().catch(console.error);
      setIsLoading(false);
    } else {
      // only Admins can use this form to create a new category
      if (!currentUser.roleId > 1) {
        setHasError(true);
        setErrMsg("Only Admins can create new users!");
      }
      /*
       */
    }
    setIsLoading(false);
  }, []);

  /* this useEffect is called only when we have 
  fetched a user from the backend */
  useEffect(() => {
    if (isDebug) logData("Fetched Category: " + fetchedCategory);

    if (fetchedCategory !== null) {
      setValue("description", fetchedCategory.description, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("title", fetchedCategory.title, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [fetchedCategory]);

  /* This useEffect is only when successful submission */
  useEffect(() => {
    if (successful) {
      //navigate("/users");
      navigate(-1);
    }
  }, [successful]);

  return (
    <React.Fragment>
      {hasError ? (
        alert(errMsg)
      ) : isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      ) : (
        <div className="container shadow border border-2 border-light rounded-3 mt-4">
          <div className="row">
            <div className="col-2 mt-2">
              <BackButton />
            </div>
            <div className="col-10">
              <h2 className="display-6">{pageTitle}</h2>
            </div>
          </div>

          <form onSubmit={handleSubmit(handleUpdate)} className="p-2">
            <div className="form-group m-2">
              <div className="row justify-content-lg-center">
                <div className="col-md-4">
                  <Input
                    register={register}
                    errors={errors}
                    fieldName="title"
                    label="Title"
                  />
                </div>
                <div className="col-md-8">
                  <Input
                    register={register}
                    errors={errors}
                    fieldName="description"
                    label="Description"
                  />
                </div>
              </div>
            </div>

            <div className="form-group m-2">
              <div className="row justify-content-lg-center"></div>
            </div>

            <div className="row justify-content-lg-center">
              <div className="col"></div>
              <div className="col">
                <center>
                  <div id="4" className="form-group m-2">
                    <input
                      className="btn btn-sm btn-primary me-2"
                      type="submit"
                      value={submitButtonLabel}
                    />
                    <input
                      className="btn btn-sm btn-danger"
                      type="button"
                      value="Cancel"
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                  </div>
                  {successful && (
                    <div className="alert alert-success">
                      Update Successful!
                    </div>
                  )}
                </center>
              </div>
              <div className="col"></div>
            </div>
          </form>
        </div>
      )}
    </React.Fragment>
  );
}
