import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useNavigate } from "react-router-dom";
import * as yup from "yup";
import _ from "lodash";

import authService from "../../services/auth.service";
import rbacService from "../../services/rbacService";
import http from "../../services/httpService";

import {
  createPocKit,
  getEnum,
  getPocKitbyID,
  updatePocKit,
} from "../../services/pocKitService";
import Input from "../common/input";
import { isDebug, logData } from "../common/helpers";
import { getVendors } from "../../services/vendorService";

export default function PocKitForm() {
  const [fetchedPocKit, setFetchedPocKit] = useState(null);
  const [pocKitStatuses, setPocKitStatuses] = useState([]);
  const [vendors, setVendors] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [errMsg, setErrMsg] = useState();

  const { pocKitId } = useParams();
  const navigate = useNavigate();

  const currentUser = authService.getCurrentUser();
  const rbac = new rbacService(currentUser);

  const isNewPocKit = pocKitId.toString() === "new" ? true : false;
  const submitButtonLabel = isNewPocKit ? "Create" : "Update";

  //if (isDebug) logData(`isNewPocKit = ${isNewPocKit}`);
  /*
   */
  const schema = yup.object().shape({
    description: yup.string().required().min(4),
    name: yup.string().required().min(2),
    vendorId: yup.number().required().positive().integer(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const handleUpdate = (data) => {
    data.userId = currentUser.id;

    if (isDebug) {
      console.log("Handling Create / Update .... data listed below");
      console.log(data);
    }

    // setting a default value for status
    if(data.status === "") data.status = "Available";

    if (isNewPocKit) {
      // Creating new pocKit entry
      const doCreate = async () => {
        const createResponse = await createPocKit(data);
        if (isDebug) {
          logData(createResponse);
          logData(createResponse.status);
        }
        if (createResponse.status === 201) {
          // HTTP POST seem to return 201 on success
          setSuccessful(true);
        } else {
          setHasError(true);
          setErrMsg("Returned error code: " + createResponse.status);
        }
      };
      doCreate().catch((e) => http.handleError(e));
    } else {
      // updating an existing item
      data.id = pocKitId;
      const pushUpdate = async () => {
        const updateResponse = await updatePocKit(pocKitId, data);
        console.log(updateResponse);
        console.log(updateResponse.status);
        if (updateResponse.status === 201) {
          // HTTP PUT seem to return 201 on success
          setSuccessful(true);
        }
      };
      //pushUpdate().catch((e) => alert(e));
      pushUpdate().catch((e) => http.handleError(e));
    }
  };

  // Runs after first render (when component mounts, sorta)
  useEffect(() => {
    setIsLoading(true);

    const getStatuses = async () => {
      const pkStatuses = await getEnum();
      setPocKitStatuses(pkStatuses);
    };
    getStatuses().catch(console.error);

    const getVendorList = async () => {
      const localVendors = await getVendors();
      const sortedVs = _.orderBy(localVendors, ["name"], "asc");

      //console.log(localVendors);
      //console.log(sortedVs);
      setVendors(sortedVs);
    }
    getVendorList().catch(console.error);

    if (!isNewPocKit) {
      const getPocKitInfo = async () => {
        const pocKit = await getPocKitbyID(pocKitId);
        setFetchedPocKit(pocKit);
        console.log(pocKit);
      };

      getPocKitInfo().catch(console.error);
      setIsLoading(false);
    } 
    
    setIsLoading(false);
  }, []);

  /* this useEffect is called only when we have 
  fetched a user from the backend */
  useEffect(() => {
    //if (isDebug) logData("Fetched PocKit: " + fetchedPocKit);

    if(fetchedPocKit !== null){
      setValue("name", fetchedPocKit.name, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("description", fetchedPocKit.description, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("status", fetchedPocKit.status, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("vendorId", fetchedPocKit.vendorId, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [fetchedPocKit]);

  /* This useEffect is only when successful submission */
  useEffect(() => {
    if (successful) {
      //navigate("/users");
      navigate(-1);
    }
  }, [successful]);

  return (
    <React.Fragment>
      {hasError ? (
        alert(errMsg)
      ) : isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      ) : (
        <div className="container shadow border border-2 border-light rounded-3 mt-4">
          <form onSubmit={handleSubmit(handleUpdate)} className="p-2">
            <div className="row justify-content-lg-center">
              <div className="col-4">
                <div className="form-group m-2">
                  <label className="text-muted mb-2" htmlFor="name">Name</label>
                  <input className="form-control" {...register("name")} id="name"/>
                  {errors["name"] && (
                    <div className="alert alert-danger m-2">
                      {errors["name"].message}
                    </div>
                  )}
                </div>
              </div>
                
              <div className="col-4">
              <div className="form-group m-2">
                  <label className="text-muted mx-2" htmlFor="vendorId">
                    <small>{"Select Vendor..."}</small>
                  </label>
                  <select
                    {...register("vendorId", {
                      onChange: (e) => {
                        setValue("vendorId", e.target.value);
                      },
                    })}
                    id="vendorId"
                    className="form-select m-2"
                    value={fetchedPocKit?.vendorId ? fetchedPocKit.vendorId : null}
                  >
                    <option key="0" value="">
                      {" "}
                    </option>
                    {vendors.map((vendor) => (
                      <option key={vendor.id} value={vendor.id}>
                        {vendor.name}
                      </option>
                    ))}
                  </select>
                  {errors["vendorId"] && (
                    <div className="alert alert-danger">
                      {errors["vendorId"].message}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-4">
                <div className="form-group m-2">
                  <label className="text-muted mx-2" htmlFor="status">
                    <small>{"Select Kit Status..."}</small>
                  </label>
                  <select
                    {...register("status", {
                      onChange: (e) => {
                        setValue("status", e.target.value);
                      },
                    })}
                    id="status"
                    className="form-select m-2"
                  >
                    {pocKitStatuses.map((status, i) => (
                      <option key={i} value={status}>
                        {status}
                      </option>
                    ))}
                  </select>
                  {errors["status"] && (
                    <div className="alert alert-danger">
                      {errors["status"].message}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="row justify-content-lg-center">
              <div className="col-6">
                <div className="form-group m-2">
                  <label className="text-muted mb-2" htmlFor="weight">Shipping Weight (in whole LBs)</label>
                  <input className="form-control" {...register("weight")} id="weight"/>
                  {errors["weight"] && (
                    <div className="alert alert-danger m-2">
                      {errors["weight"].message}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group m-2">
                  <label className="text-muted mb-2" htmlFor="dimensions">Dimensions (W x H x L) in inches</label>
                  <input className="form-control" {...register("dimensions")} id="dimensions"/>
                  {errors["dimensions"] && (
                    <div className="alert alert-danger m-2">
                      {errors["dimensions"].message}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="row justify-content-lg-center">
              <div className="col-11">
                <label className="text-muted" htmlFor="description">Description</label>
                <textarea
                  {...register("description")}
                  className="form-control"
                  rows="5"
                />
              </div>
            </div>

            <div className="row justify-content-lg-center">
              <div className="col"></div>
              <div className="col">
                <center>
                  <div id="4" className="form-group m-2">
                    <input
                      className="btn btn-sm btn-primary me-2"
                      type="submit"
                      value={submitButtonLabel}
                    />
                    <input
                      className="btn btn-sm btn-danger"
                      type="button"
                      value="Cancel"
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                  </div>
                  {successful && (
                    <div className="alert alert-success">
                      Update Successful!
                    </div>
                  )}
                </center>
              </div>
              <div className="col"></div>
            </div>
          </form>
        </div>
      )}
    </React.Fragment>
  );
}


/*

*/