import React, { Component } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import Table from "../common/table";

class InstructorReservationsTable extends Component {
  state = {
    reservations: [],
    currentPage: 1,
    pageSize: 4,
    sortColumn: { path: "name", order: "asc" },
  };

  componentDidMount() {
    const { reservations } = this.props;
    this.setState({ reservations });
  }

  columns = [
    { path: "demo_name", label: "Demo" },
    { path: "type", label: "Type" },
    {
      path: "description",
      label: "Reason",
      content: (reservation) => (
        <Link to={`/reservations/${reservation.id}`}>
          {reservation.description}
        </Link>
      ),
    },
    { path: "startDate", label: "Scheduled Start",
      content: (reservation) =>
        reservation.startDate
        ? format(new Date(reservation.startDate), "Pp")
        : "Not Yet Approved",
    },
    {
      key: "approve",
      content: (reservation) => (
        <Link to={`/reservationApproval/${reservation.id}`}>
          <button className="btn btn-sm btn-primary">
            {reservation.startDate ? "Change" : "Approve"}
          </button>
        </Link>
      ),
    },
    /*
     */
  ];

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  render() {
    const { reservations, caption } = this.props;

    console.log(reservations);
    if (reservations.length === 0) {
      return;
    } else {
      return (
        <Table
          columns={this.columns}
          data={reservations}
          sortColumn={this.state.sortColumn}
          onSort={this.handleSort}
          caption={caption}
        />
      );
    }
  }
}

export default InstructorReservationsTable;
