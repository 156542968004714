import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useNavigate } from "react-router-dom";
import * as yup from "yup";
import _ from "lodash";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";

import authService from "../../services/auth.service";
import rbacService from "../../services/rbacService";
import http from "../../services/httpService";
import { getResellers, getResellerInfo } from "../../services/resellerService";
import {
  createPocOrder,
  getEnum2,
  getPocOrderbyID,
  updatePocOrder,
} from "../../services/pocOrderService";
import { getPocKits } from "../../services/pocKitService";

import { isDebug, logData } from "../common/helpers";
import BackButton from "../common/backButtion";
import { getVendors } from "../../services/vendorService";

export default function PocOrderForm() {
  const [fetchedPocOrder, setFetchedPocOrder] = useState(null);
  const [pocOrderStatuses, setPocOrderStatuses] = useState([]);
  const [kits, setKits] = useState([]);
  const [resellers, setResellers] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [kitsEnabled, setKitsEnabled] = useState(false);
  const [shipDate, setShipDate] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [receiveDate, setReceiveDate] = useState(null);
  const [refreshDate, setRefreshDate] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [errMsg, setErrMsg] = useState();

  const { pocOrderId } = useParams();
  const navigate = useNavigate();

  const currentUser = authService.getCurrentUser();
  const rbac = new rbacService(currentUser);

  const isNewPocOrder = pocOrderId.toString() === "new" ? true : false;
  let submitButtonLabel = ""; 
  let pageTitle = ""; 

  if(rbac.isUser()) {
    submitButtonLabel = isNewPocOrder ? "Request" : "Update Request";
    pageTitle = isNewPocOrder ? "Create New Request" : "Update PoC Request";
  } else {
    submitButtonLabel = isNewPocOrder ? "Create" : "Update";
    pageTitle = isNewPocOrder
    ? "Create New Order"
    : "Update Order Information";

  }

  //if (isDebug) logData(`isNewPocOrder = ${isNewPocOrder}`);
  /*
   */
  const schema = yup.object().shape({
    description: yup.string().required().min(2),
    name: yup.string().required().min(2),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const handleUpdate = (data) => {
    data.userId = currentUser.id;
    //need to set default values if current user is a User 
    if(rbac.isUser()){
      data.requestorName = currentUser.fullName;
      data.status = "New";
      data.resellerId = currentUser.resellerId;
    }

    if (isDebug) {
      logData("Handling Create / Update ....");
      console.log(data);
    }

    if (isNewPocOrder) {
      // Creating new pocOrder entry
      const doCreate = async () => {
        const createResponse = await createPocOrder(data);
        if (isDebug) {
          logData(createResponse);
          logData(createResponse.status);
        }
        if (createResponse.status === 201) {
          // HTTP POST seem to return 201 on success
          setSuccessful(true);
        } else {
          setHasError(true);
          setErrMsg("Returned error code: " + createResponse.status);
        }
      };
      doCreate().catch((e) => http.handleError(e));
    } else {
      // updating an existing order
      if(data.shipDate === "") delete data.shipDate ;
      if(data.dueDate === "") delete data.dueDate ;
      if(data.receivedDate === "") delete data.receivedDate ;
      if(data.refreshDate === "") delete data.refreshDate ;
      if(data.weight === "") delete data.weight ;
      data.id =  pocOrderId;
      data.pocOrderId = parseInt(pocOrderId); // pocOrderId;
      console.log(data);
      const pushUpdate = async () => {
        const updateResponse = await updatePocOrder(pocOrderId, data);
        console.log(updateResponse);
        console.log(updateResponse.status);
        if (updateResponse.status === 201) {
          // HTTP PUT seem to return 201 on success
          setSuccessful(true);
        }
      };
      //pushUpdate().catch((e) => alert(e));
      pushUpdate().catch((e) => http.handleError(e));
    }

  };

  // Runs after first render (when component mounts, sorta)
  useEffect(() => {
    setIsLoading(true);

    const getStatuses = async () => {
      const pkStatuses = await getEnum2();
      setPocOrderStatuses(pkStatuses);
    };
    getStatuses().catch(console.error);

    const getResellerList = async () => {
      if(rbac.isUser()){
        const resellerList = await getResellerInfo(currentUser.resellerId);
        console.log(resellerList);
        setResellers(resellerList);
      } else {
        const resellerList = await getResellers();
        const sortedResellers = _.orderBy(resellerList, ["name"], "asc");
        setResellers(sortedResellers);
      }
    };
    getResellerList().catch(console.error);

    const getKitList = async () => {
      const localKits = await getPocKits();

      if (!isNewPocOrder) {
        setKits(localKits);
      } else {
        // for 'new' orders, filter out
        // all kits that aren't available
        const availKits = _.filter(localKits, function(k) {return k.status === "Available"}); 
        // if the current user is a user role, then don't show 'Unkitted' kits
        if(rbac.isUser()) {
          const userKits = _.filter(availKits, function(k) {return !k.name.includes("Unkitted")});
          setKits(userKits);
        } else {
          setKits(availKits);
        }
      };
    };
    getKitList().catch(console.error);

    if (!isNewPocOrder) {
      const getPocOrderInfo = async () => {
        const pocOrder = await getPocOrderbyID(pocOrderId);
        setFetchedPocOrder(pocOrder);
      };

      getPocOrderInfo().catch(console.error);
    } else {
      //this is a new order request
      const getVendorsList = async () => {
        let hasPoc = [];
        const vendorsList = await getVendors();
        console.log(vendorsList);
        vendorsList.map((v) => 
          v.pocKits.length > 0 && hasPoc.push(v)
        );
        console.log(hasPoc);
        //const sortedVendors = _.orderBy(vendorsList, ["name"], "asc");
        const sortedVendors = _.orderBy(hasPoc, ["name"], "asc");
        setVendors(sortedVendors);
      }
      getVendorsList().catch(console.error);
    }
   
    setIsLoading(false);
  }, []);

  /* this useEffect is called only when we have 
  fetched a user from the backend */
  useEffect(() => {
    if(fetchedPocOrder !== null){

      console.log(fetchedPocOrder);

      setValue("name", fetchedPocOrder.name, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("endUserName", fetchedPocOrder.endUserName, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("description", fetchedPocOrder.description, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("status", fetchedPocOrder.status, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("weight", fetchedPocOrder.weight, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("dimensions", fetchedPocOrder.dimensions, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("frtIn", fetchedPocOrder.frtIn, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("frtOut", fetchedPocOrder.frtOut, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("refreshDate", fetchedPocOrder.refreshDate, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("receiveDate", fetchedPocOrder.receiveDate, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("dueDate", fetchedPocOrder.dueDate, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("shipDate", fetchedPocOrder.shipDate, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("notes", fetchedPocOrder.notes, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("requestorName", fetchedPocOrder.requestorName, {
        shouldValidate: true,
        shouldDirty: true,
      });

      if(fetchedPocOrder.pocKits.length > 0 ){
        setValue("pocKitId", fetchedPocOrder.pocKits[0].id, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }

      setValue("resellerId", fetchedPocOrder.resellerId, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("ticketNum", fetchedPocOrder.ticketNum, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [fetchedPocOrder]);

  /* This useEffect is only when successful submission */
  useEffect(() => {
    if (successful) {
      //navigate("/users");
      navigate(-1);
    }
  }, [successful]);

  return (
    <React.Fragment>
      {hasError ? (
        alert(errMsg)
      ) : isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      ) : (
        <div className="container shadow border border-2 border-light rounded-3 mt-4">
          <form onSubmit={handleSubmit(handleUpdate)} className="p-2">
          <div className="row justify-content-lg-center">
              <div className="col-4 p-3">
                <BackButton />
              </div>
              <div className="col-8">
                <h2 className="display-6">{pageTitle} </h2>
              </div>
            </div>

            <div className="row justify-content-lg-center">
              <div className="col-6">
                <div className="form-group m-2">
                  <label className="text-muted mb-2" htmlFor="name">Order Name</label>
                  <input className="form-control" {...register("name")} id="name"/>
                  {errors["name"] && (
                    <div className="alert alert-danger m-2">
                      {errors["name"].message}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group m-2">
                  <label className="text-muted mb-2" htmlFor="endUserName">End User Name</label>
                  <input className="form-control" {...register("endUserName")} id="endUserName"/>
                  {errors["endUserName"] && (
                    <div className="alert alert-danger m-2">
                      {errors["endUserName"].message}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="row justify-content-lg-center">
              <div className="col-6">
                <div className="form-group m-2">
                  <label className="text-muted mb-2" htmlFor="requestorName">Requestor Name</label>
                  {rbac.isUser() ? (
                    <input className="form-control" {...register("requestorName")} id="requestorName" disabled placeholder={currentUser.fullName}/>
                  ):(
                    <input className="form-control" {...register("requestorName")} id="requestorName"/>
                  )}
                  {errors["requestorName"] && (
                    <div className="alert alert-danger m-2">
                      {errors["requestorName"].message}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-6">
                {rbac.isUser() ? (
                  <div className="form-group m-2">
                  <label className="text-muted mb-2 ms-2" htmlFor="resellerId">
                    Requesting Reseller...
                  </label>
                  <br />
                  <input 
                    {...register("resellerId")} 
                    type="text" 
                    className="form-control" 
                    id="resellerId" 
                    disabled
                    placeholder={resellers.name}
                    />
                  </div>
                ) : (
                   <>
                    <label className="text-muted mb-2 ms-2" htmlFor="resellerId">
                      {"Requesting Reseller..."}
                    </label>
                    <select
                      {...register("resellerId", {
                        onChange: (e) => {
                          setValue("resellerId", e.target.value);
                        },
                      })}
                      id="resellerId"
                      className="form-select m-2"
                    >
                      <option key="0" value="">
                        {" "}
                      </option>
                      {resellers.map((reseller) => (
                        <option key={reseller.id} value={reseller.id}>
                          {reseller.name}
                        </option>
                      ))}
                    </select>
                    {errors["resellerId"] && (
                      <div className="alert alert-danger">
                        {errors["resellerId"].message}
                      </div>
                    )}
                   </>
                )}
              </div>
            </div>

            <div className="row justify-content-lg-center">
              <div className="col-4">
                <div className="form-group mt-2">
                  
                <label className="text-muted mx-2" htmlFor="vendorId">
                    {"Select Vendor"}
                  </label>
                  <select
                    {...register("vendorId", {
                      onChange: (e) => {
                        setValue("vendorId", e.target.value);
                        setKitsEnabled(true);
                        const v = _.filter(vendors, {'id': parseInt(e.target.value)});
                        const userKits = _.filter(v[0].pocKits, function(k) {return !k.name.includes("Unkitted")});
                        //console.log(v);
                        // _.filter call above returns an array having one element
                        //console.log(v[0].pocKits);
                        //setKits(v[0].pocKits);
                        //console.log(userKits);
                        setKits(userKits);
                      },
                    })}
                    id="vendorId"
                    className="form-select m-2"
                  >
                    <option key="0" value="0">Please choose a Vendor</option>
                    {vendors.map((v) => (
                      <option key={v.id} value={v.id}>
                        {v.name}
                      </option>
                    ))}
                  </select>
                  {errors["vendorId"] && (
                    <div className="alert alert-danger">
                      {errors["vendorId"].message}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-4">
                <div className="form-group mt-2">
                  <label className="text-muted mx-2" htmlFor="pocKitId">
                    {"Select Kit"}
                  </label>
                  <select 
                    {...register("pocKitId", {
                      onChange: (e) => {
                        setValue("pocKitId", e.target.value);
                      },
                    })}
                    id="pocKitId"
                    className="form-select m-2"
                  >
                    <option key="0" value="0">Please choose a Kit</option>

                    { kitsEnabled && kits.map((kit) => (
                      <option key={kit.id} value={kit.id}>
                        {kit.name}
                      </option>
                    ))}
                  </select>
                  {errors["pocKitId"] && (
                    <div className="alert alert-danger">
                      {errors["pocKitId"].message}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-4">
              <div className="form-group mt-2">
                  <label className="text-muted mx-2" htmlFor="status">
                    {"Select Order Status..."}
                  </label>
                  { rbac.isUser() ? (
                    <select {...register("status")} id="status" className="form-select m-2" disabled>
                      {pocOrderStatuses.map((status, i) => (
                        <option key={i} value={status}>
                          {status}
                        </option>
                      ))}
                    </select>
                  ) : (

                  <select
                    {...register("status", {
                      onChange: (e) => {
                        setValue("status", e.target.value);
                      },
                    })}
                    id="status"
                    className="form-select m-2"
                  >
                    {pocOrderStatuses.map((status, i) => (
                      <option key={i} value={status}>
                        {status}
                      </option>
                    ))}
                  </select>
                  )}  
                  {errors["status"] && (
                    <div className="alert alert-danger">
                      {errors["status"].message}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="row justify-content-lg-center">
              <div className="col-11">
                <label className="text-muted mt-2" htmlFor="description">Description</label>
                <textarea
                  {...register("description")}
                  className="form-control mt-2"
                  rows="5"
                />
              </div>
            </div>

            {!rbac.isUser() ? ( // Need to add date picker fields and other fields for admins
            <>
              <div className="row justify-content-lg-center">
                <div className="col-4">
                  <div className="form-group m-2">
                    <label className="text-muted mb-2" htmlFor="ticketNum">Jira Ticket Number (only the TS-xxxx part) </label>
                    <input className="form-control" {...register("ticketNum")} id="ticketNum"/>
                    {errors["ticketNum"] && (
                      <div className="alert alert-danger m-2">
                        {errors["ticketNum"].message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group m-2">
                    <label className="text-muted mb-2" htmlFor="oppNumber">Opportunity Number</label>
                    <input className="form-control" {...register("oppNumber")} id="oppNumber"/>
                    {errors["oppNumber"] && (
                      <div className="alert alert-danger m-2">
                        {errors["oppNumber"].message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group m-2">
                    <label className="text-muted mb-2" htmlFor="soNumber">Sales Order Number</label>
                    <input className="form-control" {...register("soNumber")} id="soNumber"/>
                    {errors["soNumber"] && (
                      <div className="alert alert-danger m-2">
                        {errors["soNumber"].message}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row justify-content-lg-center">
                <div className="col-3">
                  <div className="form-group m-2">
                    <label className="text-muted mb-2" htmlFor="weight">Shipping Weight (in whole LBs)</label>
                    <input className="form-control" {...register("weight")} id="weight"/>
                    {errors["weight"] && (
                      <div className="alert alert-danger m-2">
                        {errors["weight"].message}
                      </div>
                    )}
                  </div>
                </div>
                
                <div className="col-3">
                  <div className="form-group m-2">
                    <label className="text-muted mb-2" htmlFor="dimensions">Dimensions (W x H x L) in inches </label>
                    <input className="form-control" {...register("dimensions")} id="dimensions"/>
                    {errors["dimensions"] && (
                      <div className="alert alert-danger m-2">
                        {errors["dimensions"].message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group m-2">
                    <label className="text-muted mb-2" htmlFor="frtIn">Frieght Cost In</label>
                    <input className="form-control" {...register("frtIn")} id="frtIn"/>
                    {errors["frtIn"] && (
                      <div className="alert alert-danger m-2">
                        {errors["frtIn"].message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group m-2">
                    <label className="text-muted mb-2" htmlFor="frtOut">Frieght Cost Out</label>
                    <input className="form-control" {...register("frtOut")} id="frtOut"/>
                    {errors["frtOut"] && (
                      <div className="alert alert-danger m-2">
                        {errors["frtOut"].message}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              
              <div className="row justify-content-lg-center">
                
              <div className="col-3">
                <div className="form-group m-2">
                  <label className="text-muted mb-2" >Shipping Date</label>
                  <Controller
                    control={control}
                    name="shipDate"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ReactDatePicker
                        selected={value}
                        isClearable
                        onChange={onChange}
                        onBlur={onBlur}
                        placeholderText="Date Kit Ship to Partner"
                        dateFormat="MM/dd/yyyy"
                      />
                    )}
                  />
                </div>
              </div>

              <div className="col-3">
                <div className="form-group m-2">
                  <label className="text-muted mb-2" >Due Back By</label>
                  <Controller
                    control={control}
                    name="dueDate"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ReactDatePicker
                        selected={value}
                        isClearable
                        onChange={onChange}
                        onBlur={onBlur}
                        placeholderText="Set Date Kit is Due Back"
                        dateFormat="MM/dd/yyyy"
                      />
                    )}
                  />
                </div>
              </div>

              <div className="col-3">
                <div className="form-group m-2">
                  <label className="text-muted mb-2" htmlFor="receivedDate">Reseller Received Date</label>
                  <Controller
                    control={control}
                    name="receiveDate"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ReactDatePicker
                        selected={value}
                        isClearable
                        onChange={onChange}
                        onBlur={onBlur}
                        placeholderText="Set Reseller Receive Date"
                        dateFormat="MM/dd/yyyy"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group m-2">
                  <label className="text-muted mb-2" htmlFor="refreshDate">Refresh Date</label>
                  <Controller
                    control={control}
                    name="refreshDate"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ReactDatePicker
                        selected={value}
                        isClearable
                        onChange={onChange}
                        onBlur={onBlur}
                        placeholderText="Date Kit Refreshed"
                        dateFormat="MM/dd/yyyy"
                      />
                    )}
                  />
                </div>
              </div>
              </div>

              <div className="row justify-content-lg-center">
                <div className="col-11">
                  <label className="text-muted mt-2" htmlFor="notes">Admin Notes</label>
                  <textarea
                    {...register("notes")}
                    className="form-control mt-2"
                    rows="5"
                  />
                </div>
              </div>
            </>
            ) : null}

            <div className="row justify-content-lg-center">
              <div className="col"></div>
              <div className="col">
                <center>
                  <div id="4" className="form-group m-2">
                    <input
                      className="btn btn-sm btn-primary me-2"
                      type="submit"
                      value={submitButtonLabel}
                    />
                    <input
                      className="btn btn-sm btn-danger"
                      type="button"
                      value="Cancel"
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                  </div>
                  {successful && (
                    <div className="alert alert-success">
                      Update Successful!
                    </div>
                  )}
                </center>
              </div>
              <div className="col"></div>
            </div>
          </form>
        </div>
      )}
    </React.Fragment>
  );
}

/*

                  <div className="form-group m-2">
                    <label className="text-muted mb-2" htmlFor="shipDate">Date Kitted Shipped to Partner</label>
                    <input className="form-control" {...register("shipDate")} id="shipDate"/>
                    {errors["shipDate"] && (
                      <div className="alert alert-danger m-2">
                        {errors["shipDate"].message}
                      </div>
                    )}
                  </div>

                  <div className="col-3">
                  <div className="form-group m-2">
                    <label className="text-muted mb-2" htmlFor="dueDate">Due Back By</label>
                    <input className="form-control" {...register("dueDate")} id="dueDate"/>
                    {errors["dueDate"] && (
                      <div className="alert alert-danger m-2">
                        {errors["dueDate"].message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-3">
                  <div className="form-group m-2">
                    <label className="text-muted mb-2" htmlFor="receivedDate">Reseller Received Date</label>
                    <input className="form-control" {...register("receivedDate")} id="receivedDate"/>
                    {errors["receivedDate"] && (
                      <div className="alert alert-danger m-2">
                        {errors["receivedDate"].message}
                      </div>
                    )}
                  </div>
                </div>

                              <div className="col-3">
                <div className="form-group m-2">
                  <label className="text-muted mb-2" htmlFor="refreshDate">Refresh Date</label>
                    <input className="form-control" {...register("refreshDate")} id="refreshDate"/>
                    {errors["refreshDate"] && (
                      <div className="alert alert-danger m-2">
                        {errors["refreshDate"].message}
                      </div>
                    )}
                  </div>
              </div>
*/