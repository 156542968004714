import config from "../../config.json";

function logData(data) {
  /* Testing...  */
  console.log("-------------------------");
  console.log(data);
  console.log("-------------------------");
  /* End Testing...  */
}

function isDebug() {
  if (config.Debug === true) {
    return true;
  } else {
    return false;
  }
}

//module.exports = { logData, isDebug };
export { logData, isDebug };
