import React, { useState } from "react";

import authService from "../../services/auth.service";
import { useNavigate } from "react-router-dom";
import eventBus from "../common/eventBus";
import rbacService from "../../services/rbacService";

const imgStyleObj = {
  maxHeight: "200px",
  maxWidth: "200px",
  height: "auto",
  width: "auto",
};

function Login() {
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [message, setMessage] = useState();
  const navigate = useNavigate();

  const onChangeUsername = (e) => {
    setUserName(e.target.value);
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  //Current Issue: Front End application loads and works until login. Now when login is completed, either through auto complete or typing in account, next page is not rendered
  //and user will end back up on a refreshed login page.
  //Possible Causes:
  //1: Lost state with data and login is failing the username and password check
  //2: Front end not sending POST? Back end is not showing any communication in its terminal on login (Confirmed) (Could be due to handleSubmit being removed)
  //3: A small issue (syntax or logic) Tallon is not seeing that could be solved in 2 seconds
  //4: A large logical piece missing that needs to be implemented to handle the async request
  const handleLogin = (e) => {
    /* setMessage(""); */
    //console.log("e");
    //console.log(e);
    //console.log("userName");
    //console.log(userName);
    if(e !== undefined){
      e.preventDefault();
      if (userName !== undefined && password !== undefined) {
        //NEW: Condition needed based on React Blog article? Logically redundent since required in HTML
        authService.login(userName, password).then(
          () => {
            eventBus.dispatch("login");
            const currentUser = authService.getCurrentUser(); //Current user not being grabbed since no POST being shown on backend
            //console.log(currentUser);
            const rbac = new rbacService(currentUser);
            
            if (rbac.isAdmin()) navigate("/admin");
            if (rbac.isInstructor()) navigate("/InstructorWorkspace");
            if (rbac.isLoanerManager()) navigate("/lmdashboard");
            //if(rbac.isUser()) navigate("/demos");
            if (rbac.isUser()) navigate("/userDashboard");
          },
          (error) => {
            //Was able to keep as error messages originate from auth.service.js, although might need to implement useForm: errors to store these
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            setMessage(resMessage);
            console.log(resMessage);
          }
        );
      }
    }
    /*
    */
  };

  return (
    <div className="text-center">
      <div className="form-signin m-auto">
        <img
          src="/TDSYNNEXLogoBlackonWhite.png"
          alt="profile-img"
          style={imgStyleObj}
          className="mt-3"
        />
        <br />
        <em>
          <span className="text-dark">Solution Center Portal</span>
        </em>
        <p className="mt-5 fs-3 mb-3 fw-normal text-dark">Please sign in</p>
        <form onSubmit={handleLogin} style={{ zIndex: 3 }}>
          <div className="form-floating">
            <input
              id="userName"
              required //NEW: Requires this field before submit
              autoComplete="on" //NEW
              style={{ zIndex: 3 }}
              type="text"
              className="form-control"
              placeholder="Enter your Username"
              name="userName" //NEW: Added as there is a bug with autoComplete
              onChange={onChangeUsername} //Removed error message handling for inputs as html required field handles this through field requirement 
            />
            <label htmlFor="userName">Username</label>
          </div>

          <div className="form-floating">
            <input
              id="password"
              required //NEW: Requires this field before submit
              autoComplete="on" //NEW
              type="password"
              className="form-control"
              placeholder="Enter your password"
              name="password" //NEW: Added as there is a bug with autoComplete
              onChange={onChangePassword} //Removed error message handling for inputs as html required field handles this through field requirement 
            />
            <label htmlFor="password">Password</label>
          </div>
          {message && <div className="alert alert-danger">{message}</div>}
          <div className="form-group">
            <input className="btn btn-primary" type="submit" value="Login" />
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
