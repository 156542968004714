import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

import BackButton from "../common/backButtion";
import DeliveredServicesTable from "./deliveredServicesTable";
import {
  getDeliveredServices,
  getEnum,
} from "../../services/deliveredServicesService";
import authService from "../../services/auth.service";

export default function DeliveredServices() {
  const [deliveredServices, setDeliveredServices] = useState();
  const [filteredDeliveredServices, setFilteredDeliveredServices] = useState();
  const [deliveredServicesTypes, setDeliveredServicesTypes] = useState();
  const [selectedServiceType, setSelectedServiceType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const currentUser = authService.getCurrentUser();

  // Run once after initial render
  useEffect(() => {
    setIsLoading(true);

    const getDSTypes = async () => {
      const dsTypes = await getEnum();
      setDeliveredServicesTypes(dsTypes);
    };
    getDSTypes().catch(console.error);

    const getDSList = async () => {
      const localDS = await getDeliveredServices();
      setDeliveredServices(localDS);
      setFilteredDeliveredServices(localDS);
    };
    getDSList().catch(console.error);

    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (selectedServiceType) {
      if (selectedServiceType === "All Service Types") {
        setFilteredDeliveredServices(deliveredServices);
      } else {
        let filteredDSs = [];
        deliveredServices.map((service) => {
          if (service.type === selectedServiceType) {
            filteredDSs.push(service);
          }
        });
        setFilteredDeliveredServices(filteredDSs);
      }
    }
  }, [selectedServiceType]);

  const filterService = (e) => {
    console.log(e.target.value);
    setSelectedServiceType(e.target.value);
  };

  return (
    <>
      {isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      ) : (
        deliveredServices && (
          <div className="container">
            <div className="row">
              <div className="col mt-2">
                <BackButton />
                {currentUser.roleId <= 2 ? (
                  <Link to="/deliveredService/new">
                    <button className="btn btn-primary btn-sm mx-2">
                      Create New Service
                    </button>
                  </Link>
                ) : null}
              </div>
            </div>
            <center>
              <h2 className="display-6 mt-2">Delivered Services</h2>

              <div className="form-group m-2">
                <label className="text-muted mx-2" htmlFor="type">
                  <small>{"Select Service type..."}</small>
                </label>
                <select
                  id="type"
                  className="form-select m-2"
                  onChange={filterService}
                >
                  <option>{"All Service Types"}</option>
                  {deliveredServicesTypes.map((type, i) => (
                    <option key={i} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>
              <DeliveredServicesTable
                deliveredServices={filteredDeliveredServices}
              />
            </center>
          </div>
        )
      )}
    </>
  );
}
