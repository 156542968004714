import React from "react";

export default function IntegrationFeature() {
  return (
    <>
      <hr className="featurette-divider" />
      <div className="container">
        <div className="row featurette align-items-center">
          <div className="col-md-7">
            <h2 className="ss-featurette-heading fw-normal lh-1">
              BTO & Integration Services
            </h2>

            <p className="lead">
              Another way TD SYNNEX can help? Of course. See the list below for
              some of our best-of-breed integration and build-to-order services.
              <br />
              <br />
            </p>
          </div>
          <div className="col-md-5">
            <img src="itsupportblockimg.jpg" className="d-block w-100" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <center>
              <p className="lead">Contact Us:</p>
            </center>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <center>
              <a href="mailto:btoquotes@tdsynnex.com">
                {" "}
                <p className="lead">BTOquotes@tdsynnex.com </p>
              </a>
            </center>
          </div>
          <div className="col-6">
            <center>
              <a href="mailto:iorservices@tdsynnex.com">
                {" "}
                <p className="lead">IORservices@tdsynnex.com </p>
              </a>
            </center>
          </div>
        </div>
        <div className="row mt-2 mb-2">
          <div className="col-6">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                Desktop, Notebook, Server, and Network Hardware Integration
              </li>
              <li className="list-group-item">
                Router, Switch, and Advanced Rack Configurations
              </li>
              <li className="list-group-item">
                Software Installation and Custom Imaging
              </li>
              <li className="list-group-item">
                Mobile Activations and Phone Provisioning
              </li>
              <li className="list-group-item">
                Asset Tagging, Laser Etching, and UID Labeling
              </li>
            </ul>
          </div>
          <div className="col-6">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                Burn in, Stress, Post, and Special Testing
              </li>
              <li className="list-group-item">
                Data Capture of SN/MAC ID and IMEI
              </li>
              <li className="list-group-item">
                Repair, Refurbishment, and Recycling Services
              </li>
              <li className="list-group-item">
                Kitting, Bundling, Overpack, and Customer White Labeling
              </li>
              <li className="list-group-item">
                Printer, Peripheral, and Customized EU Set-up
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
