import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import BackButton from "../common/backButtion";
import { isDebug, logData } from "../common/helpers";
import InstructorDemosTable from "./instructorDemosTable";
import {
  getInstructorbyID,
  getInstructorbyUserID,
} from "../../services/instructorService";
import config from "../../config.json";
import InstructorReservationsTable from "./instructorReservationsTable";
import httpService from "../../services/httpService";
import authService from "../../services/auth.service";
import ButtonWL from "../common/buttonWithLink";
import ColorCard from "../common/card";

export default function InstructorWorkspace() {
  const [fetchedInstructor, setFetchedInstructor] = useState(null);
  const [fetchedReservations, setFetchedReservations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const currentUser = authService.getCurrentUser();

  console.log(currentUser);

  useEffect(() => {
    setIsLoading(true);
    if (fetchedInstructor === null) {
      let reservs = [];

      async function getInstructorData() {
        const instructorInfo = await getInstructorbyUserID(currentUser.id);
        instructorInfo.demos.map((demo) => {
          demo?.reservations.map((reservation) => {
            reservation.demo_name = demo.name;
            console.log(reservation);
            reservs.push(reservation);
          });
        });

        setFetchedInstructor(instructorInfo);
        setFetchedReservations(reservs);
      }
      getInstructorData().catch((error) => httpService.handleError(error));
    }
    console.log(fetchedInstructor);
    console.log(fetchedReservations);
    setIsLoading(false);
  }, [fetchedInstructor]);

  return (
    <React.Fragment key="8765">
      <div className="container shadow border border-1 border-light rounded-3 pt-2 pb-2 mt-2">
        <div className="d-flex flex-row">
          <div className="col-2 ">
            <BackButton />
          </div>

          <div className="col-10 ">
            <span className="fs-3 ps-2">Your Instructor Workspace</span>
          </div>
        </div>
        {isLoading ? (
          <div className="text-center p-3">
            <span className="spinner-border spinner-border-lg align-center"></span>
          </div>
        ) : fetchedInstructor ? (
          <div className="container pt-2 mt-2">
            <div className="row">
              <div className="col text-center">
                <ButtonWL
                  size="sm"
                  variant="primary"
                  value="Create New Demo"
                  link="/demoForm/new"
                />
              </div>
              <div className="col text-center">
                <ButtonWL
                  size="sm"
                  variant="primary"
                  value="View Your Profile"
                  link={`/profile`}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <ColorCard
                  title="Instructors"
                  description="See full list of current instructors"
                  link="/instructors"
                />
              </div>
              <div className="col">
                <ColorCard
                  title="Vendors"
                  description="See full list of current vendors"
                  link="/vendors"
                  color="white"
                  bgcolor="darkgoldenrod"
                />
              </div>
              <div className="col">
                <ColorCard
                  title="Reseller Partners"
                  description="See full list of current reseller partners"
                  link="/resellers"
                  color="white"
                  bgcolor="darkgreen"
                />
              </div>
              <div className="col">
                <ColorCard
                  title="ServiceSolv"
                  description="See the ServiceSolv Page"
                  link="/servicesolv"
                  color="white"
                  bgcolor="darkcyan"
                />
              </div>
            </div>
            <div>
              <InstructorDemosTable
                demos={fetchedInstructor.demos}
                caption="Your Demos..."
              />
              <InstructorReservationsTable
                reservations={fetchedReservations}
                caption="Your Reservations..."
              />
            </div>
          </div>
        ) : (
          <div>
            <h3>Instructor Not Found</h3>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
/*
        <div>
          <InstructorReservationsTable
            reservations={instructor.demos.reservations}
            caption={customCaption}
          />
        </div>
*/
/*

                <ButtonWL
                  size="sm"
                  variant="primary"
                  value="View Your Profile"
                  link={`/user/${currentUser.id}`}
                />
*/
