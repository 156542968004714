import authHeader from "./auth-header";
import http from "./httpService";

export async function getReservationCount() {
  const { data: count } = await http.get("/api/v1/reservations/getCount", {
    headers: authHeader(),
  });
  return count;
}

export async function getReservations() {
  const { data: reservations } = await http.get("/api/v1/reservations/", {
    headers: authHeader(),
  });
  return reservations;
}

export async function getReservationInfo(reservationId) {
  const { data: reservationInfo } = await http.get(
    "/api/v1/reservations/" + reservationId,
    {
      headers: authHeader(),
    }
  );
  return reservationInfo;
}

export async function getReservationByResellerID(resellerId) {
  const { data: reservations } = await http.get(
    `/api/v1/reservations/getByFKey2/${resellerId}`,
    {
      headers: authHeader(),
    }
  );

  return reservations;
}

export async function getReservationByDemoID(demoId) {
  const { data: reservationInfo } = await http.get(
    `/api/v1/reservations/getByFKey/${demoId}`,
    {
      headers: authHeader(),
    }
  );

  return reservationInfo;
}

export async function updateReservation(reservationId, reservation) {
  const response = await http.put(
    `/api/v1/reservations/${reservationId}`,
    reservation,
    {
      headers: authHeader(),
    }
  );
  return response;
}

export async function createReservation(reservation) {
  const response = await http.post(`/api/v1/reservations/`, reservation, {
    headers: authHeader(),
  });
  return response;
}

export async function removeReservation(reservationId) {
  const response = await http.delete(`/api/v1/reservations/${reservationId}`, {
    headers: authHeader(),
  });
  return response;
}
