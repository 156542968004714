import React, { Component } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import Table from "../common/table";
import { removeFeedback } from "../../services/feedbackService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";

class FeedbacksTable extends Component {
  state = {
    feedbacks: this.props.feedbacks,
    currentPage: 1,
    pageSize: 10,
    sortColumn: { path: "type", order: "asc" },
  };

  handleResolved = (feedback) => {
    console.log("Resolved Clicked");
    console.log(feedback);
  };


  static getDerivedStateFromProps(props, state) {
    if(props.feedbacks !== state.feedbacks) {
      console.log("updated props.feedbacks");
      return{feedbacks: props.feedbacks};
    }
    return null;
  }

  columns = [
    {
      path: "id",
      label: "ID",
      content: (feedback) => (
        <Link to={`/feedback/${feedback.id}`}>{feedback.id}</Link>
      ),
    },
    { path: "type", label: "Type" },
    { path: "message", label: "Message" },
    {
      path: "user.fullName",
      label: "User",
      content: (feedback) => (
        <a
          href={`mailto:${feedback.user.email}?cc=scportaladmin@tdsynnex.com&subject=Feedback%20Response`}
        >
          {feedback.user.fullName}
        </a>
      ),
    },
    {
      path: "user.reseller.name",
      label: "Account",
    },
    {
      path: "deletedAt", label: "Date Deleted",
       content: (feedback) => feedback.deletedAt ? (format(new Date(feedback.deletedAt), "Pp")) : null
    },
    {
      key: "edit",
      content: (feedback) => (
        <Link to={`/feedbacks/${feedback.id}`} >
          <FontAwesomeIcon icon={faPenToSquare} />
        </Link>
      ),
    },
    {
      key: "delete",
      content: (feedback) => (
        feedback.actionTaken !== null ?
        <button
          onClick={() => this.props.delete(feedback)}
          className="btn btn-danger btn-sm"
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </button>
        : null
      ),
    },
    /*
     */
  ];

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  render() {
    if (this.state.feedbacks?.length === 0) {
      return;
    } else {
      return (
        <Table
          columns={this.columns}
          data={this.state.feedbacks}
          sortColumn={this.state.sortColumn}
          onSort={this.handleSort}
          caption="Current Feedback Items"
        />
      );
    }
  }
}

export default FeedbacksTable;
