import http from "./httpService";
import authHeader from "./auth-header";

export async function getUserActivities() {
  const { data: userActivities } = await http.get("/api/v1/userActivities/", {
    headers: authHeader(),
  });
  return userActivities;
}

export async function getUserActivitiesSinceDate(startDate) {
  const { data: userActivities } = await http.get(
    `/api/v1/userActivities/sinceDate/${startDate}`,
    {
      headers: authHeader(),
    }
  );
  return userActivities;
}

export async function getUserActivitybyID(userActivityId) {
  const { data: userActivityInfo } = await http.get(
    `/api/v1/userActivities/${userActivityId}`,
    {
      headers: authHeader(),
    }
  );
  return userActivityInfo;
}

export async function getUserActivitybyUserID(userId) {
  const { data: userActivities } = await http.get(
    `/api/v1/userActivities/getByUserId/${userId}`,
    {
      headers: authHeader(),
    }
  );
  return userActivities;
}

export async function updateUserActivity(userActivityid, userActivity) {
  const response = await http.put(
    `/api/v1/userActivities/${userActivityid}`,
    userActivity,
    {
      headers: authHeader(),
    }
  );
  return response;
}

export async function createUserActivity(userActivity) {
  const response = await http.post("/api/v1/userActivities/", userActivity, {
    headers: authHeader(),
  });
  return response;
}

export async function removeUserActivity(userActivityId) {
  const response = await http.delete(
    `/api/v1/userActivities/${userActivityId}`,
    {
      headers: authHeader(),
    }
  );
  return response;
}
