import http from "./httpService";
import authHeader from "./auth-header";

export async function getSignupCount() {
  const { data: count } = await http.get("/api/v1/signups/getCount", {
    headers: authHeader(),
  });
  return count;
}

export async function getSignups() {
  const { data: users } = await http.get("/api/v1/signups/", {
    headers: authHeader(),
  });
  return users;
}


export async function getSignupsSinceDate(startDate) {
  const { data: signups } = await http.get(
    `/api/v1/signups/sinceDate/${startDate}`,
    {
      headers: authHeader(),
    }
  );
  return signups;
}

export async function getSignupInfo(id) {
  const { data: userInfo } = await http.get(`/api/v1/signups/${id}`, {
    headers: authHeader(),
  });
  return userInfo;
}

export async function signupUser(user) {
  const response = await http.post(`/api/signup/signup/`, user);
  return response;
}

export async function removeSignup(signupId) {
  const response = await http.delete(`/api/v1/signups/${signupId}`, {
    headers: authHeader(),
  });
  return response;
}
