import authHeader from "./auth-header";
import http from "./httpService";

export async function getRoles() {
  const { data: roles } = await http.get("/api/v1/roles/", {
    headers: authHeader(),
  });
  return roles;
}

/*
  const roles = [
    { id: 1, name: "admin", binValue: 1, description: "Site Administrator" },
    { id: 2, name: "instructor", binValue: 2, description: "Instructor" },
    { id: 3, name: "user", binValue: 4, description: "Regular User" },
  ];

*/

/*
export async function getVendorInfo(vendorId) {
  const { data: vendorInfo } = await http.get("/api/v1/vendors/" + vendorId, {
    headers: authHeader(),
  });
  return vendorInfo;
}
*/
