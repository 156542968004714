import React , { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import authService from "../../services/auth.service";
import eventBus from "./eventBus";

const NotFound = () => {
  const navigate = useNavigate();
 // eventBus.dispatch("logout");
  useEffect(() => {
    const user = authService.getCurrentUser();
    if(user) {
      authService.logout();
      eventBus.dispatch("logout");
    }
    navigate("/");
  }, []);
 /*
  return (
    <>
      <div className="text-center">
        <h1 className="display-6">Not Found</h1>
        <p>
          The resource you requested either doesn't exist or you do not have
          access to it.
        </p>
        <button
          className="btn btn-danger"
          onClick={() => {
            navigate(-1);
          }}
        >
          Go Back
        </button>
      </div>
    </>
  );
  */
};

export default NotFound;
