import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../common/table";
import Like from "../common/like";
import { isDebug, logData } from "../common/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faPen, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";

class ResellerUsersTable extends Component {
  columns = [
    {
      path: "fullName",
      label: "Full Name",
      content: (user) => (
        <Link to={`/userDetail/${user.id}`}>{user.fullName}</Link>
      ),
    },
    {
      path: "userName",
      label: "User Name",
    },
    {
      path: "email",
      label: "Email",
      content: (user) => <a href={`mailto:${user.email}`}>{user.email}</a>,
    },
    {
      path: "workPhone",
      label: "Phone",
    },
    {
      path: "role.name",
      label: "Role",
    },
    {
      key: "edit",
      content: (user) => (
        <Link to={`/user/${user.id}`}>
          <FontAwesomeIcon icon={faPenToSquare} />
        </Link>
      ),
    },
  ];

  render() {
    const { users, onSort, sortColumn, caption } = this.props;

    if (isDebug) logData(users);

    return (
      <>
        <Table
          columns={this.columns}
          data={users}
          sortColumn={sortColumn}
          onSort={onSort}
          caption={caption}
        />
      </>
    );
  }
}

export default ResellerUsersTable;
