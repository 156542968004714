import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import * as yup from "yup";
import _ from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";

import authService from "../../services/auth.service";
import authHeader from "../../services/auth-header";
import http from "../../services/httpService";
import {
  createDemo,
  createDemoWithProgressBar,
  getDemoInfo,
  updateDemo,
} from "../../services/demoService";
import { getInstructors } from "../../services/instructorService";
import { getVendors } from "../../services/vendorService";
import { getCategories } from "../../services/categoryService";
import { isDebug, logData } from "../common/helpers";
import Input from "../common/input";
import { arrayFindAll } from "../../utils/utils";
import { getCenters } from "../../services/centerService";

export default function DemoForm() {
  const [fetchedDemo, setFetchedDemo] = useState(null);

  const [demoVendors, setDemoVendors] = useState(null);
  const [demoTopics, setDemoTopics] = useState(null);
  const [demoInstructors, setDemoInstructors] = useState(null);
  const [demoCenters, setDemoCenters] = useState(null);

  const [allInstructors, setAllInstructors] = useState([]);
  const [allVendors, setAllVendors] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allCenters, setAllCenters] = useState([]);

  const [showInstructorModal, setShowInstructorModal] = useState(false);
  const [showVendorModal, setShowVendorModal] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showCenterModal, setShowCenterModal] = useState(false);

  const [showBookingsLink, setShowBookingsLink] = useState(false);
  const [hasBookingsLink, setHasBookingsLink] = useState(false);
  const [bookingsLink, setBookingsLink] = useState(null);

  const [showDemoUpload, setShowDemoUpload] = useState(false);
  const [hasVideoTitle, setHasVideoTitle] = useState(false);
  const [videoTitle, setVideoTitle] = useState(null);

  const [showRemoteVideoLink, setShowRemoteVideoLink] = useState(false);
  const [hasRemoteVideoTitle, setHasRemoteVideoTitle] = useState(false);
  const [remoteVideoTitle, setRemoteVideoTitle] = useState(null);

  const [showDTCLink, setShowDTCLink] = useState(false);
  const [hasDTCLink, setHasDTCLink] = useState(false);
  const [ibmDTCLink, setibmDTCLink] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  /* */

  const [types, setTypes] = useState([
    "Assessment",
    "Ask Me Anything",
    "Self Service",
    "Live Instructor Led",
    "Proof of Concept",
  ]);

  const [demoDuration, setDemoDuration] = useState([
    { minutes: 10, text: "10 Minutes" },
    { minutes: 15, text: "15 Minutes" },
    { minutes: 30, text: "30 Minutes" },
    { minutes: 45, text: "45 Minutes" },
    { minutes: 60, text: "1 hour" },
    { minutes: 75, text: "1 hour 15 minutes" },
    { minutes: 90, text: "1 hour 30 minutes" },
    { minutes: 105, text: "1 hour 45 minutes" },
    { minutes: 120, text: "2 hours" },
  ]);

  const { demoId } = useParams();
  const navigate = useNavigate();

  let showBookingsLinkLabel = showBookingsLink ? "Hide" : "Show";
  let showDTCLabel = showDTCLink ? "Hide" : "Show";
  let showDemoUploadLabel = showDemoUpload ? "Hide" : "Show";
  let showRemoteVideoLabel = showRemoteVideoLink ? "Hide" : "Show";

  const currentUser = authService.getCurrentUser();
  const isNewDemo = demoId.toString() === "new" ? true : false;
  const submitButtonLabel = isNewDemo ? "Create" : "Update";
  const pageTitle = isNewDemo ? "Create New Demo" : "Update Demo Information";

  const schema = yup.object().shape({
    name: yup.string().required().min(4).max(250),
    description: yup.string().required().max(64000),
    duration: yup.number().required().positive(),
    type: yup
      .string()
      .oneOf([
        "Assessment",
        "Ask Me Anything",
        "Self Service",
        "Live Instructor Led",
        "Proof of Concept",
      ]),
    imageUrl: yup.string().url().nullable(),
    externalUrl: yup.string().url().nullable(),
    remoteVideoURL: yup.string().url().nullable(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const myOnUploadProgress = (event) => {
    setProgress(Math.round((event.loaded * 100) / event.total));
  };

  async function uploadDemowithProgressBar(demo, myOnUploadProgress) {
    let config = {
      headers: authHeader(),
      onUploadProgress: myOnUploadProgress,
    };
    const response = await http.post(`/api/v1/demos/`, demo, config);
    return response;
  }

  const handleUpdate = (data) => {
    const formData = new FormData();
    let incomplete = false;

    console.log("Handling Update...");
    console.log(data);

    if (data.screenShot?.length > 0) {
      formData.append("screenShot", data.screenShot[0]);
    }

    if (data.demoVideo?.length > 0) {
      formData.append("demoVideo", data.demoVideo[0]);
    }

    if (data.imageUrl === "") {
      delete data.imageUrl;
    }
    if (data.externalUrl === "") {
      delete data.externalUrl;
    }

    let updatedCenters = [];
    console.log(data.centers);
    data.centers.map((center, i) => {
      if (center) updatedCenters.push(center.id);
      console.log(center);
    });

    if (updatedCenters.length < 1) {
      incomplete = true;
      alert("A Demo must have at least 1 Solution Center");
    } else {
      console.log(updatedCenters);
    }

    let updatedInstructors = [];
    console.log(data.instructors);
    data.instructors.map((instructor, i) => {
      if (instructor) updatedInstructors.push(instructor.id);
      console.log(instructor);
    });

    if (updatedInstructors.length < 1) {
      incomplete = true;
      alert("A Demo must have at least 1 Instructor");
      //setErrMsg(errMsg + " Demo must have at least 1 instructor");
      //setHasError(true);
    }
    //console.log(updatedInstructors);

    let updatedVendors = [];
    //console.log(data.vendors);
    data.vendors.map((vendor, i) => {
      if (vendor) updatedVendors.push(vendor.id);
    });

    if (updatedVendors.length < 1) {
      incomplete = true;
      alert("A Demo must have at least 1 Vendor");
      //setErrMsg(errMsg + " Demo must have at least 1 vendor");
      //setHasError(true);
    }
    //console.log(updatedVendors);

    let updatedCategories = [];
    console.log(data.categories);
    data.categories.map((category, i) => {
      if (category) updatedCategories.push(category.id);
    });

    if (updatedCategories.length < 1) {
      incomplete = true;
      alert("A Demo must have at least 1 Category");
      //setErrMsg(errMsg + " Demo must have at least 1 Category");
      //setHasError(true);
    }
    //console.log(updatedCategories);

    let newData = data;
    newData.instructors = updatedInstructors;
    newData.vendors = updatedVendors;
    newData.centers = updatedCenters;
    newData.categories = updatedCategories;
    newData.duration = data.duration / 60.0;

    for (const field in newData) {
      if (field !== "screenShot" && field !== "demoVideo") {
        formData.append(`${field}`, newData[field]);
      }
    }

    if (isNewDemo) {
      delete newData.id;
      //formData.delete("id");

      const pushCreate = async () => {
        //const updateResponse = await createDemo(newData);
        //const updateResponse = await createDemo(formData);
        //const updateResponse = await createDemoWithProgressBar(formData);
        setIsUploading(true);
        const updateResponse = await uploadDemowithProgressBar(
          formData,
          myOnUploadProgress
        );
        console.log(updateResponse);
        console.log(updateResponse.status);
        if (updateResponse.status >= 200 && updateResponse.status < 300) {
          setSuccessful(true);
          setIsUploading(false);
        }
        if (updateResponse.status >= 400 && updateResponse.status < 500) {
          setErrMsg(updateResponse.data);
          setIsUploading(false);
          setHasError(true);
        }
      };
      if (incomplete === false) {
        pushCreate().catch((error) => http.handleError(error));
      }
    } else {
      //newData.id = demoId;
      formData.append("id", demoId);
      const pushUpdate = async () => {
        //const updateResponse = await updateDemo(demoId, newData);
        const updateResponse = await updateDemo(demoId, formData);
        if (isDebug) {
          console.log(updateResponse);
          console.log(updateResponse.status);
        }
        if (updateResponse.status >= 200 && updateResponse.status < 300) {
          setSuccessful(true);
        }
        if (updateResponse.status >= 400 && updateResponse.status < 500) {
          setErrMsg(updateResponse.data);
          setHasError(true);
        }
      };
      if (incomplete === false) {
        pushUpdate().catch((error) => http.handleError(error));
      }
    }
  };

  const handleShowBookingsLink = () => {
    console.log("showBookingsLink: " + showBookingsLink);
    setShowBookingsLink(!showBookingsLink);
  };

  const handleShowDemoUpload = () => {
    console.log("showDemoUpload: " + showDemoUpload);
    setShowDemoUpload(!showDemoUpload);
  };

  const handleShowRemoteVideoLink = () => {
    console.log("showRemoteVideoLink: " + showRemoteVideoLink);
    setShowRemoteVideoLink(!showRemoteVideoLink);
  };

  const handleShowDTCLink = () => {
    console.log("showDTCLink: " + showDTCLink);
    setShowDTCLink(!showDTCLink);
  };

  const handleShowInstructors = () => setShowInstructorModal(true);
  const handleCloseInstructors = () => setShowInstructorModal(false);

  const handleShowCategories = () => setShowCategoryModal(true);
  const handleCloseCategories = () => setShowCategoryModal(false);

  const handleShowVendors = () => setShowVendorModal(true);
  const handleCloseVendors = () => setShowVendorModal(false);

  const handleShowCenters = () => setShowCenterModal(true);
  const handleCloseCenters = () => setShowCenterModal(false);

  const handleInstructorUpdate = () => {
    setShowInstructorModal(false);
    const hookInstructors = getValues("instructors");
    const newInstructorsIndexes = arrayFindAll(hookInstructors, true);
    const newInstructors = allInstructors.filter((i) =>
      newInstructorsIndexes.includes(i.id)
    );
    console.log(newInstructors);
    setDemoInstructors(newInstructors);
    if (typeof fetchedDemo === "object" && fetchedDemo !== null) {
      fetchedDemo.instructors = newInstructors;
      setFetchedDemo(fetchedDemo);
    }
    setValue("instructors", newInstructors);
  };

  const handleCenterUpdate = () => {
    setShowCenterModal(false);
    const hookCenters = getValues("centers");
    const newCenterIndexes = arrayFindAll(hookCenters, true);
    console.log(newCenterIndexes);
    const newCenters = allCenters.filter((i) =>
      newCenterIndexes.includes(i.id)
    );
    //console.log(allVendors);
    console.log(newCenters);
    setDemoCenters(newCenters);
    if (typeof fetchedDemo === "object" && fetchedDemo !== null) {
      fetchedDemo.centers = newCenters;
      setFetchedDemo(fetchedDemo);
    }
    setValue("centers", newCenters);
  };

  const handleVendorUpdate = () => {
    setShowVendorModal(false);
    const hookVendors = getValues("vendors");
    const newVendorsIndexes = arrayFindAll(hookVendors, true);
    console.log(newVendorsIndexes);
    const newVendors = allVendors.filter((i) =>
      newVendorsIndexes.includes(i.id)
    );
    //console.log(allVendors);
    console.log(newVendors);
    setDemoVendors(newVendors);
    if (typeof fetchedDemo === "object" && fetchedDemo !== null) {
      fetchedDemo.vendors = newVendors;
      setFetchedDemo(fetchedDemo);
    }
    setValue("vendors", newVendors);
  };

  const handleCategoryUpdate = () => {
    setShowCategoryModal(false);
    const hookCategories = getValues("categories");
    //console.log(hookCategories);
    const newCategoriesIndexes = arrayFindAll(hookCategories, true);
    const newCategories = allCategories.filter((i) =>
      newCategoriesIndexes.includes(i.id)
    );
    //console.log(allCategories);
    console.log(newCategories);
    setDemoTopics(newCategories);
    if (typeof fetchedDemo === "object" && fetchedDemo !== null) {
      fetchedDemo.categories = newCategories;
      setFetchedDemo(fetchedDemo);
    }
    setValue("categories", newCategories);
  };

  //Runs after initial render
  useEffect(() => {
    setIsLoading(true);

    const pullDemoInfo = async () => {
      const currentDemo = await getDemoInfo(demoId);
      setFetchedDemo(currentDemo);
    };

    const pullInstructors = async () => {
      const fetchedinstructors = await getInstructors();
      console.log("Fetched Instructors");
      console.log(fetchedinstructors);
      const sortedInstructors = _.orderBy(
        fetchedinstructors,
        ["user.fullName"],
        "asc"
      );
      console.log("Sorted Instructors");
      console.log(sortedInstructors);
      //setAllInstructors(fetchedinstructors);
      setAllInstructors(sortedInstructors);
    };

    const pullCenters = async () => {
      const fetchedcenters = await getCenters();
      console.log("Fetched Solution Centers");
      console.log(fetchedcenters);
      const sortedCenters = _.orderBy(fetchedcenters, ["name"], "asc");
      console.log("Sorted Centers");
      console.log(sortedCenters);
      //setAllInstructors(fetchedinstructors);
      setAllCenters(sortedCenters);
    };

    const pullVendors = async () => {
      const fetchedvendors = await getVendors();
      console.log("Fetched Vendors");
      console.log(fetchedvendors);
      const sortedVendors = _.orderBy(fetchedvendors, ["name"], "asc");
      console.log("Sorted Vendors");
      console.log(sortedVendors);
      //setAllVendors(fetchedvendors);
      setAllVendors(sortedVendors);
    };

    const pullCategories = async () => {
      const fetchedcategories = await getCategories();
      console.log("Fetched Topics");
      console.log(fetchedcategories);
      const sortedTopics = _.orderBy(fetchedcategories, ["title"], "asc");
      console.log("Sorted Topics");
      console.log(sortedTopics);
      //setAllCategories(fetchedcategories);
      setAllCategories(sortedTopics);
    };

    pullInstructors().catch(console.error);
    pullCenters().catch(console.error);
    pullVendors().catch(console.error);
    pullCategories().catch(console.error);

    if (!isNewDemo) {
      console.log("Editing existing Demo instance");
      pullDemoInfo().catch(console.error);
    } else {
      console.log("Creating new Demo instance");
    }
    //A hack to try and prevent errors showing up
    //before data has been entered
    clearErrors();

    setIsLoading(false);
  }, []);

  //Runs when a demo is fetched
  useEffect(() => {
    // Making sure fetchedDemo actually has a value
    if (typeof fetchedDemo === "object" && fetchedDemo !== null) {
      logData("Fetched Demo ID: " + fetchedDemo.id);
    } else {
      return;
    }

    /*
    if (fetchedDemo.imageUrl) {
      setHasScreenshotTitle(true);
      const sTitleArray = fetchedDemo.imageUrl.split("/");
      console.log("sTitleArray.length = " + sTitleArray.length);
      setScreenshotTitle(sTitleArray[sTitleArray.length - 1]);
    }
    */

    if (fetchedDemo.ibmDTCurl) {
      setHasDTCLink(true);
      setValue("ibmDTCurl", fetchedDemo.ibmDTCurl, {
        shouldValidate: true,
        shouldDirty: true,
      });
      //setibmDTCLink(fetchedDemo.ibmDTCurl);
      const shortDTCLink = fetchedDemo.ibmDTCurl.slice(0,14);
      const DTCLink =shortDTCLink.concat('',"...");
      setibmDTCLink(DTCLink);
    }

    if (fetchedDemo.bookingsUrl) {
      setHasBookingsLink(true);
      const sbookingsTitle = fetchedDemo.bookingsUrl.split("/");
      console.log("sbookingsTitle.length = " + sbookingsTitle.length);
      //setBookingsLink(sbookingsTitle[sbookingsTitle.length - 1]);
      setBookingsLink(fetchedDemo.bookingsUrl);
    }

    if (fetchedDemo.externalUrl) {
      setHasVideoTitle(true);
      const vTitleArray = fetchedDemo.externalUrl.split("/");
      console.log("vTitleArray.length = " + vTitleArray.length);
      setVideoTitle(vTitleArray[vTitleArray.length - 1]);
    }

    if (fetchedDemo.remoteVideoURL) {
      setHasRemoteVideoTitle(true);
      setValue("remoteVideoURL", fetchedDemo.remoteVideoURL, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setRemoteVideoTitle(fetchedDemo.remoteVideoURL);
    }

    setValue("name", fetchedDemo.name, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue("description", fetchedDemo.description, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue("type", fetchedDemo.type, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue("duration", fetchedDemo.duration * 60.0, {
      shouldValidate: true,
      shouldDirty: true,
    });
    if (fetchedDemo.instructors) {
      fetchedDemo.instructors?.map((instructor) => {
        const instructorId = instructor.id.toString();
        setValue(`instructors[${instructorId}]`, true);
        console.log(getValues("instructors"));
      });
      setDemoInstructors(fetchedDemo.instructors);
    }
    if (fetchedDemo.vendors) {
      fetchedDemo.vendors?.map((vendor) => {
        const vendorId = vendor.id.toString();
        setValue(`vendors[${vendorId}]`, true);
        console.log(getValues("vendors"));
      });
      setDemoVendors(fetchedDemo.vendors);
    }
    if (fetchedDemo.categories) {
      fetchedDemo.categories?.map((category) => {
        const categoryId = category.id.toString();
        setValue(`categories[${categoryId}]`, true);
        console.log(getValues("categories"));
      });
      setDemoTopics(fetchedDemo.categories);
    }
    if (fetchedDemo.centers) {
      fetchedDemo.centers?.map((center) => {
        const centerId = center.id.toString();
        setValue(`centers[${centerId}]`, true);
        console.log(getValues("centers"));
      });
      setDemoCenters(fetchedDemo.centers);
    }
  }, [fetchedDemo]);

  /* This useEffect is only when successful submission */
  useEffect(() => {
    if (successful) {
      navigate(-1);
    }
  }, [successful]);

  return (
    <React.Fragment>
      {hasError ? (
        alert(errMsg)
      ) : isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      ) : (
        <>
          <center>
            <h2 className="display-6">{pageTitle}</h2>
          </center>
          <div className="container shadow border border-2 border-light rounded-3  mt-4 mb-4">
            <form onSubmit={handleSubmit(handleUpdate)} className="p-2">
              <div className="row justify-content-lg-center">
                <div className="col-md">
                  <Input
                    register={register}
                    fieldName="name"
                    label="Demo Title"
                    errors={errors}
                  />
                </div>
                <div className="col-md">
                  <div className="form-floating">
                    <select
                      {...register("type", {
                        onChange: (e) => {
                          setValue("type", e.target.value);
                        },
                      })}
                      id="type"
                      className="form-select m-2"
                    >
                      <option key="0" value="">
                        {" "}
                      </option>
                      {types.map((type, i) => (
                        <option key={i} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="type">{"Select demo type..."}</label>
                    {errors["type"] && (
                      <div className="alert alert-danger">
                        {errors["type"].message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md">
                  <div className="form-floating">
                    <select
                      {...register("duration", {
                        onChange: (e) => {
                          setValue("duration", e.target.value);
                        },
                      })}
                      id="duration"
                      className="form-select m-2"
                    >
                      <option key="0" value="">
                        {" "}
                      </option>
                      {demoDuration.map((duration) => (
                        <option key={duration.minutes} value={duration.minutes}>
                          {duration.text}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="duration">{"Demo Duration..."}</label>
                    {errors["duration"] && (
                      <div className="alert alert-danger">
                        {errors["duration"].message}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row justify-content-lg-center">
                <div className="col-12">
                  <Input
                    register={register}
                    fieldName="description"
                    label={"Describe the demo..."}
                    errors={errors}
                    type="textarea"
                  />
                </div>
              </div>

              <hr className="my-4" />

              <div className="row justify-content-lg-center align-items-center mb-3">
                <div className="col-8">
                  <center>
                    <h1 className="display-6">Pick ONLY one of the four options below</h1>
                  </center>
                </div>
              </div>
              <div className="row justify-content-lg-center align-items-center mb-3">
                <div className="col-2 ">
                  <center>
                    <p><strong>
                      Option 1:
                    </strong></p>
                    <span >
                      Use this to create a demo that allows users to schedule time with you.
                    </span>
                  </center>  
                </div>
                <div className="col-4 ">
                  <center>
                    <Button variant="primary" onClick={handleShowBookingsLink}>
                      {showBookingsLinkLabel} Bookings Link
                    </Button>
                    <br />
                    <small>
                      Current Bookings Link: <br />
                      <span className="text-danger">
                        <small>{hasBookingsLink ? bookingsLink : "NONE"}</small>
                      </span>
                    </small>
                  </center>
                </div>
                <div className="col-6 mb-3">
                  {showBookingsLink ? (
                    <center>
                      <Input
                        register={register}
                        fieldName="bookingsUrl"
                        label="Paste URL to Bookings Site"
                        placeholder={bookingsLink}
                        errors={errors}
                        className="form-control ms-2"
                      />
                    </center>
                  ) : null}
                </div>
              </div>

              <div className="row justify-content-lg-center align-items-center mb-3">
                <div className="col-2 ">
                  <hr />
                  <center>
                    <p><strong>
                      Option 2:
                    </strong></p>
                    <span >
                      Use this to upload a video of your demo that users that users can watch on thier own.
                    </span>
                  </center>  
                </div>
                <div className="col-4">
                  <center>
                    <Button variant="primary" onClick={handleShowDemoUpload}>
                      {showDemoUploadLabel} Demo Video Upload
                    </Button>
                    <br />
                    <small>
                      Current Demo File: <br />
                      <span className="text-danger">
                        <small>{hasVideoTitle ? videoTitle : "NONE"}</small>
                      </span>
                    </small>
                  </center>
                </div>
                <div className="col-6 mb-3">
                  {showDemoUpload ? (
                    <center>
                      <Input
                        register={register}
                        fieldName="demoVideo"
                        label="Upload Demo Video File"
                        errors={errors}
                        placeholder={videoTitle}
                        className="form-control ms-2"
                        type="file"
                      />
                    </center>
                  ) : null}
                </div>
              </div>

              <div className="row justify-content-lg-center align-items-center">
                <div className="col-2 ">
                  <hr />
                  <center>
                    <p><strong>
                      Option 3:
                    </strong></p>
                    <span >
                      Use this to paste a link to the URL of the video of your demo that users that users can watch on thier own.
                    </span>
                  </center>  
                </div>
                <div className="col-4">
                  <center>
                    <Button
                      variant="primary"
                      onClick={handleShowRemoteVideoLink}
                    >
                      {showRemoteVideoLabel} Remote Video Link
                    </Button>
                    <br />
                    <small>
                      Current Remote Video Link: <br />
                      <span className="text-danger">
                        <small>
                          {hasRemoteVideoTitle ? remoteVideoTitle : "NONE"}
                        </small>
                      </span>
                    </small>
                  </center>
                </div>
                <div className="col-6 mb-3">
                  {showRemoteVideoLink ? (
                    <center>
                      <Input
                        register={register}
                        fieldName="remoteVideoURL"
                        label="Paste URL to Remote Video"
                        errors={errors}
                        className="form-control ms-2"
                      />
                    </center>
                  ) : null}
                </div>
              </div>

              <div className="row justify-content-lg-center align-items-center">
                <div className="col-2 ">
                  <hr />
                  <center>
                    <p><strong>
                      Option 4:
                    </strong></p>
                    <span >
                      Use this to paste a link to the URL of the IBM system that users can use on thier own.
                    </span>
                  </center>  
                </div>
                <div className="col-4">
                  <center>
                    <Button
                      variant="primary"
                      onClick={handleShowDTCLink}
                    >
                      {showDTCLabel} Remote IBM DTC Link
                    </Button>
                    <br />
                    <small>
                      Current IBM DTC Link: <br />
                      <span className="text-danger">
                        <small>
                          {hasDTCLink ? ibmDTCLink : "NONE"}
                        </small>
                      </span>
                    </small>
                  </center>
                </div>
                <div className="col-6 mb-3">
                  {showDTCLink ? (
                    <center>
                      <Input
                        register={register}
                        fieldName="ibmDTCurl"
                        label="Paste URL to IBM DTC System"
                        errors={errors}
                        className="form-control ms-2"
                      />
                    </center>
                  ) : null}
                </div>
              </div>

              <hr className="my-4" />

              <div className="row justify-content-lg-center">
                <div className="col-md">
                  <center>
                    <div className="form-group m-2">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>
                              {" "}
                              <center>Demo Instructors</center>{" "}
                            </th>
                            <th>
                              <Button
                                variant="primary"
                                onClick={handleShowInstructors}
                              >
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </Button>
                              <Modal
                                show={showInstructorModal}
                                onHide={handleCloseInstructors}
                                scrollable={true}
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>Pick Instructor(s)</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  Instructor List
                                  <div className="col">
                                    {allInstructors &&
                                      allInstructors.map((instructor) => (
                                        <>
                                          <div className="form-check ">
                                            <label className="form-check-label p-2">
                                              <input
                                                className="form-check-input"
                                                {...register(
                                                  `instructors[${instructor?.id}]`
                                                )}
                                                type="checkbox"
                                                key={instructor?.id}
                                              />
                                              {instructor?.user.fullName}
                                            </label>
                                          </div>
                                        </>
                                      ))}
                                  </div>
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button
                                    variant="secondary"
                                    onClick={handleCloseInstructors}
                                  >
                                    Close
                                  </Button>
                                  <Button
                                    variant="primary"
                                    onClick={handleInstructorUpdate}
                                  >
                                    Submit
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {demoInstructors &&
                            demoInstructors.map((instructor, i) => (
                              <>
                                <tr>
                                  <td>
                                    <small>{instructor.user.fullName}</small>
                                  </td>
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </center>
                </div>
                <div className="col-md">
                  <center>
                    <div className="form-group m-2">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>
                              {" "}
                              <center>Demo Solution Centers</center>{" "}
                            </th>
                            <th>
                              <Button
                                variant="primary"
                                onClick={handleShowCenters}
                              >
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </Button>
                              <Modal
                                show={showCenterModal}
                                onHide={handleCloseCenters}
                                scrollable={true}
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>
                                    Choose Solution Center(s)
                                  </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  Solution Center List
                                  <div className="col">
                                    {allCenters &&
                                      allCenters.map((center) => (
                                        <>
                                          <div className="form-check ">
                                            <label className="form-check-label p-2">
                                              <input
                                                className="form-check-input"
                                                {...register(
                                                  `centers[${center.id}]`
                                                )}
                                                type="checkbox"
                                                key={center.id}
                                              />
                                              {center.name}
                                            </label>
                                          </div>
                                        </>
                                      ))}
                                  </div>
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button
                                    variant="secondary"
                                    onClick={handleCloseCenters}
                                  >
                                    Close
                                  </Button>
                                  <Button
                                    variant="primary"
                                    onClick={handleCenterUpdate}
                                  >
                                    Submit
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {demoCenters &&
                            demoCenters.map((center, i) => (
                              <>
                                <tr>
                                  <td>
                                    <small>{center.name}</small>
                                  </td>
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </center>
                </div>
              </div>

              <div className="row justify-content-lg-center">
                <div className="col-md">
                  <center>
                    <div className="form-group m-2">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>
                              {" "}
                              <center>Demo Vendors</center>{" "}
                            </th>
                            <th>
                              <Button
                                variant="primary"
                                onClick={handleShowVendors}
                              >
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </Button>
                              <Modal
                                show={showVendorModal}
                                onHide={handleCloseVendors}
                                scrollable={true}
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>Select Vendor(s)</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  Vendor List
                                  <div className="col">
                                    {allVendors &&
                                      allVendors.map((vendor) => (
                                        <>
                                          <div className="form-check ">
                                            <label className="form-check-label p-2">
                                              <input
                                                className="form-check-input"
                                                {...register(
                                                  `vendors[${vendor.id}]`
                                                )}
                                                type="checkbox"
                                                key={vendor.id}
                                              />
                                              {vendor.name}
                                            </label>
                                          </div>
                                        </>
                                      ))}
                                  </div>
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button
                                    variant="secondary"
                                    onClick={handleCloseVendors}
                                  >
                                    Close
                                  </Button>
                                  <Button
                                    variant="primary"
                                    onClick={handleVendorUpdate}
                                  >
                                    Submit
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {demoVendors &&
                            demoVendors.map((vendor, i) => (
                              <>
                                <tr>
                                  <td>
                                    <small>{vendor.name}</small>
                                  </td>
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </center>
                </div>
                <div className="col-md">
                  <center>
                    <div className="form-group m-2">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>
                              {" "}
                              <center>Demo Categories</center>{" "}
                            </th>
                            <th>
                              <Button
                                variant="primary"
                                onClick={handleShowCategories}
                              >
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </Button>
                              <Modal
                                show={showCategoryModal}
                                onHide={handleCloseCategories}
                                scrollable={true}
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>Pick Category(s)</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  Category List
                                  <div className="col">
                                    {allCategories &&
                                      allCategories.map((category) => (
                                        <>
                                          <div className="form-check ">
                                            <label className="form-check-label p-2">
                                              <input
                                                className="form-check-input"
                                                {...register(
                                                  `categories[${category.id}]`
                                                )}
                                                type="checkbox"
                                                key={category.id}
                                              />
                                              {category.title}
                                            </label>
                                          </div>
                                        </>
                                      ))}
                                  </div>
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button
                                    variant="secondary"
                                    onClick={handleCloseCategories}
                                  >
                                    Close
                                  </Button>
                                  <Button
                                    variant="primary"
                                    onClick={handleCategoryUpdate}
                                  >
                                    Submit
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {demoTopics &&
                            demoTopics.map((category, i) => (
                              <>
                                <tr>
                                  <td>
                                    <small>{category.title}</small>
                                  </td>
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </center>
                </div>
              </div>

              <div className="row justify-content-lg-center">
                <div className="col"></div>
                <div className="col">
                  {isUploading && (
                    <div>
                      <p className="lead">Upload Percentage:</p>
                      <div className="progress border border-danger">
                        <div
                          className="progress-bar progress-bar-striped"
                          role="progressbar"
                          aria-valuenow={progress}
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: progress + "%" }}
                        >
                          {progress}%
                        </div>{" "}
                      </div>
                    </div>
                  )}
                </div>
                <div className="col"></div>
              </div>

              <div className="row justify-content-lg-center">
                <div className="col"></div>
                <div className="col">
                  <center>
                    <div id="4" className="form-group m-2">
                      <input
                        className="btn btn-sm btn-primary m-2"
                        type="submit"
                        value={submitButtonLabel}
                      />
                      <input
                        className="btn btn-sm btn-danger"
                        type="button"
                        value="Cancel"
                        onClick={() => {
                          navigate(-1);
                        }}
                      />
                    </div>
                    {successful && (
                      <div className="alert alert-success">
                        Update Successful!
                      </div>
                    )}
                  </center>
                </div>
                <div className="col"></div>
              </div>
            </form>
          </div>
        </>
      )}
    </React.Fragment>
  );
} // End export default funtion DemoForm

/*
 <div className="row justify-content-lg-center align-items-center mb-3">
                <div className="col-6">
                  <center>
                    <Button
                      variant="primary"
                      onClick={handleShowScreenshotUpload}
                    >
                      {showScreenshotUploadLabel} Screenshot Upload
                    </Button>
                    <br />
                    <small>
                      Current Screenshot File: <br />
                      <span className="text-danger">
                        <small>
                          {hasScreenshotTitle ? screenshotTitle : "NONE"}
                        </small>
                      </span>
                    </small>
                  </center>
                </div>
                <div className="col-6 mb-3">
                  {showScreenshotUpload ? (
                    <center>
                      <Input
                        register={register}
                        fieldName="screenShot"
                        label="Upload Screenshot"
                        errors={errors}
                        className="form-control ms-2"
                        type="file"
                      />
                    </center>
                  ) : null}
                </div>
              </div>
*/

/*
  async function uploadDemowithProgressBar(demo, onUploadProgress) {
    let config = {
      headers: authHeader(),
      onUploadProgress: function (progressEvent) {
        //let percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total);
        //if (percentCompleted < 100) console.log("Percent Complete: " + percentCompleted);
        setProgress(Math.round( (progressEvent.loaded * 100) / progressEvent.total));
      },
    };
    const response = await http.post(`/api/v1/demos/`, demo, config);
    return response;
  }
*/

/*

              <div className="row justify-content-lg-center">
                <center>
                  <p className="text-danger fs-3">Current Values</p>
                </center>
                {hasScreenshotTitle ? (
                  <div className="col-6 my-3">
                    <center>
                      <div className="fs-5">
                        Current Screenshot Title:
                        <div className="fs-6">{screenshotTitle}</div>
                      </div>
                    </center>
                  </div>
                ) : null}
                {hasVideoTitle ? (
                  <div className="col-6 my-3">
                    <center>
                      <div className="fs-5">
                        Current Uploaded Demo Video:
                        <div className="fs-6">{videoTitle}</div>
                      </div>
                    </center>
                  </div>
                ) : null}
                {hasRemoteVideoTitle ? (
                  <div className="col-6 my-3">
                    <center>
                      <div className="fs-5">
                        Current Remote Demo Video:
                        <div className="fs-6">{remoteVideoTitle}</div>
                      </div>
                    </center>
                  </div>
                ) : null}
              </div>
*/

/*
                <div className="row justify-content-lg-center mb-3">
                <p className="text-danger text-center">
                  Please EITHER upload a video file OR Paste the Link to the
                  REMOTE video.
                </p>
                <div className="col-6">
                  <center>
                    <Input
                      register={register}
                      fieldName="demoVideo"
                      label="Upload Demo Recording..."
                      className="form-control "
                      errors={errors}
                      type="file"
                    />
                  </center>
                </div>
                <div className="col-6">
                  <center>
                    <div>
                      <Input
                        register={register}
                        fieldName="remoteVideoURL"
                        label="Paste URL to Demo Recording..."
                        className="form-control "
                        errors={errors}
                      />
                    </div>
                  </center>
                </div>
              </div>
*/
