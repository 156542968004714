import { getRoles } from "./roleService";

export default class rbacService {
    // class variable
    currentUser;

    constructor(user) {
       //this.currentUser = AuthService.getCurrentUser();
       this.currentUser = user;
    }

    isAdmin() {
      if (this.currentUser.roleId === 1 ) 
       return true; 
      else return false;
   }

    isInstructor() {
      if (this.currentUser.roleId === 2 )
        return true; 
      else return false;
    }

    isUser() {
      if (this.currentUser.roleId === 3 )
        return true; 
      else return false;
    }
   
    isLoanerManager() {
      if (this.currentUser.roleId === 4 )
        return true; 
      else return false;
    }

    hasElevatedPrivs() {
      // is the current user anyone with elevated privileges
      if (this.currentUser.roleId <= 2 || this.currentUser.roleId === 4 )
        return true; 
      else return false;
    }
  
    isAdminOrInstructor() {
      if (this.currentUser.roleId <= 2 )
        return true; 
      else return false;
    }
  
    isAdminOrLoanerAdmin() {
      if (this.currentUser.roleId === 1 || this.currentUser.roleId === 4)
        return true; 
      else return false;
    }
  
    async getRoleName() {
      const currentRoles = await getRoles();
      if(currentRoles !== null)  return currentRoles[this.currentUser.roleId].name; 
      else return null;
    }
  
}
  
//export default new rbacService();