import React, { useState, useEffect } from "react";
import { getFeedbackCount } from "../../services/feedbackService";
import ButtonWL from "../common/buttonWithLink";
import ColorCard from "../common/card";
import httpService from "../../services/httpService";
import { getPocOrderCount, getPocOrderRequestCount, getPocOrderbyStatus } from "../../services/pocOrderService";
import PocRequestsTable from "./pocRequestsTable";

function LMDashboard() {
  const [requests, setRequests] = useState(null);
  const [orderCnt, setOrderCnt] = useState(null);
  const [requestCnt, setRequestCnt] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [sortColumn, setSortColumn] = useState({ path: "name", order: "asc"});

  useEffect(() => {
    setIsLoading(true);

    async function getOpenOrderCount() {
      const localOpenOrderCount = await getPocOrderCount();
      console.log("Open Order count is: " + localOpenOrderCount);
      setOrderCnt(localOpenOrderCount);
    }
    getOpenOrderCount().catch((error) => httpService.handleError(error));

    async function getOrderRequests() {
      const localOrderRequests = await getPocOrderbyStatus("New");
      console.log(localOrderRequests);
      setRequests(localOrderRequests);
    }
    getOrderRequests().catch((error) => httpService.handleError(error));

    async function getOrderRequestsCount() {
      const localRCount = await getPocOrderRequestCount();
      console.log("Requests count is: " + localRCount);
      setRequestCnt(localRCount);
    }
    getOrderRequestsCount().catch((error) => httpService.handleError(error));

    setIsLoading(false);
  }, []);

  //This is the page that Admins work from
  return (
    <>
      {isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      ) : (
        <div className="container">
          <center>
            <p className="fs-2 mt-2">Proof-of-Concept Management</p>
          </center>

          <div className="d-flex d-flex-row mb-2">
            <div className="col-4 text-center">
              <ButtonWL
                size="sm"
                variant="secondary"
                value="Open Orders"
                link="/pocOrders"
                count={orderCnt}
              />
            </div>

            <div className="col-4 text-center">
              <ButtonWL
                size="sm"
                variant="secondary"
                value="New Order Requests"
                link="/pocRequests"
                count={requestCnt}
              />
            </div>

            
          </div>
          <div className="d-flex d-flex-row">
            <div className="col">
              <ColorCard
                title="Item Inventory"
                description="See full list of current items"
                link="/pocItems"
              />
            </div>
            <div className="col">
              <ColorCard
                title="Kit Inventory"
                description="See full list of current kits"
                link="/pockits"
                color="white"
                bgcolor="darkgoldenrod"
              />
            </div>
            <div className="col">
              <ColorCard
                title="Reseller Partners"
                description="See full list of current reseller partners"
                link="/resellers"
                color="white"
                bgcolor="darkgreen"
              />
            </div>
          </div>
          <div className="d-flex d-flex-row mb-2">
          </div>
          
        </div>
      )}
    </>
  );
}

export default LMDashboard;

/*
            <PocRequestsTable 
              pocOrders={requests}
              sortColumn={sortColumn}
              caption="Current PoC Requests"
            />
<div className="col text-center">
              <ButtonWL
                size="sm"
                variant="secondary"
                value="New User Requests"
                link="/signups"
                count={signupCnt}
              />
            </div>
            <div className="col">
              <ColorCard
                title="Open Orders"
                description="See list of current PoC Orders"
                link="/pocOrders"
                color="white"
                bgcolor="darkcyan"
              />
            </div>
*/

/*
<div className="row">
            <div className="col">
              <ColorCard
                title="Topics"
                description="See full list of Topics"
                link="/categories"
                color="black"
                bgcolor="burlywood"
              />
            </div>
            <div className="col">
              <ColorCard
                title="ServiceSolv"
                description="See the ServiceSolv Page"
                link="/servicesolv"
                color="white"
                bgcolor="darkcyan"
              />
            </div>
            <div className="col">
              <ColorCard
                title="Activities"
                description="See User Activities"
                link="/userActivities"
                color="black"
                bgcolor="burlywood"
              />
            </div>
          </div>
*/

/*

          <div className="row">
            <ColorCard
              title="Feedback"
              description="See list of feedback items."
              link="/feedbacks"
            />
            <ColorCard
              title="Reservations"
              description="See the list of Reservations in the System"
              link="/reservations"
            />
            <ColorCard
              title="Sign Ups"
              description="See list of signup requests that are open"
              link="/signups"
            />

        <Feedbacks />
        <Reservations />
        <NewUserRequest />
*/
