import React, { Component } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import _ from "lodash";
import Select from "react-select";
import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";
import Table from "../common/table";
import ExportExcel from "../common/excelExport";
import authService from "../../services/auth.service";
import { getUserActivitybyUserID, getUserActivitiesSinceDate } from "../../services/userActivityService";

class UserUserActivitiesTable extends Component {
  state = {
    //activities: this.props.userActivities,
    activities: [],
    user: this.props.user,
    currentUser: null,
    showLogin: true,
    dateString: null,
    exportData: null,
    currentPage: 1,
    dayCount: 14,
    pageSize: 20,
    sortColumn: { path: "createdAt", order: "desc" },
  };

  componentDidMount() {
    //const { userActivities } = this.props;
    const cu = authService.getCurrentUser();
    const today = format(new Date(), "ddMMMyyyy");
    
    this.getUserActivityList(this.state.dayCount);
    this.setState({
     // activities: userActivities,
      currentUser: cu,
      dateString: today,
    });
  }
/* */
  getUserActivityList = async (number) => {
    const defaultStartDate = new Date();
    // 60 here is 60 days
    //defaultStartDate.setDate(defaultStartDate.getDate() - 60);
    //defaultStartDate.setDate(defaultStartDate.getDate() - this.state.dayCount);
    defaultStartDate.setDate(defaultStartDate.getDate() - number);
    let textdate = defaultStartDate.toISOString();
    const localUserActivities = await getUserActivitiesSinceDate(textdate);
    //let intDemoID = parseInt(this.state.demoId);
    //const localFilteredActivities = _.filter(localUserActivities, { 'entityId': intDemoID, 'entityAction': "WATCH" });
    let intUserID = parseInt(this.state.user.id);
    const localFilteredActivities = _.filter(localUserActivities, { 'userId': intUserID});
    console.log(localFilteredActivities);
    //setUserActivities(localFilteredActivities);
    this.setState({activities: localFilteredActivities});
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      activities,
      showLogin,
    } = this.state;

    let TDSactitivites = [];
    if (!showLogin) {
      for (const a of activities) {
        if (a.entityAction !== "LOGIN") {
          TDSactitivites.push(a);
        }
      }
    } else {
      TDSactitivites = activities;
    }

    const sorted = _.orderBy(
      //activities,
      TDSactitivites,
      [sortColumn.path],
      [sortColumn.order]
    );

    let data = [];
    sorted.map((a) => data.push({
      Date: format(new Date(a.createdAt), "Pp") ,
      User: a.user.fullName,
      UserId: a.user.id,
      eMail: a.user.email,
      EntityAction: a.entityAction,
      EntityType: a.entityType,
      EntityName: a.entityName,
      EntityId: a.entityId,
      Details: a.details,
      Reseller: a.user.reseller.name,
    }));

    const filteredActivities = paginate(sorted, currentPage, pageSize);

    return {
      exportData: data,
      //exportData: sorted,
      totalCount: sorted.length,
      data: filteredActivities,
    };
  };

  changePageSize = (e) => {
    this.setState({ pageSize: e.value, currentPage: 1 });
  };
  
  changeDayCount = (e) => {
    this.setState({ dayCount: e.value, currentPage: 1 });
    this.getUserActivityList(e.value);
  };
  
  changeShowTDSActions = (e) => {
    console.log(this.state.showLogin);
    this.setState({ showLogin: !this.state.showLogin });
  };

  columns = [
    {
      path: "createdAt",
      label: "Date",
      content: (userActivity) => (
         format(new Date(userActivity.createdAt), "Pp") 
      ),
    },
    {
      path: "user.fullName",
      label: "User",
    },
    { path: "entityAction", label: "Action" },
    { path: "entityType", label: "Type" },
    { path: "entityName", label: "Target Name" },
    { path: "details", label: "Details" },
    {
      path: "user.reseller.name",
      label: "Account",
      content: (userActivity) => (
        <Link to={`/resellerDetail/${userActivity.user.reseller.id}`}>
          {userActivity.user.reseller.name}
        </Link>
      ),
    },
  ];

  render() {
    //const { length: count } = this.state.activities;
    const { pageSize, currentPage, sortColumn, currentUser, dateString, user, dayCount } =
      this.state;

    if (this.state.isLoading)
      return (
        <div className="text-center p-3">
          {" "}
          <span className="spinner-border spinner-border-lg align-center"></span>{" "}
        </div>
      );
   // if (count === 0) return <p>There are no logs to show.</p>;

    const {
      exportData,
      totalCount,
      data: filteredActivities,
    } = this.getPagedData();

    const selectOptions = [
      { value: 5, label: 5 },
      { value: 10, label: 10 },
      { value: 15, label: 15 },
      { value: 20, label: 20 },
      { value: 25, label: 25 },
      { value: 50, label: 50 },
      { value: 100, label: 100 },
      { value: 200, label: 200 },
    ];

    const dayOptions = [
      { value: 7, label: 7 },
      { value: 14, label: 14 },
      { value: 30, label: 30 },
      { value: 60, label: 60 },
      { value: 180, label: 180 },
    ];

    return (
      <React.Fragment key={89}>
        <div className="d-flex flex-row align-items-center justify-content-center">
          <div className="d-inline-flex pe-2 me-2">
            <p className="display-6">{user.fullName} Activity Logs last {dayCount} days</p>
          </div>
          
          
        </div>
        <div className="d-flex flex-row align-items-center justify-content-center">
        <div className="d-inline-flex pe-2">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="32456"
                checked={this.state.showLogin}
                onChange={this.changeShowTDSActions.bind(this)}
              />
              <label className="form-check-label" htmlFor="32456">
                Show Logins 
              </label>
            </div>
          </div>
          <div className="d-inline-flex">
            <Select
              id="dayCount"
              options={dayOptions}
              onChange={this.changeDayCount.bind(this)}
              placeholder="How Many Days Back Do You Want To See?"
            />
          </div>
          <div className="d-inline-flex  ms-2">
            <ExportExcel
              excelData={exportData}
              fileName={`ActivityLogsFor-${user.fullName}-pulledby-${currentUser?.fullName}-${dateString}`}
            />
          </div>
        </div>
        <div className="d-flex flex-row">
          <Table
            columns={this.columns}
            data={filteredActivities}
            sortColumn={sortColumn}
            onSort={this.handleSort}
            caption="Activity Log"
          />
        </div>
        <div className="d-flex flex-row">
          <div className="d-inline-flex pe-2">
            <Pagination
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          </div>
          <div className="d-inline-flex">
            <Select
              id="pgSize"
              options={selectOptions}
              onChange={this.changePageSize.bind(this)}
              placeholder="Number of Items / Page"
            />
          </div>
        </div>
        <div className="d-flex flex-row">
          <hr className="mb-2" />
        </div>
      </React.Fragment>
    );
  }
}

export default UserUserActivitiesTable;

/* 
             fileName="test" 
             fileName={`PortalLogs-${currentUser?.firstName}-${dateString}`}
             */
