import React, { useState, useEffect } from "react";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import authService from "../../services/auth.service";
import { getUserInfo } from "../../services/userService";
import { getInstructorbyUserID } from "../../services/instructorService";
import { isDebug, logData } from "../common/helpers";
import http from "../../services/httpService";
import { getFeedbackbyID } from "../../services/feedbackService";
import httpService from "../../services/httpService";
import BackButton from "../common/backButtion";

export default function FeedbackDetail() {
  const [redirect, setRedirect] = useState(null);
  const [feedbackInfo, setFeedbackInfo] = useState(null);

  let navigate = useNavigate();

  let { feedbackId } = useParams();

  const changePassword = () => {
    navigate("/cp");
  };

  useEffect(() => {
    const loggedInUser = authService.getCurrentUser();
    let fetchedUser = {};
    let fetchedInstructor = {};

    if (!loggedInUser) {
      setRedirect("/login");
    }

    const getFBDetail = async () => {
      const fbInfo = await getFeedbackbyID(feedbackId);
      console.log(fbInfo);
      setFeedbackInfo(fbInfo);
    };
    getFBDetail().catch((error) => httpService.handleError(error));
  }, []);

  return (
    <>
      {redirect ? <Navigate to={redirect} /> : null}
      {feedbackInfo ? (
        <div className="container shadow border border-1 border-light rounded-3 pt-2 mt-2">
          <BackButton />
          <div className="row justify-content-lg-center">
            <center>
              <h2 className="display-6">Feedback Details</h2>
            </center>
            <div className="col-md">
              <p className="h6 mt-1">Provided By:</p>
              {feedbackInfo.user.fullName} from{" "}
              {feedbackInfo.user.reseller.name} ({" "}
              {feedbackInfo.user.reseller.acctNum} )
              <hr />
              <p className="h6 mt-2">Type: </p>
              {feedbackInfo.type}
              <hr />
              <p className="h6 mt-2">Message: </p>
              {feedbackInfo.message}
              <hr />
              <p className="h6 mt-2">Action Taken: </p>
              {feedbackInfo.actionTaken}
              <hr />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

/*


                <p className="h6 mt-2">Favorite vendors: </p>
                {userInfo.vendors.map((vendor) => vendor.name)}

*/
