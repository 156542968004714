export const states = [
  { abrev: "AL", name: "Alabama" },
  { abrev: "AK", name: "Alaska" },
  { abrev: "AZ", name: "Arizona" },
  { abrev: "AR", name: "Arkansas" },
  { abrev: "CA", name: "California" },
  { abrev: "CO", name: "Colorado" },
  { abrev: "CT", name: "Connecticut" },
  { abrev: "DE", name: "Delaware" },
  { abrev: "FL", name: "Florida" },
  { abrev: "GA", name: "Georgia" },
  { abrev: "HI", name: "Hawaii" },
  { abrev: "ID", name: "Idaho" },
  { abrev: "IL", name: "Illinois" },
  { abrev: "IN", name: "Indiana" },
  { abrev: "IA", name: "Iowa" },
  { abrev: "KS", name: "Kansas" },
  { abrev: "KY", name: "Kentucky" },
  { abrev: "LA", name: "Louisiana" },
  { abrev: "ME", name: "Maine" },
  { abrev: "MD", name: "Maryland" },
  { abrev: "MA", name: "Massachusetts" },
  { abrev: "MI", name: "Michigan" },
  { abrev: "MN", name: "Minnesota" },
  { abrev: "MS", name: "Mississippi" },
  { abrev: "MO", name: "Missouri" },
  { abrev: "MT", name: "Montana" },
  { abrev: "NE", name: "Nebraska" },
  { abrev: "NV", name: "Nevada" },
  { abrev: "NH", name: "New Hampshire" },
  { abrev: "NJ", name: "New Jersey" },
  { abrev: "NM", name: "New Mexico" },
  { abrev: "NY", name: "New York" },
  { abrev: "NC", name: "North Carolina" },
  { abrev: "ND", name: "North Dakota" },
  { abrev: "OH", name: "Ohio" },
  { abrev: "OK", name: "Oklahoma" },
  { abrev: "OR", name: "Oregon" },
  { abrev: "PA", name: "Pennsylvania" },
  { abrev: "RI", name: "Rhode Island" },
  { abrev: "SC", name: "South Carolina" },
  { abrev: "SD", name: "South Dakota" },
  { abrev: "TN", name: "Tennessee" },
  { abrev: "TX", name: "Texas" },
  { abrev: "UT", name: "Utah" },
  { abrev: "VT", name: "Vermont" },
  { abrev: "VA", name: "Virginia" },
  { abrev: "WA", name: "Washington" },
  { abrev: "WV", name: "West Virginia" },
  { abrev: "WI", name: "Wisconsin" },
  { abrev: "WY", name: "Wyoming" },
];

export const serviceSolv_categories = [
  {
    title: "Pre-Sales Engineering",
    description:
      "Our pre-sales engineering support includes configuration design, BOM creations, SKU help, certification preparation, custom enablement, joint client calls & demo training with our 7 solution centers.",
  },
  {
    title: "Education",
    description:
      "Ensure you & your customers can meet today’s product, technology, & business skills requirements. We deliver instructor-led public, private, virtual, & onsite training as well as on-demand offers.",
  },
  {
    title: "Delivered Services",
    description:
      "TD SYNNEX can provide onsite and remote technical resources to assist our reseller and OEM partners with installation, configuration, testing, remediation, support and warranty services.",
  },
  {
    title: "RenewSolv",
    description:
      " TD SYNNEX RENEWSolv is a free value-add, online tool available for reseller partners. Get quick access to your services and renewals quotes with two easy clicks of your mouse",
  },
  {
    title: "Support Services",
    description:
      " Industry-leading day-two support that customers need. We provide 24/7 Helpdesk, GoldSeal®, & Managed Services that offer flexible cost-effective solutions to enhance the customer support experience.",
  },
  {
    title: "Integration",
    description:
      "TD SYNNEX can provide configuration services out of our robust ISO 9001 certified warehouses to aid our reseller and OEM partners with staging, configuration, logistics and value-added services needs.",
  },
];
