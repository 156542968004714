import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
//import reportWebVitals from "./reportWebVitals";
import "font-awesome/css/font-awesome.css";
import "bootstrap/dist/css/bootstrap.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </>
);

/*
  16 JAN 2024 Update:
  StrictMode causing abherrant behavior
  Removing Strict mode.
root.render(
  <>
    <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    </StrictMode>
  </>
);

*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

/*
import { BrowserRouter } from "react-router-dom";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </>
);



const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}></Route>
    <Route path="/login" element={<Login />} />
  )
);


*/

/*
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";

const router = createBrowserRouter([
  { path: "*", Component: }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
*/