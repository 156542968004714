import React, { useState, useEffect } from "react";
import {
  getReservationByResellerID,
  getReservations,
} from "../../services/reservationService";
import BackButton from "../common/backButtion";
import ReservationTable from "./reservationTable";
import authService from "../../services/auth.service";
import rbacService from "../../services/rbacService";

export default function Reservations(props) {
  const [reservations, setReservations] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showBackButton, setShowBackButton] = useState(props.showBackButton);

  const currentUser = authService.getCurrentUser();
  const rbac = new rbacService(currentUser);

  const caption =
    //currentUser.roleId <= 2
    rbac.isAdminOrInstructor() 
      ? "List of ALL Reservations"
      : "List of your company's reservations";

  // Run once after initial render
  useEffect(() => {
    if (reservations === null) {
      setIsLoading(true);

      const getReservationList = async () => {
        let localReservations = [];
        //if (currentUser.roleId <= 2) {
        if (rbac.isAdminOrInstructor()) {
          localReservations = await getReservations();
        } else {
          console.log(
            "In getReservationList: currentUser reseller id: " +
              currentUser.resellerId
          );
          localReservations = await getReservationByResellerID(
            currentUser.resellerId
          );
        }
        setReservations(localReservations);
        console.log(localReservations);
      };
      getReservationList().catch(console.error);
      setIsLoading(false);
    } else {
      console.log(reservations);
      console.log(reservations.length);
      console.log(typeof reservations);
    }
  }, []);

  useEffect(() => {
    if(showBackButton === undefined){
      console.log("show back button is null");
      setShowBackButton(true);
    }
  }, [showBackButton] );

  return (
    <React.Fragment>
      <div className="container shadow border border-1 border-light rounded-3 pt-2 pb-2 mt-2">
        {showBackButton ? <BackButton /> : null }
        <center>
        <h2 className="display-6">Reservations</h2>
        </center>

        {isLoading ? (
          <div className="text-center p-3">
            <span className="spinner-border spinner-border-lg align-center"></span>
          </div>
        ) : (
          reservations?.length > 0 && (
            <>
              <ReservationTable reservations={reservations} caption={caption} />
            </>
          )
        )}
      </div>
    </React.Fragment>
  );
}
