import React from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import ResponsivePlayer from "../common/responsivePlayer";

export default function FieldServicesFeature() {
  return (
    <>
      <hr className="featurette-divider" />
      <div className="container">
        <div className="row featurette align-items-center">
          <div className="col-md-7 order-md-2">
            <h2 className="ss-featurette-heading fw-normal lh-1">
              Delivered Services.{" "}
            </h2>
            <p className="lead">
              <strong>1</strong> company, <strong> 1</strong> process, and{" "}
              <strong> 1</strong> invoice.
              <br />
              <br />
              <strong> 1000+ </strong> static SKUs. Geographic coverage across{" "}
              <strong> 180+ </strong> countries.
              <br />
              Deep catalog of services from the endpoint to the cloud. Use us
              only when you need to. No minimum deal size. Product purchase from
              TD SYNNEX not required.
              <br />
              <br />
              <Link to="/deliveredServices" className="text-decoration-none">
                See Our List of Services
              </Link>
              <br />
              <br />
              <strong>
                Area Highlight: <strong>Cyber Security</strong>
              </strong>
              <br />
              TD SYNNEX security services cover a wide range of security
              capabilities from security assessments, penetration tests,
              governance and incident response, through ongoing day to day
              security management, training, asset disposal and data
              destruction. These services can be purchased with vendor products
              bought through TD SYNNEX or stand alone.
            </p>
          </div>
          <div className="col-md-5 order-md-1">
            <img src="install.jpg" className="d-block w-100" />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <center>
              <p className="lead">Contact Us:</p>
            </center>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <center>
              <a href="mailto:serviceBD@tdsynnex.com">
                {" "}
                <p className="lead">ServiceBD@tdsynnex.com </p>
              </a>
            </center>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-12">
            <center>
              <ResponsivePlayer url="https://play.vidyard.com/RgZVAnZ82uU2siRyp8ciHM.jpg" />
            </center>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col"></div>
          <div className="col">
            <center>
              <Link to="/deliveredServices" className="text-decoration-none">
                <p className="lead">See Our List of Services</p>
              </Link>
            </center>
          </div>
          <div className="col"></div>
        </div>
      </div>
    </>
  );
}
/*
              <ReactPlayer
                url="https://play.vidyard.com/RgZVAnZ82uU2siRyp8ciHM.jpg"
                control="true"
              />
*/
