import React, { useState } from "react";
import _ from "lodash";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Table from "../common/table";
import Select from "react-select";
import ExportExcel from "../common/excelExport";
import { format } from "date-fns";

function TopicDemoReport(props) {
    const [count, setCount] = useState(5);
    const [topics, setTopics] = useState();
    const [exportData, setExportData] = useState(null);
    const [dateString, setDateString] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

   // if(props.vendors === null) return null;

    useEffect(() => {
        setIsLoading(true);
        let data = [];
        const today = format(new Date(), "ddMMMyyyy");
        setDateString(today);
        //this sort ascending by default
        const sortedtopics = _.orderBy(props.topics, ['demos.length'], ['desc'] );
        const topList = _.slice(sortedtopics,0,count);
        console.log(topList);
        sortedtopics.map((t) => data.push({Name: t.title, demoCount: t.demos.length}));
        setExportData(data);
        setTopics(topList);
        setIsLoading(false);
    }, [props.topics, count]);

    const topListCount = [
        { value: 5, label: 5 },
        { value: 10, label: 10 },
        { value: 15, label: 15 },
        { value: 20, label: 20 }
    ];

    const changeCount = (e) => {
        setCount(e.value);
    };

    const columns = [
       {
        path: "title",
        label: "Topic",
        content: (topic) => <Link to={`/categoryDetail/${topic.id}`}>{topic.title}</Link>,
       },
       {
        path: "demos.length",
        label: "Demo Count",
       },
    ];

    return (
        <>
         {isLoading ? (
            <div className="text-center p-3">
              <span className="spinner-border spinner-border-lg align-center"></span>
            </div>
         ) : (
            <div className="report-tile">
              <center>
                <p className="lead">Top {count} Topics with Most Demos </p>
                <div className="d-inline-flex align-items-center">
                  <div style={{maxWidth: "250px"}}>
                    <Select
                      id="topCount"
                      options={topListCount}
                      onChange={changeCount}
                      placeholder="How many?"
                    />
                  </div>
                  <div className="d-inline-flex ps-2">
                    <ExportExcel 
                      excelData={exportData} 
                      fileName={`Topic-Demo-Report-${dateString}`} />
                  </div>
                </div>
              </center>
            <div>
                <Table columns={columns} data={topics} sortColumn={"Views"} />
            </div>
            </div>
         )}
        </>
    );
}

export default TopicDemoReport;

/*

 <div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Views</th>
                        </tr>
                    </thead>
                    <tbody>
                        {vendors ? (
                            vendors.map((vendor) => {
                                <tr>
                                    <td>
                                      <Link to={`/vendorDetail/${vendor.id}`}>{vendor.name}</Link>
                                    </td>
                                    <td>
                                        {vendor.views}
                                    </td>
                                </tr>
                            })
                        ) : (
                            <tr> <td>NO Data</td></tr>
                        )}
                    </tbody>
                </table>
            </div>
*/