import React, { Component } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import _ from "lodash";
import Select from "react-select";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";

import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";
import Table from "../common/table";
import ExportExcel from "../common/excelExport";
import authService from "../../services/auth.service";
import { getDTCRequests, getDTCRequestsSinceDate } from "../../services/dtcRequestService";

class DTCActivitiesTable extends Component {
  constructor(props) {
    super(props);
    this.typeRef = React.createRef();
    this.actionRef = React.createRef();
  }
  state = {
    //activities: this.props.dtcDTCRequests,
    activities: [],
    currentUser: null,
    dateString: null,
    exportData: null,
    startDateString: null,
    startDate: null,
    endDate: null,
    isLoading: false,
    currentPage: 1,
    pageSize: 200,
    sortColumn: { path: "createdAt", order: "desc" },
  };

  async componentDidMount() {
    this.setState({ isLoading: true});

    const defaultStartDate = new Date();
    defaultStartDate.setDate(defaultStartDate.getDate() - 14);
    let textdate = defaultStartDate.toISOString();

    let filteredActivies = [];
    const localDTCRequests = await getDTCRequestsSinceDate(textdate);
    //const localDTCRequests = await getDTCRequests();
    localDTCRequests.map(a => {
      if(a.user != null) filteredActivies.push(a);
    });
    const cu = authService.getCurrentUser();
    const today = format(new Date(), "ddMMMyyyy");
    const strDate = format(new Date(defaultStartDate), "MMM dd, yyyy p");
    console.log(localDTCRequests);
    console.log(filteredActivies);
    this.setState({
      //activities: localDTCRequests,
      activities: filteredActivies,
      currentUser: cu,
      isLoading: false,
      dateString: today,
      startDate: defaultStartDate,
      startDateString: strDate,
    });
  }
/*
  handleDelete = async (DTCRequest) => {
    const deleted = await removeUserActivity(DTCRequest.id);
    console.log(deleted);
    if (deleted.status === 200) {
      const fedbks = this.state.activities.filter(
        (fb) => fb.id !== DTCRequest.id
      );
      this.setState({ activities: fedbks });
    }
  };
*/

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      activities,
      startDate,
      endDate,
    } = this.state;


    let startDateFiltered = [];
    if (startDate) {
      const sDate = new Date(startDate);

      for (const activity of activities) {
        const aDate = new Date(activity.createdAt);
        //if (activity.createdAt >= startDate) {
        if (aDate >= sDate) {
          startDateFiltered.push(activity);
        }
      }
    } else {
      startDateFiltered = activities;
    }

    let endDateFiltered = [];
    if (endDate) {
      const eDate = new Date(endDate);

      for (const activity of startDateFiltered) {
        const aDate = new Date(activity.createdAt);
        //if (activity.createdAt >= startDate) {
        if (aDate <= eDate) {
          endDateFiltered.push(activity);
        }
      }
    } else {
      endDateFiltered = startDateFiltered;
    }

    const sorted = _.orderBy(
      //categoryFiltered,
      //this.state.activities,
      endDateFiltered,
      [sortColumn.path],
      [sortColumn.order]
    );

    const filteredDTCRequests = paginate(sorted, currentPage, pageSize);

    console.log("sorted DTC Requests:");
    console.log(sorted);

    // Need to cleanup nested objects in sorted and save as exportData
    let exportArray = [];
    for(const act of sorted) {
       exportArray.push({Date: act.createdAt,
                         Name: act.productName,
                         EndUser: act.endUserName,
                         Revenue: act.potentialRevenue,
                         Type: act.activityType,
                         DealReg: act.dealReg,
                         Comments: act.comments, 
                         PortalUser: act.user.fullName,
                         PortalUserEmail: act.user.email,
                         PartnerName: act.user.reseller.name,
                         PartnerAcctNum: act.user.reseller.acctNum,
       });
    }

    return {
      //exportData: sorted,
      exportData: exportArray,
      totalCount: sorted.length,
      data: filteredDTCRequests,
    };
  };

  changePageSize = (e) => {
    this.setState({ pageSize: e.value, currentPage: 1 });
  };

  changeStartDate = async (date) => {
    const newStartDate = new Date(date);
    let isoDate = newStartDate.toISOString();
    const localDTCRequests = await getDTCRequestsSinceDate(isoDate);
    this.setState({ startDate: date,
        activities: localDTCRequests,
     });
  };

  changeEndDate = (date) => {
    this.setState({ endDate: date });
  };


  columns = [
    {
      path: "createdAt",
      label: "Date",
      content: (DTCRequest) => (
        <Link to={`/DTCRequest/${DTCRequest.id}`}>
          <small>
            {format(new Date(DTCRequest.createdAt), "Pp")}
          </small>
        </Link>
      ),
    },
    {
      path: "user.fullName",
      label: "User",
      content: (DTCRequest) => (
        <Link to={`/userDetail/${DTCRequest.user.id}`}>
          <small>
            {DTCRequest.user.fullName}
            <small>
              {" ("}
              {DTCRequest.user.userName}
              {") "}{" "}
            </small>
          </small>
        </Link>
      ),
    },
    { path: "productName", label: "Product",
      content: (DTCRequest) => (
        <Link to={`/demoDetail/${DTCRequest.demo.id}`}>
          <small>
            {DTCRequest.demo.name}  
          </small>
        </Link>
      ),
     },
    { path: "activityType", label: "Activity" },
    { path: "endUserName", label: "End User" },
    { path: "potentialRevenue", label: "Revenue" },
    {
      path: "user.reseller.name",
      label: "Account",
      content: (DTCRequest) => (
        <Link to={`/resellerDetail/${DTCRequest.resellerId}`}>
          {DTCRequest.user.reseller.name}
        </Link>
      ),
    },
    /*
    {
      key: "delete",
      content: (DTCRequest) => (
        <button
          onClick={() => this.handleDelete(DTCRequest)}
          className="btn btn-danger btn-sm"
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </button>
      ),
    },
     */
  ];

  render() {
    const { length: count } = this.state.activities;
    const { pageSize, currentPage, sortColumn, currentUser, dateString } =
      this.state;

    if (this.state.isLoading)
      return (
        <div className="text-center p-3">
          {" "}
          <span className="spinner-border spinner-border-lg align-center"></span>{" "}
        </div>
      );
    if (count === 0) return <p>There are no logs to show.</p>;

    const {
      exportData,
      totalCount,
      data: filteredDTCRequests,
    } = this.getPagedData();

    const selectOptions = [
      { value: 5, label: 5 },
      { value: 10, label: 10 },
      { value: 15, label: 15 },
      { value: 20, label: 20 },
      { value: 25, label: 25 },
      { value: 50, label: 50 },
      { value: 100, label: 100 },
      { value: 200, label: 200 },
    ];

    return (
      <React.Fragment key={89}>
        <div className="d-flex flex-row align-items-center justify-content-center">
          <h2 className="display-6">DTC Access Since {this.state.startDateString}</h2>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-center mt-2">
          <div className="d-inline-flex pe-2">
            <ReactDatePicker
              selected={this.state.startDate}
              isClearable
              onChange={this.changeStartDate.bind(this)}
              placeholderText="Choose start date"
              dateFormat="MM/dd/yyyy h:mm aa"
            />
          </div>
          <div className="d-inline-flex pe-2">
            <ReactDatePicker
              selected={this.state.endDate}
              isClearable
              onChange={this.changeEndDate.bind(this)}
              placeholderText="Choose end date"
              dateFormat="MM/dd/yyyy h:mm aa"
            />
          </div>
          
          <div className="d-inline-flex pe-2">
            <ExportExcel
              excelData={exportData}
              fileName={`DTC-Access-${currentUser?.firstName}-${dateString}`}
            />
          </div>

        </div>
        <div className="d-flex flex-row">
          <Table
            columns={this.columns}
            data={filteredDTCRequests}
            sortColumn={this.state.sortColumn}
            onSort={this.handleSort}
            caption="Activity Log"
          />
        </div>
        <div className="d-flex flex-row">
          <div className="d-inline-flex pe-2">
            <Pagination
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          </div>
          <div className="d-inline-flex">
            <Select
              id="pgSize"
              options={selectOptions}
              onChange={this.changePageSize.bind(this)}
              placeholder="Number of Items / Page"
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DTCActivitiesTable;

/* 
             fileName="test" 
             fileName={`PortalLogs-${currentUser?.firstName}-${dateString}`}
             */

/*

  changeShowAdminActions = (e) => {
    console.log(this.state.showAdminActivity);
    this.setState({ showAdminActivity: !this.state.showAdminActivity });
  };

let typeFiltered = [];
    if (selectedEntityType) {
      for (const activity of endDateFiltered) {
        if (activity.entityType === selectedEntityType) {
          typeFiltered.push(activity);
        }
      }
    } else {
      typeFiltered = endDateFiltered;
    }

    let actionFiltered = [];
    if (selectedEntityAction) {
      for (const activity of typeFiltered) {
        if (activity.entityAction === selectedEntityAction) {
          actionFiltered.push(activity);
        }
      }
    } else {
      actionFiltered = typeFiltered;
    }
  changeType = (e) => {
    e
      ? this.setState({ selectedEntityType: e.value, currentPage: 1 })
      : this.setState({ selectedEntityType: null, currentPage: 1 });
  };

  changeAction = (e) => {
    e
      ? this.setState({ selectedEntityAction: e.value, currentPage: 1 })
      : this.setState({ selectedEntityAction: null, currentPage: 1 });
  };

    const typeOptions = [
      { value: "Demo", label: "Demo" },
      { value: "User", label: "User" },
      { value: "PoC Kit", label: "PoC Kit" },
      { value: "Reseller", label: "Reseller" },
      { value: "Solution Center", label: "Solution Center" },
      { value: "Vendor", label: "Vendor" },
    ];

    const actionOptions = [
      { value: "CREATE", label: "Create" },
      { value: "DELETE", label: "Delete" },
      { value: "LOGIN", label: "Login" },
      { value: "SCHEDULE", label: "Schedule" },
      { value: "UPDATE", label: "Update" },
      { value: "VIEW", label: "View" },
      { value: "WATCH", label: "Watch" },
      { value: "DTC Access", label: "DTC Access" },
    ];


<div className="d-inline-flex pe-2">
            <Select
              id="entityType"
              options={typeOptions}
              onChange={this.changeType.bind(this)}
              isClearable="1"
              placeholder="Choose Record Type"
            />
          </div>
          <div className="d-inline-flex pe-2">
            <Select
              id="entityAction"
              options={actionOptions}
              onChange={this.changeAction.bind(this)}
              isClearable="1"
              placeholder="Choose Action Type"
            />
          </div>
          <div className="d-inline-flex pe-2">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="32456"
                checked={this.state.showAdminActivity}
                onChange={this.changeShowAdminActions.bind(this)}
              />
              <label className="form-check-label" htmlFor="32456">
                Show Admin DTCRequests
              </label>
            </div>
          </div>

*/