import React, { Component } from "react";
import InstructorDemosTable from "./instructorDemosTable";
import { getInstructors } from "../../services/instructorService";
import BackButton from "../common/backButtion";

/* */

class InstructorsReport extends Component {
  state = {
    instructors: [],
    selectedInstructor: {},
    isLoading: false,
  };

  async componentDidMount() {
    //if (isDebug) logData(this.props);
    this.setState({ isLoading: true });
    const instructors = await getInstructors();
    this.setState({ instructors });
    this.setState({ isLoading: false });
  }
  //

  render() {
    const { length: count } = this.state.instructors;
    //const { pageSize, currentPage, sortColumn } = this.state;

    if (this.state.isLoading)
      return (
        <div className="text-center p-3">
          {" "}
          <span className="spinner-border spinner-border-lg align-center"></span>{" "}
        </div>
      );

    if (count === 0) return <p>There are no instructors in the database.</p>;

    //const { totalCount, data: instructors } = this.getPagedData();
   // const tableCaption = "Showing  " + totalCount + " instructors.";

    return (
      <>
      <div className="container shadow border border-1 border-light rounded-3 pt-2 pb-2 mt-2">
        <div className="d-flex flex-row">
          <div className="col-2 ">
            <>
              <BackButton />
            </>
          </div>

          <div className="col-10 ">
            
          </div>
        </div>
        <h2 className="display-6 mt-3 mb-3 "> 
          <center>
             All Instructors Demo Report
          </center>
        </h2>
        {this.state.instructors.map((instructor) => (
          <div>
            <>
            <hr />
            <p className="lead ">{ `Demos by ${instructor.user.fullName}` }</p>
            {instructor.demos?.length < 1 ? (

             <p className="text-muted text center">Instructor has no demos!</p>
            ) : (
            <InstructorDemosTable demos={instructor.demos} />
            )}
            </>
          </div>
        ) )}
       
     
      </div>
      <div> 
        <p className="text-muted">
          <center><small>
            End of Page
          </small></center>
        </p> 
      </div>
      </>
    );
  }
}

export default InstructorsReport;
/*

            <InstructorDemosTable
              demos={instructor.demos}
              caption={`${instructor.user.fullName}'s Demos`}
            />
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleInstructorSelect = (instructor) => {
    this.setState({ selectedInstructor: instructor });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      selectedInstructor,
      instructors,
    } = this.state;

    const filtered =
      selectedInstructor && selectedInstructor.id
        ? instructors.filter((i) => i.id === selectedInstructor.id)
        : instructors;
    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const instructors_tmp = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: instructors_tmp };
  };



 */

/*

 <InstructorsTable
          instructors={instructors}
          sortColumn={sortColumn}
          onSort={this.handleSort}
          caption={tableCaption}
        />
     <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={this.handlePageChange}
        />
*/
