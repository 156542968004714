import React from "react";

const StreetAddress = ({ address }) => {
  return (
    <table>
      <tbody>
        <tr>
          <td>
            {address.street1} {address.street2}
          </td>
        </tr>
        <tr>
          <td>
            {address.city} {address.state}, {address.zip}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default StreetAddress;
