import React, { useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import { getDemoInfo, updateRating } from "../../services/demoService";
import authService from "../../services/auth.service";

export default function RatingWrapper({ type, id, edit = true, val = null}) {
  const [user, setUser] = useState(null);
  //const [type, setType] = useState(null);
  //const [id, setId] = useState(null);
  //const [val, setVal] = useState(null);
  //const [edit, setEdit] = useState(true);
  const [entity, setEntity] = useState(null);
  const [rating, setRating] = useState(null);

  useEffect(() => {
    const cu = authService.getCurrentUser();
    setUser(cu);

   // console.log("val = " + val);
    //console.log("edit = " + edit);

    const getEntityData = async () => {
      if(type === "Demo") {
        const demoInfo = await getDemoInfo(id);
        //console.log(demoInfo);   
        setEntity(demoInfo);
      }
    }
    getEntityData().catch((error) => console.log(error));    
  }, []);

  //fires when the rating changes
  useEffect(() => {
    const updateDemoRating = async () => {
      const updateResponse = await updateRating(id, {userId: user.id, rating: rating});
      console.log(updateResponse);
    }

    if(rating !== null) {
      if(type === "Demo") {
        updateDemoRating().catch((error) => console.log(error));
      }
    }
  }, [rating]);

  const ratingChanged = (newRating) => {
    console.log(newRating);
    setRating(newRating);
  };

  return (
    <div>
      {val === null ? 
        <ReactStars
          count={5}
          size={24}
          isHalf={true}
          edit={true}
          onChange={ratingChanged}
        />
     : 
        <ReactStars
          count={5}
          size={24}
          isHalf={true}
          edit={false}
          value={val}
        />
      }
    </div>
  );
}