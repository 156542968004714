import axios from "axios";
import config from "../config.json";
import { isDebug, logData } from "../components/common/helpers";

//axios.defaults.baseURL = "http://odin.synnexlabz.local:31337";
axios.defaults.baseURL = config.defaultBaseURL;
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

if (isDebug) {
  logData("Default Base API URL: " + config.defaultBaseURL);
}

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    console.log("Logging the error: ", error);
    alert("An unexpected error occurred.");
  }

  return Promise.reject(error);
});

/*
  TESTING
*/
function handleError(e) {
  //  https://developer.mozilla.org/en-US/docs/Web/HTTP/Status#server_error_responses
  let msg = "";
  switch (e.response.status) {
    case 400:
      console.log(e);
      msg =
        e.response.data +
        " " +
        "Error: (Bad Request) There was something wrong with your request. Client Error!";
      break;
    case 403:
      msg =
        "Error: (Forbidden) You do not have permission to perform this action!";
      break;
    case 401:
      msg = "Error: (Unauthorized) You have not been AUTHENTICATED!";
      break;
    default:
      msg =
        "Error Status: " +
        e.response.status +
        " " +
        e.response.statusText +
        " " +
        e.response?.data;
  }

  alert(msg);
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  handleError,
};
