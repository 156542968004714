import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import _ from "lodash";

import DemosTable from "./demosTable";
import { getDemos, removeDemo } from "../../services/demoService";
import { getVendors } from "../../services/vendorService";
import { getInstructors } from "../../services/instructorService";
import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";
import { logData, isDebug } from "../common/helpers";
import authService from "../../services/auth.service";
import rbacService from "../../services/rbacService";
import { getCategories } from "../../services/categoryService";
import BackButton from "../common/backButtion";
import ExportExcel from "../common/excelExport";
import { format } from "date-fns";


class Demos extends Component {
  constructor(props) {
    super(props);
    this.iDref = React.createRef();
    this.vendorRef = React.createRef();
    this.instructorRef = React.createRef();
    this.categoryRef = React.createRef();
    this.handleDelete = this.handleDelete.bind(this);
  }
  state = {
    demos: [],
    instructors: [],
    vendors: [],
    categories: [],
    selectedId: {},
    selectedVendor: {},
    selectedInstructor: {},
    selectedCategory: {},
    currentPage: 1,
    pageSize: 10,
    sortColumn: { path: "name", order: "asc" },
    showAddButton: false,
    isLoading: false,
    currentUser: {},
    exportData: null,
    dateString: null,
    rbac: null,
  };

  run = 0;
  async componentDidMount() {
    if(this.run === 0){
      this.setState({ isLoading: true });
      const today = format(new Date(), "ddMMMyyyy");
      const demos = await getDemos();
      const vendors = await getVendors();
      const instructors = await getInstructors();
      const categories = await getCategories();
      const currentUser = authService.getCurrentUser();
      let rb = new rbacService(currentUser);
      if(rb.isAdminOrInstructor()) {
        this.setState({ showAddButton: true });
      } 
      this.setState({ demos, vendors, instructors, categories, currentUser, dateString: today, rbac: rb });
      this.setState({ isLoading: false });
      this.run = 1;
    }
  }

  handleDelete = async (demo) => {
    const deleted = await removeDemo(demo.id);
    if (deleted.status === 200) {
      const dmos = this.state.demos.filter((d) => d.id !== demo.id);
      this.setState({ demos: dmos });
    }
  };

  handleLike = (demo) => {
    const demos = [...this.state.demos];
    const index = demos.indexOf(demo);
    demos[index] = { ...demos[index] };
    demos[index].liked = !demos[index].liked;
    this.setState({ demos });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleIdSelect = (id) => {
    console.log(id);
    if(id !== undefined) {
      this.setState({ selectedId: id, currentPage: 1});
    }
  }

  handleVendorSelect = (vendor) => {
    if(vendor !== undefined) {
      this.setState({ selectedVendor: vendor, currentPage: 1 });
    }
  };

  handleInstructorSelect = (instructor) => {
    if(instructor !== undefined) {
      this.setState({ selectedInstructor: instructor, currentPage: 1 });
    }
  };

  handleCategorySelect = (category) => {
    if(category !== undefined) {
      this.setState({ selectedCategory: category, currentPage: 1 });
    }
  };

  handleSort = (sortColumn) => {
    if (isDebug)
      console.log(
        "Sort Column = " + sortColumn.path + " order = " + sortColumn.order
      );
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      selectedVendor,
      selectedInstructor,
      selectedCategory,
      selectedId,
      demos,
    } = this.state;

    /* */
    let idfiltered = [];
    if (selectedId.id) {
      for (const demo of demos) {
        if(demo.id === selectedId.id) {
          idfiltered.push(demo);
        }
      }
    } else {
      idfiltered = demos;
    }

    let vendorfiltered = [];
    if (selectedVendor.id) {
      for (const demo of idfiltered) {
        for (const vdr of demo.vendors) {
          if (vdr.id === selectedVendor.id) {
            vendorfiltered.push(demo);
            console.log(demo);
          }
        }
      }
    } else {
      vendorfiltered = idfiltered;
    }
    /*
    const vendorfiltered = selectedVendor?.id
      ? demos.filter((demo) =>
          demo.vendors.every((vendor) => vendor.id === selectedVendor.id)
        )
      : demos;

     */
    let instructorfiltered = [];
    if (selectedInstructor.id) {
      for (const demo of vendorfiltered) {
        for (const teacher of demo.instructors) {
          if (teacher.id === selectedInstructor.id) {
            instructorfiltered.push(demo);
            console.log(demo);
          }
        }
      }
    } else {
      instructorfiltered = vendorfiltered;
    }

    let categoryFiltered = [];
    if (selectedCategory?.title) {
      for (const demo of instructorfiltered) {
        for (const cat of demo.categories) {
          if (cat.title === selectedCategory.title) {
            categoryFiltered.push(demo);
            console.log(demo);
          }
        }
      }
    } else {
      categoryFiltered = instructorfiltered;
    }

    const sorted = _.orderBy(
      categoryFiltered,
      [sortColumn.path],
      [sortColumn.order]
    );

    const fdemos = paginate(sorted, currentPage, pageSize);

    let data = [];
    if(sorted.length > 0){
      sorted.map((d) => data.push({ DemoId: d.id,
        Name: d.name,
        Description: d.description,
        Type: d.type,
        Topic: d.categories[0]?.title,
        Vendor: d.vendors[0]?.name,
        Instructor: d.instructors[0]?.user.fullName,
      }));
    }

    return { exportData: data, totalCount: categoryFiltered.length, data: fdemos };
  };

  changePageSize = (e) => {
    this.setState({ pageSize: e.value, currentPage: 1 });
  };

  render() {
    const { length: count } = this.state.demos;
    const { pageSize, currentPage, sortColumn, dateString, rbac, currentUser } = this.state;

    if (this.state.isLoading)
      return (
        <div className="text-center p-3">
          {" "}
          <span className="spinner-border spinner-border-lg align-center"></span>{" "}
        </div>
      );
    if (count === 0) return <p>There are no demos in the database.</p>;

    const { exportData, totalCount, data: demos } = this.getPagedData();
    const myCaption = "Showing " + totalCount + " demos";

    const selectOptions = [
      { value: 5, label: 5 },
      { value: 10, label: 10 },
      { value: 15, label: 15 },
      { value: 20, label: 20 },
      { value: 25, label: 25 },
      { value: 50, label: 50 },
    ];

    if (isDebug) console.log(demos);

    return (
      <React.Fragment key={6789}>
        <div className="container pt-2">
          <div className="d-flex flex-row">
            <div className="col-3 ">
              <div className="d-inline-flex">
                <BackButton />
                {rbac  && rbac.isAdminOrInstructor() ?
                  <>
                    <Link to="/demoForm/new">
                      <button className="btn btn-primary btn-sm ms-2">
                        Add Demo
                      </button>
                    </Link>
                    <div className="d-inline-flex ps-2">
                      <ExportExcel 
                        excelData={exportData} 
                        fileName={`Portal-Demo-List-${currentUser?.fullName}-${dateString}`} 
                      />
                    </div>
                  </>
                 : null }
              </div>
            </div>
            <div className="col-9 ">
              <div className="row">
                <div className="col d-flex">
                <div className="typeahead form-floating ms-2">
                    <div className="d-flex">
                      <button
                        className="btn btn-sm btn-secondary me-1"
                        onClick={() => {
                          this.iDref.current.clear();
                          this.setState({ selectedId: {} });
                        }}
                      >
                        Clear
                      </button>
                      <Typeahead
                        id="idFilter"
                        ref={this.iDref}
                        labelKey={(option) => `${option.id}`}
                        placeholder="Filter by Demo ID"
                        minLength={1}
                        size="sm"
                        options={this.state.demos}
                        onChange={(selected) => {
                          this.handleIdSelect(selected[0]);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col d-flex">
                  <div className="typeahead form-floating ms-2">
                    <div className="d-flex">
                      <button
                        className="btn btn-sm btn-secondary me-1"
                        onClick={() => {
                          this.categoryRef.current.clear();
                          this.setState({ selectedCategory: {} });
                        }}
                      >
                        Clear
                      </button>
                      <Typeahead
                        id="categoryFilter"
                        ref={this.categoryRef}
                        labelKey={(option) => `${option.title}`}
                        placeholder="Filter by Topic"
                        minLength={2}
                        size="sm"
                        options={this.state.categories}
                        onChange={(selected) => {
                          this.handleCategorySelect(selected[0]);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md mt-1 mb-1">
                  <div className="typeahead form-floating ">
                    <div className="d-flex">
                      <button
                        className="btn btn-sm btn-secondary me-1"
                        onClick={() => {
                          this.vendorRef.current.clear();
                          this.setState({ selectedVendor: {} });
                        }}
                      >
                        Clear
                      </button>
                      <Typeahead
                        id="vendorFilter"
                        ref={this.vendorRef}
                        labelKey={(option) => `${option.name}`}
                        placeholder="Filter by Vendor"
                        minLength={2}
                        size="sm"
                        options={this.state.vendors}
                        onChange={(selected) => {
                          this.handleVendorSelect(selected[0]);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="typeahead form-floating">
                    <div className="d-flex">
                      <button
                        className="btn btn-sm btn-secondary me-1"
                        onClick={() => {
                          this.instructorRef.current.clear();
                          this.setState({ selectedInstructor: {} });
                        }}
                      >
                        Clear
                      </button>
                      <Typeahead
                        id="instructorFilter"
                        ref={this.instructorRef}
                        labelKey={(option) => `${option.user.fullName}`}
                        placeholder="Filter by Instructor"
                        minLength={2}
                        size="sm"
                        options={this.state.instructors}
                        onChange={(selected) => {
                          this.handleInstructorSelect(selected[0]);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h2 className="display-6 mt-3">Demo List</h2>

          <DemosTable
            demos={demos}
            sortColumn={sortColumn}
            onLike={this.handleLike}
            onDelete={this.handleDelete}
            onSort={this.handleSort}
            caption={myCaption}
          />
          <div className="d-inline-flex pe-2">
            <Pagination
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          </div>
          <div className="d-inline-flex">
            <Select
              id="pgSize"
              options={selectOptions}
              onChange={this.changePageSize.bind(this)}
              placeholder="Number of Items / Page"
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Demos;

    /*
    if (currentUser.roleId === 1 || currentUser.roleId === 2) {
      // current User is either Admin or Instructor
      this.setState({ showAddButton: true });
    }
    */
/*
          <nav className="quicklinks">
            <div className="row">
              <div className="col"></div>
              <div className="col">
                <QuickLink
                  title="Delivered Services"
                  link="/deliveredServices"
                />
              </div>
              <div className="col"></div>
            </div>
          </nav>
 */

    /*
    this.setState({ vendors });
    this.setState({ instructors });
    this.setState({ currentUser });
    */