import React, { useState, useEffect } from "react";
import httpService from "../../services/httpService";

import { isDebug } from "../common/helpers";
import { getSignups, getSignupsSinceDate, removeSignup } from "../../services/signupService";
import BackButton from "../common/backButtion";
import NewUserRequestsTable from "./newUserRequestsTable";

export default function NewUserRequests() {
  const [signups, setSignups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sinceDate, setSinceDate] = useState(false);
  const [btnLabel, setBtnLabel] = useState("Show Deleted Records");

  const toggleSinceDate = () => {
    if(sinceDate) {
      setBtnLabel("Show Deleted Records");
    } else {
      setBtnLabel("Hide Deleted Records");
    }
    setSinceDate(!sinceDate);
  }

  const handleDelete = async (signup) => {
    console.log("Delete Clicked");
    const deleted = await removeSignup(signup.id);
    console.log(deleted);
    if (deleted.status === 200) {
      const fedbks = signups.filter((s) => s.id !== signup.id);
      //this.setState({ signups: fedbks });
      setSignups(fedbks);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    console.log("sinceDate useEffect run ...");
    /*
    */
    if(sinceDate) {
      const threeWeeksAgo = new Date();
      // 21 here is 21 days
      threeWeeksAgo.setDate(threeWeeksAgo.getDate() - 21);
      let textdate = threeWeeksAgo.toISOString();
      //console.log(textdate);
  
      const getSignupsForReporting = async () => {
        //const localUserActivities = await getUserActivities();
        const localSignupsForReporting = await getSignupsSinceDate(textdate);
        setSignups(localSignupsForReporting);
        if (isDebug) console.log(localSignupsForReporting);
      }
      getSignupsForReporting().catch(console.error);

    } else {
        const getSignupsList = async () => {
          const localSignups = await getSignups();
          setSignups(localSignups);
        };
        getSignupsList().catch((error) => httpService.handleError(error));
    }

    setIsLoading(false);
  }, [sinceDate ]);

  useEffect(() => {
    console.log(signups);
  }, [signups]);

  return (
    <>
      <div className="container shadow border border-1 border-light rounded-3 pt-2 pb-2 mt-2">
        <>
          <BackButton />
          <button className="btn btn-sm btn-primary ms-2" onClick={toggleSinceDate}>
            {btnLabel}    
          </button> 
        </>
        <h2 className="display-6">New User Requests</h2>
        {isLoading ? (
          <div className="text-center p-3">
            <span className="spinner-border spinner-border-lg align-center"></span>
          </div>
        ) : (
          signups?.length > 0 && (
            <>
             
              <NewUserRequestsTable signups={signups} delete={handleDelete} />
            </>
          )
        )}
      </div>
    </>
  );
}

/*
 <button className="btn btn-primary" onClick={setSinceDate(true)}>
                Get Reporting Data
              </button> 
*/
