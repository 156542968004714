import React from "react";
import { HashLink } from "react-router-hash-link";

export default function SupportServicesFeature() {
  return (
    <>
      <hr className="featurette-divider" />
      <div className="container">
        <div className="row featurette align-items-center">
          <div className="col-md-7">
            <h2 className="ss-featurette-heading fw-normal lh-1">
              Support Services
            </h2>

            <p className="lead">
              Another way TD SYNNEX can help? We have Helpdesk, Managed
              Services, and GoldSeal available to assist you on day two on
              onwards during your customer's journey.
            </p>
          </div>
          <div className="col-md-5 ">
            <img src="customerserviceblockimg.jpg" className="d-block w-100" />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <center>
              <p className="lead">Contact Us:</p>
            </center>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <center>
              <a href="mailto:supportservices@tdsynnex.com">
                {" "}
                <p className="lead">SupportServices@tdsynnex.com </p>
              </a>
            </center>
          </div>
        </div>

        <div className="row mt-2 mb-2">
          <div className="col-md">
            <center className="fs-3">Helpdesk</center>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">General Usage Questions</li>
              <li className="list-group-item">Desktop Support</li>
              <li className="list-group-item">Application Support</li>
              <li className="list-group-item">User Access Support</li>
              <li className="list-group-item">Basic Troubleshooting</li>
              <li className="list-group-item">User Management</li>
              <li className="list-group-item">Data Gathering</li>
              <li className="list-group-item">Ticket Escalation</li>
            </ul>
          </div>
          <div className="col-md">
            <center className="fs-3">Managed Services</center>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">Proactive Management</li>
              <li className="list-group-item">Problem Assignment</li>
              <li className="list-group-item">Problem Analysis</li>
              <li className="list-group-item">Escalation and Resolution</li>
              <li className="list-group-item">Software and Patch Managment</li>
              <li className="list-group-item">Basic Change Management</li>
              <li className="list-group-item">Managed RMM Tool</li>
              <li className="list-group-item">Dashboard Reports</li>
            </ul>
          </div>
          <div className="col-md">
            <center className="fs-3">GoldSeal</center>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                24/7 Nationwide Emergency Support
              </li>
              <li className="list-group-item">100% Vendor Approved</li>
              <li className="list-group-item">
                100% of Vendor Maintenance Entitlements
              </li>
              <li className="list-group-item">
                Incident Management from First Call to Resolution
              </li>
              <li className="list-group-item">
                Next Business Day Engineer to Site for Replacement Equipment
              </li>
              <li className="list-group-item">Startup Assistance</li>
              <li className="list-group-item">Cross Vendor Experience</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
