import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../common/table";
import authService from "../../services/auth.service";
import rbacService from "../../services/rbacService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";

class PocKitItemsTable extends Component {
  state = {
    pocItems: this.props.pocItems,
    caption: this.props.caption,
    sortColumn: { path: "id", order: "asc"},
    currentUser: {},
    rbac: {},
    showAddButton: false,
  };


  componentDidMount() {
    const loggedInUser = authService.getCurrentUser();
    const localRBAC = new rbacService(loggedInUser);

    this.setState({ currentUser: loggedInUser, rbac: localRBAC });
    //if (loggedInUser.roleId <= 2) {
    if (localRBAC.isAdminOrLoanerAdmin()) {
      // logged in user is either admin or instructor
      this.columns.push(...this.adminColumns);
      this.setState({ showAddButton: true });
    }
  }

  columns = [
    { path: "type", label: "Type" },
    {
      path: "name",
      label: "Name",
      content: (pocItem) => (
        <Link to={`/pocItem/${pocItem.id}`}>{pocItem.name}</Link>
      ),
    },
    { path: "status", label: "Status" },
    { path: "serialNum", label: "Serial #" },
    { path: "mfgPartNum", label: "Mfg Part #" },
    { path: "sapMatId", label: "SAP Mat ID" },
  ];
    
  adminColumns = [
    {
      key: "edit",
      content: (pocItem) => (
        <Link to={`/pocItemForm/${pocItem.id}`}>
          <FontAwesomeIcon icon={faPenToSquare} />
        </Link>
      ),
    },
    {
      key: "delete",
      content: (pocItem) => (
        <button
          onClick={() => this.props.onDelete(pocItem)}
          className="btn btn-danger btn-sm"
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </button>
      ),
    },
  ];

  render() {
    const { pocItems,  caption, sortColumn } = this.state;

    return (
      <Table
        columns={this.columns}
        data={pocItems}
        sortColumn={sortColumn}
        caption={caption}
      />
    );
  }
}

export default PocKitItemsTable;
/*

      <Table
        columns={this.columns}
        data={pocItems}
        sortColumn={sortColumn}
        onSort={onSort}
        onDelete={onDelete}
        caption={caption}
      />
*/