import http from "./httpService";
import authHeader from "./auth-header";

export async function getResellers() {
  const { data: resellers } = await http.get("/api/v1/resellers/", {
    headers: authHeader(),
  });
  return resellers;
}

export async function getResellerInfo(resellerId) {
  const { data: resellerInfo } = await http.get(
    `/api/v1/resellers/${resellerId}`,
    {
      headers: authHeader(),
    }
  );
  return resellerInfo;
}

export async function updateReseller(resellerid, reseller) {
  const response = await http.put(`/api/v1/resellers/${resellerid}`, reseller, {
    headers: authHeader(),
  });
  return response;
}

export async function createReseller(reseller) {
  const response = await http.post("/api/v1/resellers/", reseller, {
    headers: authHeader(),
  });
  return response;
}

export async function removeReseller(resellerId) {
  const response = await http.delete(`/api/v1/resellers/${resellerId}`, {
    headers: authHeader(),
  });
  return response;
}
