import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import http from "../../services/httpService";
import authService from "../../services/auth.service";
import { getDemoInfo } from "../../services/demoService";
import {
  getReservationInfo,
  updateReservation,
  createReservation,
} from "../../services/reservationService";
import { getResellerInfo } from "../../services/resellerService";
import { logData, isDebug } from "../common/helpers";

export default function ReservationForm() {
  const [startdate1, setStartDate1] = useState(null);
  const [startdate2, setStartDate2] = useState(null);
  const [startdate3, setStartDate3] = useState(null);
  const [fetchedReservation, setFetchedReservation] = useState(null);
  const [fetchedDemo, setFetchedDemo] = useState(null);
  const [fetchedReseller, setFetchedReseller] = useState(null);
  const [types, setTypes] = useState([
    "Admin Time",
    "Business Partner Training",
    "Demo for Business Partner",
    "Demo for End User",
    "Practice for Demo",
    "Setup for Demo",
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [errMsg, setErrMsg] = useState();

  const { reservationId } = useParams();

  let navigate = useNavigate();

  let location = useLocation();
  const { demoId, demoName } = location.state;

  const currentUser = authService.getCurrentUser();
  const isNewReservation = reservationId.toString() === "new" ? true : false;

  const submitButtonLabel = isNewReservation
    ? "Schedule Demo"
    : "Update Reservation";

  const demoTitle = isNewReservation ? demoName : fetchedDemo?.name;

  /* Begin scheduler Testing */
  /* End Scheduler Testing */
  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  // Create 3 date objects that will represent
  // next week, 2 weeks from now, and 3 weeks from now
  // Set the time at 10am EST, 2pm EST, and 1pm EST
  const nextWeek = new Date();
  nextWeek.setDate(new Date().getDate() + 7);
  nextWeek.setUTCHours(15);
  nextWeek.setUTCMinutes(0);

  const twoWeeks = new Date();
  twoWeeks.setDate(new Date().getDate() + 14);
  twoWeeks.setUTCHours(19);
  twoWeeks.setUTCMinutes(0);

  const threeWeeks = new Date();
  threeWeeks.setDate(new Date().getDate() + 21);
  threeWeeks.setUTCHours(18);
  threeWeeks.setUTCMinutes(0);

  const defaultValues = {
    choice1: nextWeek,
    choice2: twoWeeks,
    choice3: threeWeeks,
  };

  const schema = yup.object().shape({
    description: yup
      .string()
      .required("Reason for request is a required field")
      .max(64000),
    type: yup
      .string()
      .oneOf([
        "Admin Time",
        "Business Partner Training",
        "Demo for Business Partner",
        "Demo for End User",
        "Practice for Demo",
        "Setup for Demo",
      ]),
    expectedNumOfAttendees: yup
      .number()
      .required("Attendee Count is a required field")
      .positive(),
    ticketNum: yup.string().nullable(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: defaultValues,
  });

  /*
   */

  const handleUpdate = (data) => {
    if (isDebug) {
      console.log(`Handling ${submitButtonLabel} for demo ID ${demoId}`);
      console.log("Original data submitted...");
      console.log(data);
    }
    let newData = data;
    newData.demoId = demoId;

    if (!data.resellerId) newData.resellerId = currentUser.resellerId;
    if (!data.requestedBy) newData.requestedBy = currentUser.id;
    if (!data.acctNum) {
      newData.acctNum = currentUser.acctNumber;
      console.log("data.acctNum is falsey...");
      console.log("current user: ");
      console.log(currentUser);
    } else {
      console.log("data.acctNum is truthy. data.acctNum = ");
      console.log(data.acctNum);
    }
    /*
    if (data.acctNum === "") {
      if (isNewReservation) {
        newData.acctNum = currentUser.acctNum;
      } else {
        newData.acctNum = fetchedReservation?.acctNum;
      }
    }
    */

    newData.duration = fetchedDemo?.duration;
    //newData.id = reservationId;

    if (isDebug) {
      console.log("New data after correction...");
      console.log(newData);
    }
    /*
     */
    const addReservation = async () => {
      const updateResponse = await createReservation(newData);
      if (isDebug) {
        logData(updateResponse);
        logData(updateResponse.status);
      }
      if (updateResponse.status >= 200 && updateResponse.status < 300) {
        setSuccessful(true);
      }
      if (updateResponse.status >= 400 && updateResponse.status < 500) {
        setErrMsg(updateResponse.data);
        setHasError(true);
      }
    };

    const pushUpdate = async () => {
      newData.id = reservationId;
      const updateResponse = await updateReservation(reservationId, newData);
      if (isDebug) {
        logData(updateResponse);
        logData(updateResponse.status);
      }
      if (updateResponse.status >= 200 && updateResponse.status < 300) {
        // should be 201
        setSuccessful(true);
      }
      if (updateResponse.status >= 400 && updateResponse.status < 500) {
        setErrMsg(updateResponse.data);
        setHasError(true);
      }
    };

    /*  Commenting out for testing purposes */
    if (reservationId === "new") {
      addReservation().catch((error) => http.handleError(error));
    } else {
      pushUpdate().catch((error) => http.handleError(error));
    }
  };

  //Runs after initial render
  useEffect(() => {
    setIsLoading(true);

    const pullInfo = async () => {
      const currentReservation = await getReservationInfo(reservationId);
      setFetchedReservation(currentReservation);
      console.log(currentReservation);
    };

    if (!isNewReservation) {
      if (isDebug) {
        logData("Editing existing reservation");
      }
      pullInfo().catch(console.error);
    } else {
      // is new Reservation
      if (isDebug) {
        logData(currentUser);
      }
      const pullReseller = async () => {
        const currentReseller = await getResellerInfo(currentUser?.resellerId);
        if (isDebug) {
          logData(currentReseller);
        }
        setFetchedReseller(currentReseller);
      };

      if (currentUser.resellerId) {
        pullReseller().catch(console.error);
      }
    }

    const pullDemoInfo = async () => {
      const currentDemo = await getDemoInfo(demoId);
      setFetchedDemo(currentDemo);
    };
    pullDemoInfo().catch(console.error);

    clearErrors();

    setIsLoading(false);
  }, []);

  //Runs when a reservation is fetched
  useEffect(() => {
    // Making sure fetchedUser actually has a value
    if (typeof fetchedReservation === "object" && fetchedReservation !== null) {
      logData("Fetched Reservation ID: " + fetchedReservation.id);
    } else {
      return;
    }

    const pullReseller = async () => {
      const currentReseller = await getResellerInfo(
        fetchedReservation?.resellerId
      );
      if (isDebug) {
        logData(currentReseller);
      }
      setFetchedReseller(currentReseller);
    };
    if (fetchedReservation.resellerId) {
      pullReseller().catch(console.error);
    }

    setValue("description", fetchedReservation.description, {
      shouldValidate: true,
      shouldDirty: true,
    });
    //const formattedDate = correctDate(fetchedReservation.startTime);
    setValue("startTime", fetchedReservation.startTime, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue(
      "expectedNumOfAttendees",
      fetchedReservation.expectedNumOfAttendees,
      {
        shouldValidate: true,
        shouldDirty: true,
      }
    );
    setValue("type", fetchedReservation.type, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue("resellerId", fetchedReservation.resellerId, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue("requestedBy", fetchedReservation.user.id, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue("acctNum", fetchedReservation.acctNum, {
      shouldValidate: true,
      shouldDirty: true,
    });
    console.log(fetchedReservation);
  }, [fetchedReservation]);

  /* This useEffect is only when successful submission */
  useEffect(() => {
    if (successful) {
      //navigate("/users");
      navigate(-1);
    }
  }, [successful]);

  return (
    <React.Fragment key={1234}>
      {hasError ? (
        alert(errMsg)
      ) : isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      ) : (
        <>
          <div className="container shadow border border-2 border-light rounded-3 pt-2 mt-2 mb-5 ">
            <div className="row text-center">
              <div className="col fs-4">
                Request a Reservation for:{" "}
                <span className="fs-3"> {demoTitle} </span>
              </div>
            </div>
            <form onSubmit={handleSubmit(handleUpdate)} className="p-2">
              {currentUser.roleId < 3 ? (
                <div className="row justify-content-lg-center">
                  <div className="col"></div>
                  <div className="col">
                    <div className="form-group m-2">
                      <label
                        className="text-muted"
                        htmlFor="expectedNumOfAttendees"
                      >
                        <small>{"Enter Jira Ticket Number if Available"}</small>
                      </label>
                      <input
                        {...register("ticketNum")}
                        className="form-control"
                      />
                      {errors["ticketNum"] && (
                        <small>
                          <small>
                            <div className="alert alert-danger">
                              {errors["ticketNum"].message}
                            </div>
                          </small>
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col"></div>
                </div>
              ) : null}
              <div className="row justify-content-lg-center shadow border border-2 rounded-3 mx-2 mb-3">
                <div className="col pt-2">
                  <span className="text-muted">Reseller:</span>
                  <div>
                    {fetchedReseller?.name} {" ("} {fetchedReseller?.acctNum}{" "}
                    {") "}{" "}
                  </div>
                </div>
                <div className="col">
                  <div className="form-group m-2">
                    <label className="text-muted " htmlFor="type">
                      <small>{"Select Reservation type..."}</small>
                    </label>
                    <select
                      {...register("type", {
                        onChange: (e) => {
                          setValue("type", e.target.value);
                        },
                      })}
                      id="type"
                      className="form-select"
                    >
                      <option key="0" value="">
                        {" "}
                      </option>
                      {types.map((type, i) => (
                        <option key={i} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                    {errors["type"] && (
                      <small>
                        <small>
                          <div className="alert alert-danger">
                            {errors.type?.message}
                          </div>
                        </small>
                      </small>
                    )}
                  </div>
                </div>
                <div className="col">
                  <div className="form-group m-2">
                    <label
                      className="text-muted"
                      htmlFor="expectedNumOfAttendees"
                    >
                      <small>{"How Many Attendees Do You Expect?"}</small>
                    </label>
                    <input
                      {...register("expectedNumOfAttendees")}
                      className="form-control"
                    />
                    {errors["expectedNumOfAttendees"] && (
                      <small>
                        <small>
                          <div className="alert alert-danger">
                            {errors["expectedNumOfAttendees"].message}
                          </div>
                        </small>
                      </small>
                    )}
                  </div>
                </div>
              </div>

              <div className="row justify-content-lg-center shadow border border-2 rounded-3 mx-2 mb-3">
                <div className="col-12">
                  <div className="form-group m-2">
                    <label className="text-muted" htmlFor="description">
                      Reason for Demo:
                    </label>
                    <textarea
                      {...register("description")}
                      className="form-control"
                      rows="5"
                    />
                  </div>
                </div>
              </div>

              <div className="row justify-content-lg-center shadow border border-2 rounded-3 mx-2 mt-2">
                <p className="fs-5 text-center pt-2">
                  Pick 3 options for Starting Date and Time for the demo.
                </p>
                <p className="text-center fw-light text-danger">
                  We pre-loaded possible values for you.
                </p>
                <div className="col-3 m-2 border border-light rounded p-2 shadow">
                  <Controller
                    control={control}
                    name="choice1"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ReactDatePicker
                        selected={value}
                        isClearable
                        filterDate={isWeekday}
                        excludeDateIntervals={[
                          {
                            start: subDays(new Date(), 90),
                            end: addDays(new Date(), 7),
                          },
                        ]}
                        onChange={onChange}
                        onBlur={onBlur}
                        placeholderText="1st Pref for Demo Time..."
                        showTimeSelect
                        timeIntervals={60}
                        includeTimes={[
                          setHours(setMinutes(new Date(), 0), 10),
                          setHours(setMinutes(new Date(), 0), 11),
                          setHours(setMinutes(new Date(), 0), 13),
                          setHours(setMinutes(new Date(), 0), 14),
                          setHours(setMinutes(new Date(), 0), 15),
                          setHours(setMinutes(new Date(), 0), 16),
                        ]}
                        dateFormat="MM/dd/yyyy h:mm aa"
                      />
                    )}
                  />
                </div>
                <div className="col-3 m-2 border border-light rounded p-2 shadow">
                  <Controller
                    control={control}
                    name="choice2"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ReactDatePicker
                        selected={value}
                        isClearable
                        filterDate={isWeekday}
                        excludeDateIntervals={[
                          {
                            start: subDays(new Date(), 90),
                            end: addDays(new Date(), 7),
                          },
                        ]}
                        onChange={onChange}
                        onBlur={onBlur}
                        placeholderText="2nd Pref for Demo Time..."
                        showTimeSelect
                        timeIntervals={60}
                        includeTimes={[
                          setHours(setMinutes(new Date(), 0), 10),
                          setHours(setMinutes(new Date(), 0), 11),
                          setHours(setMinutes(new Date(), 0), 13),
                          setHours(setMinutes(new Date(), 0), 14),
                          setHours(setMinutes(new Date(), 0), 15),
                          setHours(setMinutes(new Date(), 0), 16),
                        ]}
                        dateFormat="MM/dd/yyyy h:mm aa"
                      />
                    )}
                  />
                </div>
                <div className="col-3 m-2 border border-light rounded p-2 shadow">
                  <Controller
                    control={control}
                    name="choice3"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ReactDatePicker
                        selected={value}
                        isClearable
                        filterDate={isWeekday}
                        excludeDateIntervals={[
                          {
                            start: subDays(new Date(), 90),
                            end: addDays(new Date(), 7),
                          },
                        ]}
                        onChange={onChange}
                        onBlur={onBlur}
                        placeholderText="3rd Pref for Demo Time..."
                        showTimeSelect
                        timeIntervals={60}
                        includeTimes={[
                          setHours(setMinutes(new Date(), 0), 10),
                          setHours(setMinutes(new Date(), 0), 11),
                          setHours(setMinutes(new Date(), 0), 13),
                          setHours(setMinutes(new Date(), 0), 14),
                          setHours(setMinutes(new Date(), 0), 15),
                          setHours(setMinutes(new Date(), 0), 16),
                        ]}
                        dateFormat="MM/dd/yyyy h:mm aa"
                      />
                    )}
                  />
                </div>
              </div>

              <div className="row justify-content-lg-center">
                <div className="col-md">
                  <center>
                    <div className="form-group m-2">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>
                              {" "}
                              <center>Instructors</center>{" "}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {fetchedDemo?.instructors &&
                            fetchedDemo.instructors.map((instructor, i) => (
                              <>
                                <tr key={instructor.id}>
                                  <td>
                                    <small>{instructor.user.fullName}</small>
                                  </td>
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </center>
                </div>
                <div className="col-md">
                  <center>
                    <div className="form-group m-2">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>
                              {" "}
                              <center>Vendors</center>{" "}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {fetchedDemo?.vendors &&
                            fetchedDemo.vendors.map((vendor, i) => (
                              <>
                                <tr key={vendor.id}>
                                  <td>
                                    <small>{vendor.name}</small>
                                  </td>
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </center>
                </div>
                <div className="col-md">
                  <center>
                    <div className="form-group m-2">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>
                              {" "}
                              <center>Topics</center>{" "}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {fetchedDemo?.categories &&
                            fetchedDemo.categories.map((category, i) => (
                              <>
                                <tr key={category.id}>
                                  <td>
                                    <small>{category.title}</small>
                                  </td>
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </center>
                </div>
              </div>

              <div className="row justify-content-lg-center">
                <div className="col"></div>
                <div className="col">
                  <center>
                    <div id="4" className="form-group m-2">
                      <input
                        className="btn btn-sm btn-primary me-2 mb-2"
                        type="submit"
                        value={submitButtonLabel}
                      />
                      <input
                        className="btn btn-sm btn-danger mb-2 "
                        type="button"
                        value="Cancel"
                        onClick={() => {
                          navigate(-1);
                        }}
                      />
                    </div>
                    <div id="4" className="form-group m-2"></div>
                    {successful && (
                      <div className="alert alert-success">
                        Update Successful!
                      </div>
                    )}
                  </center>
                </div>
                <div className="col"></div>
              </div>
            </form>
          </div>
        </>
      )}
    </React.Fragment>
  );
} // End export default funtion DemoForm

/*

                    <Input
                      register={register}
                      fieldName="description"
                      label="Reason for Demo:"
                      errors={errors}
                      type="textarea"
                    />
*/
/*                    
                    <label className="text-muted" htmlFor="description">
                      <small>{"Reason for Demo?"}</small>
                    </label>
                    <textarea
                      {...register("description")}
                      className="form-control"
                    />
                    {errors.description && (
                      <small>
                        <small>
                          <div className="alert alert-danger">
                            {errors.description?.message}
                          </div>
                        </small>
                      </small>
                    )}
*/
