import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

import BackButton from "../common/backButtion";
import StreetAddress from "../common/streetAddress";
import { getResellerInfo } from "../../services/resellerService";
import authService from "../../services/auth.service";
import rbacService from "../../services/rbacService";
import RenderBlock from "../common/renderBlock";
import ResellerUsersTable from "./resellerUsersTable";
import ResellerReservationsTable from "./resellerReservationsTable";
import ResellerPocTable from "./resellerPoCTable";

export default function ResellerDetail() {
  const [fetchedReseller, setFetchedReseller] = useState();
  const [sortColumn, setSortColumn] = useState({
    path: "fullName",
    order: "asc",
  });
  let { resellerId } = useParams();

  const currentUser = authService.getCurrentUser();
  const rbac = new rbacService(currentUser);

  const handleSort = (sortColumn) => {
    setSortColumn(sortColumn);
  };

  useEffect(() => {
    const pullResellerInfo = async () => {
      const reseller = await getResellerInfo(resellerId);
      setFetchedReseller(reseller);
      console.log(reseller);
    };
    pullResellerInfo();
  }, []);

  function renderAddress() {
    const resellerAddress = {
      street1: fetchedReseller.addressStreet1,
      street2: fetchedReseller.addressStreet2,
      city: fetchedReseller.addressCity,
      state: fetchedReseller.addressState,
      zip: fetchedReseller.addressZip,
    };

    return resellerAddress;
  }

  if (fetchedReseller === null || typeof fetchedReseller === `undefined`) {
    return (
      <div className="container shadow border border-1 border-light rounded-3 pt-2 mt-2">
        <center>
          <h3 className="display-5">Reseller Not Found</h3>
        </center>
      </div>
    );
  } else {
    return (
      <>
        <div className="container shadow border border-1 border-light rounded-3 pb-2 pt-2 mt-2">
          <div className="row">
            <div className="col-2 mt-2">
              <BackButton />
               {rbac.hasElevatedPrivs() ? (
                    <Link to={`/resellerForm/${fetchedReseller.id}`}>
                      <button className="btn btn-primary btn-sm ms-2">Edit</button>
                    </Link>
                  ) : null}
            </div>
            <div className="col-10">
              <div className="d-flex flex-row justify-content-start">
                <p className="display-6">Reseller Info</p>
               
              </div>
            </div>
          </div>

          <div className="d-flex row">
            <div className="col pt-2 pb-2 text-center align-self-center">
              {RenderBlock("Name", fetchedReseller.name)}
              <hr />
              {RenderBlock("Account Number", fetchedReseller.acctNum)}
            </div>
            <div className="col pt-2 pb-2 text-center align-self-center">
              {RenderBlock("Phone Number", fetchedReseller.workPhone)}
              <hr />
              {fetchedReseller.dba
                ? RenderBlock("Doing Business As", fetchedReseller.dba)
                : RenderBlock("Doing Business As", "N / A")}
            </div>
            <div className="col pt-2 pb-2 text-center align-self-center">
              <p className="h6 mt-1">Address:</p>
              <hr />
              <center>
                <StreetAddress address={renderAddress()} />
              </center>
            </div>
          </div>
        </div>
        {rbac.hasElevatedPrivs() && (
          <>
          <div className="container shadow border border-1 border-light rounded-3 pb-2 pt-2 mt-2 mb-2">
            <ResellerUsersTable
              users={fetchedReseller.users}
              sortColumn={sortColumn}
              onSort={handleSort}
              caption={"Users for " + fetchedReseller.name}
            />
          </div>
          <div className="container shadow border border-1 border-light rounded-3 pb-2 pt-2 mt-2 mb-2">
            <ResellerPocTable 
               pocOrders={fetchedReseller.pocOrders}
               sortColumn={sortColumn}
               onSort={handleSort}
               caption={fetchedReseller.name + " PoC Orders"}
            />
          </div>
          </>
        )}
        <div className="container shadow border border-1 border-light rounded-3 pb-2 pt-2 mt-2 mb-2">
          {fetchedReseller.reservations.length > 0 ? (
            <ResellerReservationsTable
              reservations={fetchedReseller.reservations}
              caption={fetchedReseller.name + "'s Reservations"}
            />
          ) : (
            <div className="text-center text-muted">
              Your Company Has No Current Reservations
            </div>
          )}
        </div>
      </>
    );
  }
}
