import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import _ from "lodash"
import authService from "../../services/auth.service";
import { isDebug } from "../common/helpers";
import { getCategories, getCategorybyID } from "../../services/categoryService";
import BackButton from "../common/backButtion";
import rbacService from "../../services/rbacService";
import TopicDemosTable from "./topicDemosTable";

export default function CategoryDetail() {
  const [isLoading, setIsLoading] = useState(false);
  const [topic, setTopic] = useState(null);
  const [topics, setTopics] = useState(null);
  const [rbac, setRBAC] = useState(null);
 // const [cu, setCU] = useState(null);

  let { categoryId } = useParams();
  let navigate = useNavigate();

  const chooseTopic = (e) => {
    //if(isDebug) console.log(e.target.value);
    if(e.target.value === "All Topics"){
      navigate("/categories");
    }
    if(topics) {
      const tid = parseInt(e.target.value);
      //if(isDebug) console.log("tid = " + tid);
      //setTopic(topics[tid]);
      //const newTopic = topics.filter((t) => t.id === tid);
      const newTopic = topics.find((t) => t.id === tid);
      setTopic(newTopic);
      //if(isDebug) console.log(newTopic);
    }
  }

  useEffect(() => {
    if(topic === null){
      setIsLoading(true);
      const loggedInUser = authService.getCurrentUser();
      const rbac = new rbacService(loggedInUser);
     // setCU(loggedInUser);
      setRBAC(rbac);

      const getAllTopics = async () => {
        const ltopics = await getCategories();
        //if(isDebug) console.log(ltopics);
        const sortedTopics = _.orderBy(ltopics, ['title'], ['asc']);
        //if(isDebug) console.log(sortedTopics);
        //setTopics(ltopics);
        setTopics(sortedTopics);
        const tid = parseInt(categoryId) - 1;
        setTopic(ltopics[tid]);
      }
      getAllTopics().catch((error) => console.log(error));

      /* */
      setIsLoading(false);
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      ) : topic ? (
        <div className="container shadow border border-1 border-light rounded-3 pt-2 mt-2">
          <div className="row justify-content-lg-center">
            <div className="col-2 mt-2">
              <div className="d-flex flex-column mb-2 align-items-center">
                <div className="p-2">
                  <BackButton />
                  { rbac.isAdminOrInstructor() ? (
                      <Link to={`/categoryForm/${topic.id}`}>
                        <button className="btn btn-primary btn-sm ms-2">Edit</button>
                      </Link>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-6 text-align-center">
              <div className="d-flex flex-row justify-content-start">
                <p className="display-6">{topic.title}</p>
                <div className="col"></div>
                <div className="col"></div>
              </div>
                <p className="fs-6 fst-italic">{topic.description}</p>
            </div>
            <div className="col-4 text-align-center">
              <div className="form-group ">
                <label htmlFor="nextTopic" className="ms-2 mb-2" >Choose A Different Topic</label>
                <div style={{ maxWidth: "250px"}} >

                <select
                  id="nextTopic"
                  className="form-select ms-2"
                  onChange={chooseTopic}
                >
                  <option>{"All Topics"}</option>
                  {topics && topics.map((nextTopic, i) => (
                    <option key={i} value={nextTopic.id}>
                      {nextTopic.title}
                    </option>
                  ))}
                </select>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-lg-center p-2">
            <TopicDemosTable 
               demos={topic.demos}
               caption={`Demos about ${topic.title}`}
            />
          </div>
        </div>
      ) : (
        <h3> Topic Not Found</h3>
      )}
    </>
  );
}

/*

*/
