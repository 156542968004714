import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Typeahead } from "react-bootstrap-typeahead";
import Select from "react-select";
import UsersTable from "./usersTable";
import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";
import { logData, isDebug } from "../common/helpers";
import { getUsers, removeUser } from "../../services/userService";
import { format } from "date-fns";
import authService from "../../services/auth.service";
import rbacService from "../../services/rbacService";
import ExportExcel from "../common/excelExport";
import BackButton from "../common/backButtion";

class Users extends Component {
  constructor() {
    super();
    this.handleDelete = this.handleDelete.bind(this);
    this.fullNameRef = React.createRef();
    this.emailRef = React.createRef();
    this.userNameRef = React.createRef();
  }
  state = {
    users: [],
    selectedUser: {},
    selectedRole: null,
    showTDSUsers: true,
    currentPage: 1,
    pageSize: 10,
    isLoading: false,
    sortColumn: { path: "fullName", order: "asc" },
    currentUser: null,
    rbac: null,
    exportData: null,
    dateString: null,
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    const cu = authService.getCurrentUser();
    const lrbac = new rbacService(cu);
    const usersList = await getUsers();
    const today = format(new Date(), "ddMMMyyyy");
    
    this.setState({ users: usersList, currentUser: cu, rbac: lrbac, dateString: today });
    if(isDebug) console.log(usersList);
    this.setState({ isLoading: false });
  }

  handleDelete = async (user) => {
    const deleted = await removeUser(user.id);
    console.log(deleted);
    if (deleted.status === 200) {
      const users = this.state.users.filter((d) => d.id !== user.id);
      this.setState({ users: users });
    }
  };

  handleLike = (user) => {
    const users = [...this.state.users];
    const index = users.indexOf(user);
    users[index] = { ...users[index] };
    users[index].liked = !users[index].liked;
    this.setState({ users });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleUserSelect = (user) => {
    if(user !== undefined) {
      this.setState({ selectedUser: user, currentPage: 1 });
    }
  };

  handleSort = (sortColumn) => {
    if (isDebug)
      logData(
        "Sort Column = " + sortColumn.path + " order = " + sortColumn.order
      );
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      selectedUser,
      selectedRole,
      showTDSUsers,
      users,
    } = this.state;

    let displayedUsers = [];
    if(!showTDSUsers){
      for(const u of users) {
        if(u.resellerId !== 4) {
          displayedUsers.push(u);
        }
      }
    } else {
      displayedUsers = users;
    }

    const roleFilter = selectedRole
      ? displayedUsers.filter((r) => r.roleId === selectedRole)
      : displayedUsers;
    /*
      const roleFilter = selectedRole
      ? users.filter((r) => r.roleId === selectedRole)
      : users;
    */
    const filtered =
      selectedUser && selectedUser.id
        ? roleFilter.filter((r) => r.id === selectedUser.id)
        : roleFilter;

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const paged_users = paginate(sorted, currentPage, pageSize);

    let data = [];
    if(sorted.length > 0) {
      sorted.map((u) => data.push({ Name: u.fullName,
                                    UserName: u.userName,
                                    eMail: u.email, 
                                    Reseller: u.reseller.name, 
                                    Role: u.role.name}) 
                                    );
    }
    return { exportData: data, totalCount: filtered.length, data: paged_users };
  };

  changePageSize = (e) => {
    this.setState({ pageSize: e.value, currentPage: 1 });
  };

  changeRole = (e) => {
    this.setState({ selectedRole: e.value, currentPage: 1 });
  };

  changeShowTDSUsers = (e) => {
    this.setState({ showTDSUsers: !this.state.showTDSUsers});
  }

  render() {
    const { length: count } = this.state.users;
    const { pageSize, currentPage, sortColumn, rbac, currentUser, dateString } = this.state;

    const pageSizeOptions = [
      { value: 5, label: 5 },
      { value: 10, label: 10 },
      { value: 15, label: 15 },
      { value: 20, label: 20 },
      { value: 25, label: 25 },
      { value: 50, label: 50 },
    ];
    const roleOptions = [
      { value: null, label: "Show All Roles" },
      { value: 1, label: "Admin" },
      { value: 2, label: "Instructor" },
      { value: 3, label: "User" },
    ];

    if (this.state.isLoading)
      return (
        <div className="text-center p-3">
          {" "}
          <span className="spinner-border spinner-border-lg align-center"></span>{" "}
        </div>
      );

    if (count === 0) return <p>There are no users in the database.</p>;

    const { exportData, totalCount, data: users } = this.getPagedData();
    const myCaption = "Showing " + totalCount + " users in the database";

    return (
      <div className="container pt-2">
        <div className="d-flex flex-row">
          <div className="col-3 ">
            <BackButton />
            {rbac && rbac.hasElevatedPrivs() ? 
            <>
            <Link to="/user/new">
              <button className="btn btn-primary btn-sm ms-2">Add User</button>
            </Link>
            <div className="d-inline-flex ps-2">
              <ExportExcel excelData={exportData} fileName={`Portal-User-List-${currentUser?.userName}-${dateString}`} />
            </div>
            </>
             : null }
          </div>
          <div className="col-9">
            <div className="typeahead form-floating ">
              <div className="d-flex justify-content-start">
                <div className="d-inline-flex align-items-center">
                  <div className="d-inline-flex me-4">
                    <Select
                      id="roleSelect"
                      options={roleOptions}
                      onChange={this.changeRole.bind(this)}
                      placeholder="Filter by Role"
                    />
                  </div>
                  <button
                    className="btn btn-sm btn-secondary me-1"
                    onClick={() => {
                      this.fullNameRef.current.clear();
                      this.setState({ selectedUser: {} });
                    }}
                  >
                    Clear
                  </button>
                  <Typeahead
                    id="userFilter"
                    ref={this.fullNameRef}
                    labelKey={(option) => `${option.fullName}`}
                    placeholder="Filter by User Full Name"
                    minLength={2}
                    size="sm"
                    options={this.state.users}
                    onChange={(selected) => {
                      this.handleUserSelect(selected[0]);
                    }}
                  />
                  <div className="form-check ms-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="31495"
                      checked={this.state.showTDSUsers}
                      onChange={this.changeShowTDSUsers.bind(this)}
                      />
                      <label className="form-check-label" htmlFor="31495">
                        Show TD SYNNEX Users
                      </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h2 className="display-6 mt-3">User List</h2>
        <UsersTable
          users={users}
          sortColumn={sortColumn}
          onLike={this.handleLike}
          onDelete={this.handleDelete}
          onSort={this.handleSort}
          caption={myCaption}
        />
        <div className="d-inline-flex pe-2">
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
          />
        </div>
        <div className="d-inline-flex">
          <Select
            id="pgSize"
            options={pageSizeOptions}
            onChange={this.changePageSize.bind(this)}
            placeholder="Number of Items / Page"
          />
        </div>
      </div>
    );
  }
}

export default Users;
/*
 */
