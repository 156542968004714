import React from "react";
import { Link } from "react-router-dom";

export default function HeroBlock(title, subtitle, detail = "", link = null) {
  return (
    <div className="col p-4 pe-lg-0 pt-lg-5 align-items center rounded-3 border shadow-lg m-3">
      <h2 className="ss-featurette-heading fw-normal lh-1 servicesolv-title">
        {link ? <Link to={link}>{title}</Link> : title}
      </h2>
      <strong>{subtitle}</strong>
      <br />
      <small className="text-muted p-2">{detail}</small>
    </div>
  );
}
