import http from "./httpService";
import authHeader from "./auth-header";
//import axios from "axios";

//const jobURL = "https://tickets.ansiblejira.dev/api/v2/job_templates/49/launch";

export async function createJiraDemoTicket(ticketData) {
    const response = await http.post("/api/v1/ansibleJira/createTicket", ticketData, {
      headers: authHeader(),
    });
    return response;
  }


/*
export async function createDemoTicket(ticketData) {
    const response = await axios.post(jobURL, ticketData, {
        auth: {
            username: ansibleUname, 
            password: ansiblePass
        }
    //    headers: {
    //        'content-type': "application/json;charset-utf-8",
    //        'Access-Control-Allow-Origin': '*'
    //    }
    });
   // console.log(response);
    return response;
}
*/