import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import PocKitsTable from "./pocKitsTable";
import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

import rbacService from "../../services/rbacService";
import authService from "../../services/auth.service";
import BackButton from "../common/backButtion";

import { getPocKits, removePocKit } from "../../services/pocKitService";
import { getVendors } from "../../services/vendorService";

class PocKits extends Component {
  constructor(props) {
    super(props);
    this.pocKitRef = React.createRef();
  }
  state = {
    pocKits: [],
    vendors: [],
    isLoading: false,
    selectedpocKit: {},
    selectedVendor: null,
    currentPage: 1,
    pageSize: 10,
    sortColumn: { path: "name", order: "asc" },
    currentUser: null,
    rbac: null,
    showBackButton: this.props.showBackButton,
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    console.log(this.state.showBackButton);
    console.log(this.props.showBackButton);
    if(this.state.showBackButton === undefined){
      this.setState({showBackButton: true});
    }
    const localpocKits = await getPocKits();
    const localvendors = await getVendors();
    const sortedVendors = _.orderBy(localvendors, ["name"], ["asc"]);
    console.log(localpocKits);
    const cu = authService.getCurrentUser();
    const rb = new rbacService(cu);
    const availKits = _.filter(localpocKits, function(k) {return k.status === "Available"});
    if(rb.isUser()) {
      const userKits = _.filter(availKits, function(k) {return !k.name.includes("Unkitted")});
      this.setState({pocKits: userKits});
    } else {
      this.setState({pocKits: localpocKits});
    }
    this.setState({ 
        currentUser: cu, 
        rbac: rb, 
        vendors: sortedVendors,
        isLoading: false });
    //this.setState({ pocKits: localpocKits });
    //this.setState({ isLoading: false });
  }

  filterVendor = (e) => {
    console.log(e.target.value);
    this.setState({selectedVendor: e.target.value});
  }

  //
  handlepocKitSelect = (pocKit) => {
    if(pocKit !== undefined) {
      this.setState({ selectedpocKit: pocKit, currentPage: 1 });
    }
  };

  handleDelete = async (pocKit) => {
    const deleted = await removePocKit(pocKit.id);
    if (deleted.status === 200) {
      const pocKits_tmp = this.state.pocKits.filter((i) => i.id !== pocKit.id);
      this.setState({ pocKits: pocKits_tmp });
    }
  };

  handleLike = (pocKit) => {
    const pocKits = [...this.state.pocKits];
    const index = pocKits.indexOf(pocKit);
    pocKits[index] = { ...pocKits[index] };
    pocKits[index].liked = !pocKits[index].liked;
    this.setState({ pocKits });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const { selectedpocKit, selectedVendor, pageSize, currentPage, sortColumn, pocKits } =
      this.state;

    const pocKitfiltered =
      selectedpocKit && selectedpocKit.id
        ? pocKits.filter((v) => v.id === selectedpocKit.id)
        : pocKits;
    
    console.log("selectedVendor");
    console.log(selectedVendor);

    let vendorFiltered = "";
    if(selectedVendor === "All Vendors") {
      vendorFiltered = pocKitfiltered;
    } else {
      vendorFiltered = 
         selectedVendor ? 
         pocKitfiltered.filter((k) => k.vendor.name === selectedVendor )
         : pocKitfiltered;
    }

    const sorted = _.orderBy(
      //pocKitfiltered,
      vendorFiltered,
      [sortColumn.path],
      [sortColumn.order]
    );

    const pocKits_tmp = paginate(sorted, currentPage, pageSize);

    return { totalCount: pocKitfiltered.length, data: pocKits_tmp };
  };

  changePageSize = (e) => {
    this.setState({ pageSize: e.value, currentPage: 1 });
  };

  render() {
    const { length: count } = this.state.pocKits;
    const { pageSize, currentPage, sortColumn, showBackButton, vendors } = this.state;
    //const { showBackbutton } = this.props;
    console.log(showBackButton);

    if (this.state.isLoading)
      return (
        <div className="text-center p-3">
          {" "}
          <span className="spinner-border spinner-border-lg align-center"></span>{" "}
        </div>
      );

    if (count === 0) return <p>There are no Proof-of-Concept Kits in the database.</p>;

    const selectOptions = [
      { value: 5, label: 5 },
      { value: 10, label: 10 },
      { value: 15, label: 15 },
      { value: 20, label: 20 },
      { value: 25, label: 25 },
      { value: 50, label: 50 },
    ];

    const { totalCount, data: pocKits } = this.getPagedData();
    const tableCaption = "Showing " + totalCount + " Proof-of-Concept Kits in the database.";

    return (
      <div className="container shadow border border-1 border-light rounded-3 pt-2 pb-2 mt-2">
        <div className="d-flex flex-row align-items-center">
          <div className="col-2 ">
            { showBackButton ? <BackButton /> : null }
            { this.state.rbac.isAdminOrLoanerAdmin() ?
                <Link to="/pocKitForm/new">
                 <button className="btn btn-primary btn-sm ms-2">
                   Create Kit
                 </button>
               </Link>
               :  
               <Link to="/pocOrderForm/new">
                 <button className="btn btn-primary btn-sm ms-2">
                   Request Gear
                 </button>
               </Link>
            }
          </div>

          <div className="col-8">
             <h2 className="display-6 mt-3 text-center">Proof-of-Concept Kit List</h2>
          </div>
        </div>

        <div className="d-flex flex-row align-items-center">
          <div className="col-2">
            <div className="typeahead form-floating ">
              <div className="d-flex justify-content-start">
                <button
                  className="btn btn-sm btn-secondary me-1"
                  onClick={() => {
                    this.pocKitRef.current.clear();
                    this.setState({ selectedpocKit: {} });
                  }}
                >
                  Clear
                </button>
                <Typeahead
                  id="pocKitFilter"
                  ref={this.pocKitRef}
                  labelKey={(option) => `${option.name}`}
                  placeholder="Filter by pocKit"
                  minLength={2}
                  size="sm"
                  options={this.state.pocKits}
                  onChange={(selected) => {
                    this.handlepocKitSelect(selected[0]);
                  }}
                />
              </div>
            </div>
         </div>
          
        <div className="col-2">
          <div className="form-group ">
            <select
              id="vendor"
              className="form-select ms-2"
              onChange={this.filterVendor}
            >
              <option>{"All Vendors"}</option>
              {vendors.map((vendor) => (
                <option key={vendor.id} value={vendor.name}>
                  {vendor.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        </div>
        
        <div className="row">
          <div className="d-inline-flex pe-2">
            <PocKitsTable
              pocKits={pocKits}
              sortColumn={sortColumn}
              onLike={this.handleLike}
              onDelete={this.handleDelete}
              onSort={this.handleSort}
              caption={tableCaption}
            />
          </div>
          <div className="d-inline-flex pe-2">
            <Pagination
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
            <Select
              className="ms-2"
              id="pgSize"
              options={selectOptions}
              onChange={this.changePageSize.bind(this)}
              placeholder="Number of Items / Page"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default PocKits;
