import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import BackButton from "../common/backButtion";
import { isDebug, logData } from "../common/helpers";
import authService from "../../services/auth.service";
import rbacService from "../../services/rbacService";
import { getPocOrderbyID } from "../../services/pocOrderService";
import PocKitItemsTable from "./pocKitItemsTable";

export default function PocOrder() {
  const [pocOrder, setPocOrder] = useState();
  const [rbac, setRBAC] = useState();
  let { pocOrderId } = useParams();

  async function getPocOrderData() {
    const localPocOrder = await getPocOrderbyID(pocOrderId);
    console.log(localPocOrder);
    setPocOrder(localPocOrder);
  }

  useEffect(() => {
    const cu = authService.getCurrentUser();
    const lrbac = new rbacService(cu);
    setRBAC(lrbac);
    getPocOrderData();
  }, []);

  if (pocOrder === null || typeof pocOrder === `undefined`) {
    return (
      <div>
        <h3>Order Not Found</h3>
      </div>
    );
  } else {
    if (isDebug) logData(pocOrder);
    return (
      <div className="container pt-2 mt-2 shadow rounded-3">
        <BackButton />
        <center>
          <h4 className="display-6"> PoC Order Details</h4>
        </center>

        {!rbac.isUser() ? (
          <div className="d-flex flex-row ">
            <div className="col-2">
              <div className="p-2">Jira Ticket Number:</div>
            </div>
            <div className="col-10">
              <div className="p-2"> 
                <a 
                  href={`https://servicedesk.synnex.com/browse/${pocOrder.ticketNum}`} 
                  target="_blank">  
                  {pocOrder.ticketNum}
                </a> 
                </div>
            </div>
          </div>
        ) : null }

        <div className="d-flex flex-row ">
          <div className="col-2">
            <div className="p-2">Reseller:</div>
          </div>
          <div className="col-10">
            <div className="p-2"> {pocOrder.reseller.name} </div>
          </div>
        </div>

        <div className="d-flex flex-row ">
          <div className="col-2">
            <div className="p-2">Order Name:</div>
          </div>
          <div className="col-10">
            <div className="p-2">{pocOrder.name}</div>
          </div>
        </div>

        <div className="d-flex flex-row ">
          <div className="col-2">
            <div className="p-2">End User Name:</div>
          </div>
          <div className="col-10">
            <div className="p-2">{pocOrder.endUserName}</div>
          </div>
        </div>

        <div className="d-flex flex-row ">
          <div className="col-2">
            <div className="p-2">Description:</div>
          </div>
          <div className="col-10">
            <div className="p-2"> {pocOrder.description} </div>
          </div>
        </div>
        <hr />
        <br />
        <PocKitItemsTable 
          pocItems={pocOrder.pocKits[0].pocItems} 
          caption={pocOrder.pocKits[0].vendor.name + " " + pocOrder.pocKits[0].name} 
        />
      </div>
    );
  }
}

/*

          {pocKit?.logoUrl ? (
            <img
              style={imgStyleObj}
              src={config.imgBaseURL + pocKit.logoUrl}
              alt=""
            />
          ) : null}
*/
