import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import authHeader from "./../../services/auth-header";

export default function EducationFeature() {
  return (
    <>
      <hr className="featurette-divider" />
      <div className="container">
        <div className="row featurette align-items-center">
          <div className="col-md-7 order-md-2">
            <h2 className="featurette-heading fw-normal lh-1">
              Education & Certifications.{" "}
            </h2>
            <p className="lead">
              We also provide custom enablement activities for you and your
              team. From sales psychology training, to deep technical
              work-shops, and everything in-between, we are here to support you
              in a 360 degree manner.
              <br />
              <strong>
                <Link to="/demos" className="text-decoration-none mt-3">
                  {" "}
                  Here{" "}
                </Link>{" "}
                are our demos and trainings available to you now.
              </strong>{" "}
              Some are self-service, others are one-on-one with our engineers.
            </p>
          </div>
          <div className="col-md-5 order-md-1">
            <img src="meeting01.jpg" className="d-block w-100" />
          </div>
        </div>
        <center>
          <Link to="/demos" className="text-decoration-none mt-3">
            <p className="lead">See Our Available Trainings</p>
          </Link>
        </center>

        <div className="row">
          <div className="col-12">
            <center>
              <p className="lead">Contact Us:</p>
            </center>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <center>
              <a href="mailto:presales@tdsynnex.com">
                {" "}
                <p className="lead">PreSales@tdsynnex.com </p>
              </a>
            </center>
          </div>
        </div>
      </div>
    </>
  );
}
