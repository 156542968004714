import React, { useState, useEffect } from "react";
import { getFeedbacks, removeFeedback } from "../../services/feedbackService";
import BackButton from "../common/backButtion";
import { isDebug } from "../common/helpers";
import FeedbacksTable from "./feedbacksTable";
import { format } from "date-fns";
import ExportExcel from "../common/excelExport";

export default function Feedbacks() {
  const [feedbacks, setFeedbacks] = useState([]);
  const [allfbs, setAllFBS] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);
  const [btnLabel, setBtnLabel] = useState("Show Deleted Records");
  const [exportData, setExportData] = useState(null);
  const [dateString, setDateString] = useState(null);

  const toggleShowDeleted = () => {
    if(showDeleted) {
      setBtnLabel("Show Deleted Records");
    } else {
      setBtnLabel("Hide Deleted Records");
    }
    setShowDeleted(!showDeleted);
  }

  const handleDelete = async (feedback) => {
    console.log("Delete Clicked");
    const deleted = await removeFeedback(feedback.id);
    console.log(deleted);
    if (deleted.status === 200) {
      const fedbks = feedbacks.filter((fb) => fb.id !== feedback.id);
      setFeedbacks(fedbks);
    }
  };
  
  useEffect(() => {
    setIsLoading(true);
    const today = format(new Date(), "ddMMMyyyy");
    setDateString(today);

    const getFeedbackList = async () => {
      const localFeedbacks = await getFeedbacks();
      setAllFBS(localFeedbacks);
      let data = [];
      localFeedbacks.map((f) =>  data.push({ID: f.id, 
                                  Type: f.type, 
                                  User: f.user.fullName, 
                                  Partner: f.user.reseller.name,  
                                  Created: format(new Date(f.createdAt), "Pp"),
                                  Deleted: f.deletedAt ? format(new Date(f.deletedAt), "Pp"): "NULL",
                                  Message: f.message,
                                  ActionTaken: f.actionTaken,
                                }) );
      setExportData(data);

      if(!showDeleted) {
        const currentFeedbacks = localFeedbacks.filter((f) => f.deletedAt === null);
        setFeedbacks(currentFeedbacks);
        if (isDebug) console.log(currentFeedbacks);
      } else {
        setFeedbacks(localFeedbacks);
        if (isDebug) console.log(localFeedbacks);
      }
    };
    getFeedbackList().catch(console.error);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if(showDeleted) {
      setFeedbacks(allfbs);
    } else {
      const lfbs = allfbs.filter((f) => f.deletedAt === null);
      setFeedbacks(lfbs);
    }
    setIsLoading(false);
  }, [showDeleted]);

  useEffect(() => {
    console.log(feedbacks);
  }, [feedbacks]);

  return (
    <>
    {isLoading ? (
       <div className="text-center p-3">
         <span className="spinner-border spinner-border-lg align-center"></span>
       </div>
      ) : (
        <div className="p-2 pb-2 m-2">
          <div className="d-inline-flex align-items-center">

          <BackButton />
          <button className="btn btn-sm btn-primary ms-2 me-2" onClick={toggleShowDeleted}>
             {btnLabel}    
           </button> 
           <ExportExcel excelData={exportData} fileName={`Feedback-Items-${dateString}`} />
          </div>
           <h2 className="display-6 text-center">Feedback</h2>
            <>
              <FeedbacksTable feedbacks={feedbacks} delete={handleDelete} />
              <p className="lead mb-3 text-center">End of Feedbacks</p>
            </>
        </div>
        )}
    </>
  );
}
