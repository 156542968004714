import React, { Component } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import InstructorsTable from "./instructorsTable";
import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";
import { getInstructors } from "../../services/instructorService";
import _ from "lodash";
import BackButton from "../common/backButtion";
import { Link } from "react-router-dom";

/* */

class Instructors extends Component {
  constructor(props) {
    super(props);
    this.nameRef = React.createRef();
  }
  state = {
    instructors: [],
    selectedInstructor: {},
    isLoading: false,
    currentPage: 1,
    pageSize: 20,
    sortColumn: { path: "user.fullName", order: "asc" },
  };

  async componentDidMount() {
    //if (isDebug) logData(this.props);
    this.setState({ isLoading: true });
    const instructors = await getInstructors();
    this.setState({ instructors });
    this.setState({ isLoading: false });
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleInstructorSelect = (instructor) => {
    if(instructor !== undefined) {
      this.setState({ selectedInstructor: instructor, currentPage: 1 });
    }
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      selectedInstructor,
      instructors,
    } = this.state;

    const filtered =
      selectedInstructor && selectedInstructor.id
        ? instructors.filter((i) => i.id === selectedInstructor.id)
        : instructors;
    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const instructors_tmp = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: instructors_tmp };
  };

  //

  render() {
    const { length: count } = this.state.instructors;
    const { pageSize, currentPage, sortColumn } = this.state;

    if (this.state.isLoading)
      return (
        <div className="text-center p-3">
          {" "}
          <span className="spinner-border spinner-border-lg align-center"></span>{" "}
        </div>
      );

    if (count === 0) return <p>There are no instructors in the database.</p>;

    const { totalCount, data: instructors } = this.getPagedData();
    const tableCaption = "Showing  " + totalCount + " instructors.";

    return (
      <div className="container shadow border border-1 border-light rounded-3 pt-2 pb-2 mt-2">
        <div className="d-flex flex-row">
          <div className="col-2 ">
            <>
              <BackButton />
              <Link to="/instructorsReport">
                <button className="btn btn-sm btn-primary ms-2">Show Report</button>
              </Link>
            </>
          </div>

          <div className="col-10 ">
            <div className="typeahead form-floating ">
              <div className="d-flex justify-content-start">
                <button
                  className="btn btn-sm btn-secondary me-1"
                  onClick={() => {
                    this.nameRef.current.clear();
                    this.setState({ selectedInstructor: {} });
                  }}
                >
                  Clear
                </button>
                <Typeahead
                  id="instructorfilter"
                  ref={this.nameRef}
                  labelKey={(option) => `${option.user.fullName}`}
                  placeholder="Filter by Instructor Name"
                  minLength={2}
                  size="sm"
                  options={this.state.instructors}
                  onChange={(selected) => {
                    this.handleInstructorSelect(selected[0]);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <h2 className="display-6 mt-3">Instructor List</h2>
        <InstructorsTable
          instructors={instructors}
          sortColumn={sortColumn}
          onSort={this.handleSort}
          caption={tableCaption}
        />
        <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={this.handlePageChange}
        />
      </div>
    );
  }
}

export default Instructors;
/*
 */
