import React, { Component } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import Table from "../common/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { removeReservation } from "../../services/reservationService";
import authService from "../../services/auth.service";

class DemoReservationTable extends Component {
  state = {
    reservations: [],
    currentPage: 1,
    pageSize: 6,
    sortColumn: { path: "name", order: "asc" },
    currentUser: null,
  };

  componentDidMount() {
    const loggedInUser = authService.getCurrentUser();
    this.setState({ currentUser: loggedInUser });
    if (loggedInUser.roleId === 1 || loggedInUser.roleId === 2) {
      // logged in user is either admin or instructor
      this.columns.push(...this.adminColumns);
    }
    const { reservations } = this.props;
    console.log(reservations);
    this.setState({ reservations });
  }

  columns = [
    {
      key: "edit",
      content: (reservation) => (
        <Link
          to={`/reservation/${reservation.id}`}
          state={{ demoId: reservation.demoId }}
        >
          <FontAwesomeIcon icon={faPenToSquare} size="1x" />
        </Link>
      ),
    },
    {
      path: "user.fullName",
      label: "Requestor",
    },
    {
      path: "acctNum",
      label: "Account",
      content: (reservation) => (
        <Link to={`/resellerDetail/${reservation.resellerId}`}>
          {reservation.reseller.name}
        </Link>
      ),
    },
    {
      path: "description",
      label: "Description",
      content: (reservation) => (
        <Link to={`/reservations/${reservation.id}`}>
          {reservation.description}
        </Link>
      ),
    },
    {
      path: "startDate",
      label: "Date",
      content: (reservation) =>
        reservation.startDate
          ? format(new Date(reservation.startDate), "Pp")
          : "Not Yet Approved",
    },
    { path: "expectedNumOfAttendees", label: "Attendee Count" },
    { path: "duration", label: "Duration (hrs)" },
    /*
     */
  ];

  adminColumns = [
    {
      key: "approve",
      content: (reservation) => (
        <Link to={`/reservationApproval/${reservation.id}`}>
          <button className="btn btn-sm btn-primary">
            {reservation.startDate ? "Change" : "Approve"}
          </button>
        </Link>
      ),
    },
    {
      key: "delete",
      content: (reservation) => (
        <button
          onClick={() => this.handleDelete(reservation)}
          className="btn btn-danger btn-sm"
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </button>
      ),
    },
  ];

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleDelete = async (reservation) => {
    console.log("Delete Clicked");
    const deleted = await removeReservation(reservation.id);
    console.log(deleted);
    if (deleted.status === 200) {
      const filteredReservations = this.state.reservations.filter(
        (r) => r.id !== reservation.id
      );
      this.setState({ reservations: filteredReservations });
    }
  };

  /*
   */

  render() {
    const { caption } = this.props;

    //const dateCorrectedReservations = reservations.map(this.formatDate);

    if (this.state.reservations.length === 0) {
      return;
    } else {
      return (
        <Table
          columns={this.columns}
          data={this.state.reservations}
          sortColumn={this.state.sortColumn}
          onSort={this.handleSort}
          onDelete={this.handleDelete}
          caption={caption}
        />
      );
    }
  }
}

export default DemoReservationTable;
