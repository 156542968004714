import React, { useState, useEffect } from "react";
import { isDebug } from "../common/helpers";
import { getFeedbackCount } from "../../services/feedbackService";
import { getReservationCount } from "../../services/reservationService";
import { getSignupCount } from "../../services/signupService";
import ButtonWL from "../common/buttonWithLink";
import httpService from "../../services/httpService";
import DemoViewReport from "./demoViewReport";
import VendorViewReport from "./vendorViewReport";
import InstructorDemoReport from "./instructorDemoReport";
import UserActivityReport from "./userActivityReport";
import TopicDemoReport from "./topicDemoReport";
import { getVendors } from "../../services/vendorService";
import { getDemos } from "../../services/demoService";
import { getResellers } from "../../services/resellerService";
import { getInstructors } from "../../services/instructorService";
import { getUsersWithActivites } from "../../services/userService";
import { getCategories } from "../../services/categoryService";

function AdminDashboard() {
  const [feedbackCnt, setFeedbackCnt] = useState(null);
  const [rCnt, setRCnt] = useState(null);
  const [signupCnt, setSignupCnt] = useState(null);
  const [resellers, setResellers] = useState(null);
  const [resellersCnt, setResellersCnt] = useState(0);
  const [demos, setDemos] = useState(null);
  const [demosCnt, setDemosCnt] = useState(0);
  const [vendors, setVendors] = useState(null);
  const [vendorsCnt, setVendorsCnt] = useState(0);
  const [instructors, setInstructors] = useState(null);
  const [instructorsCnt, setInstructorsCnt] = useState(0);
  const [users, setUsers] = useState(null);
  const [usersCnt, setUsersCnt] = useState(0);
  const [topics, setTopics] = useState(null);
  const [topicsCnt, setTopicsCnt] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    async function getSUCount() {
      const localSUCount = await getSignupCount();
      if(isDebug)  console.log("Sign Up count is: " + localSUCount);
      setSignupCnt(localSUCount);
    }
    getSUCount().catch((error) => httpService.handleError(error));

    async function getFBCount() {
      const localFBCount = await getFeedbackCount();
      if(isDebug) console.log("Feed back count is: " + localFBCount);
      setFeedbackCnt(localFBCount);
    }
    getFBCount().catch((error) => httpService.handleError(error));

    async function getReserveCount() {
      const localRCount = await getReservationCount();
      if(isDebug) console.log("Reservations count is: " + localRCount);
      setRCnt(localRCount);
    }
    getReserveCount().catch((error) => httpService.handleError(error));

    async function getRList() {
      const lresellers = await getResellers();
      if(isDebug) console.log(lresellers);
      setResellers(lresellers);
      setResellersCnt(lresellers.length);
    }
    getRList().catch((error) => httpService.handleError(error));

    async function getDList() {
      const ldemos = await getDemos();
      if(isDebug) console.log(ldemos);
      setDemos(ldemos);
      setDemosCnt(ldemos.length);
    }
    getDList().catch((error) => httpService.handleError(error));

    async function getVList() {
      const lvendors = await getVendors();
      if(isDebug) console.log(lvendors);
      setVendors(lvendors);
      setVendorsCnt(lvendors.length);
    }
    getVList().catch((error) => httpService.handleError(error));

    async function getIList() {
      const linstructors = await getInstructors();
      if(isDebug) console.log(linstructors);
      setInstructors(linstructors);
      setInstructorsCnt(linstructors.length);
    }
    getIList().catch((error) => httpService.handleError(error));

    async function getUList() {
      const lusers = await getUsersWithActivites();
      if(isDebug) console.log(lusers);
      setUsers(lusers);
      setUsersCnt(lusers.length);
    }
    getUList().catch((error) => httpService.handleError(error));

    async function getTList() {
      const ltopics = await getCategories();
      if(isDebug) console.log(ltopics);
      setTopics(ltopics);
      setTopicsCnt(ltopics.length);
    }
    getTList().catch((error) => httpService.handleError(error));

    setIsLoading(false);
  }, []);

  //This is the page that Admins work from
  return (
    <>
      {isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      ) : (
        <div className="container">
          <center>
            <p className="fs-2 mt-2">Admin Dashboard</p>
          </center>

          <hr className="mt-3 mb-3" />
          <div className="row">
            <div className="col text-center">
              <ButtonWL
                size="sm"
                variant="primary"
                value="View Feedback"
                link="/feedbacks"
                count={feedbackCnt}
              />
            </div>

            <div className="col text-center">
              <ButtonWL
                size="sm"
                variant="primary"
                value="View Reservations"
                link="/reservations"
                count={rCnt}
              />
            </div>

            <div className="col text-center">
              <ButtonWL
                size="sm"
                variant="primary"
                value="View New User Requests"
                link="/signups"
                count={signupCnt}
              />
            </div>

            <div className="col text-center">
              <ButtonWL
                size="sm"
                variant="primary"
                value="View DTC Requests"
                link="/dtcRequests"
                count={0}
              />
            </div>

            <div className="col text-center">
              <ButtonWL
                size="sm"
                variant="danger"
                value="View User Activities"
                link="/userActivities"
                count={0}
              />
            </div>

          </div>
          
          <hr className="mt-3 mb-3" />
          <div className="row">
            <div className="col text-center">
              <ButtonWL
                size="sm"
                variant="success"
                value="View Topics"
                link="/categories"
                count={0}
              />
            </div>

            <div className="col text-center">
              <ButtonWL
                size="sm"
                variant="success"
                value="View All Instructors"
                link="/instructors"
                count={0}
              />
            </div>

            <div className="col text-center">
              <ButtonWL
                size="sm"
                variant="success"
                value="View All Vendors"
                link="/vendors"
                count={0}
              />
            </div>

            <div className="col text-center">
              <ButtonWL
                size="sm"
                variant="success"
                value="View All Resellers"
                link="/resellers"
                count={0}
              />
            </div>

            <div className="col text-center">
              <ButtonWL
                size="sm"
                variant="info"
                value="PoC Management"
                link="/lmdashboard"
                count={0}
              />
            </div>

            <div className="col text-center">
              <ButtonWL
                size="sm"
                variant="info"
                value="View ServiceSolv Info"
                link="/servicesolv"
                count={0}
              />
            </div>

          </div>

          <hr className="mt-3 mb-3" />
          <div className="row ">
            <center><b>
              <div className="col d-inline-flex pe-2">Demos Count: {demosCnt} {" | "} </div>
              <div className="col d-inline-flex pe-2">Vendor Count: {vendorsCnt} {" | "}</div>
              <div className="col d-inline-flex pe-2">Reseller Count: {resellersCnt} {" | "}</div>
              <div className="col d-inline-flex pe-2">User Count: {usersCnt} {" | "}</div>
              <div className="col d-inline-flex pe-2">Instructor Count: {instructorsCnt} {" | "}</div>
              <div className="col d-inline-flex">Topics Count: {topicsCnt}</div>
            </b> </center>
          </div>

          <hr className="mt-3 mb-3" />
          <div className="row">
            <div className="col-6">
                <DemoViewReport demos={demos} />
            </div>
            <div className="col-6">
                <UserActivityReport users={users} />
            </div>
          </div>

          <hr className="mt-3 mb-3" />
          <div className="row">
            <div className="col-4">
                <InstructorDemoReport instructors={instructors} />
            </div>
            <div className="col-4">
                 <VendorViewReport vendors={vendors} />
            </div>
            <div className="col-4">
                 <TopicDemoReport topics={topics} />
            </div>
          </div>

          <hr className="mt-3 mb-3" />
          <div className="row">
          </div>
        </div>
      )}
    </>
  );
}

export default AdminDashboard;

/*

          <div className="row">
            <ColorCard
              title="Feedback"
              description="See list of feedback items."
              link="/feedbacks"
            />
            <ColorCard
              title="Reservations"
              description="See the list of Reservations in the System"
              link="/reservations"
            />
            <ColorCard
              title="Sign Ups"
              description="See list of signup requests that are open"
              link="/signups"
            />

        <Feedbacks />
        <Reservations />
        <NewUserRequest />
*/
/*

            <div className="col">
              <ColorCard
                title="Instructors"
                description="See full list of current instructors"
                link="/instructors"
              />
            </div>
  <div className="col">
              <ColorCard
                title="Activities"
                description="See User Activities"
                link="/userActivities"
                color="black"
                bgcolor="burlywood"
              />
            </div>


            <div className="col">
              <ColorCard
                title="Reseller Partners"
                description="See full list of current reseller partners"
                link="/resellers"
                color="white"
                bgcolor="darkgreen"
              />
            </div>

            <div className="col">
              <ColorCard
                title="ServiceSolv"
                description="See the ServiceSolv Page"
                link="/servicesolv"
                color="white"
                bgcolor="darkcyan"
              />
            </div>

            
            <div className="col">
              <ColorCard
                title="Vendors"
                description="See full list of current vendors"
                link="/vendors"
                color="white"
                bgcolor="darkgoldenrod"
              />
            </div>

            
            <div className="col">
              <ColorCard
                title="PoC Managment"
                description="Manage Proof-of-Concept System"
                link="/lmdashboard"
                color="white"
                bgcolor="darkgreen"
              />
            </div>
*/