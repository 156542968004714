import React from "react";

export default function RenderBlock(label, value) {
  return (
    <>
      <span className="text-muted">{label}</span>
      <br />
      <span className="fs-6">{value}</span>
    </>
  );
}
