import React from "react";
import { HashLink } from "react-router-hash-link";

export default function PreSalesEngineeringFeature() {
  return (
    <>
      <hr className="featurette-divider" />
      <div className="container">
        <div className="row featurette align-items-center">
          <div className="col-md-7">
            <h2 className="featurette-heading fw-normal lh-1">
              Pre-Sales Engineering{" "}
            </h2>

            <p className="lead">
              Another way TD SYNNEX can help? Of course. With unlimited
              Pre-Sales support, we can extend your technical bench, join calls
              with your customers, design complex solutions and create
              value-added BoMs for all needed designs.
            </p>
          </div>
          <div className="col-md-5">
            <img src="console01.jpg" className="d-block w-100" />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <center>
              <p className="lead">Contact Us:</p>
            </center>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <center>
              <a href="mailto:presales@tdsynnex.com">
                {" "}
                <p className="lead">PreSales@tdsynnex.com </p>
              </a>
            </center>
          </div>
        </div>
      </div>
    </>
  );
}
