import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../common/table";
import authService from "../../services/auth.service";
import rbacService from "../../services/rbacService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";

class ResellerPocTable extends Component {
  state = {
    currentUser: {},
    rbac: null,
    showAddButton: false,
  };


  componentDidMount() {
    const loggedInUser = authService.getCurrentUser();
    const localRBAC = new rbacService(loggedInUser);

    this.setState({ currentUser: loggedInUser, rbac: localRBAC });
    //if (loggedInUser.roleId <= 2) {
    if (localRBAC.isAdminOrLoanerAdmin()) {
      // logged in user is either admin or instructor
      this.columns.push(...this.adminColumns);
      this.setState({ showAddButton: true });
    }
  }

  columns = [
    {
      path: "name",
      label: "Name",
      content: (pocOrder) => (
        <Link to={`/pocOrder/${pocOrder.id}`}>{pocOrder.name}</Link>
      ),
    },
    {
      path: "pocKits[0].name",
      label: "Kit",
      content: (pocOrder) => (
        <Link to={`/pocKit/${pocOrder.pocKits[0]?.id}`}>{pocOrder.pocKit === null ? "Unkitted" : pocOrder.pocKits[0]?.name  }</Link>
      ),
    },
    { path: "status", label: "Status" },
    { path: "requestorName", label: "Requested by" },
    { path: "endUserName", label: "End User" },
    { path: "description", label: "Description" },
  ];
    /*
    {
      path: "user.fullName",
      label: "User",
      content: (pocOrder) => (
        <a
          href={`mailto:${pocOrder.user.email}?cc=scportaladmin@tdsynnex.com&subject=pocOrder%20Response`}
        >
          {pocOrder.user.fullName}
        </a>
      ),
    },
    {
      path: "user.reseller.name",
      label: "Account",
    },
    */
  adminColumns = [
    {
      key: "edit",
      content: (pocOrder) => (
        <Link to={`/pocOrderForm/${pocOrder.id}`}>
          <FontAwesomeIcon icon={faPenToSquare} />
        </Link>
      ),
    },
    {
      key: "delete",
      content: (pocOrder) => (
        <button
          onClick={() => this.props.onDelete(pocOrder)}
          className="btn btn-danger btn-sm"
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </button>
      ),
    },
  ];

  render() {
    const { pocOrders, onSort, onDelete, sortColumn, caption } = this.props;

    return (
      <Table
        columns={this.columns}
        data={pocOrders}
        sortColumn={sortColumn}
        onSort={onSort}
        onDelete={onDelete}
        caption={caption}
      />
    );
  }
}

export default ResellerPocTable;
