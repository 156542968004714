import React, { useEffect, useState}  from "react";
import rbacService from "../../services/rbacService";
import authService from "../../services/auth.service";
import { getRoles } from "../../services/roleService";
import PocItems from "./pocItems";
import PocKits from "./pocKits";
import PocUserOrders from "./pocUserOrders";
import BackButton from "../common/backButtion";

/*
  Loaner: The top level view of loaner equipment status
  LoanOrder: A request of loaner equipment it 
             is made up of LoanOrderItems
  LoanOrderItem: instance of HW item shipped to customer
                 if LoanOrder is approved
  LoanOrderItemKit: instance of a collection of hw items
                    that are bundled together and treated
                    as a single item, but have all of the
                    data of all individual items.
*/

export default function Loaner() {
  const [roleName, setRoleName] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [rbac, setRBAC] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errMsg, setErrMsg] = useState(null);

   // Runs after first render (when component mounts, sorta)
  useEffect(() => {
    setIsLoading(true);

    const cU = authService.getCurrentUser();
    let rb = new rbacService(cU);
    setRBAC(rb);
    setCurrentUser(cU);

    const getRName = async () => {
      const currentRoles = await getRoles();
      /*
      console.log(currentRoles);
      console.log(currentRoles[cU.roleId - 1].name);
      */
      const currentRoleName = currentRoles[cU.roleId - 1].name;
      setRoleName(currentRoleName);
    };
    getRName().catch(console.error);
    setIsLoading(false);
  }, []);

  return (
    <React.Fragment>
      {hasError ? 
        ( alert(errMsg)) 
        : isLoading ? (
          <div className="text-center p-3">
            <span className="spinner-border spinner-border-lg align-center"></span>
          </div>
        ) : (
        <>
          <div className="container">

            <BackButton />
          <center>
            <h2 className="display-4">Proof of Concept Program</h2>
            <br />
            <p className="lead">
              TD SYNNEX offers collection of hardware and software items, know as <b><i>kits</i></b> to our reseller partners.  
              These kits can be used as on-site demonstration units, or
              as a way to prove that a solution will work as desired.  See the list of PoC Kits below.  If your company has any open 
              requests or orders for PoCs they are listed below as well.
            </p>
            <br />

          </center>
          </div>

          <PocKits showBackButton={false} />
          <PocUserOrders />
        </>
      )}
    </React.Fragment>
  );
};
/*
            <p className="lead">Hello {roleName}</p>

            <p className="lead"> 
            { rbac?.isAdmin() ? (" Admin ") : (" non-admin ")}
            { rbac?.isUser() ? " regular user " : " other "}
            </p>

*/