import authHeader from "./auth-header";
import http from "./httpService";

export async function getVendors() {
  const { data: vendors } = await http.get("/api/v1/vendors/", {
    headers: authHeader(),
  });
  return vendors;
}

export async function getVendorInfo(vendorId) {
  const { data: vendorInfo } = await http.get("/api/v1/vendors/" + vendorId, {
    headers: authHeader(),
  });
  return vendorInfo;
}

export async function getVendorByName(name) {
  const { data: vendorName } = await http.get(
    "/api/v1/vendors/getByName/" + name,
    {
      headers: authHeader(),
    }
  );
  return vendorName;
}

export async function createVendor(vendor) {
  console.log("in createVendor...");
  const response = await http.post("/api/v1/vendors/", vendor, {
    headers: authHeader(),
  });
  return response;
}

export async function updateVendor(vendorId, vendor) {
  const response = await http.put(`/api/v1/vendors/${vendorId}`, vendor, {
    headers: authHeader(),
  });
  return response;
}

export async function removeVendor(vendorId) {
  const response = await http.delete(`/api/v1/vendors/${vendorId}`, {
    headers: authHeader(),
  });
  return response;
}
