import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import * as yup from "yup";

import authService from "../../services/auth.service";
import {
  createReseller,
  getResellerInfo,
  updateReseller,
} from "../../services/resellerService";
import Input from "../common/input";
import { states } from "../../utils/const";
import { logData, isDebug } from "../common/helpers";
import BackButton from "./../common/backButtion";
import rbacService from "../../services/rbacService";

export default function ResellerForm() {
  const [fetchedReseller, setFetchedReseller] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [errMsg, setErrMsg] = useState();

  const { resellerId } = useParams();
  const navigate = useNavigate();

  const currentUser = authService.getCurrentUser();
  const rbac = new rbacService(currentUser);

  const isNewReseller = resellerId.toString() === "new" ? true : false;

  const submitButtonLabel = isNewReseller ? "Create" : "Update";
  const pageTitle = isNewReseller
    ? "Create New Reseller"
    : "Update Reseller Information";

  let location = useLocation();
  /*
   */
  const schema = yup.object().shape({
    name: yup.string().required("Reseller Name is required").min(2),
    acctNum: yup
      .string()
      .required("Reseller Account Number is required")
      .min(4),
    workPhone: yup.string().min(8).max(17).nullable(),
    addressStreet1: yup.string().min(4).nullable(),
    addressCity: yup.string().min(2).nullable(),
    addressState: yup.string().min(2).nullable(),
    addressZip: yup.string().min(2).nullable(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const handleUpdate = (data) => {
    if (isDebug) {
      logData("Handling Update...");
      logData(data);
    }

    if (isNewReseller) {
      // Creating new reseller
      const doCreate = async () => {
        const createResponse = await createReseller(data);
        if (isDebug) {
          logData(createResponse);
          logData(createResponse.status);
        }
        if (createResponse.status === 201) {
          setSuccessful(true);
        }
      };
      doCreate().catch(console.error);
    } else {
      // Updating an existing reseller
      data.id = resellerId;

      const pushUpdate = async () => {
        const updateResponse = await updateReseller(resellerId, data);
        if (isDebug) {
          logData(updateResponse);
          logData(updateResponse.status);
        }
        if (updateResponse.status === 200) {
          setSuccessful(true);
        }
      };
      pushUpdate().catch(console.error);
    }
  };

  // Runs after first render (when component mounts, sorta)
  useEffect(() => {
    setIsLoading(true);

    if (!isNewReseller) {
      const pullResellerInfo = async () => {
        const instructor = await getResellerInfo(resellerId);
        setFetchedReseller(instructor);
      };

      pullResellerInfo().catch(console.error);
      setIsLoading(false);
    } else {
      // only Admins can use this form to create a new instructor
      if (currentUser.roleId > 2) {
        console.log(currentUser);
        setHasError(true);
        setErrMsg("Only Admins can create new resellers!");
      } else {
        console.log(location.state);
        if (location.state) {
          let newReseller = {};
          newReseller.acctNum = location.state.acctNumber;
          newReseller.name = location.state.companyName;
          newReseller.workPhone = location.state.workPhone;
          setFetchedReseller(newReseller);
        }
        setIsLoading(false);
      }
    }
  }, []);

  /* this useEffect is called only when we have 
  fetched a user from the backend */
  useEffect(() => {
    console.log("Fetched Reseller: ");
    console.log(fetchedReseller);
    if (fetchedReseller !== null) {
      setValue("name", fetchedReseller.name, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("dba", fetchedReseller.dba, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("acctNum", fetchedReseller.acctNum, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("addressStreet1", fetchedReseller.addressStreet1, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("addressStreet2", fetchedReseller.addressStreet2, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("addressCity", fetchedReseller.addressCity, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("addressState", fetchedReseller.addressState, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("addressZip", fetchedReseller.addressZip, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("workPhone", fetchedReseller.workPhone, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [fetchedReseller]);

  /* This useEffect is only when successful submission */
  useEffect(() => {
    if (successful) {
      //navigate("/users");
      navigate(-1);
    }
  }, [successful]);

  return (
    <React.Fragment>
      {hasError ? (
        alert(errMsg)
      ) : isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      ) : (
        <>
          <div className="container shadow border border-2 border-light rounded-3 mt-4">
            <div className="row">
              <div className="col-2 mt-2">
                <BackButton />
              </div>
              <div className="col-10">
                <h2 className="display-6">{pageTitle}</h2>
              </div>
            </div>
            <form onSubmit={handleSubmit(handleUpdate)} className="p-2">
              <div className="row justify-content-lg-center">
                <div className="col-md">
                  <Input
                    register={register}
                    label="Name"
                    fieldName="name"
                    errors={errors}
                  />
                  <Input
                    register={register}
                    label="Doing Business As"
                    fieldName="dba"
                    errors={errors}
                  />
                  <Input
                    register={register}
                    label="Account Number"
                    fieldName="acctNum"
                    errors={errors}
                  />
                  <Input
                    register={register}
                    label="Telephone Number"
                    fieldName="workPhone"
                    errors={errors}
                  />
                </div>
                <div className="col-md">
                  <Input
                    register={register}
                    label="Street Address"
                    fieldName="addressStreet1"
                    errors={errors}
                  />
                  <Input
                    register={register}
                    label="Street Address pt. 2"
                    fieldName="addressStreet2"
                    errors={errors}
                  />
                  <Input
                    register={register}
                    label="City"
                    fieldName="addressCity"
                    errors={errors}
                  />
                  <div className="row justify-content-lg-center">
                    <div className="col-md">
                      <div className="form-floating">
                        <select
                          {...register("addressState", {
                            onChange: (e) => {
                              setValue("addressState", e.target.value);
                            },
                          })}
                          id="addressState"
                          className="form-select m-2"
                        >
                          {states.map((state, i) => (
                            <option key={i} value={state.abrev}>
                              {state.name}
                            </option>
                          ))}
                        </select>
                        <label htmlFor="addressState">
                          {"Select reseller state"}
                        </label>
                        {errors["addressState"] && (
                          <div className="alert alert-danger">
                            {errors["addressState"].message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md">
                      <Input
                        register={register}
                        label="Zipcode"
                        fieldName="addressZip"
                        errors={errors}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-lg-center">
                <div className="col"></div>
                <div className="col">
                  <center>
                    <div id="4" className="form-group m-2">
                      <input
                        className="btn btn-sm btn-primary me-2"
                        type="submit"
                        value={submitButtonLabel}
                      />
                      <input
                        className="btn btn-sm btn-danger"
                        type="button"
                        value="Cancel"
                        onClick={() => {
                          navigate(-1);
                        }}
                      />
                    </div>
                    {successful && (
                      <div className="alert alert-success">
                        Update Successful!
                      </div>
                    )}
                  </center>
                </div>
                <div className="col"></div>
              </div>
            </form>
          </div>
        </>
      )}
    </React.Fragment>
  );
}
