import React, { useState, useEffect } from "react";

import BackButton from "../common/backButtion";
import { isDebug, logData } from "../common/helpers";
import UserActivitiesTable from "./userActivitiesTable";

export default function UserActivities() {
  const [startDate, setStartDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const defaultStartDate = new Date();
    // 14 here is 14 days
    defaultStartDate.setDate(defaultStartDate.getDate() - 14);
    let textdate = defaultStartDate.toISOString();
    setStartDate(textdate);
    //console.log(textdate);

    /*
    const getUserActivityList = async () => {
      //const localUserActivities = await getUserActivities();
      const localUserActivities = await getUserActivitiesSinceDate(textdate);
      setUserActivities(localUserActivities);
      if (isDebug) logData(localUserActivities);
    };
    getUserActivityList().catch(console.error);
    */
    setIsLoading(false);
  }, []);
/*
  useEffect(() => {
    console.log(userActivities);
  }, [userActivities]);
*/

  return (
    <>
      <div className="container shadow border border-1 border-light rounded-3 pt-2 pb-2 mt-2">
        <BackButton />
        {isLoading ? (
          <div className="text-center p-3">
            <span className="spinner-border spinner-border-lg align-center"></span>
          </div>
        ) : startDate !== null ? (
          <UserActivitiesTable  />
        ) : null}
      </div>
    </>
  );
}

/*
          <UserActivitiesTable userActivities={userActivities} />
*/