import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import BackButton from "../common/backButtion";
import authService from "../../services/auth.service";
import rbacService from "../../services/rbacService";

import { getEnum, getEnum2, getPocItems, removePocItem } from "../../services/pocItemService";
import PocItemsTable from "./pocItemsTable";

class PocItems extends Component {
  constructor(props) {
    super(props);
    this.pocItemRef = React.createRef();
  }
  state = {
    pocItems: [],
    itemTypes: [],
    itemStatues: [],
    isLoading: false,
    selectedpocItem: {},
    selectedType: null,
    selectedStatus: null,
    currentUser: null,
    rbac: null,
    currentPage: 1,
    pageSize: 10,
    sortColumn: { path: "id", order: "asc"},
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    const localPocItems = await getPocItems();
    const localItemTypes = await getEnum();
    const localItemStatuses = await getEnum2();
    const cu = authService.getCurrentUser();
    const localrbac = new rbacService(cu);

    console.log(localPocItems);

    this.setState({ rbac: localrbac, 
                    currentUser: cu, 
                    pocItems: localPocItems, 
                    itemStatues: localItemStatuses,
                    itemTypes: localItemTypes,
                    isLoading: false});
    //this.setState({ currentUser: cu});
    //this.setState({ pocItems: localPocItems});
    //this.setState({ isLoading: false});
  };

  filterType = (e) => {
    console.log(e.target.value);
    this.setState({selectedType: e.target.value});
  }

  filterStatus = (e) => {
    console.log(e.target.value);
    this.setState({ selectedStatus: e.target.value});
  }

  handlepocItemSelect = (pocItem) => {
    if(pocItem !== undefined) {
      this.setState({ selectedpocItem: pocItem, currentPage: 1 });
    }
  };

  handleDelete = async (pocItem) => {
    const deleted = await removePocItem(pocItem.id);
    if (deleted.status === 200) {
      const pocItems_tmp = this.state.pocItems.filter((i) => i.id !== pocItem.id);
      this.setState({ pocItems: pocItems_tmp });
    }
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  
  getPagedData = () => {
    const { selectedpocItem, pageSize, currentPage, sortColumn, pocItems, selectedStatus, selectedType } =
      this.state;

    const pocItemfiltered =
      selectedpocItem && selectedpocItem.id
        ? pocItems.filter((v) => v.id === selectedpocItem.id)
        : pocItems;

    // handle filter by type
    let itemTypeFiltered = "";
    if(selectedType === "All Item Types") {
      itemTypeFiltered = pocItemfiltered;
    } else {
      itemTypeFiltered = 
        selectedType ? pocItemfiltered.filter((v) => v.type === selectedType)
        : pocItemfiltered;
    }

    // handle filter by type
    let itemStatusFiltered = "";
    if(selectedStatus === "All Item Statuses"){
      itemStatusFiltered =  itemTypeFiltered;
    } else {
      itemStatusFiltered = 
        selectedStatus ? pocItemfiltered.filter((v) => v.status === selectedStatus)
        : itemTypeFiltered;
    }

    const sorted = _.orderBy(
      itemStatusFiltered,
      [sortColumn.path],
      [sortColumn.order]
    );

    const pocItems_tmp = paginate(sorted, currentPage, pageSize);

    return { totalCount: itemStatusFiltered.length, data: pocItems_tmp };
  };

  
  changePageSize = (e) => {
    this.setState({ pageSize: e.value, currentPage: 1 });
  };

  render() {
    const { length: count } = this.state.pocItems;
    const { pageSize, currentPage, sortColumn, itemTypes, itemStatues } = this.state;

    
    if (this.state.isLoading)
      return (
        <div className="text-center p-3">
          {" "}
          <span className="spinner-border spinner-border-lg align-center"></span>{" "}
        </div>
      );

    if (count === 0) return <p>There are no Proof-of-Concept Kits in the database.</p>;

    const { totalCount, data: pocItems } = this.getPagedData();
    const tableCaption = "Showing " + totalCount + " Proof-of-Concept Items in the database.";

    const selectOptions = [
      { value: 5, label: 5 },
      { value: 10, label: 10 },
      { value: 15, label: 15 },
      { value: 20, label: 20 },
      { value: 25, label: 25 },
      { value: 50, label: 50 },
    ];

    return (
      <div className="container shadow border border-1 border-light rounded-3 pt-2 pb-2 mt-2">
      <div className="d-flex flex-row">
        <div className="col-2 ">
          <BackButton />
          <Link to="/pocItemForm/new">
            <button className="btn btn-primary btn-sm ms-2">
              Add Item
            </button>
          </Link>
        </div>
        <div className="col-2">
          <div className="typeahead form-floating ">
            <div className="d-flex justify-content-start">
              <button
                className="btn btn-sm btn-secondary me-1"
                onClick={() => {
                  this.pocItemRef.current.clear();
                  this.setState({ selectedpocItem: {} });
                }}
              >
                Clear
              </button>
              <Typeahead
                id="pocItemFilter"
                ref={this.pocItemRef}
                labelKey={(option) => `${option.serialNum}`}
                placeholder="Filter by Serial #"
                minLength={2}
                size="sm"
                options={this.state.pocItems}
                onChange={(selected) => {
                  this.handlepocItemSelect(selected[0]);
                }}
              />
            </div>
          </div>
        </div>

        <div className="col-2">
          <div className="form-group ">
            <select
              id="type"
              className="form-select ms-2"
              onChange={this.filterType}
            >
              <option>{"All Item Types"}</option>
              {itemTypes.map((type, i) => (
                <option key={i} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-2">
          <div className="form-group ms-2">
            <select
              id="status"
              className="form-select ms-2"
              onChange={this.filterStatus}
            >
              <option>{"All Item Statuses"}</option>
              {itemStatues.map((status, i) => (
                <option key={i} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </div>
        </div>

      </div>

      <h2 className="display-6 mt-3 text-center">Proof-of-Concept Item Inventory</h2>
      
      <div className="row">
        <div className="d-inline-flex pe-2">
          <PocItemsTable
            pocItems={pocItems}
            sortColumn={sortColumn}
            onLike={this.handleLike}
            onDelete={this.handleDelete}
            onSort={this.handleSort}
            caption={tableCaption}
          />
        </div>
        <div className="d-inline-flex pe-2">
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
          />
          <Select
            className="ms-2"
            id="pgSize"
            options={selectOptions}
            onChange={this.changePageSize.bind(this)}
            placeholder="Number of Items / Page"
          />
        </div>
      </div>
    </div>
    );
  }
}

export default PocItems;

/*

                <label className="text-muted mx-2" htmlFor="type">
                  <small>{"Select Item type..."}</small>
                </label>
*/

/*
return (
  <>
    <div className="container shadow border border-1 border-light rounded-3 pt-2 pb-2 mt-2">
      <BackButton />
      <center>
      <h2 className="display-6">Proof of Concept Items</h2>
      </center>
      {isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      ) : (
        pocItems?.length > 0 && (
          <>
            <PocItemsTable pocItems={pocItems} />
          </>
        )
      )}
    </div>
  </>
);
*/