import authHeader from "./auth-header";
import http from "./httpService";

export async function getCenters() {
  const { data: centers } = await http.get("/api/v1/centers/", {
    headers: authHeader(),
  });
  return centers;
}

export async function getCenterbyID(centerId) {
  const { data: centerInfo } = await http.get("/api/v1/centers/" + centerId, {
    headers: authHeader(),
  });
  return centerInfo;
}

export async function createCenter(center) {
  const response = await http.post("/api/v1/centers/", center, {
    headers: authHeader(),
  });
  return response;
}

export async function updateCenter(centerId, center) {
  const response = await http.put(`/api/v1/centers/${centerId}`, center, {
    headers: authHeader(),
  });
  return response;
}

export async function removeCenter(centerId) {
  const response = await http.delete(`/api/v1/centers/${centerId}`, {
    headers: authHeader(),
  });
  return response;
}

/*
export async function getVendorInfo(vendorId) {
  const { data: vendorInfo } = await http.get("/api/v1/vendors/" + vendorId, {
    headers: authHeader(),
  });
  return vendorInfo;
}
*/
