import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useNavigate } from "react-router-dom";
import * as yup from "yup";

import { isDebug, logData } from "../common/helpers";
import config from "../../config.json";

import authService from "../../services/auth.service";
import http from "../../services/httpService";
import {
  createVendor,
  getVendorInfo,
  updateVendor,
} from "../../services/vendorService";

export default function VendorForm() {
  const [fetchedVendor, setFetchedVendor] = useState(null);
  const [currentLogo, setCurrentLogo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [errMsg, setErrMsg] = useState();

  const { vendorId } = useParams();
  const navigate = useNavigate();
  const currentUser = authService.getCurrentUser();

  if (isDebug) logData(currentUser);

  const isNewVendor = vendorId.toString() === "new" ? true : false;
  const submitButtonLabel = isNewVendor ? "Create" : "Update";
  const pageTitle = isNewVendor ? "Create New Vendor" : "Update Vendor";

  if (isDebug) logData(`isNewVendor = ${isNewVendor}`);
  /*
   */
  const schema = yup.object().shape({
    name: yup.string().required().min(2),
    description: yup.string().required().min(6),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const handleUpdate = (data) => {
    if (isDebug) {
      logData("Handling Create / Update ....");
      logData(data);
    }

    const formData = new FormData();
    formData.append("userId", currentUser.id);
    //data.userId = currentUser.id;

    if (data.logo?.length > 0) {
      formData.append("logo", data.logo[0]);
    }

    formData.append("name", data.name);
    formData.append("description", data.description);
    if (isNewVendor) {
      // Creating new feedback entry
      const doCreate = async () => {
        console.log("in doCreate ...");
        //const createResponse = await createVendor(data);
        const createResponse = await createVendor(formData);
        if (isDebug) {
          logData(createResponse);
          logData(createResponse.status);
        }
        if (createResponse.status >= 200 && createResponse.status < 300) {
          // HTTP POST seem to return 201 on success
          setSuccessful(true);
        } else {
          setHasError(true);
          setErrMsg("Returned error code: " + createResponse.status);
        }
      };
      doCreate().catch((e) => http.handleError(e));
    } else {
      // updating an existing item
      //data.id = vendorId;
      formData.append("id", vendorId);
      const pushUpdate = async () => {
        //const updateResponse = await updateVendor(vendorId, data);
        const updateResponse = await updateVendor(vendorId, formData);
        console.log(updateResponse);
        console.log(updateResponse.status);
        if (updateResponse.status >= 200 && updateResponse.status < 300) {
          // HTTP PUT seem to return 201 on success
          setSuccessful(true);
        }
      };
      //pushUpdate().catch((e) => alert(e));
      pushUpdate().catch((e) => http.handleError(e));
    }
  };

  // Runs after first render (when component mounts, sorta)
  useEffect(() => {
    setIsLoading(true);

    if (!isNewVendor) {
      const getInfo = async () => {
        const vendor = await getVendorInfo(vendorId);
        console.log(vendor);
        setFetchedVendor(vendor);
      };

      getInfo().catch((e) => http.handleError(e));
      setIsLoading(false);
    }
    setIsLoading(false);
  }, []);

  /* this useEffect is called only when we have 
  fetched a user from the backend */
  useEffect(() => {
    if (isDebug) logData("Fetched Vendor: " + fetchedVendor);

    if (fetchedVendor !== null) {
      setValue("name", fetchedVendor.name, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("description", fetchedVendor.description, {
        shouldValidate: true,
        shouldDirty: true,
      });

      if (fetchedVendor.logoUrl !== null) {
        setCurrentLogo(config.imgBaseURL + fetchedVendor.logoUrl);
      }
    }
  }, [fetchedVendor]);

  /* This useEffect is only when successful submission */
  useEffect(() => {
    if (successful) {
      navigate(-1);
    }
  }, [successful]);

  const imgStyleObj = {
    maxHeight: "100px",
    maxWidth: "200px",
    height: "auto",
    width: "auto",
  };

  return (
    <React.Fragment>
      {hasError ? (
        alert(errMsg)
      ) : isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      ) : (
        <div className="container shadow border border-2 border-light rounded-3 mt-4">
          <form onSubmit={handleSubmit(handleUpdate)} className="p-2">
            <div className="row justify-content-lg-center">
              <center>
                <p className="display-6">{pageTitle}</p>
              </center>
              <div className="col-8">
                <div className="form-group ">
                  <label className="text-muted " htmlFor="name">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control "
                    {...register("name", { required: "Name is required" })}
                  />
                  {errors.name && (
                    <div className="alert alert-danger">
                      {errors.name?.message}
                    </div>
                  )}
                  <label className="text-muted mt-2 " htmlFor="logo">
                    Vendor Logo
                  </label>
                  <input
                    {...register("logo")}
                    type="file"
                    className="form-control form-control-sm mt-2 px-2"
                  />
                  {errors.logo && (
                    <div className="alert alert-danger">
                      {errors.logo?.message}
                    </div>
                  )}
                  {currentLogo ? (
                    <img
                      src={currentLogo}
                      className="my-2"
                      style={imgStyleObj}
                      alt="Vendor Logo"
                    />
                  ) : (
                    <>
                      <img
                        src={config.imgBaseURL + "/headshots/unknown.jpg"}
                        width="200px"
                        height="200px"
                        alt="Vendor Logo"
                      />
                      <p>No Vendor Logo Loaded</p>
                    </>
                  )}
                  <br />
                </div>
              </div>
            </div>

            <div className="row justify-content-lg-center">
              <div className="col-md-8">
                <label htmlFor="description">
                  <span className="text-muted"> Description </span>{" "}
                </label>
                <textarea
                  {...register("description")}
                  className="form-control"
                  rows="5"
                />
              </div>
            </div>

            <div className="row justify-content-lg-center">
              <div className="col"></div>
              <div className="col">
                <center>
                  <div id="4" className="form-group m-2">
                    <input
                      className="btn btn-sm btn-primary me-2"
                      type="submit"
                      value={submitButtonLabel}
                    />
                    <input
                      className="btn btn-sm btn-danger"
                      type="button"
                      value="Cancel"
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                  </div>
                  {successful && (
                    <div className="alert alert-success">
                      Update Successful!
                    </div>
                  )}
                </center>
              </div>
              <div className="col"></div>
            </div>
          </form>
        </div>
      )}
    </React.Fragment>
  );
}
