import "./App.css";
import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import eventBus from "./components/common/eventBus";
import AuthService from "./services/auth.service";
import rbacService from "./services/rbacService";

//public components/routes
import Navbar from "./components/common/navbar";
import Home from "./components/home";
import Login from "./components/auth/login.component";
import NotFound from "./components/common/notFound";
import ServiceSolv from "./components/serviceSolv/serviceSolv";
import DeliveredServices from "./components/serviceSolv/deliveredServices";

// Solution Center Stuff
import Centers from "./components/centers/centers";
import CenterDetail from "./components/centers/centerDetail";

// Vendor Stuff
import Vendors from "./components/vendors/vendors";
import Vendor from "./components/vendors/vendor";

// Demo stuff
import Demos from "./components/demos/demos";
import DemoDetail from "./components/demos/demoDetail";
import DemoForm from "./components/demos/demoForm";
import DTCRequestForm from "./components/demos/dtcRequestForm";

// User Stuff
import Users from "./components/users/users";
import UserForm from "./components/users/userForm";
import UserDetail from "./components/users/userDetail";
import SignUpForm from "./components/users/signUp";
import FeedbackForm from "./components/users/feedbackForm";
import ChangePassword from "./components/users/changePassword";

// Instructors....
import Instructors from "./components/instructors/instructors";
import InstructorsReport from "./components/instructors/instructorsReport";
import Instructor from "./components/instructors/instructor";

// Resellers....
import Resellers from "./components/resellers/resellers";
import ResellerDetail from "./components/resellers/resellerDetail";
import ResellerForm from "./components/resellers/resellerForm";

// Admin stuff....
import AdminDashboard from "./components/admin/admin";
import Reservations from "./components/reservations/reservations";
import Reservation from "./components/reservations/reservation";
import ReservationForm from "./components/reservations/reservationForm";
import ReservationApprovalForm from "./components/reservations/reservationApproval";
import InstructorForm from "./components/instructors/instructorForm";
import NewUserRequests from "./components/users/newUserRequests";
import NewUserRequestDetail from "./components/users/newUserRequestDetail";
import Feedbacks from "./components/users/feedbacks";
import Categories from "./components/admin/categories";
import CategoryForm from "./components/admin/categoryForm";
import InstructorWorkspace from "./components/instructors/instructorWorkspace";
import FeedbackDetail from "./components/users/feedbackDetail";
import DeliveredServicesForm from "./components/serviceSolv/deliveredServicesForm";
import VendorForm from "./components/vendors/vendorForm";
import CenterForm from "./components/centers/centersForm";
import CategoryDetail from "./components/admin/categoryDetail";
import UserActivities from "./components/users/userActivities";
import UserActivityDetail from "./components/users/userActivityDetail";
import DTCActivities from "./components/admin/dtcActivities";
import DTCRequest from "./components/admin/dtcRequest";

// Loaner Manager stuff ...
import Loaner from "./components/loaners/loaner";
import LMDashboard from "./components/loaners/lmDashboard";
import PocItems from "./components/loaners/pocItems";
import PocKits from "./components/loaners/pocKits";
import PocKit from "./components/loaners/pocKit";
import PocItem from "./components/loaners/pocItem";
import PocKitForm from "./components/loaners/pocKitForm";
import PocItemForm from "./components/loaners/pocItemForm";
import PocOrders from "./components/loaners/pocOrders";
import PocOrder from "./components/loaners/pocOrder";
import PocOrderForm from "./components/loaners/pocOrderForm";
import PocRequests from "./components/loaners/pocRequests";
import UserDashboard from "./components/users/userDashboard";


class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      isAdmin: false,
      isInstructor: false,
      isLoanerManager: false,
      currentUser: undefined,
      isLoggedIn: false,
    };
  }

  componentDidMount() {
    eventBus.on("login", () => {
      const user = AuthService.getCurrentUser();
      if (user) {
        const rbac = new rbacService(user);
        this.setState({
          currentUser: user,
          isLoggedIn: true,
          isAdmin: rbac.isAdmin(), //s.includes("ROLE_ADMIN"),
          isInstructor: rbac.isInstructor(), //s.includes("ROLE_INSTRUCTOR"),
          isLoanerManager: rbac.isLoanerManager(), //s.includes("ROLE_INSTRUCTOR"),
        });
      }
    });

    eventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    eventBus.remove("logout");
    eventBus.remove("login");
    //this.logOut();
  }

  logOut() {
    AuthService.logout();
    console.log("in App.js -> logOut");
    this.setState({
      isAdmin: false,
      isInstructor: false,
      isLoanerManager: false,
      currentUser: undefined,
      isLoggedIn: false,
    });
  }

  render() {
    const { currentUser, isLoggedIn, isAdmin, isInstructor, isLoanerManager } = this.state;

    /* Moved axios.default settings to services/httpService.js */

    //if (currentUser) console.log(currentUser);
    //currentUser ? console.log(currentUser) : console.log("Current User NULL");

    return (
      <>
        <div className="App">
          <Navbar />
          <div className="main">
            <Routes>
              <Route path="/register" element={<SignUpForm />} />
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Home />} />
              <Route path="*" element={<NotFound />} />
              {isLoggedIn && (
                <>
                  <Route path="/userDashboard" element={<UserDashboard />} />
                  <Route path="/centers" element={<Centers />} />
                  <Route path="/categories" element={<Categories />} />
                  <Route path="/loaner" element={<Loaner />} />
                  <Route path="/pockit/:pocKitId" element={<PocKit />} />
                  <Route path="/pocOrder/:pocOrderId" element={<PocOrder />} />
                  <Route path="/pocitem/:pocItemId" element={<PocItem />} />
                  <Route path="/pocOrderform/:pocOrderId" element={<PocOrderForm />} />
                  <Route
                    path="/centerDetail/:centerId"
                    element={<CenterDetail />}
                  />
                  <Route
                    path="/categoryDetail/:categoryId"
                    element={<CategoryDetail />}
                  />
                  <Route path="/servicesolv" element={<ServiceSolv />} />
                  <Route
                    path="/deliveredServices"
                    element={<DeliveredServices />}
                  />
                  <Route
                    path="/feedbacks/:feedbackId"
                    element={<FeedbackForm />}
                  />
                  <Route
                    path="/feedback/:feedbackId"
                    element={<FeedbackDetail />}
                  />
                  <Route path="/reservations" element={<Reservations />} />
                  <Route path="/vendor/:vendorId" element={<Vendor />} />
                  <Route path="/user/:userId" element={<UserForm />} />
                  <Route path="/profile" element={<UserDetail />} />
                  <Route path="/cp" element={<ChangePassword />} />
                  <Route path="/userDetail/:userId" element={<UserDetail />} />
                  <Route path="/demos" element={<Demos />} />
                  <Route path="/demoDetail/:demoId" element={<DemoDetail />} />
                  <Route path="/demoForm/:demoId" element={<DemoForm />} />
                  <Route
                    path="/reservations/:reservationId"
                    element={<Reservation />}
                  />
                  <Route
                    path="/reservation/:reservationId"
                    element={<ReservationForm />}
                  />
                  <Route
                    path="/dtcRequestForm/:dtcRequestId"
                    element={<DTCRequestForm />}
                  />
                  <Route
                    path="/instructorProfile/:instructorId"
                    element={<Instructor />}
                  />
                  <Route
                    path="/resellerDetail/:resellerId"
                    element={<ResellerDetail />}
                  />
                </>
              )}
              { (isAdmin || isLoanerManager) && (
                <>
                  <Route path="/users" element={<Users />} />
                  <Route path="/lmdashboard" element={<LMDashboard />} />
                  <Route path="/pocitems" element={<PocItems />} />
                  <Route path="/pockits" element={<PocKits />} />
                  <Route path="/pocOrders" element={<PocOrders />} />
                  <Route path="/pocRequests" element={<PocRequests />} />
                  <Route path="/pockitform/:pocKitId" element={<PocKitForm />} />
                  <Route path="/pocitemform/:pocItemId" element={<PocItemForm />} />
                </>
              )}
              {isAdmin && (
                <>
                  <Route path="/admin" element={<AdminDashboard />} />
                  <Route path="/feedbacks" element={<Feedbacks />} />
                  <Route path="/userActivities" element={<UserActivities />} />
                  <Route
                    path="/userActivity/:userActivityId"
                    element={<UserActivityDetail />}
                  />
                  <Route path="/signups" element={<NewUserRequests />} />
                  <Route path="/instructorsReport" element={<InstructorsReport />} />
                  <Route
                    path="/signup/:signupId"
                    element={<NewUserRequestDetail />}
                  />
                </>
              )}
              {(isAdmin || isInstructor || isLoanerManager) && (
                <>
                  <Route
                    path="/resellerForm/:resellerId"
                    element={<ResellerForm />}
                  />
                  <Route path="/resellers" element={<Resellers />} />
                  <Route path="/dtcRequests" element={<DTCActivities />} />
                  <Route
                    path="/dtcRequest/:dtcRequestId"
                    element={<DTCRequest />}
                  />
                  <Route path="/vendors" element={<Vendors />} />
                  <Route
                    path="/vendorForm/:vendorId"
                    element={<VendorForm />}
                  />
                </>
              )}
              {(isAdmin || isInstructor) && (
                <>
                  <Route
                    path="/deliveredService/:dsId"
                    element={<DeliveredServicesForm />}
                  />
                  <Route
                    path="/centerForm/:centerId"
                    element={<CenterForm />}
                  />
                  <Route
                    path="/instructor/:instructorId"
                    element={<InstructorForm />}
                  />
                  <Route
                    path="/instructorWorkspace"
                    element={<InstructorWorkspace />}
                  />
                  <Route
                    path="/categoryForm/:categoryId"
                    element={<CategoryForm />}
                  />
                  <Route path="/instructors" element={<Instructors />} />
                  <Route
                    path="/reservationApproval/:reservationId"
                    element={<ReservationApprovalForm />}
                  />
                </>
              )}
            </Routes>
          </div>
        </div>
      </>
    );
  }
}

export default App;

/*

              <Route path="*" element={<NotFound />} />
*/
/*
          <Footer />
 */
