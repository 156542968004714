import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

import BackButton from "../common/backButtion";
import { isDebug, logData } from "../common/helpers";
import PocKitItemsTable from "./pocKitItemsTable";
import { getPocKitbyID } from "../../services/pocKitService";
import rbacService from "../../services/rbacService";
import authService from "../../services/auth.service";

export default function PocKit() {
  const [pocKit, setPocKit] = useState(null);
  const [pocKitItems, setPocKitItems] = useState(null);
  const [cu, setCU] = useState(null);
  const [rbac, setRBAC] = useState(null);

  let { pocKitId } = useParams();

  async function getPocKitData() {
    if(pocKitId !== "undefined" && pocKitId !== null){
      const localPocKit = await getPocKitbyID(pocKitId);
      setPocKit(localPocKit);
    }
  }

  // runs on first render
  useEffect(() => {
    getPocKitData();
    if(pocKit?.pocItems) {
      setPocKitItems(pocKit.pocItems);
    }
    const currentUser = authService.getCurrentUser();
    const localrbac = new rbacService(currentUser);
    setCU(currentUser);
    setRBAC(localrbac);
  }, []);

  if (pocKit === null || typeof pocKit === `undefined`) {
    return (
      <div className="text-center">
        <h3 className="text-danger">Kit Not Found</h3>
        <p className="text-danger">--------</p>
        <BackButton />
      </div>
    );
  } else {
    if (isDebug) {
      logData(pocKit);
    }

    return (
      <div className="container shadow rounded-3 pt-2 mt-2">
        <div className="d-flex flex-row mb-3">
          <div className="col-2">
            <BackButton />
            { rbac.isAdminOrLoanerAdmin() ? (
              <Link to={`/pocKitForm/${pocKit.id}`}>
                <button className="btn btn-primary btn-sm ms-2">Edit</button>
              </Link>
            ) : null}
          </div>
          <div className="col-8">
            <h4 className="display-6">Proof of Concept Kit Details </h4>
          </div>
        </div>
        <div className="d-flex flex-row">
          <div className="col-2">Name:</div>
          <div className="col-8">{pocKit.name}</div>
        </div>
        <div className="d-flex flex-row">
          <div className="col-2">Status:</div>
          <div className="col-8">{pocKit.status}</div>
        </div>
        <div className="d-flex flex-row mb-3">
          <div className="col-2">Description:</div>
          <div className="col-8">{pocKit.description}</div>
        </div>

        <PocKitItemsTable pocItems={pocKit.pocItems} caption={pocKit.name + "'s Items"} />

      </div>
    );
  }
}

/*

          {pocKit?.logoUrl ? (
            <img
              style={imgStyleObj}
              src={config.imgBaseURL + pocKit.logoUrl}
              alt=""
            />
          ) : null}
*/
