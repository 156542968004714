import React, { Component } from "react";
import { Link } from "react-router-dom";
import ResellersTable from "./resellersTable";
import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";
import Select from "react-select";
import _ from "lodash";
import { getResellers, removeReseller } from "../../services/resellerService";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import BackButton from "../common/backButtion";
import { isDebug } from "../common/helpers";

class Resellers extends Component {
  constructor() {
    super();
    this.handleDelete = this.handleDelete.bind(this);
    this.resellerRef = React.createRef();
    this.acctNumRef = React.createRef();
  }
  state = {
    resellers: [],
    selectedReseller: {},
    isLoading: false,
    currentPage: 1,
    pageSize: 5,
    sortColumn: { path: "name", order: "asc" },
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    const resellerList = await getResellers();
    if(isDebug) console.log(resellerList);
    this.setState({ resellers: resellerList });
    this.setState({ isLoading: false });
  }

  async handleDelete(reseller) {
    const delResponse = await removeReseller(reseller.id);
    if (delResponse.status !== 200) {
      alert("Reseller Removal Failed");
    } else {
      const filteredResellers = this.state.resellers.filter(
        (d) => d.id !== reseller.id
      );
      this.setState({ resellers: filteredResellers });
    }
  }

  handleLike = (reseller) => {
    const resellers = [...this.state.resellers];
    const index = resellers.indexOf(reseller);
    resellers[index] = { ...resellers[index] };
    resellers[index].liked = !resellers[index].liked;
    this.setState({ resellers });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleResellerSelect = (reseller) => {
    if(reseller !== undefined) {
      this.setState({ selectedReseller: reseller, currentPage: 1 });
    }
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const { pageSize, currentPage, sortColumn, selectedReseller, resellers } =
      this.state;

    const filtered =
      selectedReseller && selectedReseller.id
        ? resellers.filter((r) => r.id === selectedReseller.id)
        : resellers;

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const paged_resellers = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: paged_resellers };
  };

  changePageSize = (e) => {
    this.setState({ pageSize: e.value, currentPage: 1 });
  };

  render() {
    const { length: count } = this.state.resellers;
    const { pageSize, currentPage, sortColumn } = this.state;

    const pageSizeOptions = [
      { value: 5, label: 5 },
      { value: 10, label: 10 },
      { value: 15, label: 15 },
      { value: 20, label: 20 },
      { value: 25, label: 25 },
      { value: 50, label: 50 },
    ];

    if (this.state.isLoading)
      return (
        <div className="text-center p-3">
          {" "}
          <span className="spinner-border spinner-border-lg align-center"></span>{" "}
        </div>
      );

    if (count === 0) return <p>There are no resellers in the database.</p>;

    const { totalCount, data: resellers } = this.getPagedData();
    const myCaption = "Showing " + totalCount + " resellers";

    return (
      <div className="container pt-2">
        <div className="d-flex flex-row">
          <div className="col-2 ">
            <BackButton />
            <Link to="/resellerForm/new">
              <button className="btn btn-primary btn-sm ms-2">
                Add Reseller
              </button>
            </Link>
          </div>
          <div className="col-10">
            <div className="typeahead form-floating ">
              <div className="d-flex justify-content-start">
                <button
                  className="btn btn-sm btn-secondary me-1"
                  onClick={() => {
                    this.acctNumRef.current.clear();
                    this.setState({ selectedReseller: {} });
                  }}
                >
                  Clear
                </button>
                <Typeahead
                  id="resellerFilter"
                  ref={this.acctNumRef}
                  labelKey={(option) => `${option.acctNum}`}
                  placeholder="Filter by Account Number"
                  minLength={2}
                  size="sm"
                  options={this.state.resellers}
                  onChange={(selected) => {
                    this.handleResellerSelect(selected[0]);
                  }}
                />
                <button
                  className="btn btn-sm btn-secondary me-1 ms-5"
                  onClick={() => {
                    this.resellerRef.current.clear();
                    this.setState({ selectedReseller: {} });
                  }}
                >
                  Clear
                </button>
                <Typeahead
                  id="resellerFilter"
                  ref={this.resellerRef}
                  labelKey={(option) => `${option.name}`}
                  placeholder="Filter by Reseller Name"
                  minLength={2}
                  size="sm"
                  options={this.state.resellers}
                  onChange={(selected) => {
                    this.handleResellerSelect(selected[0]);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <h2 className="display-6 mt-3">Reseller List</h2>
        <ResellersTable
          resellers={resellers}
          sortColumn={sortColumn}
          onLike={this.handleLike}
          onDelete={this.handleDelete}
          onSort={this.handleSort}
          caption={myCaption}
        />
        <div className="d-inline-flex pe-2">
        <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={this.handlePageChange}
        />
        </div>
        <div className="d-inline-flex pe-2">
        <Select
            id="pgSize"
            options={pageSizeOptions}
            onChange={this.changePageSize.bind(this)}
            placeholder="Number of Items / Page"
          />
        </div>
      </div>
    );
  }
}

export default Resellers;
