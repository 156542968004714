import authHeader from "./auth-header";
import http from "./httpService";

export async function getEnum() {
  const { data: dsTypes } = await http.get(
    "/api/v1/deliveredServices/getEnum",
    {
      headers: authHeader(),
    }
  );
  return dsTypes;
}

export async function getDeliveredServices() {
  const { data: deliveredServices } = await http.get(
    "/api/v1/deliveredServices/",
    {
      headers: authHeader(),
    }
  );
  return deliveredServices;
}

export async function getDeliveredServiceInfo(dsId) {
  const { data: dsInfo } = await http.get("/api/v1/deliveredServices/" + dsId, {
    headers: authHeader(),
  });
  return dsInfo;
}

export async function createDeliveredService(deliveredService) {
  const response = await http.post(
    "/api/v1/deliveredServices/",
    deliveredService,
    {
      headers: authHeader(),
    }
  );
  return response;
}

export async function updateDeliveredService(dsId, deliveredService) {
  const response = await http.put(
    `/api/v1/deliveredServices/${dsId}`,
    deliveredService,
    {
      headers: authHeader(),
    }
  );
  return response;
}
/*
 */
