import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import VendorsDemoTable from "./vendorDemosTable";

import BackButton from "../common/backButtion";
import { isDebug, logData } from "../common/helpers";
import authHeader from "../../services/auth-header";
import config from "../../config.json";
import { getVendorInfo } from "../../services/vendorService";
import authService from "../../services/auth.service";
import rbacService from "../../services/rbacService";

export default function Vendor() {
  const [vendor, setVendor] = useState(null);
  const [cu, setCU] = useState(null);
  const [rbac, setRBAC] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  let { vendorId } = useParams();


  useEffect(() => {
    if(vendor === null) {
      setIsLoading(true);
      const getVendorData = async () => {
        const curVendor = await getVendorInfo(vendorId);
        if(isDebug) console.log(curVendor);
        setVendor(curVendor);
      }
      getVendorData();
      const cuser = authService.getCurrentUser();
      const rbac = new rbacService(cuser);
      setCU(cuser);
      setRBAC(rbac);
      setVendor(vendor);
      setIsLoading(false);
    }
  }, []);

  const imgStyleObj = {
    maxHeight: "200px",
    maxWidth: "400px",
    height: "auto",
    width: "auto",
  };

  if (isDebug) console.log(vendor);

  return (
    <React.Fragment key={9237}>
       {isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
       ) : vendor ? (
        <>
        <div className="container shadow border border0 border-light rounded-3 pt-2 mt-2">
          <div className="row justify-content-lg-center">
            <div className="col-2 mt-2">
              <div className="d-flex flex-column mb-2 align-items-center">
                <div className="p-2">
                  <BackButton />
                  { rbac.isAdminOrInstructor() ? (
                     <Link to={`/vendorForm/${vendor.id}`}>
                        <button className="btn btn-primary btn-sm ms-2">Edit</button>
                     </Link>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-10 text-align-center mt-2">
              <div className="d-flex flex-row justify-content-start align-items-center">
                <div className="col">
                  <p>
                    {vendor?.logoUrl ? (
                      <img
                        style={imgStyleObj}
                        src={config.imgBaseURL + vendor.logoUrl}
                        alt=""
                      />
                    ) : (
                      <p className="display-6">{vendor.name}</p>
                    )}
                  </p>
                </div>
              </div>
            </div>
              <p className="fs-6 fst-italic mb-4">{vendor.description}</p>
          </div>
          <div className="row">
           
          </div>
        <strong>Available Demos:</strong>
        <VendorsDemoTable demos={vendor.demos} />
        </div>
        </>
       ) : (
        <h3>Demo Not Found</h3>
       )}
    </React.Fragment>
   );
}

/*


 <BackButton />
        <h1>{vendor.name} </h1>
        <p>{vendor.description}</p>

          {vendor?.logoUrl ? (
            <img
              style={imgStyleObj}
              src={config.imgBaseURL + vendor.logoUrl}
              alt=""
            />
          ) : null}
*/
