import React from "react";
import { useParams, useNavigate, Link } from "react-router-dom";

export default function BackButton() {
  let navigate = useNavigate();
  return (
    <button className="btn btn-sm btn-primary" onClick={() => navigate(-1)}>
      Back
    </button>
  );
}
