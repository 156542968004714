import React from "react";
import { Link } from "react-router-dom";

function ColorCard({
  title,
  description,
  link,
  color = "white",
  bgcolor = "#266a7d",
}) {
  let divStyleObj = {
    color: color,
    backgroundColor: bgcolor,
  };
  return (
    <div className="col-sm servicesolv-card m-2" style={divStyleObj}>
      <center>
        <p className="pt-2">
          <button className="btn btn-light">
            <Link to={link} className="text-decoration-none">
              {title}
            </Link>
          </button>
        </p>
        <p>
          <small>
            {" "}
            <small> {description} </small>{" "}
          </small>
        </p>
      </center>
    </div>
  );
}

export default ColorCard;

/*
  <p className="lead">{title}</p>
*/
