import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import BackButton from "../common/backButtion";
import { isDebug, logData } from "../common/helpers";
import InstructorDemosTable from "./instructorDemosTable";
import { getInstructorbyID } from "../../services/instructorService";
import config from "../../config.json";
//import InstructorReservationsTable from "./instructorReservationsTable";
import httpService from "../../services/httpService";

export default function Instructor() {
  const [fetchedInstructor, setFetchedInstructor] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  let { instructorId } = useParams();

  if (isDebug) console.log("Parameter id = " + instructorId);

  useEffect(() => {
    setIsLoading(true);
    if (fetchedInstructor === null) {
      async function getInstructorData() {
        const instructorInfo = await getInstructorbyID(instructorId);
        setFetchedInstructor(instructorInfo);
      }
      getInstructorData().catch((error) => httpService.handleError(error));
    }
    console.log(fetchedInstructor);
    setIsLoading(false);
  }, []);

  return (
    <React.Fragment key="8765">
      {isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      ) : fetchedInstructor ? (
        <div className="container pt-2 mt-2">
          <div>
            <BackButton />
            <h1 className="display-4 text-center">{fetchedInstructor.user.fullName} </h1>
            <div className="d-flex row align-items-center">
              <div className="col-4 text-center">
                <p>
                  {fetchedInstructor?.imageUrl ? (
                    <img
                      src={config.imgBaseURL + fetchedInstructor.imageUrl}
                      width="200"
                      height="200"
                      alt={fetchedInstructor.imageUrl}
                    />
                  ) : (
                    <img
                    src={config.imgBaseURL + "/headshots/unknown.jpg"}
                    width="200px"
                    height="200px"
                    alt="Instructor  headshot"
                  />
                  )}
                </p>
              </div>

              <div className="col-8">
                <div className="d-flex flex-column">
                  <div className="p-2">
                      <Markdown 
                        remarkPlugins={[remarkGfm]} 
                        components={{
                          a(props) {
                           const { node, ...rest} = props
                          return <a target="_blank" rel="noopener noreferrer" {...rest} />
                          } 
                        }}
                      >
                        {fetchedInstructor.description}
                      </Markdown>  
                  </div>
                  <div className="p-2" >
                    <p className="lead"> 
                      Engage with {fetchedInstructor.user.fullName}: {"  "}
                    </p>
                    <p>
                      <a
                        href={`mailto:${fetchedInstructor.user.email}; presales@tdsynnex.com?subject=Information%20Request:%20Solution%20Center%20Portal`}
                      >
                        <FontAwesomeIcon icon={faEnvelope} size="2x" />
                      </a>
                      {fetchedInstructor.twitterURL ? (
                        <a className="ps-2" target="_blank" rel="noopener noreferrer" href={fetchedInstructor.twitterURL}>
                          <FontAwesomeIcon icon={faTwitter} size="2x"/>
                          </a>
                      ) : null}
                      {fetchedInstructor.linkedInURL ? (
                        <a className="ps-2" target="_blank" rel="noopener noreferrer" href={fetchedInstructor.linkedInURL}>
                          <FontAwesomeIcon icon={faLinkedin} size="2x"/>
                          </a>
                      ) : null}
                      {fetchedInstructor.githubURL ? (
                        <a className="ps-2" target="_blank" rel="noopener noreferrer" href={fetchedInstructor.githubURL}>
                          <FontAwesomeIcon icon={faGithub} size="2x"/>
                          </a>
                      ) : null}
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div>
            <InstructorDemosTable
              demos={fetchedInstructor.demos}
              caption={`${fetchedInstructor.user.firstName}'s Demos`}
            />
          </div>
        </div>
      ) : (
        <div>
          <h3>Instructor Not Found</h3>
        </div>
      )}
    </React.Fragment>
  );
}
/*
        <div>
          <InstructorReservationsTable
            reservations={instructor.demos.reservations}
            caption={customCaption}
          />
        </div>
*/
