import React, { useState, useEffect } from "react";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import authService from "../../services/auth.service";
import { getUserInfo } from "../../services/userService";
import { getInstructorbyUserID } from "../../services/instructorService";
import { getUserActivitybyID } from "../../services/userActivityService";
import httpService from "../../services/httpService";
import BackButton from "../common/backButtion";
import RenderBlock from "../common/renderBlock";

export default function UserActivityDetail() {
  const [redirect, setRedirect] = useState(null);
  const [userActivityInfo, setUserActivityInfo] = useState(null);

  let navigate = useNavigate();

  let { userActivityId } = useParams();

  const changePassword = () => {
    navigate("/cp");
  };

  useEffect(() => {
    const loggedInUser = authService.getCurrentUser();
    let fetchedUser = {};
    let fetchedInstructor = {};

    if (!loggedInUser) {
      setRedirect("/login");
    }

    const getFBDetail = async () => {
      const fbInfo = await getUserActivitybyID(userActivityId);
      console.log(fbInfo);
      setUserActivityInfo(fbInfo);
    };
    getFBDetail().catch((error) => httpService.handleError(error));
  }, []);

  return (
    <>
      {redirect ? <Navigate to={redirect} /> : null}
      {userActivityInfo ? (
        <div className="container shadow border border-1 border-light rounded-3 pt-2 mt-2">
          <BackButton />
          <div className="row justify-content-lg-center">
            <center>
              <h2 className="display-6">Activity Details</h2>
            </center>
            <div className="col-md">
              <p className="h6 text-center mt-1">
                <b>{userActivityInfo.user.fullName}</b> from{" "}
                <i>
                  {userActivityInfo.user.reseller.name} ({" "}
                  {userActivityInfo.user.reseller.acctNum} )
                </i>
              </p>
              <hr />
            </div>
          </div>

          <div className="d-flex row">
            <div className="col pt-2 pb-2 text-center align-self-center">
              {RenderBlock("Type", userActivityInfo.entityType)}
              <hr />
              {RenderBlock("Action", userActivityInfo.entityAction)}
            </div>
            <div className="col pt-2 pb-2 text-center align-self-center">
              {userActivityInfo.entityId
                ? RenderBlock("Target ID", userActivityInfo.entityId)
                : RenderBlock("Target ID", "N / A")}
              <hr />
              {userActivityInfo.entityName
                ? RenderBlock("Target Name", userActivityInfo.entityName)
                : RenderBlock("Target Name", "N / A")}
            </div>
            <div className="col pt-2 pb-2 text-center align-self-center">
              <p className="h6 mt-1">Request:</p>
              <hr />
              <center>
                {userActivityInfo.requestMethod} {userActivityInfo.url}{" "}
              </center>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

/*


                <p className="h6 mt-2">Favorite vendors: </p>
                {userInfo.vendors.map((vendor) => vendor.name)}

*/
