import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useNavigate } from "react-router-dom";
import * as yup from "yup";

import authService from "../../services/auth.service";
import http from "../../services/httpService";

import {
  getDeliveredServiceInfo,
  createDeliveredService,
  getEnum,
  updateDeliveredService,
} from "../../services/deliveredServicesService";
import Input from "../common/input";
import { isDebug, logData } from "../common/helpers";

export default function DeliveredServicesForm() {
  const [fetchedDS, setFetchedDS] = useState(null);
  const [dsTypes, setDSTypes] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [errMsg, setErrMsg] = useState();

  const { dsId } = useParams();
  const navigate = useNavigate();
  const currentUser = authService.getCurrentUser();

  if (isDebug) logData(currentUser);

  const isNewDeliveredService = dsId.toString() === "new" ? true : false;
  const submitButtonLabel = isNewDeliveredService ? "Create" : "Update";
  const pageTitle = isNewDeliveredService
    ? "Create New Service"
    : "Update Service";

  if (isDebug) logData(`isNewDeliveredService = ${isNewDeliveredService}`);
  /*
   */
  const schema = yup.object().shape({
    title: yup.string().required().min(6),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const handleUpdate = (data) => {
    data.userId = currentUser.id;

    if (isDebug) {
      logData("Handling Create / Update ....");
      logData("message = " + data.message);
    }

    if (isNewDeliveredService) {
      // Creating new feedback entry
      const doCreate = async () => {
        const createResponse = await createDeliveredService(data);
        if (isDebug) {
          logData(createResponse);
          logData(createResponse.status);
        }
        if (createResponse.status === 201) {
          // HTTP POST seem to return 201 on success
          setSuccessful(true);
        } else {
          setHasError(true);
          setErrMsg("Returned error code: " + createResponse.status);
        }
      };
      doCreate().catch((e) => http.handleError(e));
    } else {
      // updating an existing item
      data.id = dsId;
      const pushUpdate = async () => {
        const updateResponse = await updateDeliveredService(dsId, data);
        console.log(updateResponse);
        console.log(updateResponse.status);
        if (updateResponse.status === 201) {
          // HTTP PUT seem to return 201 on success
          setSuccessful(true);
        }
      };
      //pushUpdate().catch((e) => alert(e));
      pushUpdate().catch((e) => http.handleError(e));
    }
  };

  // Runs after first render (when component mounts, sorta)
  useEffect(() => {
    setIsLoading(true);

    const getDSTypes = async () => {
      const dSTypes = await getEnum();
      setDSTypes(dSTypes);
    };
    getDSTypes().catch(console.error);

    if (!isNewDeliveredService) {
      const getDSInfo = async () => {
        const ds = await getDeliveredServiceInfo(dsId);
        setFetchedDS(ds);
      };

      getDSInfo().catch(console.error);
      setIsLoading(false);
    }
    setIsLoading(false);
  }, []);

  /* this useEffect is called only when we have 
  fetched a user from the backend */
  useEffect(() => {
    if (isDebug) logData("Fetched Delivered Service: " + fetchedDS);

    if (fetchedDS !== null) {
      setValue("title", fetchedDS.title, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("description", fetchedDS.description, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("type", fetchedDS.type, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [fetchedDS]);

  /* This useEffect is only when successful submission */
  useEffect(() => {
    if (successful) {
      //navigate("/users");
      navigate(-1);
    }
  }, [successful]);

  return (
    <React.Fragment>
      {hasError ? (
        alert(errMsg)
      ) : isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      ) : (
        <div className="container shadow border border-2 border-light rounded-3 mt-4">
          <form onSubmit={handleSubmit(handleUpdate)} className="p-2">
            <div className="row justify-content-lg-center">
              <center>
                <p className="display-6">{pageTitle}</p>
              </center>
              <div className="col-6">
                <div className="form-group m-2">
                  <label className="text-muted ms-2" htmlFor="title">
                    Title
                  </label>
                  <input
                    style={{ zIndex: 3 }}
                    type="text"
                    className="form-control ms-2"
                    {...register("title", { required: "Title is required" })}
                  />
                  {errors.title && (
                    <div className="alert alert-danger">
                      {errors.title?.message}
                    </div>
                  )}

                  <br />

                  <label className="text-muted mx-2" htmlFor="type">
                    <small>{"Select Service type..."}</small>
                  </label>
                  <select
                    {...register("type", {
                      onChange: (e) => {
                        setValue("type", e.target.value);
                      },
                    })}
                    id="type"
                    className="form-select m-2"
                  >
                    {dsTypes.map((type, i) => (
                      <option key={i} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                  {errors["type"] && (
                    <div className="alert alert-danger">
                      {errors["type"].message}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="row justify-content-lg-center">
              <div className="col-md-8">
                <label htmlFor="description">
                  <span className="text-muted"> Description </span>{" "}
                </label>
                <textarea
                  {...register("description")}
                  className="form-control"
                  rows="5"
                />
              </div>
            </div>

            <div className="row justify-content-lg-center">
              <div className="col"></div>
              <div className="col">
                <center>
                  <div id="4" className="form-group m-2">
                    <input
                      className="btn btn-sm btn-primary me-2"
                      type="submit"
                      value={submitButtonLabel}
                    />
                    <input
                      className="btn btn-sm btn-danger"
                      type="button"
                      value="Cancel"
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                  </div>
                  {successful && (
                    <div className="alert alert-success">
                      Update Successful!
                    </div>
                  )}
                </center>
              </div>
              <div className="col"></div>
            </div>
          </form>
        </div>
      )}
    </React.Fragment>
  );
}
