import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Badge from "react-bootstrap/Badge";

import BackButton from "../common/backButtion";
import { isDebug, logData } from "../common/helpers";
import authService from "../../services/auth.service";
import { getCenterbyID, removeCenter } from "../../services/centerService";
import CentersDemoTable from "./centerDemosTable";
import config from "../../config.json";
import ResponsivePlayer from "./../common/responsivePlayer";
import { createUserActivity } from "../../services/userActivityService";
import rbacService from "../../services/rbacService";

export default function CenterDetail() {
  const [center, setCenter] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const currentUser = authService.getCurrentUser();
  const rbac = new rbacService(currentUser);
  const navigate = useNavigate();

  let { centerId } = useParams();

  function renderVideo(center) {
    if (center.externalUrl !== null) {
      return (
        <div>
          <ResponsivePlayer
            entityName={center.name}
            entityType="Solution Center"
            url={center.externalUrl}
            controls={true}
          />
          <hr className="sm-featurette-divider" />
        </div>
      );
    }
  }

  function renderVendors(center) {
    if (isDebug) {
      console.log("center.vendors");
      console.log(center.vendors);
    } 
    if (center.vendors?.length > 0) {
      return (
        <div className="row">
          <div className="col-md">
            <small className="text-muted mt-2">
              Vendors in this Solution Center:
            </small>
            <br />
            {center.vendors.map((vendor) => (
              <Badge pill className="m-2" bg="light">
                <Link to={`/vendor/${vendor.id}`}> {vendor.name} </Link>
              </Badge>
            ))}
            <hr className="sm-featurette-divider" />
          </div>
        </div>
      );
    }
  }

  function renderCategories(center) {
    if (isDebug) {
      console.log("center.categories");
      console.log(center.categories);
    } 
    if (center.categories?.length > 0) {
      return (
        <div>
          <small className="text-muted mt-2">
            Topics in this Solution Center:
          </small>
          <br />
          {center.categories.map((category) => (
            <Badge className="me-2" bg="secondary">
              {category.title}
            </Badge>
          ))}
          <hr className="sm-featurette-divider" />
        </div>
      );
    }
  }

  function renderDemos(center) {
    if (center.demos?.length > 0) {
      return <CentersDemoTable demos={center.demos} />;
    }
  }

  // runs once after initial render
  useEffect(() => {
    setIsLoading(true);
    if(center === null) {
      const getCenterData = async () => {
        const currentcenter = await getCenterbyID(centerId);
        setCenter(currentcenter);
        if (isDebug) {
          console.log("currentcenter");
          console.log(currentcenter);
        } 
        const newActivity = {};
        newActivity.userId = currentUser.id;
        newActivity.userName = currentUser.userName;
        newActivity.entityName = currentcenter.name;
        newActivity.entityType = "Solution Center";
        newActivity.entityAction = "View";
        const actResponse = await createUserActivity(newActivity);
        //if (isDebug) console.log(actResponse);
      };

      getCenterData().catch(console.error);
    }
    setIsLoading(false);
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      ) : center ? (
        <>
          <div className="container pt-2 mt-2">
            <div>
              <BackButton />
              {rbac.isAdmin() ? (
                <>
                <Link to={`/centerForm/${centerId}`}>
                  <button className="btn btn-sm btn-primary me-2 ms-2">
                    Edit
                  </button>
                </Link>
                <button className="btn btn-sm btn-danger me-2 "
                  onClick={async () => await removeCenter(center.id) 
                  && navigate(-1)
                  }
                >
                  Delete
                </button>
                </>
              ) : null}
            </div>
            <center>
              <h1 className="display-6">{center.name}</h1>
              <div>
                <p className="lead">
                  <small>
                    <small>{center.description}</small>
                  </small>
                </p>
                <br />
                <p className="lead">
                  <small>
                    Point of Contact <br />
                    <small>
                      <a href={`mailto:${center.contactEmail}`}>
                        {center.contactEmail}
                      </a>{" "}
                    </small>
                  </small>
                </p>
              </div>
              <hr className="sm-featurette-divider" />
              {renderCategories(center)}
              {renderVendors(center)}
              {renderVideo(center)}
              {renderDemos(center)}
            </center>
          </div>
        </>
      ) : (
        <div className="text-center p-3">
          <h3>Solution Center Not Found</h3>

        </div>
      )}
    </React.Fragment>
  );
}

/*
ServiceSolv Deep Dive
<script type="text/javascript" async src="https://play.vidyard.com/embed/v4.js"></script>
<img style="width: 100%; margin: auto; display: block;" class="vidyard-player-embed" src="https://play.vidyard.com/RgZVAnZ82uU2siRyp8ciHM.jpg" data-uuid="RgZVAnZ82uU2siRyp8ciHM" data-v="4" data-type="inline" />



          url={config.imgBaseURL + center.externalUrl}


            <table className="table table-light table-sm caption-top table-borderless">
              <caption>Vendors associated with this Solution Center</caption>
              <tbody>
                {center.vendors.map((vendor) => (
                  <tr>
                    <td>
                      <small className="text-muted" key={vendor.id}>
                        <Link to={`/vendor/${vendor.id}`}>{vendor.name}</Link>
                      </small>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
*/
