import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import authService from "../../services/auth.service";
import { signupUser } from "../../services/signupService";
import Input from "../common/input";
import { arrayFindAll } from "../../utils/utils";

// Categories (i.e. Topics)
const AllCategories = [
  "AI",
  "App Dev",
  "Automation",
  "Data Protection",
  "DevOps",
  "DevSecOps",
  "HCI and Converged Infrastructure",
  "Hybrid Cloud",
  "MSP",
  "Networking",
  "Network Security",
  "Physical Security",
  "Private Cloud",
  "Pro A/V",
  "Professional Services",
  "Power Management",
  "Public Cloud",
  "SaaS",
  "Software",
  "UI",
  "UCC",
  "Wireless",
  "Storage",
];

const firstCategories = [
  "AI",
  "App Dev",
  "Automation",
  "Data Protection",
  "DevOps",
];

const secondCategories = [
  "DevSecOps",
  "HCI and Converged Infrastructure",
  "Hybrid Cloud",
  "MSP",
];

const thirdCategories = [
  "Networking",
  "Network Security",
  "Physical Security",
  "Private Cloud",
  "Pro A/V",
];

const fourthCategories = [
  "Professional Services",
  "Power Management",
  "Public Cloud",
  "SaaS",
];

const fifthCategories = ["Software", "UI", "UCC", "Wireless", "Storage"];

// Vendors
const AllVendors = [
  "Arista",
  "Aruba",
  "Brocade",
  "Cisco",
  "Dell",
  "Eaton",
  "Extreme",
  "F5",
  "FireEye",
  "ForeScout",
  "Fortinet",
  "HPE",
  "HPI",
  "IBM",
  "Imperva",
  "Lenovo",
  "Microsoft",
  "NetApp",
  "NVIDIA",
  "Palo Alto Networks",
  "Poly",
  "Red Hat",
  "Ruckus",
  "Symantec",
  "Veeam",
  "VMware",
  "WatchGuard",
];

const firstVendors = ["Arista", "Aruba", "Brocade", "Cisco", "Dell"];

const secondVendors = ["Eaton", "Extreme", "F5", "FireEye", "ForeScout"];

const thirdVendors = ["Fortinet", "HPE", "HPI", "IBM", "Imperva"];

const fourthVendors = [ "Lenovo", "Microsoft", "NetApp", "NVIDIA"];

const fifthVendors = [
  "Palo Alto Networks",
  "Poly",
  "Red Hat",
  "Ruckus",
  "Symantec",
];

const sixthVendors = [
  "Veeam",
  "VMware",
  "WatchGuard",
];

const MAXSTEPS = 5;

export default function SignUpForm(props) {
  const [formStep, setFormStep] = useState(0);
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState();
  const navigate = useNavigate();

  const schema = yup.object().shape({
    email: yup.string().required().email(),
    firstName: yup.string().required(),
    lastName: yup.string().required().min(2),
    companyName: yup.string().required().min(2),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, isValid },
  } = useForm({ resolver: yupResolver(schema), mode: "onBlur" });

  const form = useRef(null);

  const nextFormStep = () => {
    setFormStep(formStep + 1);
  };

  const prevFormStep = () => {
    setFormStep(formStep - 1);
  };

  const renderButtons = () => {
    if (formStep >= MAXSTEPS) {
      return (
        <>
          <h2 className="display-6 mt-3 mb-3">Congratulations</h2>
          <p>
            Your information has been sent to our engineering and
            sales teams.
          </p>
          <p>
            Some one should be in contact with you within 1-2 business
            days.
          </p>
          <p>Thank you for giving us the opportunity to serve you.</p>
          <p className="lead text-danger">
            <b> You may now close this browser tab/window </b>{" "}
          </p>
        </>
      );
    } else {
      return (
        <>
          {formStep > 0 && (
            <button
              type="button"
              className="btn btn-sm btn-primary mt-2 me-2"
              onClick={prevFormStep}
            >
              {"< Previous Step"}
            </button>
          )}
          {formStep < MAXSTEPS - 1 && (
            <button
              type="button"
              className="btn btn-sm btn-primary mt-2"
              onClick={nextFormStep}
            >
              {"Next Step >"}
            </button>
          )}
          {formStep === MAXSTEPS - 1 && (
            <button
              disabled={!isValid}
              className="btn btn-lg btn-primary mt-2"
              type="submit"
            >
              Request Access
            </button>
          )}
        </>
      );
    }
  };

  const handleRegister = (data) => {
    //e.preventDefault();

    setMessage("");
    setSuccessful("");

    const hookCategories = getValues("categories");
    const newCategoriesIndex = arrayFindAll(hookCategories, true);
    const newCategories = [];
    newCategoriesIndex.forEach((element) =>
      newCategories.push(AllCategories[element])
    );

    data.topics = newCategories.join(" - ");
    data.categories = "";

    const hookVendors = getValues("vendorChecks");
    const newVendorsIndexes = arrayFindAll(hookVendors, true);
    const newVendors = [];
    newVendorsIndexes.forEach((element) =>
      newVendors.push(AllVendors[element])
    );

    data.vendors = newVendors.join(" - ");
    data.vendorChecks = "";

    const prettyOutput = JSON.stringify(data);
    console.log(prettyOutput);

    const signEmUp = async () => {
      const signupResponse = await signupUser(data);
      if (signupResponse.status === 201) {
        setSuccessful(true);
        setMessage(signupResponse.data.message);
      }
    };
    signEmUp().catch(console.error);

    nextFormStep();
  };

  return (
    <center>
      <div className="container">
        <div className="form text-center">
          <form onSubmit={handleSubmit(handleRegister)}>
            {!successful && (
              <div>
                {formStep === 0 && (
                  <section>
                    <h2 className="display-6 mt-3 mb-3">
                      Personal Information
                    </h2>
                    <div className="card">
                      <small>
                        {" "}
                        <small>
                          {" "}
                          <small>Step 1 of 5</small>{" "}
                        </small>{" "}
                      </small>
                      <div className="form-floating">
                        <Input
                          register={register}
                          fieldName="firstName"
                          label="Enter Your First Name"
                          errors={errors}
                        />
                        <Input
                          register={register}
                          fieldName="lastName"
                          label="Enter Your Last Name"
                          errors={errors}
                        />
                        <Input
                          register={register}
                          fieldName="email"
                          label="Enter Your Email"
                          type="email"
                          errors={errors}
                        />
                        <Input
                          register={register}
                          fieldName="workPhone"
                          label="Enter Your Work Phone"
                          errors={errors}
                        />
                      </div>
                    </div>
                  </section>
                )}

                {formStep === 1 && (
                  <section>
                    <h2 className="display-6 mt-3 mb-3">
                      Reseller Information
                    </h2>
                    <div className="card">
                      <small>
                        {" "}
                        <small>
                          {" "}
                          <small>Step 2 of 5</small>{" "}
                        </small>{" "}
                      </small>
                      <Input
                        register={register}
                        fieldName="companyName"
                        label="Your Company's Name"
                        errors={errors}
                      />
                      <Input
                        register={register}
                        fieldName="acctNumber"
                        label="(OPTIONAL) TD SYNNEX Account Number"
                        errors={errors}
                      />
                      <Input
                        register={register}
                        fieldName="salesPerson"
                        label="(OPTIONAL) TD SYNNEX Sales Person's Name"
                        errors={errors}
                      />
                      <Input
                        register={register}
                        fieldName="salesPersonEmail"
                        label="(OPTIONAL) TD SYNNEX Sales Person's Email"
                        errors={errors}
                      />
                    </div>
                  </section>
                )}

                {formStep === 2 && (
                  <section>
                    <h2 className="display-6 mt-3 mb-3">
                      In What <strong>Topics</strong> Are You Interested?
                    </h2>
                    <small>
                      <small>
                        <small>Step 3 of 5</small>{" "}
                      </small>
                    </small>
                    <div className="row">
                      <div className="col text-start me-1 mb-1">
                        <div className="card">
                          {firstCategories &&
                            firstCategories.map((category, i) => (
                              <>
                                <div className="form-check ">
                                  <label className="form-check-label p-2">
                                    <input
                                      className="form-check-input"
                                      {...register(`categories[${i}]`)}
                                      type="checkbox"
                                      key={i}
                                    />
                                    {category}
                                  </label>
                                </div>
                              </>
                            ))}
                        </div>
                      </div>
                      <div className="col text-start me-1 mb-1">
                        <div className="card">
                          {secondCategories &&
                            secondCategories.map((category, i) => (
                              <>
                                <div className="form-check ">
                                  <label className="form-check-label p-2">
                                    <input
                                      className="form-check-input"
                                      {...register(`categories[${i + 5}]`)}
                                      type="checkbox"
                                      key={i + 5}
                                    />
                                    {category}
                                  </label>
                                </div>
                              </>
                            ))}
                        </div>
                      </div>
                      <div className="col text-start me-1 mb-1">
                        <div className="card">
                          {thirdCategories &&
                            thirdCategories.map((category, i) => (
                              <>
                                <div className="form-check ">
                                  <label className="form-check-label p-2">
                                    <input
                                      className="form-check-input"
                                      {...register(`categories[${i + 9}]`)}
                                      type="checkbox"
                                      key={i + 9}
                                    />
                                    {category}
                                  </label>
                                </div>
                              </>
                            ))}
                        </div>
                      </div>
                      <div className="col text-start me-1 mb-1">
                        <div className="card">
                          {fourthCategories &&
                            fourthCategories.map((category, i) => (
                              <>
                                <div className="form-check ">
                                  <label className="form-check-label p-2">
                                    <input
                                      className="form-check-input"
                                      {...register(`categories[${i + 14}]`)}
                                      type="checkbox"
                                      key={i + 14}
                                    />
                                    {category}
                                  </label>
                                </div>
                              </>
                            ))}
                        </div>
                      </div>
                      <div className="col text-start me-1 mb-1">
                        <div className="card">
                          {fifthCategories &&
                            fifthCategories.map((category, i) => (
                              <>
                                <div className="form-check ">
                                  <label className="form-check-label p-2">
                                    <input
                                      className="form-check-input"
                                      {...register(`categories[${i + 18}]`)}
                                      type="checkbox"
                                      key={i + 18}
                                    />
                                    {category}
                                  </label>
                                </div>
                              </>
                            ))}
                        </div>
                      </div>
                    </div>
                  </section>
                )}

                {formStep === 3 && (
                  <section>
                    <h2 className="display-6 mt-3 mb-3">
                      In What <strong>Vendors</strong> Are You Interested?
                    </h2>
                    <small>
                      {" "}
                      <small>
                        {" "}
                        <small>Step 4 of 5</small>{" "}
                      </small>{" "}
                    </small>
                    <div className="row">
                      <div className="col text-start me-1 mb-1">
                        <div className="card">
                          {firstVendors &&
                            firstVendors.map((vendor, i) => (
                              <>
                                <div className="form-check ">
                                  <label className="form-check-label p-2">
                                    <input
                                      className="form-check-input"
                                      {...register(`vendorChecks[${i}]`)}
                                      type="checkbox"
                                      key={i}
                                    />
                                    {vendor}
                                  </label>
                                </div>
                              </>
                            ))}
                        </div>
                      </div>

                      <div className="col text-start me-1 mb-1">
                        <div className="card">
                          {secondVendors &&
                            secondVendors.map((vendor, i) => (
                              <>
                                <div className="form-check ">
                                  <label className="form-check-label p-2">
                                    <input
                                      className="form-check-input"
                                      {...register(`vendorChecks[${i + 5}]`)}
                                      type="checkbox"
                                      key={i + 5}
                                    />
                                    {vendor}
                                  </label>
                                </div>
                              </>
                            ))}
                        </div>
                      </div>

                      <div className="col text-start me-1 mb-1">
                        <div className="card">
                          {thirdVendors &&
                            thirdVendors.map((vendor, i) => (
                              <>
                                <div className="form-check ">
                                  <label className="form-check-label p-2">
                                    <input
                                      className="form-check-input"
                                      {...register(`vendorChecks[${i + 10}]`)}
                                      type="checkbox"
                                      key={i + 10}
                                    />
                                    {vendor}
                                  </label>
                                </div>
                              </>
                            ))}
                        </div>
                      </div>

                      <div className="col text-start me-1 mb-1">
                        <div className="card">
                          {fourthVendors &&
                            fourthVendors.map((vendor, i) => (
                              <>
                                <div className="form-check ">
                                  <label className="form-check-label p-2">
                                    <input
                                      className="form-check-input"
                                      {...register(`vendorChecks[${i + 15}]`)}
                                      type="checkbox"
                                      key={i + 15}
                                    />
                                    {vendor}
                                  </label>
                                </div>
                              </>
                            ))}
                        </div>
                      </div>

                      <div className="col text-start me-1 mb-1">
                        <div className="card">
                          {fifthVendors &&
                            fifthVendors.map((vendor, i) => (
                              <>
                                <div className="form-check ">
                                  <label className="form-check-label p-2">
                                    <input
                                      className="form-check-input"
                                      {...register(`vendorChecks[${i + 20}]`)}
                                      type="checkbox"
                                      key={i + 20}
                                    />
                                    {vendor}
                                  </label>
                                </div>
                              </>
                            ))}
                        </div>
                      </div>

                      <div className="col text-start me-1 mb-1">
                        <div className="card">
                          {sixthVendors &&
                            sixthVendors.map((vendor, i) => (
                              <>
                                <div className="form-check ">
                                  <label className="form-check-label p-2">
                                    <input
                                      className="form-check-input"
                                      {...register(`vendorChecks[${i + 25}]`)}
                                      type="checkbox"
                                      key={i + 25}
                                    />
                                    {vendor}
                                  </label>
                                </div>
                              </>
                            ))}
                        </div>
                      </div>

                    </div>
                  </section>
                )}

                {formStep === 4 && (
                  <section>
                    <h2 className="display-6 mt-3 mb-3">Comments</h2>
                    <div className="card">
                      <small>
                        <small>
                          <small>Step 5 of 5</small>
                        </small>
                      </small>
                      <div className="form-group m-2">
                        <div className="form">
                          <label className="text-muted" htmlFor="description">
                            <small>
                              {"Provide any extra info you would like..."}
                            </small>
                          </label>
                          <textarea
                            {...register("comments")}
                            className="form-control"
                          />
                          {errors["description"] && (
                            <div className="alert alert-danger">
                              {errors["description"].message}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                )}

                {formStep === MAXSTEPS && (
                  <>
                    <h2 className="display-6 mt-3 mb-3">Congratulations</h2>
                    <p>
                      Your information has been sent to our engineering and
                      sales teams.
                    </p>
                    <p>
                      Some one should be in contact with you within 1-2 business
                      days.
                    </p>
                    <p>Thank you for giving us the opportunity to serve you.</p>
                    <p className="lead text-danger">
                      <b> You may now close this browser tab/window </b>{" "}
                    </p>
                  </>
                )}
              </div>
            )}

            <div className="form-group">{renderButtons()}</div>

            {message && (
              <div className="form-group">
                <div
                  className={
                    successful ? "alert alert-success" : "alert alert-danger"
                  }
                  role="alert"
                >
                  {message}
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </center>
  );
}
