import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../common/table";
import Like from "../common/like";
import { isDebug, logData } from "../common/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faPen, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";

class ResellersTable extends Component {
  columns = [
    {
      path: "name",
      label: "Name",
      content: (reseller) => (
        <Link to={`/resellerDetail/${reseller.id}`}>{reseller.name}</Link>
      ),
    },
    {
      path: "acctNum",
      label: "Account Number",
    },
    {
      path: "workPhone",
      label: "Main Phone Number",
    },
    {
      path: "dba",
      label: "Doing Business As",
      // content: (demo) => {
      //   demo.description;
      // },
    },
    {
      key: "edit",
      content: (reseller) => (
        <Link to={`/reseller/${reseller.id}`}>
          <FontAwesomeIcon icon={faPenToSquare} />
        </Link>
      ),
    },
    {
      key: "like",
      content: (reseller) => (
        <Like
          liked={reseller.liked}
          onClick={() => this.props.onLike(reseller)}
        />
      ),
    },
    {
      key: "delete",
      content: (reseller) => (
        <button
          onClick={() => this.props.onDelete(reseller)}
          className="btn btn-danger btn-sm"
        >
          Delete
        </button>
      ),
    },
  ];

  render() {
    const { resellers, onSort, sortColumn, caption } = this.props;

    return (
      <>
        <Table
          columns={this.columns}
          data={resellers}
          sortColumn={sortColumn}
          onSort={onSort}
          caption={caption}
        />
      </>
    );
  }
}

export default ResellersTable;
