import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
} from "@fortawesome/free-regular-svg-icons";

import DemoReservationTable from "./demoReservationsTable";
import BackButton from "../common/backButtion";
import { isDebug } from "../common/helpers";
import authService from "../../services/auth.service";
import { getDemoInfo } from "../../services/demoService";
import { createUserActivity } from "../../services/userActivityService";
import { incrementView } from "../../services/demoService";
import config from "../../config.json";
import DemoPlayer from "./demoPlayer";
import rbacService from "../../services/rbacService";
//import _ from "lodash";
import DemoUserActivitiesTable from "./demoUserActivitiesTable";
import RatingWrapper from "../common/ratingWrapper";
import { createJiraDemoTicket } from "../../services/jiraansibleService";
import { getUserInfo } from "../../services/userService";


export default function DemoDetail() {
  const [demo, setDemo] = useState(null);
  const [rbac, setRBAC] = useState(null);
  const [cu, setCU] = useState(null);
  //const [userActivities, setUserActivities] = useState(null);
  const [showWatchReport, setShowWatchReport] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showVideo, setShowVideo] = useState(false);
  const [showRemoteVideo, setShowRemoteVideo] = useState(false);
  const [videoButtonLabel, setVideoButtonLabel] = useState("Show Video");
  const [remoteVideoButtonLabel, setRemoteVideoButtonLabel] =
    useState("Show Remote Video");

  const currentUser = authService.getCurrentUser();

  let { demoId } = useParams();

  const updateViewCount = async () => {
      const incviewResponse = await incrementView(demoId);
      if(isDebug) console.log(incviewResponse);
  }

  const logActivity = async (action) => {
      const newActivity = {};
      //console.log(currentUser);
      newActivity.userId = currentUser.id;
      newActivity.userName = currentUser.userName;
      newActivity.entityType = "Demo";
      newActivity.entityAction = action;
      newActivity.entityName = demo.name;
      newActivity.entityId = demo.id;
      const actResponse = await createUserActivity(newActivity);
      if(isDebug) console.log(actResponse);
      updateViewCount();
      //createTicket();
  }
  
  const createTicket = async () => {
    const ticketData = {};
    ticketData.extra_vars = {
      "jiraassignee": demo?.instructors[0]?.user?.email,
      "assignee": demo?.instructors[0]?.user?.email,
      "SoftwareVendor": demo?.vendors[0]?.name,
      "summary": `Portal user from ${cu.reseller?.name} consumed demo from ${demo?.vendors[0]?.name}`,
      "jiradescription": `${cu.fullName} ${cu.email} from ${cu.reseller?.name} - ${cu.reseller?.acctNum} consumed ${demo?.name}`
    };
    const ticketResponse = await createJiraDemoTicket(ticketData);
    console.log(ticketResponse);
  }
 /*
 */

  function showHideVideo() {
    showVideo
      ? setVideoButtonLabel("Show Video")
      : setVideoButtonLabel("Hide Video");
    setShowVideo(!showVideo);
  }

  function showHideRemoteVideo() {
    showRemoteVideo
      ? setRemoteVideoButtonLabel("Show Remote Video")
      : setRemoteVideoButtonLabel("Hide Remote Video");
    setShowRemoteVideo(!showRemoteVideo);
  }

  //async function renderVideo(demo) {
  let run = 0;
  function renderVideo(demo) {
    if (demo.externalUrl !== null) {
      //console.log("Demo External URL: " + demo.externalUrl);
      console.log("Full URL: " + config.imgBaseURL + demo.externalUrl);
      if(run === 0) {
        updateViewCount(); 
        createTicket();
        run++;
        console.log(run);
        console.log("Called create ticket for local video");
      }
      return <DemoPlayer demo={demo} url={config.imgBaseURL + demo.externalUrl} />
    }
  }

  function renderRemoteVideo(demo) {
    if (demo.remoteVideoURL !== null) {
      console.log("Demo Remote Video URL: " + demo.remoteVideoURL);
      if(run === 0) {
        updateViewCount();
        createTicket();
        run++;
        console.log(run);
        console.log("Called create ticket for remote video");
      }
       
      return <DemoPlayer demo={demo} url={demo.remoteVideoURL} />;
    }
  }

  function renderReservations() {
    const tableCaption = "Current Reservations...";
    let filtered = [];
    if (demo.reservations.length > 0) {
      if (currentUser.roleId === 3) {
        filtered = demo.reservations.filter(
          (r) => r.resellerId === currentUser.resellerId
        );
      } else {
        filtered = demo.reservations;
      }
      console.log(filtered);
      return (
        <div>
          <DemoReservationTable
            reservations={filtered}
            caption={tableCaption}
          />
        </div>
      );
    }
  }

  function canSchedule(demo) {
    if (
      demo.type === "Live Instructor Led" ||
      demo.type === "Proof of Concept" ||
      demo.type === "Assessment" ||
      demo.type === "Ask Me Anything" ||
      demo.type === "AMA - Ask Me Anything"
    ) {
      return true;
    }
    return false;
  }

  function renderIBMDTCLink(demo){
    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="p-2">
          {demo?.ibmDTCurl ? (
            <center>
            <Link
              to={`/dtcRequestForm/new`}
              state={{ demoId: demoId, demoName: demo.name }}
            >
               <button className="btn btn-sm btn-primary">
                 Access DTC Demo
               </button>
            </Link>
          </center>
          ) : null  }
        </div>
      </div>
    );
  }

  function renderScheduleLink(demo) {
    if (canSchedule(demo)) {
      return (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="p-2">
            {demo?.bookingsUrl ? (
              <center>
                <a target="_blank" rel="noreferrer" href={demo.bookingsUrl}>
                  <button className="btn btn-sm btn-primary" onClick={logActivity("SCHEDULE")}>Schedule Demo</button>
                </a>
              </center>
            ) : (
              null
            )}
          </div>
        </div>
      );
    }
  }

  function renderInstructors(demo) {
    const namewithSpaces = demo.name.replace(/ /gi, "%20");
    /*
    if (isDebug) {
      console.log(demo.instructors);
      console.log(demo.name);
     // console.log(namewithSpaces);
    } 
    */
    if (demo.instructors.length > 0) {
      return (
        <div className="border border-light rounded p-2 mb-3 shadow">
          <center>
            <small className="text-muted me-2">Instructors</small>
            <hr />
            <table className="table  table-striped table-hover table-light table-sm caption-top table-borderless">
              <tbody>
                {demo.instructors.map((instructor) => (
                  <tr key={instructor.id}>
                    <td className="text-center align-middle">
                      <small className="text-muted" key={instructor.id}>
                        <Link to={`/instructorProfile/${instructor.id}`}>
                          {instructor.user.fullName}
                        </Link>
                      </small>
                    </td>
                    <td className="text-center">
                      <small>
                        {" "}
                        <a
                          href={`mailto:${instructor.user.email};presales@tdsynnex.com?subject=Demo%20Request:%20${namewithSpaces}`}
                        >
                          <FontAwesomeIcon icon={faEnvelope} size="2x" />
                          <br />
                        </a>
                      </small>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </center>
        </div>
      );
    }
  }

  function renderMtoN(arr, heading = "Heading", fieldname = "name") {
    /*
    if (isDebug) {
      console.log("renderMtoN: array = ");
      console.log(arr);
      console.log("fieldname: " + fieldname);
    }
    */
    if (arr.length > 0) {
      return (
        <div className="border border-light rounded p-2 align-middle shadow">
          <center>
            <small className="text-muted me-2">{heading}</small>
            <hr />
            {arr.map((item) => (
              <Badge key={item.id} className="me-2" bg="secondary">
                {item[fieldname]}
              </Badge>
            ))}
          </center>
        </div>
      );
    }
  }

  // runs once after initial render
  useEffect(() => {
    if(demo === null) {
      setIsLoading(true);
      const currentUser = authService.getCurrentUser();
      const localrbac = new rbacService(currentUser);
      setRBAC(localrbac);
      //setCU(currentUser);
      
      const getUserData = async() => {
        const userInfo = await getUserInfo(currentUser.id);
        setCU(userInfo);
        console.log(userInfo);
      };
      getUserData().catch(console.error);

      const getDemoData = async () => {
        const currentDemo = await getDemoInfo(demoId);
        setDemo(currentDemo);
        if (isDebug) console.log(currentDemo);
      };
      getDemoData().catch(console.error);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if(demo !== null){
      if(demo.type === "Self Service") {
        if(rbac.hasElevatedPrivs()) {
          setShowWatchReport(true);
        }
      }
    }
  }, [demo]);

  return (
    <React.Fragment key={3456}>
      {isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      ) : demo ? (
        <>
          <div className="container shadow border border0 border-light rounded-3 pt-2 mt-2 pb-2 mb-2">
            <div className="row justify-content-lg-center">
              <div className="col-2 mt-2">
                <div className="d-flex flex-column mb-2 align-items-center">
                  <div className="p-2">
                    <BackButton />
                    { rbac.isAdminOrInstructor() ? (
                       <Link to={`/demoForm/${demo.id}`}>
                          <button className="btn btn-primary btn-sm ms-2">Edit</button>
                       </Link>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-10 text-align-center">
                <div className="d-flex flex-row justify-content-start">
                  <p className="display-6">{demo.name}</p>
                  <div className="col"></div>
                  <div className="col"></div>
                </div>
                <div className="d-flex flex-row justify-content-start mt-2">
                  <div className="col-2 fs-6 fst-italic">Description</div>
                  <div className="col-10 fs-6 ">{demo.description}</div>
                </div>
                <div className="d-flex flex-row justify-content-start align-items-center mt-2">
                  <div className="col-2 fs-6 fst-italic">Type</div>
                  <div className="col-10 fs-6 ">{demo.type}</div>
                </div>
                <div className="d-flex flex-row justify-content-start align-items-center mt-2">
                  <div className="col-2 fs-6 fst-italic">Views</div>
                  <div className="col-10 fs-6 ">{demo.views}</div>
                </div>
                <div className="d-flex flex-row justify-content-start align-items-center mt-2">
                  <div className="col-2 fs-6 fst-italic">Average User Rating</div>
                  <div className="col-10 fs-6 ">
                      <RatingWrapper
                         id={demo.id}
                         type="Demo"
                         edit={false}
                         val={demo.avgRating ? demo.avgRating : 0 }
                      />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-lg-center mt-3">
              <div className="col p-2">{renderInstructors(demo)}</div>
              <div className="col p-2">{renderMtoN(demo.vendors, "Vendors")}</div>
              <div className="col p-2">
                {renderMtoN(demo.categories, "Topics", "title")}
              </div>
              <div className="col p-2">
                {renderMtoN(demo.centers, "Solution Centers")}
              </div>
            </div>

            {demo.ibmDTCurl ? (
              <div className="row justify-content-center">
                <div className="col-12 m-2">
                  <center>
                    {renderIBMDTCLink(demo)}
                  </center>
                </div>
              </div>
            ) : null}

            {demo.externalUrl ? (
              <div className="row justify-content-center">
                <div className="col-12 m-2">
                  <center>
                    {demo.externalUrl ? (
                      <button
                        className="btn btn-primary mb-2"
                        onClick={showHideVideo}
                      >
                        {videoButtonLabel}
                      </button>
                    ) : null}
                    {showVideo && renderVideo(demo)}
                  </center>
                </div>
              </div>
            ) : null}

            {demo.remoteVideoURL ? (
              <div className="row justify-content-center">
                <div className="col-12 m-2">
                  <center>
                    {demo.remoteVideoURL ? (
                      <button
                        className="btn btn-primary mb-2"
                        onClick={showHideRemoteVideo}
                      >
                        {remoteVideoButtonLabel}
                      </button>
                    ) : null}
                    {showRemoteVideo && renderRemoteVideo(demo)}
                  </center>
                </div>
              </div>
            ) : null}

            <div className="p-2">
              <div className="col mb-3">{renderScheduleLink(demo)}</div>
            </div>

            {renderReservations()}
            {showWatchReport ? (
              <DemoUserActivitiesTable demoId={demoId} />
            ) : null }
          </div>
        </>
      ) : (
        <h3>Demo Not Found</h3>
      )}
    </React.Fragment>
  );
}

/*

  function renderIBMDTCLink(demo){
    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="p-2">
          {demo?.ibmDTCurl ? (
            <>
            <center>
              <a target="_blank" rel="noreferrer" href={demo.ibmDTCurl}>
                <button className="btn btn-sm btn-primary" onClick={logActivity("DTC Access")}>Access DTC</button>
              </a>
            </center>
            <center>
            <Link
              to={`/reservation/new`}
              state={{ demoId: demoId, demoName: demo.name }}
            >
              <FontAwesomeIcon icon={faCalendarPlus} size="2x" />
              <div className="p-0">
                <small> Schedule Demo...</small>
              </div>
            </Link>
          </center>
          </>
          ) : null  }
        </div>
      </div>
    );
  }


function renderScheduleLink(demo) {
    if (canSchedule(demo)) {
      return (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="p-2">
            {demo?.bookingsUrl ? (
              <center>
                <a target="_blank" rel="noreferrer" href={demo.bookingsUrl}>
                  <button className="btn btn-sm btn-primary" onClick={logActivity("SCHEDULE")}>Schedule Demo</button>
                </a>
              </center>
            ) : (
              <center>
                <Link
                  to={`/reservation/new`}
                  state={{ demoId: demoId, demoName: demo.name }}
                >
                  <FontAwesomeIcon icon={faCalendarPlus} size="2x" />
                  <div className="p-0">
                    <small> Schedule Demo...</small>
                  </div>
                </Link>
              </center>
            )}
          </div>
        </div>
      );
    }
  }
*/


/*
ServiceSolv Deep Dive
<script type="text/javascript" async src="https://play.vidyard.com/embed/v4.js"></script>
<img style="width: 100%; margin: auto; display: block;" class="vidyard-player-embed" src="https://play.vidyard.com/RgZVAnZ82uU2siRyp8ciHM.jpg" data-uuid="RgZVAnZ82uU2siRyp8ciHM" data-v="4" data-type="inline" />

*/
/*

              <center>
                <a target="_blank" href={demo.bookingsUrl}>
                  <FontAwesomeIcon icon={faCalendarPlus} size="2x" />
                  <div className="p-0">
                    <small> Schedule Demo...</small>
                  </div>
                </a>
              </center>
*/
/*
  <div className="col">{renderCategories(demo)}</div>
  <div className="col">{renderInstructors(demo)}</div>

  function renderCategories(demo) {
    if (isDebug) logData(demo.categories);
    if (demo.categories?.length > 0) {
      return (
        <div>
          <small className="text-muted me-2">Topics:</small>
          {demo.categories.map((category) => (
            <Badge className="me-2" bg="secondary">
              {category.title}
            </Badge>
          ))}
        </div>
      );
    }
  }
*/

      /*
        const defaultStartDate = new Date();
        // 60 here is 60 days
        defaultStartDate.setDate(defaultStartDate.getDate() - 60);
        let textdate = defaultStartDate.toISOString();

        const getUserActivityList = async () => {
          //const localUserActivities = await getUserActivities();
          const localUserActivities = await getUserActivitiesSinceDate(textdate);
          let intDemoID = parseInt(demoId);
          const localFilteredActivities = _.filter(localUserActivities, { 'entityId': intDemoID, 'entityAction': "WATCH" });
          console.log(localFilteredActivities);
          setUserActivities(localFilteredActivities);
        };
        if(rbac.hasElevatedPrivs()) {
          getUserActivityList().catch(console.error);
        }
        */
