import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../common/table";
import authService from "../../services/auth.service";
import rbacService from "../../services/rbacService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";

class PocItemsTable extends Component {
  state = {
    currentUser: {},
    rbac: null,
    showAddButton: false,
  };


  componentDidMount() {
    const loggedInUser = authService.getCurrentUser();
    const localRBAC = new rbacService(loggedInUser);

    this.setState({ currentUser: loggedInUser, rbac: localRBAC });
    //if (loggedInUser.roleId <= 2) {
    if (localRBAC.isAdminOrLoanerAdmin()) {
      // logged in user is either admin or instructor
      this.columns.push(...this.adminColumns);
      this.setState({ showAddButton: true });
    }
  }

  columns = [
    {
      path: "name",
      label: "Name",
      content: (pocItem) => (
        <Link to={`/pocItem/${pocItem.id}`}>{pocItem.name}</Link>
      ),
    },
    {
      path: "pocKit.name",
      label: "Kit",
      content: (pocItem) => (
        <Link to={`/pocKit/${pocItem.pocKit?.id}`}>{pocItem.pocKit === null ? "Unkitted" : pocItem.pocKit.name  }</Link>
      ),
    },
    { path: "type", label: "Type" },
    { path: "status", label: "Status" },
    { path: "serialNum", label: "Serial #" },
    { path: "mfgPartNum", label: "Mfg Part #" },
    { path: "sapMatId", label: "SAP Mat ID" },
  ];
    /*
    {
      path: "user.fullName",
      label: "User",
      content: (pocItem) => (
        <a
          href={`mailto:${pocItem.user.email}?cc=scportaladmin@tdsynnex.com&subject=pocItem%20Response`}
        >
          {pocItem.user.fullName}
        </a>
      ),
    },
    {
      path: "user.reseller.name",
      label: "Account",
    },
    */
  adminColumns = [
    {
      key: "edit",
      content: (pocItem) => (
        <Link to={`/pocItemForm/${pocItem.id}`}>
          <FontAwesomeIcon icon={faPenToSquare} />
        </Link>
      ),
    },
    {
      key: "delete",
      content: (pocItem) => (
        <button
          onClick={() => this.props.onDelete(pocItem)}
          className="btn btn-danger btn-sm"
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </button>
      ),
    },
  ];

  render() {
    const { pocItems, onSort, onDelete, sortColumn, caption } = this.props;

    return (
      <Table
        columns={this.columns}
        data={pocItems}
        sortColumn={sortColumn}
        onSort={onSort}
        onDelete={onDelete}
        caption={caption}
      />
    );
  }
}

export default PocItemsTable;
