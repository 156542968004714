import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faPen, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import {
  faChessKing,
  faMoneyBill1,
  faChartBar,
} from "@fortawesome/free-regular-svg-icons";
import { faChartColumn } from "@fortawesome/free-solid-svg-icons";

export default function Home() {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
      window.addEventListener("resize", handleWindowResize);
      return () => {
        window.removeEventListener("resize", handleWindowResize);
      };
    };
  });
  //const slideColor = "#282c34";
  const slideColor = "#1D3152";
  return (
    <React.Fragment>
      <div>
        <div
          className="row"
          style={{
            maxWidth: `${windowSize[0]}`,
            backgroundImage: "url(/servicesolv-masthead2.jpg)",
            height: "400px",
          }}
        >
          <div className="ms-3 mb-3 pb-3">
            <img
              className="img-fluid ms-3 p-1"
              src="/logoServiceSolv2-282x65.png"
              alt="TD SYNNEX Solution Center Portal"
              style={{
                maxWidth: "282px",
                height: "auto",
                verticalAlign: "middle",
              }}
            />
          </div>
        </div>
        <div className="row bg-warning">
          <center>
           <p className="lead mt-2"> 
             <strong>
              <a className="link-dark"
                href="https://outlook.office365.com/book/ResellerEnabledSecurityAssessments@tdworldwide.onmicrosoft.com/"
                target="_blank"
                rel="noreferrer"
                >
              Click Here to Check out our FREE Assessments
              </a>
             </strong>
              </p>
          </center>
        </div>
        <div className="carousel slide p-0">
          <Carousel>
            <Carousel.Item>
              <div className="carousel-item active">
                <svg
                  className="bd-placeholder-img"
                  width="100%"
                  height="100%"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                >
                  <rect width="100%" height="100%" fill={slideColor} />
                </svg>

                <div className="container">
                  <div className="carousel-caption text-start">
                    <h1>Solution Centers</h1>
                    <p>
                      The TD SYNNEX Solution Centers are the hub for centralized
                      partner resources and product demonstrations. These
                      resources are designed to be an extension of your Sales
                      Engineering Teams. The centers give you access to a team
                      of highly skilled technologists dedicated to providing
                      technical assistance and enablement on a variety of
                      technologies.
                    </p>
                    <p>
                      <Link to="/register">
                        <button className="btn btn-lg btn-primary">
                          Sign up today
                        </button>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <svg
                className="bd-placeholder-img"
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
              >
                <rect width="100%" height="100%" fill={slideColor} />
              </svg>

              <div className="container">
                <div className="carousel-caption">
                  <h1>Demos</h1>
                  <p>
                    The centers offer over 175 solutions in AI, automation,
                    containers, network management, DevOps, security and cloud.
                    We offer instructor-led, self-service, proof-of-concept and
                    other types of demos for you and your customers.
                  </p>
                  <p>
                    <Link to="/register">
                      <button className="btn btn-lg btn-primary">
                        Sign up today
                      </button>
                    </Link>
                  </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <svg
                className="bd-placeholder-img"
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
              >
                <rect width="100%" height="100%" fill={slideColor} />
              </svg>
              <div className="container">
                <div className="carousel-caption text-end">
                  <h1>Pre-Sales Services</h1>
                  <p>
                    Technologists to support your enablement activities. Expert
                    guidance on cloud providers such as AWS, Azure, GCP and
                    hybrid cloud as well. Access to loaner gear from various
                    vendors as well as setup assistance for Proof-of-Value
                    events.
                  </p>
                  <p>
                    <Link to="/register">
                      <button className="btn btn-lg btn-primary">
                        Sign up today
                      </button>
                    </Link>
                  </p>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>

      <div className="container marketing">
        <div className="row">
          <div className="col-lg-4">
            <FontAwesomeIcon icon={faChessKing} size="6x" />
            <h2 className="fw-normal mt-2">Stand Out</h2>
            <p>
              Differentiate your company from your competitors with our
              technical education and enablement assistance.
            </p>
            <p>
              <Link to="/register">
                <button className="btn btn-sm btn-secondary">
                  Sign Up Today
                </button>
              </Link>
            </p>
          </div>
          <div className="col-lg-4">
            <FontAwesomeIcon icon={faChartColumn} size="6x" />
            <h2 className="fw-normal mt-2">Close More</h2>
            <p>
              With our sales and strategic support, your company can close more
              business.
            </p>
            <p>
              <Link to="/register">
                <button className="btn btn-sm btn-secondary">
                  Sign Up Today
                </button>
              </Link>
            </p>
          </div>
          <div className="col-lg-4">
            <FontAwesomeIcon icon={faMoneyBill1} size="6x" />
            <h2 className="fw-normal mt-2">Earn More</h2>
            <p>
              We can assist with any portion of the sales cycle, or all of them.
              By including our extensive post-sales services, you gain higher
              margin in each deal.
            </p>
            <p>
              <Link to="/register">
                <button className="btn btn-sm btn-secondary">
                  Sign Up Today
                </button>
              </Link>
            </p>
          </div>
        </div>
      </div>

      <hr className="featurette-divider" />

      <div className="container">
        <div className="row featurette">
          <div className="col-md-7">
            <h2 className="featurette-heading fw-normal lh-1">
              Drive higher margins.{" "}
              <span className="text-muted">Services are the secret.</span>
            </h2>
            <p className="lead">
              By leveraging our complete array of services, you and your company
              can continue to drive higher margins on many of your deals and
              become the Trusted Advisor to your clients.
            </p>
          </div>
          <div className="col-md-5">
            <img src="install.jpg" className="d-block w-100" />
          </div>
        </div>
      </div>

      <hr className="featurette-divider" />

      <div className="container">
        <div className="row featurette">
          <div className="col-md-7 order-md-2">
            <h2 className="featurette-heading fw-normal lh-1">
              Be the trusted advisor.{" "}
              <span className="text-muted">Let us help.</span>
            </h2>
            <p className="lead">
              Another way TD SYNNEX can help? Of course. With unlimited
              Pre-Sales support, we can extend your technical bench, join calls
              with your customers, design complex solutions and create
              value-added BoMs for all needed designs.
            </p>
          </div>
          <div className="col-md-5 order-md-1">
            <img src="meeting01.jpg" className="d-block w-100" />
          </div>
        </div>
      </div>

      <hr className="featurette-divider" />

      <div className="container">
        <div className="row featurette">
          <div className="col-md-7">
            <h2 className="featurette-heading fw-normal lh-1">
              And lastly, this one.{" "}
              <span className="text-muted">Checkmate.</span>
            </h2>
            <p className="lead">
              We also provide custom enablement activities for you and your
              team. From sales psychology training, to deep technical
              work-shops, and everything in-between, we are here to support you
              in a 360 degree manner.
            </p>
          </div>
          <div className="col-md-5">
            <img src="console01.jpg" className="d-block w-100" />
          </div>
        </div>
      </div>

      <hr className="featurette-divider" />
    </React.Fragment>
  );
}

/*

                href="https://outlook.office365.com/owa/calendar/CloudSecurityFSAJackSproul@tdworldwide.onmicrosoft.com/bookings/"

                href="https://outlook.office.com/bookwithme/user/eec407983d3a42cda7e3d2e5a4713378@tdsynnex.com/meetingtype/27ch3XyUBkW0qP5JRXs_Fg2?anonymous&ep=mlink" 

              <img src="switch00.jpg" className="d-block w-100" />
     
           <Carousel.Item>
              <img src="console01.jpg" className="d-block w-100" />
              <Carousel.Caption>Brain Storming</Carousel.Caption>
            </Carousel.Item>              
            <Carousel.Item>
              <img src="install.jpg" className="d-block w-100" />
              <Carousel.Caption>Professional Installation</Carousel.Caption>
            </Carousel.Item>

      <div id="myCarousel" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#myCarousel"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#myCarousel"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#myCarousel"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div class="carousel-inner">

          <div class="carousel-item active">
            <svg
              class="bd-placeholder-img"
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            >
              <rect width="100%" height="100%" fill="#333" />
            </svg>

            <div class="container">
              <div class="carousel-caption text-start">
                <h1>Example headline.</h1>
                <p>
                  Some representative placeholder content for the first slide of
                  the carousel.
                </p>
                <p>
                  <a class="btn btn-lg btn-primary" href="#">
                    Sign up today
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div class="carousel-item">
            <svg
              class="bd-placeholder-img"
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            >
              <rect width="100%" height="100%" fill="#888" />
            </svg>

            <div class="container">
              <div class="carousel-caption">
                <h1>Another example headline.</h1>
                <p>
                  Some representative placeholder content for the second slide
                  of the carousel.
                </p>
                <p>
                  <a class="btn btn-lg btn-primary" href="#">
                    Learn more
                  </a>
                </p>
              </div>
            </div>
          </div>


          <div class="carousel-item">
            <svg
              class="bd-placeholder-img"
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            >
              <rect width="100%" height="100%" fill="#888" />
            </svg>

            <div class="container">
              <div class="carousel-caption text-end">
                <h1>One more for good measure.</h1>
                <p>
                  Some representative placeholder content for the third slide of
                  this carousel.
                </p>
                <p>
                  <a class="btn btn-lg btn-primary" href="#">
                    Browse gallery
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>


        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
      */
/*<div className="col-lg-4">
              <svg
                class="bd-placeholder-img rounded-circle"
                width="140"
                height="140"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                aria-label="Placeholder: 140x140"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
              >
                <title>Placeholder</title>
                <rect width="100%" height="100%" fill="#777" />
                <text x="50%" y="50%" fill="#777" dy=".3em">
                  140x140
                </text>
              </svg>
              <FontAwesomeIcon icon={faMoneyBill1} size="2x" />
              <h2 className="fw-normal">Earn More</h2>
              <p>
                We can assist with any portion of the sales cycle, or all of
                them. By including our extensive post-sales services, you gain
                higher margin in each deal.
              </p>
              <p>
                <a className="btn btn-secondary" href="#">
                  View details &raquo;
                </a>
              </p>
            </div>s
      
*/
