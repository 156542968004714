import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useNavigate } from "react-router-dom";
import * as yup from "yup";

import authService from "../../services/auth.service";
import rbacService from "../../services/rbacService";
import http from "../../services/httpService";

import {
  createFeedback,
  getEnum,
  getFeedbackbyID,
  updateFeedback,
} from "../../services/feedbackService";
import Input from "../common/input";
import { isDebug, logData } from "../common/helpers";

export default function FeedbackForm() {
  const [fetchedFeedback, setFetchedFeedback] = useState({});
  const [feedbackTypes, setFeedbackTypes] = useState([]);
  const [userFullName, setUserFullName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [errMsg, setErrMsg] = useState();

  const { feedbackId } = useParams();
  const navigate = useNavigate();
  const currentUser = authService.getCurrentUser();
  const lrbac = new rbacService(currentUser);

  const isNewFeedback = feedbackId.toString() === "new" ? true : false;
  const submitButtonLabel = isNewFeedback ? "Create" : "Update";

  const schema = yup.object().shape({
    message: yup.string().required().min(4),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const handleUpdate = (data) => {
    //data.userId = currentUser.id;

    if (isDebug) {
      console.log("Handling Create / Update ....");
      console.log("data");
      console.log(data);
    }

    if (isNewFeedback) {
      // Creating new feedback entry
      const doCreate = async () => {
        const createResponse = await createFeedback(data);
        if (isDebug) {
          logData(createResponse);
          logData(createResponse.status);
        }
        if (createResponse.status === 201) {
          // HTTP POST seem to return 201 on success
          setSuccessful(true);
        } else {
          setHasError(true);
          setErrMsg("Returned error code: " + createResponse.status);
        }
      };
      doCreate().catch(console.error);
    } else {
      // updating an existing item
      data.id = feedbackId;
      const pushUpdate = async () => {
        const updateResponse = await updateFeedback(feedbackId, data);
        console.log(updateResponse);
        console.log(updateResponse.status);
        if (updateResponse.status === 201) {
          // HTTP PUT seem to return 201 on success
          setSuccessful(true);
        }
      };
      //pushUpdate().catch((e) => alert(e));
      pushUpdate().catch((e) => http.handleError(e));
    }
  };

  // Runs after first render (when component mounts, sorta)
  useEffect(() => {
    setIsLoading(true);

    if (isDebug) {
      console.log("currentUser");
      console.log(currentUser);
      console.log(`isNewFeedback = ${isNewFeedback}`);
    }

    const getFBTypes = async () => {
      const fbTypes = await getEnum();
      setFeedbackTypes(fbTypes);
    };
    getFBTypes().catch(console.error);

    if (!isNewFeedback) {
      const getFeedbackInfo = async () => {
        const feedback = await getFeedbackbyID(feedbackId);
        setFetchedFeedback(feedback);
      };
      getFeedbackInfo().catch(console.error);
    } 
    setIsLoading(false);
  }, []);

  /* this useEffect is called only when we have 
  fetched a user from the backend */
  useEffect(() => {
    if (isDebug) {
      console.log("Fetched Feedback: " );
      console.log( fetchedFeedback);
    } 
    if(fetchedFeedback) {

      setUserFullName( fetchedFeedback?.user?.fullName);  

      setValue("userId", fetchedFeedback.userId, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("message", fetchedFeedback.message, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("actionTaken", fetchedFeedback.actionTaken, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("type", fetchedFeedback.type, {
        shouldValidate: true,
        shouldDirty: true,
      });
    } else {
      setUserFullName(currentUser.fullName);
    }
  }, [fetchedFeedback]);

  /* This useEffect is only when successful submission */
  useEffect(() => {
    if (successful) {
      //navigate("/users");
      navigate(-1);
    }
  }, [successful]);

  return (
    <React.Fragment>
      {hasError ? (
        alert(errMsg)
      ) : isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      ) : (
        <div className="container shadow border border-2 border-light rounded-3 mt-4">
          <form onSubmit={handleSubmit(handleUpdate)} className="p-2">
            <div className="row justify-content-lg-center">
              <div className="col-6">
                <span className="m-3 text-muted">
                  From: {userFullName}
                </span>
                <div className="form-group m-2">
                  <label className="text-muted mx-2" htmlFor="type">
                    <small>{"Select feedback type..."}</small>
                  </label>
                  <select
                    {...register("type", {
                      onChange: (e) => {
                        setValue("type", e.target.value);
                      },
                    })}
                    id="type"
                    className="form-select m-2"
                  >
                    {feedbackTypes.map((type, i) => (
                      <option key={i} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                  {errors["type"] && (
                    <div className="alert alert-danger">
                      {errors["type"].message}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="row justify-content-lg-center">
              <div className="col-md-8">
                <label htmlFor="message">Feedback</label>
                <textarea
                  {...register("message")}
                  className="form-control"
                  rows="5"
                />
              </div>
            </div>

            {lrbac.isAdmin() ? (
              <div className="row justify-content-lg-center">
                <div className="col-md-8">
                  <label htmlFor="actionTaken">Actions Taken</label>
                  <textarea
                    {...register("actionTaken")}
                    className="form-control"
                    rows="5"
                  />
                </div>
              </div>
            ) : null}

            <div className="row justify-content-lg-center">
              <div className="col"></div>
              <div className="col">
                <center>
                  <div id="4" className="form-group m-2">
                    <input
                      className="btn btn-sm btn-primary me-2"
                      type="submit"
                      value={submitButtonLabel}
                    />
                    <input
                      className="btn btn-sm btn-danger"
                      type="button"
                      value="Cancel"
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                  </div>
                  {successful && (
                    <div className="alert alert-success">
                      Update Successful!
                    </div>
                  )}
                </center>
              </div>
              <div className="col"></div>
            </div>
          </form>
        </div>
      )}
    </React.Fragment>
  );
}

/*
                <span className="m-3 text-muted">
                  From: {currentUser.fullName}
                </span>
*/