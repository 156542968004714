import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faPenToSquare } from "@fortawesome/free-regular-svg-icons";

import Table from "../common/table";
import authService from "../../services/auth.service";

class CategoriesTable extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    topics: this.props.categories,
    selectedTopic: this.props.selectedTopic,
    currentPage: 1,
    pageSize: 5,
    sortColumn: { path: "title", order: "asc" },
    currentUser: null,
  };

  componentDidMount() {
    const loggedInUser = authService.getCurrentUser();
    this.setState({ currentUser: loggedInUser });
    if (loggedInUser.roleId <= 2) {
      // logged in user is either admin or instructor
      this.columns.push(...this.adminColumns);
      this.setState({ showAddButton: true });
    }
  }

  columns = [
    {
      path: "title",
      label: "Title",
      content: (category) => (
        <Link to={`/categoryDetail/${category.id}`}>{category.title}</Link>
      ),
    },
    { path: "description", label: "Description" },
    /*
     */
  ];

  //These additional columns are only present when user
  //is an instructor or admin
  adminColumns = [
    {
      key: "edit",
      content: (category) => (
        <Link to={`/categoryForm/${category.id}`}>
          <FontAwesomeIcon icon={faPenToSquare} />
        </Link>
      ),
    },
    {
      key: "delete",
      content: (category) => (
        <button
          onClick={() => this.props.onDelete(category)}
          className="btn btn-danger btn-sm"
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </button>
      ),
    },
  ];

  render() {
    const { categories, onSort, onDelete, sortColumn, caption } = this.props;

    return (
      <Table
        columns={this.columns}
        data={categories}
        sortColumn={sortColumn}
        onDelete={onDelete}
        onSort={onSort}
        caption={caption}
      />
    );
  }
}

export default CategoriesTable;
