import http from "./httpService";
import authHeader from "./auth-header";

export async function getUserInfo(userid) {
  const { data: userInfo } = await http.get(`/api/v1/users/${userid}`, {
    headers: authHeader(),
  });
  return userInfo;
}

export async function getUsers() {
  const { data: users } = await http.get("/api/v1/users/", {
    headers: authHeader(),
  });
  return users;
}

export async function getUsersWithActivites() {
  const { data: users } = await http.get("/api/v1/users/getWithActivities", {
    headers: authHeader(),
  });
  return users;
}

export async function updateUser(userid, user) {
  const response = await http.put(`/api/v1/users/${userid}`, user, {
    headers: authHeader(),
  });
  return response;
}

export async function createUser(user) {
  const response = await http.post(`/api/v1/users/`, user, {
    headers: authHeader(),
  });
  return response;
}

export async function removeUser(userId) {
  const response = await http.delete(`/api/v1/users/${userId}`, {
    headers: authHeader(),
  });
  return response;
}
