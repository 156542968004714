import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useNavigate } from "react-router-dom";
import * as yup from "yup";
import _ from "lodash";

import authService from "../../services/auth.service";
import rbacService from "../../services/rbacService";
import http from "../../services/httpService";

import {
  createPocItem,
  getEnum,
  getEnum2,
  getPocItembyID,
  updatePocItem,
} from "../../services/pocItemService";
import { getPocKits } from "../../services/pocKitService";
import { isDebug, logData } from "../common/helpers";
import BackButton from "../common/backButtion";
import { getVendors } from "../../services/vendorService";

export default function PocItemForm() {
  const [fetchedPocItem, setFetchedPocItem] = useState(null);
  const [pocItemStatuses, setPocItemStatuses] = useState([]);
  const [pocItemTypes, setPocItemTypes] = useState([]);
  const [kits, setKits] = useState([]);
  const [vendors, setVendors] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [errMsg, setErrMsg] = useState();

  const { pocItemId } = useParams();
  const navigate = useNavigate();

  const currentUser = authService.getCurrentUser();
  const rbac = new rbacService(currentUser);

  const isNewPocItem = pocItemId.toString() === "new" ? true : false;
  const submitButtonLabel = isNewPocItem ? "Create" : "Update";
  const pageTitle = isNewPocItem
    ? "Create New Item"
    : "Update Item Information";

  //if (isDebug) logData(`isNewPocItem = ${isNewPocItem}`);
  /*
   */
  const schema = yup.object().shape({
    serialNum: yup.string().required().min(2),
    name: yup.string().required().min(2),
    vendorId: yup.number().required().positive().integer(),              
    pocKitId: yup.number().required().positive().integer(),              
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const handleUpdate = (data) => {
    data.userId = currentUser.id;

    if (isDebug) {
      logData("Handling Create / Update ....");
    }

    if (isNewPocItem) {
      // Creating new pocItem entry
      const doCreate = async () => {
        const createResponse = await createPocItem(data);
        if (isDebug) {
          logData(createResponse);
          logData(createResponse.status);
        }
        if (createResponse.status === 201) {
          // HTTP POST seem to return 201 on success
          setSuccessful(true);
        } else {
          setHasError(true);
          setErrMsg("Returned error code: " + createResponse.status);
        }
      };
      doCreate().catch((e) => http.handleError(e));
    } else {
      // updating an existing item
      data.id = pocItemId;
      const pushUpdate = async () => {
        const updateResponse = await updatePocItem(pocItemId, data);
        console.log(updateResponse);
        console.log(updateResponse.status);
        if (updateResponse.status === 201) {
          // HTTP PUT seem to return 201 on success
          setSuccessful(true);
        }
      };
      //pushUpdate().catch((e) => alert(e));
      pushUpdate().catch((e) => http.handleError(e));
    }
  };

  // Runs after first render (when component mounts, sorta)
  useEffect(() => {
    setIsLoading(true);

    const getStatuses = async () => {
      const pkStatuses = await getEnum2();
      setPocItemStatuses(pkStatuses);
    };
    getStatuses().catch(console.error);

    const getItemTypes = async () => {
      const piTypes = await getEnum();
      setPocItemTypes(piTypes);
    };
    getItemTypes().catch(console.error);

    const getKitList = async () => {
      const localKits = await getPocKits();
      setKits(localKits);
    };
    getKitList().catch(console.error);

    const getVendorList = async () => {
      const localvs = await getVendors();
      const sortedVendors = _.orderBy(localvs, ["name"], ["asc"]);
      setVendors(sortedVendors);
    }
    getVendorList().catch((error) => console.log(error));

    if (!isNewPocItem) {
      const getPocItemInfo = async () => {
        const pocItem = await getPocItembyID(pocItemId);
        setFetchedPocItem(pocItem);
      };

      getPocItemInfo().catch(console.error);
      setIsLoading(false);
    } 
    
    setIsLoading(false);
  }, []);

  /* this useEffect is called only when we have 
  fetched a user from the backend */
  useEffect(() => {
    //if (isDebug) logData("Fetched PocItem: " + fetchedPocItem);

    if(fetchedPocItem !== null){

      console.log(fetchedPocItem);

      setValue("name", fetchedPocItem.name, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("description", fetchedPocItem.description, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("status", fetchedPocItem.status, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("type", fetchedPocItem.type, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("mfgPartNum", fetchedPocItem.mfgPartNum, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("serialNum", fetchedPocItem.serialNum, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("sapMatId", fetchedPocItem.sapMatId, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("pocKitId", fetchedPocItem.pocKitId, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("vendorId", fetchedPocItem.vendorId, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [fetchedPocItem]);

  /* This useEffect is only when successful submission */
  useEffect(() => {
    if (successful) {
      //navigate("/users");
      navigate(-1);
    }
  }, [successful]);

  return (
    <React.Fragment>
      {hasError ? (
        alert(errMsg)
      ) : isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      ) : (
        <div className="container shadow border border-2 border-light rounded-3 mt-4">
          <form onSubmit={handleSubmit(handleUpdate)} className="p-2">
            <div className="row justify-content-lg-center">
              <div className="col-4 p-3">
                <BackButton />
              </div>
              <div className="col-8">
                <h2 className="display-6">{pageTitle} </h2>

              </div>
            </div>
            <div className="row justify-content-lg-center">
              <div className="col-6">
                <div className="form-group m-2">
                  <label className="text-muted mb-2" htmlFor="name">Name</label>
                  <input className="form-control" {...register("name")} id="name"/>
                  {errors["name"] && (
                    <div className="alert alert-danger m-2">
                      {errors["name"].message}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-6">
              <div className="form-group mt-2">
                  <label className="text-muted mx-2" htmlFor="vendorId">
                    {"Select Vendor"}
                  </label>
                  <select
                    {...register("vendorId", {
                      onChange: (e) => {
                        setValue("vendorId", e.target.value);
                      },
                    })}
                    id="vendorId"
                    className="form-select m-2"
                  >
                    <option key="0" value="0">Vendor Not Chosen</option>
                    {vendors.map((vendor) => (
                      <option key={vendor.id} value={vendor.id}>
                        {vendor.name}
                      </option>
                    ))}
                  </select>
                  {errors["vendorId"] && (
                    <div className="alert alert-danger">
                      {errors["vendorId"].message}
                    </div>
                  )}
                </div>
              </div>
            </div>


            <div className="row justify-content-lg-center">
            <div className="col-4">
              <div className="form-group mt-2">
                  <label className="text-muted mx-2" htmlFor="pocKitId">
                    {"Select Kit"}
                  </label>
                  <select
                    {...register("pocKitId", {
                      onChange: (e) => {
                        setValue("pocKitId", e.target.value);
                      },
                    })}
                    id="pocKitId"
                    className="form-select m-2"
                  >
                    <option key="0" value="0">Please select a Kit</option>
                    {kits.map((kit) => (
                      <option key={kit.id} value={kit.id}>
                        {kit.name}
                      </option>
                    ))}
                  </select>
                  {errors["pocKitId"] && (
                    <div className="alert alert-danger">
                      {errors["pocKitId"].message}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-4">
              <div className="form-group m-2">
                  <label className="text-muted " htmlFor="type">
                    {"Select Item Type..."}
                  </label>
                  <select
                    {...register("type", {
                      onChange: (e) => {
                        setValue("type", e.target.value);
                      },
                    })}
                    id="type"
                    className="form-select mt-2"
                  >
                    <option key="0" value="0">Please select Item Type</option>
                    {pocItemTypes.map((type, i) => (
                      <option key={i} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                  {errors["type"] && (
                    <div className="alert alert-danger">
                      {errors["type"].message}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-4">
              <div className="form-group mt-2">
                  <label className="text-muted mx-2" htmlFor="status">
                    {"Select Item Status..."}
                  </label>
                  <select
                    {...register("status", {
                      onChange: (e) => {
                        setValue("status", e.target.value);
                      },
                    })}
                    id="status"
                    className="form-select m-2"
                  >
                    <option key="0" value="0">Please select Item Status</option>
                    {pocItemStatuses.map((status, i) => (
                      <option key={i} value={status}>
                        {status}
                      </option>
                    ))}
                  </select>
                  {errors["status"] && (
                    <div className="alert alert-danger">
                      {errors["status"].message}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="row justify-content-lg-center">
              <div className="col-4">
              
              </div>

              <div className="col-4">
                
              </div>
            </div>

            <div className="row justify-content-lg-center">
            <div className="col-4">
              <div className="form-group m-2">
                  <label className="text-muted mb-2" htmlFor="serialNum">Serial #</label>
                  <input className="form-control" {...register("serialNum")} id="serialNum"/>
                  {errors["serialNum"] && (
                    <div className="alert alert-danger m-2">
                      {errors["serialNum"].message}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-4">
                <div className="form-group m-2">
                  <label className="text-muted mb-2" htmlFor="mfgPartNum">Mfg Part #</label>
                  <input className="form-control" {...register("mfgPartNum")} id="mfgPartNum"/>
                  {errors["mfgPartNum"] && (
                    <div className="alert alert-danger m-2">
                      {errors["mfgPartNum"].message}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-4">
                <div className="form-group m-2">
                  <label className="text-muted mb-2" htmlFor="sapMatId">SAP Mat ID</label>
                  <input className="form-control" {...register("sapMatId")} id="sapMatId"/>
                  {errors["sapMatId"] && (
                    <div className="alert alert-danger m-2">
                      {errors["sapMatId"].message}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="row justify-content-lg-center">
              <div className="col-11">
                <label className="text-muted mt-2" htmlFor="description">Description</label>
                <textarea
                  {...register("description")}
                  className="form-control mt-2"
                  rows="5"
                />
              </div>
            </div>

            <div className="row justify-content-lg-center">
              <div className="col"></div>
              <div className="col">
                <center>
                  <div id="4" className="form-group m-2">
                    <input
                      className="btn btn-sm btn-primary me-2"
                      type="submit"
                      value={submitButtonLabel}
                    />
                    <input
                      className="btn btn-sm btn-danger"
                      type="button"
                      value="Cancel"
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                  </div>
                  {successful && (
                    <div className="alert alert-success">
                      Update Successful!
                    </div>
                  )}
                </center>
              </div>
              <div className="col"></div>
            </div>
          </form>
        </div>
      )}
    </React.Fragment>
  );
}


/*
                    value={fetchedPocItem?.pocKitId ? fetchedPocItem.pocKitId : null}
                    value={fetchedPocItem?.vendorId ? fetchedPocItem.vendorId : null}

                    value={fetchedPocItem?.pocKitId ? fetchedPocItem.pocKitId : "0"}
*/