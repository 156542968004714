import React, { useState } from "react";
import _ from "lodash";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Table from "../common/table";
import Select from "react-select";
import ExportExcel from "../common/excelExport";
import { format } from "date-fns";

function InstructorDemoReport(props) {
    const [count, setCount] = useState(5);
    const [instructors, setInstructors] = useState();
    const [exportData, setExportData] = useState(null);
    const [dateString, setDateString] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

   // if(props.vendors === null) return null;

    useEffect(() => {
        setIsLoading(true);
        let data = [];
        const today = format(new Date(), "ddMMMyyyy");
        setDateString(today);
        //this sort ascending by default
        const sortedinstructors = _.orderBy(props.instructors, ['demos.length'], ['desc'] );
        const topList = _.slice(sortedinstructors,0,count);
        sortedinstructors.map((inst) => data.push({ name: inst.user.fullName, demoCount: inst.demos.length}));
        //console.log(topList);
        setInstructors(topList);
        setExportData(data);
        setIsLoading(false);
    }, [props.instructors, count]);

    const topListCount = [
        { value: 5, label: 5 },
        { value: 10, label: 10 },
        { value: 15, label: 15 },
        { value: 20, label: 20 }
    ];

    const changeCount = (e) => {
        setCount(e.value);
    };

    const columns = [
       {
        path: "user.fullName",
        label: "Instructor",
        content: (instructor) => <Link to={`/instructorProfile/${instructor.id}`}>{instructor.user.fullName}</Link>,
       },
       {
        path: "demos.length",
        label: "Demo Count",
       },
    ];

    return (
        <>
         {isLoading ? (
            <div className="text-center p-3">
              <span className="spinner-border spinner-border-lg align-center"></span>
            </div>
         ) : (
            <div className="report-tile">
              <center>

                 <p className="lead">Top {count} Instructors with Most Demos </p>
                 <div className="d-inline-flex align-items-center">
                   <div style={{maxWidth: "250px"}}>
                   <Select
                     id="topCount"
                     options={topListCount}
                     onChange={changeCount}
                     placeholder="How many?"
                   />
                  </div>
                  <div className="d-inline-flex ps-2">
                    <ExportExcel 
                      excelData={exportData} 
                      fileName={`Instructor-Demo-Report-${dateString}`} />
                  </div>
                </div>
              </center>
            <div>
                <Table columns={columns} data={instructors} sortColumn={"Views"} />
            </div>
            </div>
         )}
        </>
    );
}

export default InstructorDemoReport;

/*

 <div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Views</th>
                        </tr>
                    </thead>
                    <tbody>
                        {vendors ? (
                            vendors.map((vendor) => {
                                <tr>
                                    <td>
                                      <Link to={`/vendorDetail/${vendor.id}`}>{vendor.name}</Link>
                                    </td>
                                    <td>
                                        {vendor.views}
                                    </td>
                                </tr>
                            })
                        ) : (
                            <tr> <td>NO Data</td></tr>
                        )}
                    </tbody>
                </table>
            </div>
*/