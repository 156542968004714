import http from "./httpService";
import authHeader from "./auth-header";

export async function getEnum() {
  const { data: pocItemTypes } = await http.get("/api/v1/pocItems/getEnum", {
    headers: authHeader(),
  });
  return pocItemTypes;
}

export async function getEnum2() {
  const { data: pocItemStatuses } = await http.get("/api/v1/pocItems/getEnum2", {
    headers: authHeader(),
  });
  return pocItemStatuses;
}

export async function getPocItemCount() {
  const { data: count } = await http.get("/api/v1/pocItems/getCount", {
    headers: authHeader(),
  });
  return count;
}

export async function getPocItems() {
  const { data: feedbacks } = await http.get("/api/v1/pocItems/", {
    headers: authHeader(),
  });
  return feedbacks;
}

export async function getPocItembyID(pocItemId) {
  const { data: feedbackInfo } = await http.get(
    `/api/v1/pocItems/${pocItemId}`,
    {
      headers: authHeader(),
    }
  );
  return feedbackInfo;
}

export async function updatePocItem(feedbackid, feedback) {
  const response = await http.put(`/api/v1/pocItems/${feedbackid}`, feedback, {
    headers: authHeader(),
  });
  return response;
}

export async function createPocItem(feedback) {
  const response = await http.post("/api/v1/pocItems/", feedback, {
    headers: authHeader(),
  });
  return response;
}

export async function removePocItem(feedbackId) {
  const response = await http.delete(`/api/v1/pocItems/${feedbackId}`, {
    headers: authHeader(),
  });
  return response;
}
