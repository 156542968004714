import React, { useState } from "react";
import _ from "lodash";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Table from "../common/table";
import Select from "react-select";
import ExportExcel from "../common/excelExport";
import { format } from "date-fns";


function UserActivityReport(props) {
    const [count, setCount] = useState(5);
    const [users, setUsers] = useState();
    const [showTDSUsers, setShowTDSUsers] = useState(false);
    const [exportData, setExportData] = useState(null);
    const [dateString, setDateString] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

   // if(props.vendors === null) return null;

    useEffect(() => {
        setIsLoading(true);
        //this sort ascending by default
        const today = format(new Date(), "ddMMMyyyy");
        setDateString(today);
       
        let data = [];
        if(showTDSUsers) {
          const sortedusers = _.orderBy(props.users, ['userActivities.length'], ['desc'] );
          sortedusers.map((s) => data.push({Name: s.fullName, Reseller: s.reseller.name, ActivityCount: s.userActivities.length}) );
          setExportData(data);
          const topList = _.slice(sortedusers,0,count);
          console.log(topList);
          setUsers(topList);
        } else {
          const filteredUsers = _.filter(props.users, (u) => u.resellerId !== 4 );
          const sortedusers = _.orderBy(filteredUsers, ['userActivities.length'], ['desc'] );
          sortedusers.map((s) => data.push({Name: s.fullName, Reseller: s.reseller.name, ActivityCount: s.userActivities.length}) );
          setExportData(data);
          const topList = _.slice(sortedusers,0,count);
          console.log(topList);
          setUsers(topList);
        }
        setIsLoading(false);
    }, [props.users, count, showTDSUsers]);

    const topListCount = [
        { value: 5, label: 5 },
        { value: 10, label: 10 },
        { value: 15, label: 15 },
        { value: 20, label: 20 }
    ];

    const changeCount = (e) => {
        setCount(e.value);
    };

    
    const changeShowTDSUsers = (e) => {
      setShowTDSUsers( !showTDSUsers);
    }

    const columns = [
       {
        path: "fullName",
        label: "User",
        content: (user) => <Link to={`/userDetail/${user.id}`}>{user.fullName}</Link>,
       },
       {
        path: "reseller",
        label: "Reseller",
        content: (user) => <Link to={`/resellerDetail/${user.reseller.id}`}>{user.reseller.name}</Link>,
       },
       {
        path: "userActivities.length",
        label: "Activity",
       },
    ];

    return (
        <>
         {isLoading ? (
            <div className="text-center p-3">
              <span className="spinner-border spinner-border-lg align-center"></span>
            </div>
         ) : (
            <div className="report-tile">
              <center>

                 <p className="lead">Top {count} Most Active Users </p>
                 <div className="d-inline-flex align-items-center">
                 <div className="d-inline-flex pe-2">
                    <ExportExcel 
                      excelData={exportData} 
                      fileName={`User-Activity-Report-${dateString}`} />
                </div>
                   <div style={{maxWidth: "250px"}}>
                     <Select
                       id="topCount"
                       options={topListCount}
                       onChange={changeCount}
                       placeholder="How many?"
                   />
                  </div>
                  <div className="form-check ms-2" style={{maxWidth: "250px"}}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="31495"
                      checked={showTDSUsers}
                      onChange={changeShowTDSUsers}
                    />
                    <label className="form-check-label" htmlFor="31495">
                      <small>
                        Show TD SYNNEX Users
                      </small>
                    </label>
                  </div>
                 </div>
              </center>
            <div>
                <Table columns={columns} data={users} sortColumn={"Reseller"} />
            </div>
            </div>
         )}
        </>
    );
}

export default UserActivityReport;

/*

 <div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Views</th>
                        </tr>
                    </thead>
                    <tbody>
                        {vendors ? (
                            vendors.map((vendor) => {
                                <tr>
                                    <td>
                                      <Link to={`/vendorDetail/${vendor.id}`}>{vendor.name}</Link>
                                    </td>
                                    <td>
                                        {vendor.views}
                                    </td>
                                </tr>
                            })
                        ) : (
                            <tr> <td>NO Data</td></tr>
                        )}
                    </tbody>
                </table>
            </div>
*/