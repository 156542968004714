import http from "./httpService";
import authHeader from "./auth-header";


export async function getEnum2() {
  const { data: pocOrderStatuses } = await http.get("/api/v1/pocOrders/getEnum2", {
    headers: authHeader(),
  });
  return pocOrderStatuses;
}

export async function getPocOrderCount() {
  const { data: count } = await http.get("/api/v1/pocOrders/getCount", {
    headers: authHeader(),
  });
  return count;
}

export async function getPocOrderRequestCount() {
  const { data: count } = await http.get("/api/v1/pocOrders/getRequestsCount", {
    headers: authHeader(),
  });
  return count;
}

export async function getPocOrders() {
  const { data: feedbacks } = await http.get("/api/v1/pocOrders/", {
    headers: authHeader(),
  });
  return feedbacks;
}

export async function getPocOrderbyID(pocOrderId) {
  const { data: feedbackInfo } = await http.get(
    `/api/v1/pocOrders/${pocOrderId}`,
    {
      headers: authHeader(),
    }
  );
  return feedbackInfo;
}

export async function getPocOrderbyStatus(status) {
  const { data: pocOrders } = await http.get(
    `/api/v1/pocOrders/getByStatus/${status}`,
    {
      headers: authHeader(),
    }
  );
  return pocOrders;
}

export async function updatePocOrder(pocOrderId, pocOrder) {
  const response = await http.put(`/api/v1/pocOrders/${pocOrderId}`, pocOrder, {
    headers: authHeader(),
  });
  return response;
}

export async function createPocOrder(pocOrder) {
  const response = await http.post("/api/v1/pocOrders/", pocOrder, {
    headers: authHeader(),
  });
  return response;
}

export async function removePocOrder(poId) {
  const response = await http.delete(`/api/v1/pocOrders/${poId}`, {
    headers: authHeader(),
  });
  return response;
}
