import authHeader from "./auth-header";
import http from "./httpService";

export async function getDTCRequestCount() {
  const { data: count } = await http.get("/api/v1/dtcRequests/getCount", {
    headers: authHeader(),
  });
  return count;
}

export async function getDTCRequests() {
  const { data: dtcRequests } = await http.get("/api/v1/dtcRequests/", {
    headers: authHeader(),
  });
  return dtcRequests;
}

export async function getDTCRequestsSinceDate(startDate) {
  const { data: dtcRequests } = await http.get(
    `/api/v1/dtcRequests/sinceDate/${startDate}`,
    {
      headers: authHeader(),
    }
  );
  return dtcRequests;
}


export async function getDTCRequestInfo(dtcRequestId) {
  const { data: dtcRequestInfo } = await http.get(
    "/api/v1/dtcRequests/" + dtcRequestId,
    {
      headers: authHeader(),
    }
  );
  return dtcRequestInfo;
}

export async function getDTCRequestByResellerID(resellerId) {
  const { data: dtcRequests } = await http.get(
    `/api/v1/dtcRequests/getByFKey2/${resellerId}`,
    {
      headers: authHeader(),
    }
  );

  return dtcRequests;
}

export async function getDTCRequestByDemoID(demoId) {
  const { data: dtcRequestInfo } = await http.get(
    `/api/v1/dtcRequests/getByFKey/${demoId}`,
    {
      headers: authHeader(),
    }
  );

  return dtcRequestInfo;
}

export async function updateDTCRequest(dtcRequestId, dtcRequest) {
  const response = await http.put(
    `/api/v1/dtcRequests/${dtcRequestId}`,
    dtcRequest,
    {
      headers: authHeader(),
    }
  );
  return response;
}

export async function createDTCRequest(dtcRequest) {
  const response = await http.post(`/api/v1/dtcRequests/`, dtcRequest, {
    headers: authHeader(),
  });
  return response;
}

export async function removeDTCRequest(dtcRequestId) {
  const response = await http.delete(`/api/v1/dtcRequests/${dtcRequestId}`, {
    headers: authHeader(),
  });
  return response;
}
