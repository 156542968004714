import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { createUserActivity } from "../../services/userActivityService";
import authService from "../../services/auth.service";

export default function ResponsivePlayer(props) {
  let secondsPlayed = 0.0;
  let percentPlayed = 0.0;
  let currentUser = {};

  useEffect(() => {
    //This fires on component mount
    console.log("Responsive Player Mounted!");
    currentUser = authService.getCurrentUser();
    const entityName = props.entityName;
    const entityType = props.entityType;

    return () => {
      // This fires on component unmount
      console.log("responsive player unmounted. :( ");
      const msg2 =
        "User played " +
        secondsPlayed +
        " seconds (" +
        percentPlayed +
        "%) of video";
      console.log(msg2);
      const newActivity = {};
      newActivity.userId = currentUser.id;
      newActivity.userName = currentUser.userName;
      newActivity.entityName = entityName;
      newActivity.entityType = entityType;
      newActivity.entityAction = "WATCH";
      newActivity.details = msg2;
      const actResponse = createUserActivity(newActivity);
    };
  }, []);

  function handleDuration(duration) {
    // console.log(" onDuration: ", duration);
  }

  function handlePlay() {
    console.log("onPlay");
  }

  function handlePause() {
    console.log("onPause");
  }

  function handleProgress(played) {
    console.log(played);
    // console.log(played.played);
    //console.log(played.played * 100);
    secondsPlayed = played.playedSeconds.toFixed(2);
    percentPlayed = (played.played * 100).toFixed(2);
  }

  return (
    <div className="player-wrapper">
      <ReactPlayer
        className="react-player"
        url={props.url}
        width="100%"
        height="100%"
        controls={true}
        onDuration={handleDuration}
        onPlay={handlePlay}
        onPause={handlePause}
        onProgress={handleProgress}
      />
    </div>
  );
}
