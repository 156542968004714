import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { createUserActivity } from "../../services/userActivityService";
import authService from "../../services/auth.service";

export default function DemoPlayer(props) {
  let secondsPlayed = 0.0;
  let percentPlayed = 0.0;
  let currentUser = {};
  let demo = null;

  useEffect(() => {
    if(demo === null){
      //This fires on component mount
      console.log("Responsive Player Mounted!");
      currentUser = authService.getCurrentUser();
      demo = props.demo;

      return () => {
        // This fires on component unmount
        console.log("responsive player unmounted. :( ");
        const msg2 =
          "User played " +
          secondsPlayed +
          " seconds (" +
          percentPlayed +
          "%) of video";
        console.log(msg2);
        const newActivity = {};
        //console.log(currentUser);
        newActivity.userId = currentUser.id;
        newActivity.userName = currentUser.userName;
        newActivity.entityType = "Demo";
        newActivity.entityAction = "WATCH";
        newActivity.entityName = demo.name;
        newActivity.entityId = demo.id;
        newActivity.relatedEntityName = demo.vendors[0].name;
        newActivity.relatedEntityId = demo.vendors[0].id;
        newActivity.details = msg2;
        const actResponse = createUserActivity(newActivity);
      };
    }
  }, []);

  function handleProgress(played) {
    //console.log(played);
    //console.log(played.played);
    //console.log(played.played * 100);
    secondsPlayed = played.playedSeconds.toFixed(2);
    percentPlayed = (played.played * 100).toFixed(2);
  }

  function handleError(error) {
    console.log(error);
  }

  return (
    <div className="player-wrapper">
      <ReactPlayer
        className="react-player"
        url={props.url}
        width="100%"
        height="100%"
        controls={true}
        onProgress={handleProgress}
        onError={handleError}
      />
    </div>
  );
}
