import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../common/table";
import Like from "../common/like";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faPen, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";

class InstructorsTable extends Component {
  columns = [
    {
      path: "user.fullName",
      label: "Name",
      content: (instructor) => (
        <Link to={`/instructorProfile/${instructor.id}`}>
          {instructor.user.fullName}
        </Link>
      ),
    },
    { path: "user.email", label: "Email" },
    {
      path: "user.role.name",
      label: "Role",
    },
    {
      key: "edit",
      content: (instructor) => (
        <Link to={`/instructor/${instructor.id}`}>
          <FontAwesomeIcon icon={faPenToSquare} />
        </Link>
      ),
    },
  ];

  render() {
    const { instructors, onSort, sortColumn, caption } = this.props;

    console.log(instructors);

    return (
      <Table
        columns={this.columns}
        data={instructors}
        sortColumn={sortColumn}
        onSort={onSort}
        caption={caption}
      />
    );
  }
}

export default InstructorsTable;
