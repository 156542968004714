import React, { useState, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import authService from "../../services/auth.service";
import { getSignupInfo } from "../../services/signupService";
import BackButton from "../common/backButtion";

export default function NewUserRequestDetail() {
  const [redirect, setRedirect] = useState(null);
  const [signupInfo, setSignupInfo] = useState(null);

  let { signupId } = useParams();
  console.log(signupId);

  useEffect(() => {
    const loggedInUser = authService.getCurrentUser();
    let fetchedUser = {};

    if (!loggedInUser) {
      setRedirect("/login");
    }

    const getUserDetails = async () => {
      fetchedUser = await getSignupInfo(signupId);
      setSignupInfo(fetchedUser);
      console.log(fetchedUser);
    };
    getUserDetails().catch(console.error);
  }, []);

  const handleUpdate = () => {
    const link = "/user/new";
    console.log(link);
    setRedirect(link);
  };

  return (
    <>
      {redirect ? <Navigate to={redirect} state={signupInfo} /> : null}
      {signupInfo ? (
        <>
          <div className="container shadow border border-1 border-light rounded-3 pt-2 mt-2">
            <BackButton />
            <center>
              <h2 className="display-6"> New User Request</h2>
            </center>

            <div className="row justify-content-lg-center">
              <h6 className="display-6">{signupInfo.fullName}</h6>
            </div>
            <div className="row justify-content-lg-center">
              <div className="col-3">
                <p className="h6 mt-2">First Name: </p>
                {signupInfo.firstName}
                <hr />
                <p className="h6 mt-2">Last Name: </p>
                {signupInfo.lastName}
                <hr />
              </div>

              <div className="col-3">
                <p className="h6 mt-1">Email:</p>
                {signupInfo.email}
                <hr />
                <p className="h6 mt-2">Work Phone: </p>
                {signupInfo.workPhone}
                <hr />
              </div>

              <div className="col-3">
                <p className="h6 mt-1">Reseller:</p>
                {signupInfo.companyName}
                <hr />
                <p className="h6 mt-1">Reseller Acct#:</p>
                {signupInfo.acctNumber === ""
                  ? "Unknown"
                  : signupInfo.acctNumber}
                <hr />
              </div>

              <div className="col-3">
                <p className="h6 mt-1">Sales Person:</p>
                {signupInfo.salesPerson}
                <hr />
                <p className="h6 mt-1">Sales Person Email:</p>
                {signupInfo.salesPersonEmail === ""
                  ? "Unknown"
                  : signupInfo.salesPersonEmail}
                <hr />
              </div>
            </div>

            <div className="row justify-content-lg-center">
              <div className="col mb-3">
                <p className="h6 mt-1">Comments:</p>
                {signupInfo.comments}
              </div>
            </div>

            <div className="row justify-content-lg-center">
              <div className="col mb-3">
                <p className="h6 mt-1">Topics of Interest:</p>
                {signupInfo.topics}
              </div>
            </div>

            <div className="row justify-content-lg-center">
              <div className="col">
                <p className="h6 mt-1">Vendors of Interest:</p>
                {signupInfo.vendors}
              </div>
            </div>

            <div className="row justify-content-lg-center">
              <center className="mt-3">
                <button
                  onClick={handleUpdate}
                  className="btn-sm btn-primary mb-3 me-3"
                >
                  Create New User from Request
                </button>
              </center>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
