import React, { useState, useEffect } from "react";
import authService from "../../services/auth.service";
import { getUserActivitybyUserID } from "../../services/userActivityService";
import httpService from "../../services/httpService";
import RatingWrapper from "../common/ratingWrapper";
import ReactStars from "react-rating-stars-component";
import { getDemoInfo, getDemos } from "../../services/demoService";

export default function UserDemoRatingTable() {
  const [isLoading, setIsLoading] = useState(true);
  const [curUser, setCurUser] = useState(null);
  const [consumedDemos, setConsumedDemos] = useState([]);
  const [userDemos, setUserDemos] = useState([]);

  // Fires on first render
  useEffect(() => {
    setIsLoading(true);

    const localcu = authService.getCurrentUser();
    setCurUser(localcu);

    async function getUsersDemoActivities() {
      const localActivities = await getUserActivitybyUserID(localcu.id);  
      
      // Find the users activities with action WATCH
      let watchActivities = localActivities.filter((a) => a.entityAction === "WATCH");
      const uniqWatches = [];
      const watchMap = new Map();
      watchActivities.map((a) => {
        if(!watchMap.has(a.entityId)){
          watchMap.set(a.entityId, true);
          uniqWatches.push(a);
        }
      });

      // Find the users activities with action SCHEDULE
      let schedActivities = localActivities.filter((a) => a.entityAction === "SCHEDULE");
      const uniqSched = [];
      const schedMap = new Map();
      schedActivities.map((s) => {
        if(!schedMap.has(s.entityId)){
          schedMap.set(s.entityId, true);
          uniqSched.push(s)
        }
      });
      
      // Find the users activities with action DTC Access
      let dtcActivities = localActivities.filter((a) => a.entityAction === "DTC Access");
      const uniqDTC = [];
      const DTCMap = new Map();
      dtcActivities.map((s) => {
        if(!DTCMap.has(s.entityId)){
          DTCMap.set(s.entityId, true);
          uniqDTC.push(s)
        }
      });
      
      // combine the two arrays of user activitiy records and set state
      let localconsumedDemos = uniqWatches.concat(uniqSched);
      localconsumedDemos = localconsumedDemos.concat(uniqDTC);
      setConsumedDemos(localconsumedDemos);
      //console.log(localconsumedDemos);
    }
    getUsersDemoActivities().catch((error) => httpService.handleError(error));

  }, []);

  /*
 */
  //fires when consumedDemos changes
  useEffect(() => {
    if(consumedDemos.length > 0) {
      let localDemos = [];

      async function getUsersDemos() {
        const allDemos = await getDemos();
        consumedDemos.map((cd) => {
          const matchDemo = allDemos.filter((d) => d.id === cd.entityId);
          localDemos.push(matchDemo[0]);
        })
        //console.log(localDemos);
        if(localDemos.length > 0){
          localDemos.map((d) => {
            const alreadyRated = d.demoRatings.filter((r) => r.userId === curUser.id);
            if(alreadyRated.length > 0 ) {
              d.alreadyRated = true;
              d.curUserRating = alreadyRated[0].rating;
            } else {
              d.alreadyRated = false;
            }
          });
          console.log(userDemos);
        }
        setUserDemos(localDemos);
      }

      getUsersDemos().catch((error) => httpService.handleError(error));
    }
    setIsLoading(false);
  }, [consumedDemos]);


  return (
    <>
      {isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
       ) : (
          <div className="container shadow border border-1 border-light rounded-3 pt-2 pb-2 mt-2 mb-2">
            <center>
              <p className="lead text-center">Your Demo History</p>
            </center>
            {userDemos.length > 0  ? (
              <>
              <div className="d-flex flex-row justify-content-start">
                <div className="col-6 ps-4"><strong>Demo Title</strong></div>
                <div className="col-2"><strong>Vendor</strong></div>
                <div className="col-2"><strong>Your Rating</strong></div>
                <div className="col-2"><strong>Avg Rating</strong></div>
              </div>

              <div className="d-flex flex-column justify-content-center m-3">
                {userDemos.map((d) => 
                  <div key={d.id} className="d-flex flex-row justify-content-start border border-1 border-primary-subtle rounded-3 m-2 p-2">
                    <div className="col-6 ">
                      {d.name}
                    </div>
                    <div className="col-2 ">
                      <em> {d.vendors[0].name} </em>
                    </div>
                    <div className="col-2 ">
                      {// determine if current user has already rated this demo
                       d.alreadyRated ? 
                        <center>
                          <RatingWrapper 
                            type="Demo"
                            id={d.id}
                            edit={false}
                            val={d.curUserRating}
                          />
                        </center>
                        :  
                        <center>
                          <RatingWrapper 
                            type="Demo"
                            id={d.id}
                          />
                        </center>
                      }
                    </div>
                    <div className="col-2 ">
                      <center>
                        <RatingWrapper 
                          type="Demo"
                          id={d.id}
                          edit={false}
                          val={d.avgRating}
                        />
                      </center>
                    </div>
                  </div>
                )}
              </div>
              </>
            ) : null}
          </div>
      )}
    </>
  );
}

/*
 const columns = [
    {
        path: "entityId",
        label: "Demo ID",
    },
    {
      path: "entityName",
      label: "Demo",
      // content: (demo) => {
      //   demo.description;
      // },
    },
    {
      path: "relatedEntityName",
      label: "Vendor",
      // content: (demo) => {
      //   demo.description;
      // },
    },
    {
      key: "rateIt",
      content: (demo) => <ReactStars 
          count={5}
          size={24}
          isHalf={true}
          onChange={ratingChanged}
          />
      ,
    },
  ];
*/

/*
  {
      key: "rateIt",
      content: (demo) => <Link to={`/demoRating/${demo.entityId}`}>
        <button className="btn btn-sm btn-primary">Rate It</button>
      </Link>,
    },
*/

/*
 {consumedDemos.length > 0 ? (
                <>
                  <p className="lead text-center">Please Rate These Demos</p>
                  <Table
                    columns={columns}
                    data={consumedDemos}
                    sortColumn={sortColumm}
                    onSort={onSort}
                    caption=""
                  />  
                </> 
          ) : (
            <>
              <p className="lead text-center">Your Demo Ratings</p>
            </>
          )}
*/