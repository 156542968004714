import React, { useState, useEffect } from "react";
//import { getFeedbackCount } from "../../services/feedbackService";
//import ButtonWL from "../common/buttonWithLink";
import ColorCard from "../common/card";
//import { getPocOrderCount, getPocOrderRequestCount, getPocOrderbyStatus } from "../../services/pocOrderService";
//import Reservations from "../reservations/reservations";
import PocUserOrders from "../loaners/pocUserOrders";
import authService from "../../services/auth.service";
import ButtonWL from "../common/buttonWithLink";
import { getDemos } from "../../services/demoService";
//import { getUserInfo } from "../../services/userService";
import httpService from "../../services/httpService";
import DemosTable from "../demos/demosTable";
import UserDemoRatingTable from "../users/userDemoRatingTable";
import { Link } from "react-router-dom";

function UserDashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [curUser, setCurUser] = useState(null);
  const [recentDemos, setRecentDemos] = useState([]);
  const [consumedDemos, setConsumedDemos] = useState([]);
  const [sortColumn, setSortColumn] = useState({ path: "name", order: "asc"});

  useEffect(() => {
    setIsLoading(true);

    const localcu = authService.getCurrentUser();
    console.log(localcu);
    setCurUser(localcu);

    async function getRecentDemos() {
      const localdemos = await getDemos();
      let recentdemos = localdemos.filter((d) => d.createdAt > localcu.lastLogin);
      console.log(recentdemos);
      setRecentDemos(recentdemos);
    }
    getRecentDemos().catch((error) => httpService.handleError(error));

    setIsLoading(false);
  }, []);

  const handleSort = (sortColumn) => {
    setSortColumn(sortColumn);
  }

  //This is the page that Admins work from
  return (
    <>
      {isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      ) : (
        <div className="container">
          <center>
            <p className="display-6 mt-2 mb-3">
              Welcome to the TD SYNNEX Solution Center Portal 
            </p>
          </center>

          <div className="d-flex d-flex-row">
            <div className="col">
              <ColorCard
                title="Our Demos"
                description="See full list of current Demos"
                link="/demos"
              />
            </div>
            <div className="col">
              <ColorCard
                title="Our Centers"
                description="See full list of Solution Centers"
                link="/centers"
                color="white"
                bgcolor="darkgoldenrod"
              />
            </div>
            <div className="col">
              <ColorCard
                title="PoC Gear"
                description="See full list of current reseller partners"
                link="/loaner"
                color="white"
                bgcolor="darkgreen"
              />
            </div>
          </div>
            {curUser ? (
              <div className="container ">
                <div className="d-flex d-flex-row mb-2 justify-content-center">

                <div className="col-6  shadow border border-1 border-light rounded-3 pt-2 pb-2 m-2">
                  <p className="lead text-center"> <strong> Your Favorite Topics </strong> </p>
                   {curUser?.utopics.length < 1 && 
                     <div className="text-danger">
                      <Link to="/profile">
                        <center><strong> Edit Your Profile to Select Your Favorite Topics </strong> </center>
                      </Link>
                     </div>
                   }
                  <div className="d-flex flex-wrap justify-content-center">
                    {curUser.utopics.map((t) =>
                      <div key={t.id} className="p-2">
                        <ButtonWL 
                          size="sm" 
                          variant="primary" 
                          value={t.title} 
                          link={`/categoryDetail/${t.id}`}
                        /> 
                      </div>
                     )}
                  </div>
                </div>
                <div className="col-6 shadow border border-1 border-light rounded-3 pt-2 pb-2 m-2 ">
                  <p className="lead text-center"><strong>  Your Favorite Vendors </strong> </p>
                   {curUser?.uvendors.length < 1 && 
                     <div className="text-danger">
                       <Link to="/profile">
                         <center><strong> Edit Your Profile to Select Your Favorite Vendors </strong> </center>
                       </Link>
                     </div>
                   }
                  <div className="d-flex flex-wrap justify-content-center">
                    {curUser.uvendors.map((v) =>
                      <div key={v.id} className="pe-2">
                        <ButtonWL 
                          size="sm" 
                          variant="primary" 
                          value={v.name} 
                          link={`/vendor/${v.id}`}
                        /> 
                      </div>
                     )}
                  </div>
                </div>
                </div>
              </div>
              ) : null}
          <div className="d-flex d-flex-row mb-2">
            <PocUserOrders />
          </div>
          {recentDemos.length > 0  ? (
            <div className="d-flex d-flex-row mb-2">
              <div className="container shadow border border-1 border-light rounded-3 pt-2 pb-2 mt-2">
                <center>
                  <p className="lead">Demos Created Since Your Last Login</p>
                </center>
                <DemosTable 
                  demos={recentDemos} 
                  caption="" 
                  sortColumn={sortColumn}
                  onSort={handleSort}
                />
              </div>
            </div>
          ) : null}
          
          <div className="d-flex d-flex-row mb-2">
            <UserDemoRatingTable />
          </div>
          
        </div>
      )}
    </>
  );
}

export default UserDashboard;

/*
              <div className="container shadow border border-1 border-light rounded-3 pt-2 pb-2 mt-2">
*/
/*
          <Reservations showBackButton={false} /> 
*/

 /*
    async function getOpenOrderCount() {
      const localOpenOrderCount = await getPocOrderCount();
      console.log("Open Order count is: " + localOpenOrderCount);
      setOrderCnt(localOpenOrderCount);
    }
    getOpenOrderCount().catch((error) => httpService.handleError(error));

    async function getOrderRequests() {
      const localOrderRequests = await getPocOrderbyStatus("New");
      console.log(localOrderRequests);
      setRequests(localOrderRequests);
    }
    getOrderRequests().catch((error) => httpService.handleError(error));

    async function getOrderRequestsCount() {
      const localRCount = await getPocOrderRequestCount();
      console.log("Requests count is: " + localRCount);
      setRequestCnt(localRCount);
    }
    getOrderRequestsCount().catch((error) => httpService.handleError(error));
    */

/*
            <div className="col-4 text-center">
              <ButtonWL
                size="sm"
                variant="secondary"
                value="Open Orders"
                link="/pocOrders"
                count={orderCnt}
              />
            </div>

            <div className="col-4 text-center">
              <ButtonWL
                size="sm"
                variant="secondary"
                value="New Order Requests"
                link="/pocRequests"
                count={requestCnt}
              />
            </div>

            <PocRequestsTable 
              pocOrders={requests}
              sortColumn={sortColumn}
              caption="Current PoC Requests"
            />
<div className="col text-center">
              <ButtonWL
                size="sm"
                variant="secondary"
                value="New User Requests"
                link="/signups"
                count={signupCnt}
              />
            </div>
            <div className="col">
              <ColorCard
                title="Open Orders"
                description="See list of current PoC Orders"
                link="/pocOrders"
                color="white"
                bgcolor="darkcyan"
              />
            </div>
*/

/*
<div className="row">
            <div className="col">
              <ColorCard
                title="Topics"
                description="See full list of Topics"
                link="/categories"
                color="black"
                bgcolor="burlywood"
              />
            </div>
            <div className="col">
              <ColorCard
                title="ServiceSolv"
                description="See the ServiceSolv Page"
                link="/servicesolv"
                color="white"
                bgcolor="darkcyan"
              />
            </div>
            <div className="col">
              <ColorCard
                title="Activities"
                description="See User Activities"
                link="/userActivities"
                color="black"
                bgcolor="burlywood"
              />
            </div>
          </div>
*/

/*

          <div className="row">
            <ColorCard
              title="Feedback"
              description="See list of feedback items."
              link="/feedbacks"
            />
            <ColorCard
              title="Reservations"
              description="See the list of Reservations in the System"
              link="/reservations"
            />
            <ColorCard
              title="Sign Ups"
              description="See list of signup requests that are open"
              link="/signups"
            />

        <Feedbacks />
        <Reservations />
        <NewUserRequest />
*/
