import React from "react";

const Input = ({
  fieldName,
  register,
  label,
  errors,
  placeholder,
  className = "form-floating",
  type = "text",
  autoComplete = "off",
}) => {
  return (
    <div className={className}>
      <input
        {...register(fieldName)}
        id={fieldName}
        placeholder={placeholder}
        className="form-control m-2"
        type={type}
        autoComplete={autoComplete}
      />
      <label htmlFor={fieldName}>{label}</label>
      {errors[fieldName] && (
        <div className="alert alert-danger m-2">
          {errors[fieldName].message}
        </div>
      )}
    </div>
  );
};

export default Input;
