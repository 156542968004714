import http from "./httpService";
import authHeader from "./auth-header";

export async function getEnum() {
  const { data: feedbackTypes } = await http.get("/api/v1/feedbacks/getEnum", {
    headers: authHeader(),
  });
  return feedbackTypes;
}

export async function getFeedbackCount() {
  const { data: count } = await http.get("/api/v1/feedbacks/getCount", {
    headers: authHeader(),
  });
  return count;
}

export async function getFeedbacks() {
  const { data: feedbacks } = await http.get("/api/v1/feedbacks/", {
    headers: authHeader(),
  });
  return feedbacks;
}

export async function getFeedbackbyID(feedbackId) {
  const { data: feedbackInfo } = await http.get(
    `/api/v1/feedbacks/${feedbackId}`,
    {
      headers: authHeader(),
    }
  );
  return feedbackInfo;
}

export async function updateFeedback(feedbackid, feedback) {
  const response = await http.put(`/api/v1/feedbacks/${feedbackid}`, feedback, {
    headers: authHeader(),
  });
  return response;
}

export async function createFeedback(feedback) {
  const response = await http.post("/api/v1/feedbacks/", feedback, {
    headers: authHeader(),
  });
  return response;
}

export async function removeFeedback(feedbackId) {
  const response = await http.delete(`/api/v1/feedbacks/${feedbackId}`, {
    headers: authHeader(),
  });
  return response;
}
