import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import BackButton from "../common/backButtion";
import { isDebug, logData } from "../common/helpers";
import { getPocItembyID } from "../../services/pocItemService";
import authService from "../../services/auth.service";
import rbacService from "../../services/rbacService";
import { Link } from "react-router-dom";

export default function PocItem() {
  const [pocItem, setPocItem] = useState();
  const [rbac, setRBAC] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  let { pocItemId } = useParams();

  useEffect(() => {
    if(pocItem === null || typeof pocItem === `undefined`){
      setIsLoading(true);
      const currentUser = authService.getCurrentUser();
      const localrbac = new rbacService(currentUser);
      setRBAC(localrbac);

      const getPocKitData = async() => {
        const localPocItem = await getPocItembyID(pocItemId);
        setPocItem(localPocItem);
      }
      getPocKitData().catch(console.error);

      setIsLoading(false);
    }
  }, []);

  return (
    <React.Fragment key={9821}>
      {isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      ) : pocItem ? (
        <div className="container pt-2 mt-2 shadow border border-2 border-light rounded-3 mt-4">
          <div className="row justify-content-lg-center pb-2">
            <div className="col-2 mt-2">
              <div className="d-flex flex-column mb-2 align-items-center">
                <div className="p-2">
                  <BackButton />
                  { rbac.hasElevatedPrivs() ? (
                      <Link to={`/pocItemForm/${pocItem.id}`}>
                        <button className="btn btn-primary btn-sm ms-2">Edit</button>
                      </Link>
                  ) : null }
                </div>
              </div>
            </div>
            <div className="col-10 text-align-center">
              <div className="d-flex flex-row justify-content-start">
                <p className="display-6">{pocItem.name}</p>
              </div>
              <div className="d-flex flex-row justify-content-start mt-2">
                <div className="col-2 fs-6 fst-italic">Description</div>
                <div className="col-10 fs-6 ">{pocItem.description}</div>
              </div>
              <div className="d-flex flex-row justify-content-start mt-2">
                <div className="col-2 fs-6 fst-italic">Availability</div>
                <div className="col-10 fs-6 ">{pocItem.status}</div>
              </div>
              <div className="d-flex flex-row justify-content-start mt-2">
                <div className="col-2 fs-6 fst-italic">Mfg Part Number:</div>
                <div className="col-10 fs-6 ">{pocItem.mfgPartNum}</div>
              </div>
              <div className="d-flex flex-row justify-content-start mt-2">
                <div className="col-2 fs-6 fst-italic">Serial Number:</div>
                <div className="col-10 fs-6 ">{pocItem.serialNum}</div>
              </div>
              <div className="d-flex flex-row justify-content-start mt-2">
                <div className="col-2 fs-6 fst-italic">SAP MAT ID:</div>
                <div className="col-10 fs-6 ">{pocItem.sapMatId}</div>
              </div>
              <div className="d-flex flex-row justify-content-start mt-2">
                <div className="col-2 fs-6 fst-italic">Vendor (ID):</div>
                <div className="col-10 fs-6 ">{pocItem.vendor.name} ( {pocItem.vendorId} )</div>
              </div>
              <div className="d-flex flex-row justify-content-start mt-2">
                <div className="col-2 fs-6 fst-italic">Kit (ID):</div>
                <div className="col-10 fs-6 ">
                  <Link to={`/pocKit/${pocItem.pocKitId}`}>
                    {pocItem.pocKit.name} 
                  </Link>
                  ( {pocItem.pocKitId } )
                </div>
              </div>
            </div>
          </div>
      </div>
      ) : ( 
        <h3>PoC Item Not found</h3>
        )}
    </React.Fragment>
  );
}

/*
  if (pocItem === null || typeof pocItem === `undefined`) {
    return (
      <div>
        <h3>Item Not Found</h3>
      </div>
    );
  } else {
    if (isDebug) logData(pocItem);
    return (

    );
  }


          {pocKit?.logoUrl ? (
            <img
              style={imgStyleObj}
              src={config.imgBaseURL + pocKit.logoUrl}
              alt=""
            />
          ) : null}
*/
