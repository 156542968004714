import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { isDebug, logData } from "../common/helpers";
import { format } from "date-fns";
import { getReservationInfo } from "../../services/reservationService";
import httpService from "../../services/httpService";
import BackButton from "../common/backButtion";
import ButtonWL from "./../common/buttonWithLink";
import authService from "../../services/auth.service";

export default function Reservation() {
  const [reservation, setReservation] = useState(null);
  const [c1, setC1] = useState(null);
  const [c2, setC2] = useState(null);
  const [c3, setC3] = useState(null);
  let { reservationId } = useParams();

  const currentUser = authService.getCurrentUser();

  useEffect(() => {
    if (reservation === null) {
      async function getReservationData() {
        const reservationInfo = await getReservationInfo(reservationId);
        setReservation(reservationInfo);
        console.log(reservationInfo);
      }

      getReservationData().catch((error) => httpService.handleError(error));
      console.log("Reservation ID: " + reservationId);
    } else {
      if (reservation?.choice1 !== null) {
        const date1 = new Date(reservation?.choice1);
        const d1String = format(date1, "Pp");
        setC1(d1String);
      } else {
        setC1("Not Provided");
      }

      if (reservation?.choice2 !== null) {
        const date2 = new Date(reservation?.choice2);
        const d2String = format(date2, "Pp");
        setC2(d2String);
      } else {
        setC2("Not Provided");
      }

      if (reservation?.choice3 !== null) {
        const date3 = new Date(reservation?.choice3);
        const d3String = format(date3, "Pp");
        setC3(d3String);
      } else {
        setC3("Not Provided");
      }
    }
  }, [reservation]);

  if (reservation === null || typeof reservation === `undefined`) {
    return (
      <div className="container shadow border border-1 border-light rounded-3 pt-2 mt-2">
        <center>
          <h3 className="display-5">Reservation Not Found</h3>
        </center>
      </div>
    );
  } else {
    return (
      <div className="container shadow border border-1 border-light rounded-3 pt-2 mt-2">
        <div className="row">
          <div className="col-2 mt-2">
            <BackButton />
          </div>
          <div className="col-10"><span className="fs-1">Reservation Info</span> 
            <p className="fs-4 mt-2">for {"  "}
              <i>
              {reservation?.demo.name}
              </i> 
              </p>
          
          </div>
        </div>
        <div className="row justify-content-lg-center align-items-center me-2">
          <div className="col">
            <p className="fs-4 text-center mt-2">Requested by:</p>
            <div className="row">
              <div className="col ms-2 text-end bg-secondary text-light">
                Name:
              </div>
              <div className="col fs-6 text-center border border-secondary">
                {reservation?.user.fullName}
              </div>
            </div>
            <div className="row">
              <div className="col ms-2 text-end bg-secondary text-light">
                Email:
              </div>
              <div className="col fs-6 text-center border border-secondary">
                <a
                  href={`mailto:${reservation.user.email}`}
                  className="text-decoration-none"
                >
                  {reservation?.user.email}
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col text-end ms-2 bg-secondary text-light">
                Work Phone:
              </div>
              <div className="col fs-6 text-center border border-secondary">
                {reservation?.user.workPhone}
              </div>
            </div>
          </div>
          <div className="col">
            <p className="fs-4 text-center mt-2">Reseller Partner :</p>
            <div className="row">
              <div className="col text-end ms-2 bg-secondary text-light">
                Name:
              </div>
              <div className="col fs-6 text-center border border-secondary">
                {reservation?.reseller.name}
              </div>
            </div>
            <div className="row">
              <div className="col text-end ms-2 bg-secondary text-light">
                Acct #:
              </div>
              <div className="col fs-6 text-center border border-secondary">
                {reservation?.acctNum}
              </div>
            </div>
            <div className="row">
              <div className="col text-end ms-2 bg-secondary text-light">
                Jira Ticket Number:
              </div>
              <div className="col fs-6 text-center border border-secondary">
                <a
                  href={`https://servicedesk.synnex.com/browse/${reservation.ticketNum}`}
                  className="text-decoration-none"
                  target="_blank"
                >
                  {reservation?.ticketNum}
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col text-end ms-2 bg-secondary text-light">
                Work Phone:
              </div>
              <div className="col fs-6 text-center border border-secondary">
                {reservation?.reseller.workPhone}
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-lg-center mt-3 align-items-center">
          <div className="col-4 text-center fs-4">Reason for Demo Request:</div>
          <div className="col-8 fs-6 ">
            ({reservation?.type}) {reservation?.description}{" "}
          </div>
        </div>
        <div className="row justify-content-lg-center mt-3 mb-2">
          <div className="col-12 text-center fs-4">
            Customer's Choices of Start Dates and Times:{" "}
          </div>
        </div>
        <div className="row justify-content-lg-center">
          <div className="col">
            <p className="fs-6 text-center bg-primary text-light rounded rounded-3">
              1st Choice
            </p>
            <p className="fw-light text-center">{c1}</p>
          </div>
          <div className="col">
            <p className="fs-6 text-center bg-warning rounded rounded-5">
              2nd Choice
            </p>
            <p className="fw-light text-center">{c2}</p>
          </div>
          <div className="col">
            <p className="fs-6 text-center bg-danger text-light rounded rounded-3">
              3rd Choice
            </p>
            <p className="fw-light text-center">{c3}</p>
          </div>
        </div>
        <div className="row justify-content-lg-center">
          <div className="col-12 mb-2 text-center">
            {currentUser.roleId <= 2 ? (
              <ButtonWL
                size="sm"
                variant="primary"
                value="Approve"
                link={`/reservationApproval/${reservationId}`}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

/*
            <p className="fw-light text-center">{reservation?.choice1}</p>
*/
