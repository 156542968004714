import React from "react";
import { HashLink } from "react-router-hash-link";
import ReactPlayer from "react-player";
import ResponsivePlayer from "../common/responsivePlayer";

export default function RenewSolvFeature() {
  return (
    <>
      <hr className="featurette-divider" />
      <div className="container">
        <div className="row featurette align-items-center">
          <div className="col-md-7">
            <h2 className="ss-featurette-heading fw-normal lh-1">RenewSolv</h2>

            <p className="lead">
              Another way TD SYNNEX can help? Of course. Our RenewSolv portal is
              a single pane of glass for all your services and renewals, with
              automated quoting functionality. It's completely agnostic, for
              both hardware and software warranties/services
            </p>
            <p className="lead">
              Be sure to check out the videos in the 'Demos' area. Look for
              Topic or Vendor of RenewSolv
            </p>
          </div>
          <div className="col-md-5">
            <img src="RenewsolvUI.png" className="d-block w-100" />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <center>
              <p className="lead">Contact Us:</p>
            </center>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <center>
              <a href="mailto:renewsolv@tdsynnex.com">
                {" "}
                <p className="lead">RenewSolv@tdsynnex.com </p>
              </a>
            </center>
          </div>
        </div>

        <div className="row mt-2 align-items-center ">
          <div className="col-12">
            <center>
              <ResponsivePlayer url="https://play.vidyard.com/GbmmMCLbCAA8dxSCco3bBP.jpg" />
            </center>
          </div>
        </div>
      </div>
    </>
  );
}
/*

            <script
              type="text/javascript"
              async
              src="https://play.vidyard.com/embed/v4.js"
            ></script>
            <img
              style={{ width: "100%", margin: "auto", display: "block" }}
              className="vidyard-player-embed"
              src="https://play.vidyard.com/GbmmMCLbCAA8dxSCco3bBP.jpg"
              data-uuid="GbmmMCLbCAA8dxSCco3bBP"
              data-v="4"
              data-type="inline"
            />

              <ReactPlayer
                url="https://play.vidyard.com/GbmmMCLbCAA8dxSCco3bBP.jpg"
                controls="true"
              />
*/
