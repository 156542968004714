import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../common/table";
import authService from "../../services/auth.service";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import rbacService from "../../services/rbacService";

class PocKitsTable extends Component {
  state = {
    currentUser: {},
    rbac: null,
    pocKits: [],
    showAddButton: false,
  };

  async componentDidMount() {
    const loggedInUser = authService.getCurrentUser();
    const localRBAC = new rbacService(loggedInUser);
    this.setState({ currentUser: loggedInUser, rbac: localRBAC });
    //if (loggedInUser.roleId <= 2) {
    if (localRBAC.isAdminOrLoanerAdmin()) {
      // logged in user is either admin or loaner manager
      this.columns.push(...this.adminColumns);
      this.setState({ showAddButton: true });
    }
  }

  columns = [
    {
      path: "name",
      label: "Name",
      content: (pocKit) => (
        <Link to={`/vendor/${pocKit.vendor.id}`}>{pocKit.vendor.name}</Link>
      ),
    },
    {
      path: "vendor.name",
      label: "vendor",
      content: (pocKit) => (
        <Link to={`/pocKit/${pocKit.id}`}>{pocKit.name}</Link>
      ),
    },
    { path: "status", label: "Availability" },
    { path: "description", label: "Description" },
    /*
    {
      key: "like",
      content: (pocKit) => (
        <Like liked={pocKit.liked} onClick={() => this.props.onLike(pocKit)} />
      ),
    },
    */
  ];

  //These additional columns are only present when user
  //is an instructor or admin
  adminColumns = [
    {
      key: "edit",
      content: (pocKit) => (
        <Link to={`/pockitform/${pocKit.id}`}>
          <FontAwesomeIcon icon={faPenToSquare} />
        </Link>
      ),
    },
    {
      key: "delete",
      content: (demo) => (
        <button
          onClick={() => this.props.onDelete(demo)}
          className="btn btn-danger btn-sm"
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </button>
      ),
    },
  ];

  render() {
    const { pocKits, onSort, onDelete, sortColumn, caption } = this.props;

    console.log(pocKits);

    return (
      <>
        <Table
          columns={this.columns}
          data={pocKits}
          sortColumn={sortColumn}
          onDelete={onDelete}
          onSort={onSort}
          caption={caption}
        />
      </>
    );
  }
}

export default PocKitsTable;
