import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import VendorsTable from "./vendorsTable";
import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { getVendors, removeVendor } from "../../services/vendorService";
import authService from "../../services/auth.service";
import BackButton from "../common/backButtion";

class Vendors extends Component {
  constructor(props) {
    super(props);
    this.vendorRef = React.createRef();
  }
  state = {
    vendors: [],
    isLoading: false,
    selectedVendor: {},
    currentPage: 1,
    pageSize: 10,
    sortColumn: { path: "name", order: "asc" },
    currentUser: null,
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    const localvendors = await getVendors();
    const cu = authService.getCurrentUser();
    this.setState({ currentUser: cu });
    this.setState({ vendors: localvendors });
    this.setState({ isLoading: false });
  }

  //
  handleVendorSelect = (vendor) => {
    if(vendor !== undefined) {
      this.setState({ selectedVendor: vendor, currentPage: 1 });
    }
  };

  handleDelete = async (vendor) => {
    const deleted = await removeVendor(vendor.id);
    if (deleted.status === 200) {
      const vendors_tmp = this.state.vendors.filter((i) => i.id !== vendor.id);
      this.setState({ vendors: vendors_tmp });
    }
  };

  handleLike = (vendor) => {
    const vendors = [...this.state.vendors];
    const index = vendors.indexOf(vendor);
    vendors[index] = { ...vendors[index] };
    vendors[index].liked = !vendors[index].liked;
    this.setState({ vendors });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const { selectedVendor, pageSize, currentPage, sortColumn, vendors } =
      this.state;

    const vendorfiltered =
      selectedVendor && selectedVendor.id
        ? vendors.filter((v) => v.id === selectedVendor.id)
        : vendors;

    const sorted = _.orderBy(
      vendorfiltered,
      [sortColumn.path],
      [sortColumn.order]
    );

    const vendors_tmp = paginate(sorted, currentPage, pageSize);

    return { totalCount: vendorfiltered.length, data: vendors_tmp };
  };

  changePageSize = (e) => {
    this.setState({ pageSize: e.value, currentPage: 1 });
  };

  render() {
    const { length: count } = this.state.vendors;
    const { pageSize, currentPage, sortColumn } = this.state;

    if (this.state.isLoading)
      return (
        <div className="text-center p-3">
          {" "}
          <span className="spinner-border spinner-border-lg align-center"></span>{" "}
        </div>
      );

    if (count === 0) return <p>There are no vendors in the database.</p>;

    const selectOptions = [
      { value: 5, label: 5 },
      { value: 10, label: 10 },
      { value: 15, label: 15 },
      { value: 20, label: 20 },
      { value: 25, label: 25 },
      { value: 50, label: 50 },
    ];

    const { totalCount, data: vendors } = this.getPagedData();
    const tableCaption = "Showing " + totalCount + " vendors in the database.";

    return (
      <div className="container shadow border border-1 border-light rounded-3 pt-2 pb-2 mt-2">
        <div className="d-flex flex-row">
          <div className="col-2 ">
            <BackButton />
            <Link to="/vendorForm/new">
              <button className="btn btn-primary btn-sm ms-2">
                Add Vendor
              </button>
            </Link>
          </div>
          <div className="col-10">
            <div className="typeahead form-floating ">
              <div className="d-flex justify-content-start">
                <button
                  className="btn btn-sm btn-secondary me-1"
                  onClick={() => {
                    this.vendorRef.current.clear();
                    this.setState({ selectedVendor: {} });
                  }}
                >
                  Clear
                </button>
                <Typeahead
                  id="vendorFilter"
                  ref={this.vendorRef}
                  labelKey={(option) => `${option.name}`}
                  placeholder="Filter by Vendor"
                  minLength={2}
                  size="sm"
                  options={this.state.vendors}
                  onChange={(selected) => {
                    this.handleVendorSelect(selected[0]);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <h2 className="display-6 mt-3">Vendor List</h2>
        <div className="row">
          <div className="d-inline-flex pe-2">
            <VendorsTable
              vendors={vendors}
              sortColumn={sortColumn}
              onLike={this.handleLike}
              onDelete={this.handleDelete}
              onSort={this.handleSort}
              caption={tableCaption}
            />
          </div>
          <div className="d-inline-flex pe-2">
            <Pagination
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
            <Select
              className="ms-2"
              id="pgSize"
              options={selectOptions}
              onChange={this.changePageSize.bind(this)}
              placeholder="Number of Items / Page"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Vendors;
