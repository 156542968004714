import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

import Input from "../common/input";
import http from "../../services/httpService";
import authService from "../../services/auth.service";
import { updateUser } from "../../services/userService";
import { isDebug, logData } from "../common/helpers";

export default function ChangePassword() {
  const [successful, setSuccessful] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const navigate = useNavigate();

  const currentUser = authService.getCurrentUser();

  const schema = yup.object().shape({
    password: yup.string(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Password must match"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const handleUpdate = (data) => {
    if (isDebug) {
      logData("Handling Update...");
      logData(data);
      logData("currentUser id " + currentUser.id);
    }

    if (data.password) {
      const newData = {
        password: data.password,
        id: currentUser.id.toString(),
      };
      const pushUpdate = async () => {
        const updateResponse = await updateUser(currentUser.id, newData);
        if (updateResponse.status >= 200 && updateResponse.status < 300) {
          setSuccessful(true);
        }
      };
      pushUpdate().catch((error) => http.handleError(error));
    }
  };

  /* This useEffect is only when successful submission */
  useEffect(() => {
    if (successful) {
      //navigate("/users");
      navigate(-1);
    }
  }, [successful]);

  return (
    <React.Fragment>
      <div className="card mt-3">
        <center>
          Changing Password for {currentUser.fullName}
          <form onSubmit={handleSubmit(handleUpdate)} className="p-2">
            <div className="col-md">
              <Input
                register={register}
                fieldName="password"
                label="New Password:"
                errors={errors}
                type="password"
              />
              <Input
                register={register}
                fieldName="confirmPassword"
                label="Confirm New Password"
                errors={errors}
                type="password"
              />
            </div>
            <button onClick={handleUpdate} className="btn-sm btn-primary">
              Change Password
            </button>
            <button
              className="btn-sm btn-secondary ms-2 mb-2"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </form>
        </center>
      </div>
    </React.Fragment>
  );
}
