import React, { Component } from "react";
import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import Table from "../common/table";
import Like from "../common/like";
import { isDebug, logData } from "../common/helpers";
import authService from "../../services/auth.service";
import rbacService from "../../services/rbacService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faTrashCan,
} from "@fortawesome/free-regular-svg-icons";
import RatingWrapper from "../common/ratingWrapper";

class DemosTable extends Component {
  state = {
    currentUser: {},
    demos: [],
    instructors: [],
    vendors: [],
    showAddButton: false,
  };

  run = 0;

  async componentDidMount() {
    const loggedInUser = authService.getCurrentUser();
    const rbac = new rbacService(loggedInUser);
    this.setState({ currentUser: loggedInUser });
    if (rbac.isAdminOrInstructor() && this.run === 0) {
      // logged in user is either admin or instructor
      this.run = 1;
      this.columns.push(...this.adminColumns);
      this.setState({ showAddButton: true });
    }
  }

  columns = [
    // {
    //   key: "schedule",
    //   content: (demo) => (
    //     <Link
    //       to={`/reservation/new`}
    //       state={{ demoId: demo.id, demoName: demo.name }}
    //     >
    //       <FontAwesomeIcon icon={faCalendarPlus} size="1x" />
    //     </Link>
    //   ),
    // },
    {
      path: "id",
      label: "ID",
    },
    {
      path: "name",
      label: "Name",
      content: (demo) => <Link to={`/demoDetail/${demo.id}`}>{demo.name}</Link>,
    },
    {
      path: "description",
      label: "Description",
      // content: (demo) => {
      //   demo.description;
      // },
    },
    {
      path: "type",
      label: "Type",
    },
    {
      path: "categories.title",
      label: "Topics",
      content: (demo) =>
         (demo.categories.length > 0) && (
          demo?.categories.map((category) => (
            <>
              <Badge key={category.id} pill bg="secondary">
                {category.title}
              </Badge>
              <br />
            </>
         ))),
    },
    {
      path: "avgRating",
      label: "Avg Rating",
      content: (demo) => (
            demo.avgRating ? demo.avgRating : "None"
      ),
    },
    {
      path: "vendors.name",
      label: "Vendor",
      content: (demo) => 
         (demo.vendors.length > 0) && (
          demo?.vendors.map((vendor) => (
            <>
              <Link to={`/vendor/${vendor.id}`}>
                {vendor.name}
              </Link>
               <br />
            </>
         ))),
    },
    {
      path: "instructors.name",
      label: "Instructor",
      content: (demo) => (
         (demo.instructors.length > 0) && (
          demo.instructors.map((instructor) => 
            <>
               <Link to={`/instructorProfile/${instructor.id}`}>
                 {instructor.user.fullName}
               </Link>
               <br />
            </>
          ))
      ),
    },
    /*
    {
      key: "like",
      content: (demo) => (
        <Like liked={demo.liked} onClick={() => this.props.onLike(demo)} />
      ),
    },
    */
  ];

  //These additional columns are only present when user
  //is an instructor or admin
  adminColumns = [
    {
      key: "edit",
      content: (demo) => (
        <Link to={`/demoForm/${demo.id}`}>
          <FontAwesomeIcon icon={faPenToSquare} />
        </Link>
      ),
    },
    {
      key: "delete",
      content: (demo) => (
        <button
          onClick={() => this.props.onDelete(demo)}
          className="btn btn-danger btn-sm"
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </button>
      ),
    },
  ];

  render() {
    const { demos, onSort, sortColumn, caption } = this.props;

   // if (isDebug) console.log(demos);

    return (
      <>
        <Table
          columns={this.columns}
          data={demos}
          sortColumn={sortColumn}
          onSort={onSort}
          caption={caption}
        />
      </>
    );
  }
}

export default DemosTable;


 /*
    {
      path: "vendors.name",
      label: "Vendor",
      content: (demo) => (
        <Link to={`/vendor/${demo?.vendors[0].id}`}>
          {demo?.vendors[0].name}
        </Link>
      ),
    },
    */