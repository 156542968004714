import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../common/table";
import Like from "../common/like";
import { isDebug, logData } from "../common/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";

class UsersTable extends Component {
  columns = [
    {
      path: "fullName",
      label: "Full Name",
      content: (user) => (
        <Link to={`/userDetail/${user.id}`}>{user.fullName}</Link>
      ),
    },
    {
      path: "userName",
      label: "User Name",
    },
    {
      path: "email",
      label: "Email",
      content: (user) => (
        <a href={`mailto:${user.email}`} className="text-decoration-none">
          {user.email}
        </a>
      ),
    },
    {
      path: "reseller.name",
      label: "Company",
      content: (user) => (
        <Link to={`/resellerDetail/${user.reseller.id}`}>
          {user.reseller.name}
        </Link>
      ),
    },
    {
      path: "role.name",
      label: "Role",
    },
    {
      key: "edit",
      content: (user) => (
        <Link to={`/user/${user.id}`}>
          <FontAwesomeIcon icon={faPenToSquare} />
        </Link>
      ),
    },
    /*
    {
      key: "like",
      content: (user) => (
        <Like liked={user.liked} onClick={() => this.props.onLike(user)} />
      ),
    },
    */
    {
      key: "delete",
      content: (user) => (
        <button
          onClick={() => this.props.onDelete(user)}
          className="btn btn-danger btn-sm"
        >
          Delete
        </button>
      ),
    },
  ];

  render() {
    const { users, onSort, sortColumn, caption } = this.props;

    if (isDebug) logData(users);

    return (
      <>
        <Table
          columns={this.columns}
          data={users}
          sortColumn={sortColumn}
          onSort={onSort}
          caption={caption}
        />
      </>
    );
  }
}

export default UsersTable;
