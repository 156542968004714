import React from "react";
import { Link } from "react-router-dom";

function ButtonWL({ size, variant, value, link, count = 0 }) {
  if (count === 0) {
    return (
      <div className="">
        <Link to={link} className="">
          <button className={`btn btn-${variant} btn-${size}`}>{value}</button>
        </Link>
      </div>
    );
  } else {
    return (
      <div className="">
        <Link to={link} className="">
          <button
            className={`btn btn-${variant} btn-${size} position-relative`}
          >
            {value}
            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
              {count}
              <span className="visually-hidden">count of {value}</span>
            </span>
          </button>
        </Link>
      </div>
    );
  }
}

export default ButtonWL;
