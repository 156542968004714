import React, { useState } from "react";
import _ from "lodash";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Table from "../common/table";
import Select from "react-select";
import ExportExcel from "../common/excelExport";
import { format } from "date-fns";

function DemoViewReport(props) {
    const [count, setCount] = useState(5);
    const [demos, setDemos] = useState(null);
    const [exportData, setExportData] = useState(null);
    const [dateString, setDateString] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

   // if(props.demos === null) return null;

    useEffect(() => {
        setIsLoading(true);
        if(props.demos) {
          let data =[];
          //this sort ascending by default
          const today = format(new Date(), "ddMMMyyyy");
          setDateString(today);
          const sortedDemos = _.orderBy(props.demos, ['views'], ['desc'] );
          const topList = _.slice(sortedDemos,0,count);
          console.log(topList);
          console.log("num of sorted demos = " + sortedDemos.length);
          console.log("props.demos");
          console.log(props.demos);
          if(sortedDemos.length > 0){
            console.log("sorted Demos");
            console.log(sortedDemos);
            sortedDemos.map((demo) => data.push({Name: demo.name, Vendor: demo.vendors[0].name, Views: demo.views }));
            setExportData(data);
          }
          setDemos(topList);
        }
        setIsLoading(false);
    }, [props.demos, count]);

    const topListCount = [
        { value: 5, label: 5 },
        { value: 10, label: 10 },
        { value: 15, label: 15 },
        { value: 20, label: 20 }
    ];

    const changeCount = (e) => {
        setCount(e.value);
    };

    const columns = [
       {
        path: "name",
        label: "Demo",
        content: (demo) => <Link to={`/demoDetail/${demo.id}`}>{demo.name}</Link>,
       },
       {
        path: "vendors",
        label: "Vendor",
        content: (demo) => <Link to={`/vendor/${demo.vendors[0].id}`}>{demo.vendors[0].name}</Link>,
       },
       {
        path: "views",
        label: "Views",
       },
    ];

    return (
        <>
         {isLoading ? (
            <div className="text-center p-3">
              <span className="spinner-border spinner-border-lg align-center"></span>
            </div>
         ) : (
            <div className="report-tile">
              <center>
                 <p className="lead">Top {count} Most Viewed Demos </p>
                 <div className="d-inline-flex align-items-center">

                 <div style={{maxWidth: "250px"}} >
                 <Select
                   id="topCount"
                   options={topListCount}
                   onChange={changeCount}
                   placeholder="How many?"
                 />
                </div>
                <div className="d-inline-flex ps-2">
                    <ExportExcel 
                      excelData={exportData} 
                      fileName={`Demo-Views-Report-${dateString}`} />
                </div>
                 </div>
              </center>
            <div>
               {demos ? (
                 <Table columns={columns} data={demos} sortColumn={"Views"} />
               ) : null } 
            </div>
            </div>
         )}
        </>
    );
}

export default DemoViewReport;

/*

 <div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Views</th>
                        </tr>
                    </thead>
                    <tbody>
                        {demos ? (
                            demos.map((demo) => {
                                <tr>
                                    <td>
                                      <Link to={`/demoDetail/${demo.id}`}>{demo.name}</Link>
                                    </td>
                                    <td>
                                        {demo.views}
                                    </td>
                                </tr>
                            })
                        ) : (
                            <tr> <td>NO Data</td></tr>
                        )}
                    </tbody>
                </table>
            </div>
*/