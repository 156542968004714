import http from "./httpService";
import authHeader from "./auth-header";

export async function getCategories() {
  const { data: categories } = await http.get("/api/v1/categories", {
    headers: authHeader(),
  });
  return categories;
}

export async function getCategorybyID(categoryId) {
  const { data: categoryInfo } = await http.get(
    `/api/v1/categories/${categoryId}`,
    {
      headers: authHeader(),
    }
  );
  return categoryInfo;
}

export async function createCategory(category) {
  const response = await http.post("/api/v1/categories/", category, {
    headers: authHeader(),
  });
  return response;
}

export async function updateCategory(categoryId, category) {
  const response = await http.put(
    `/api/v1/categories/${categoryId}`,
    category,
    {
      headers: authHeader(),
    }
  );
  return response;
}

export async function getCategoryByTitle(title) {
  const { data: topic } = await http.get(
    "/api/v1/categories/getByName/" + title,
    {
      headers: authHeader(),
    }
  );
  return topic;
}

export async function removeCategory(categoryId) {
  const response = await http.delete(`/api/v1/categories/${categoryId}`, {
    headers: authHeader(),
  });
  return response;
}
