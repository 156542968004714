import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../common/table";

class VendorsDemoTable extends Component {
  state = {
    demos: [],
    currentPage: 1,
    pageSize: 4,
    sortColumn: { path: "name", order: "asc" },
  };

  componentDidMount() {
    const { demos } = this.props;
    this.setState({ demos });
  }

  columns = [
    {
      path: "name",
      label: "Name",
      content: (demo) => <Link to={`/demoDetail/${demo.id}`}>{demo.name}</Link>,
    },
    { path: "description", label: "Description" },
  ];

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  render() {
    const { demos } = this.props;

    if (demos.length === 0) {
      return;
    } else {
      return (
        <Table
          columns={this.columns}
          data={demos}
          sortColumn={this.state.sortColumn}
          onSort={this.handleSort}
        />
      );
    }
  }
}

export default VendorsDemoTable;
