import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";
import format from "date-fns/format";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import http from "../../services/httpService";
import authService from "../../services/auth.service";
import { getDemoInfo } from "../../services/demoService";
import {
  getReservationInfo,
  updateReservation,
  createReservation,
} from "../../services/reservationService";
import { getResellerInfo } from "../../services/resellerService";
import { logData, isDebug } from "../common/helpers";
import DemoDetail from "./../demos/demoDetail";

export default function ReservationApprovalForm() {
  const [startdate1, setStartDate1] = useState(null);
  const [startdate2, setStartDate2] = useState(null);
  const [startdate3, setStartDate3] = useState(null);
  const [fetchedReservation, setFetchedReservation] = useState(null);
  const [fetchedDemo, setFetchedDemo] = useState(null);
  const [fetchedReseller, setFetchedReseller] = useState(null);
  const [types, setTypes] = useState([
    "Admin Time",
    "Business Partner Training",
    "Demo for Business Partner",
    "Demo for End User",
    "Practice for Demo",
    "Setup for Demo",
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [errMsg, setErrMsg] = useState();

  const { reservationId } = useParams();

  let navigate = useNavigate();

  //let location = useLocation();
  //const { demoId, demoName } = location.state;

  const currentUser = authService.getCurrentUser();
  //const isNewReservation = reservationId.toString() === "new" ? true : false;

  const submitButtonLabel = "Approve Reservation Request";
  /*
  const submitButtonLabel = isNewReservation
    ? "Schedule Demo"
    : "Update Reservation";

  const demoTitle = isNewReservation ? demoName : fetchedDemo?.name;
  */

  /* Begin scheduler Testing */
  /* End Scheduler Testing */
  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  // Create 3 date objects that will represent
  // next week, 2 weeks from now, and 3 weeks from now
  // Set the time at 10am EST, 2pm EST, and 1pm EST
  const nextWeek = new Date();
  nextWeek.setDate(new Date().getDate() + 7);
  nextWeek.setUTCHours(15);
  nextWeek.setUTCMinutes(0);

  const twoWeeks = new Date();
  twoWeeks.setDate(new Date().getDate() + 14);
  twoWeeks.setUTCHours(19);
  twoWeeks.setUTCMinutes(0);

  const threeWeeks = new Date();
  threeWeeks.setDate(new Date().getDate() + 21);
  threeWeeks.setUTCHours(18);
  threeWeeks.setUTCMinutes(0);

  const defaultValues = {
    choice1: nextWeek,
    choice2: twoWeeks,
    choice3: threeWeeks,
  };

  const schema = yup.object().shape({
    ticketNum: yup.string().nullable(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: defaultValues,
  });

  /*
   */

  const handleUpdate = (data) => {
    if (isDebug) {
      console.log(
        `Handling ${submitButtonLabel} for demo ID ${fetchedReservation?.demo.id}`
      );
      console.log("Original data submitted...");
      console.log(data);
    }
    let newData = data;
    if (fetchedReservation.startDate)
      newData.startDate = fetchedReservation.startDate;
    if (data.Approve1st) newData.startDate = data.choice1;
    if (data.Approve2nd) newData.startDate = data.choice2;
    if (data.Approve3rd) newData.startDate = data.choice3;
    if (data.newStartDate) newData.startDate = new Date(data.newStartDate);
    newData.demoId = fetchedReservation?.demo.id;
    newData.duration = fetchedReservation?.duration;

    if (isDebug) {
      console.log("New data after correction...");
      console.log(newData);
    }

    if (!newData.startDate) {
      if (window.confirm("You must select a start date and time to approve!")) {
        console.log("User clicked OK");
        return;
      } else {
        console.log("User clicked Cancel");
        navigate(-1);
        return;
      }
      /*
      setErrMsg("You must select a start date and time to approve!");
      setHasError(true);
      return;
      */
    }

    const pushUpdate = async () => {
      newData.id = reservationId;
      const updateResponse = await updateReservation(reservationId, newData);
      if (isDebug) {
        logData(updateResponse);
        logData(updateResponse.status);
      }
      if (updateResponse.status >= 200 && updateResponse.status < 300) {
        // should be 201
        setSuccessful(true);
      }
      if (updateResponse.status >= 400 && updateResponse.status < 500) {
        setErrMsg(updateResponse.data);
        setHasError(true);
      }
    };
    pushUpdate().catch((error) => http.handleError(error));
    /*
     */
  };

  //Runs after initial render
  useEffect(() => {
    setIsLoading(true);
    if (typeof fetchedReservation === "object" && fetchedReservation !== null) {
      logData("Fetched Reservation ID: " + fetchedReservation.id);
      setIsLoading(false);
      return;
    } else {
      const pullInfo = async () => {
        const currentReservation = await getReservationInfo(reservationId);
        setFetchedReservation(currentReservation);
        console.log(currentReservation);
      };
      pullInfo().catch(console.error);
    }

    clearErrors();
    setIsLoading(false);
  }, []);

  //Runs when a reservation is fetched
  useEffect(() => {
    // Making sure fetchedUser actually has a value
    if (typeof fetchedReservation === "object" && fetchedReservation !== null) {
      logData("Fetched Reservation ID: " + fetchedReservation.id);
    } else {
      return;
    }
    setValue("ticketNum", fetchedReservation.ticketNum, {
      shouldValidate: true,
      shouldDirty: true,
    });
    /*
    if (fetchedReservation.startDate) {
      setValue(
        "startDate",
        format(new Date(fetchedReservation.startDate), "Pp"),
        {
          shouldValidate: true,
          shouldDirty: true,
        }
      );
    }
    */
    /*
    setValue("type", fetchedReservation.type, {
      shouldValidate: true,
      shouldDirty: true,
    });
*/
    console.log(fetchedReservation);
  }, [fetchedReservation]);

  /* This useEffect is only when successful submission */
  useEffect(() => {
    if (successful) {
      //navigate("/users");
      navigate(-1);
    }
  }, [successful]);

  function RenderBlock(label, value) {
    return (
      <>
        <span className="text-muted">{label}</span>
        <br />
        <span className="fs-6">{value}</span>
      </>
    );
  }

  return (
    <React.Fragment key={1234}>
      {hasError ? (
        alert(errMsg)
      ) : isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      ) : (
        <>
          {fetchedReservation ? (
            <div className="container shadow border border-2 border-light rounded-3 pt-2 mt-2 mb-5 ">
              <div className="row text-center">
                <div className="col fs-4">
                  Approve a Reservation for:{" "}
                  <span className="fs-3"> {fetchedReservation.demo.name} </span>
                </div>
              </div>
              <form onSubmit={handleSubmit(handleUpdate)} className="p-2">
                {currentUser.roleId < 3 ? (
                  <div className="row justify-content-lg-center">
                    <div className="col"></div>
                    <div className="col">
                      <div className="form-group m-2">
                        <label
                          className="text-muted"
                          htmlFor="expectedNumOfAttendees"
                        >
                          <small>
                            {"Enter Jira Ticket Number if Available"}
                          </small>
                        </label>
                        <input
                          {...register("ticketNum")}
                          className="form-control"
                        />
                        {errors["ticketNum"] && (
                          <small>
                            <small>
                              <div className="alert alert-danger">
                                {errors["ticketNum"].message}
                              </div>
                            </small>
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col"></div>
                  </div>
                ) : null}
                <div className="row justify-content-lg-center shadow border border-2 rounded-3 mx-2 mb-3">
                  <div className="col pt-2 pb-2 text-center">
                    {RenderBlock(
                      "Reseller Name",
                      fetchedReservation.reseller.name
                    )}
                    <hr />
                    {RenderBlock(
                      "Reseller Account Number",
                      fetchedReservation.reseller.acctNum
                    )}
                  </div>
                  <div className="col pt-2 pb-2 text-center">
                    {RenderBlock("Activity Type", fetchedReservation.type)}
                    <hr />
                    {RenderBlock(
                      "Requested By:",
                      fetchedReservation.user.fullName
                    )}
                  </div>
                  <div className="col pt-2 pb-2 text-center">
                    {RenderBlock(
                      "Time Required",
                      fetchedReservation.duration + " hrs"
                    )}
                    <hr />
                    {RenderBlock(
                      "Number of Attendees",
                      fetchedReservation.expectedNumOfAttendees
                    )}
                  </div>
                </div>

                <div className="row justify-content-lg-center shadow border border-2 rounded-3 mx-2 mb-3 p-2">
                  <div className="col-4">
                    <span className="text-muted">Reason for Demo:</span>
                  </div>
                  <div className="col-8">
                    <span className="fs-6">
                      {fetchedReservation.description}
                    </span>
                  </div>
                </div>

                <div className="d-flex flex-row row justify-content-lg-center shadow border border-2 rounded-3 mx-2 mt-2">
                  <p className="fs-4 text-center pt-2">
                    Set the starting date and time for the demo.
                    <br />
                    {fetchedReservation.startDate ? (
                      <span className="fs-6 text-danger">
                        <em>
                          Current Scheduled Start Date and Time are set to{" "}
                          {format(new Date(fetchedReservation.startDate), "Pp")}{" "}
                        </em>
                      </span>
                    ) : null}
                  </p>
                  <div className="col-4">
                    <div className="form-group">
                      <div className="form-check ">
                        <label className="form-check-label p-2">
                          <input
                            className="form-check-input"
                            {...register("Approve1st")}
                            type="checkbox"
                            key="1"
                          />
                          {format(new Date(fetchedReservation.choice1), "Pp")}
                        </label>
                      </div>
                      <div className="form-check ">
                        <label className="form-check-label p-2">
                          <input
                            className="form-check-input"
                            {...register("Approve2nd")}
                            type="checkbox"
                            key="1"
                          />
                          {format(new Date(fetchedReservation.choice2), "Pp")}
                        </label>
                      </div>
                      <div className="form-check ">
                        <label className="form-check-label p-2">
                          <input
                            className="form-check-input"
                            {...register("Approve3rd")}
                            type="checkbox"
                            key="1"
                          />
                          {format(new Date(fetchedReservation.choice3), "Pp")}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-2 align-self-center">- OR -</div>
                  <div className="col-4 align-self-center text-center">
                    <span className="fw-bold">
                      Set a new time and date for the demo.
                    </span>
                    <br />
                    <Controller
                      control={control}
                      name="newStartDate"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <ReactDatePicker
                          selected={value}
                          isClearable
                          filterDate={isWeekday}
                          excludeDateIntervals={[
                            {
                              start: subDays(new Date(), 90),
                              end: addDays(new Date(), 7),
                            },
                          ]}
                          onChange={onChange}
                          onBlur={onBlur}
                          placeholderText="Click here..."
                          showTimeSelect
                          timeIntervals={60}
                          includeTimes={[
                            setHours(setMinutes(new Date(), 0), 10),
                            setHours(setMinutes(new Date(), 0), 11),
                            setHours(setMinutes(new Date(), 0), 13),
                            setHours(setMinutes(new Date(), 0), 14),
                            setHours(setMinutes(new Date(), 0), 15),
                            setHours(setMinutes(new Date(), 0), 16),
                          ]}
                          dateFormat="MM/dd/yyyy h:mm aa"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="row justify-content-lg-center">
                  <div className="col"></div>
                  <div className="col">
                    <center>
                      <div id="4" className="form-group m-2">
                        <input
                          className="btn btn-sm btn-primary me-2 mb-2"
                          type="submit"
                          value={submitButtonLabel}
                        />
                        <input
                          className="btn btn-sm btn-danger mb-2 "
                          type="button"
                          value="Cancel"
                          onClick={() => {
                            navigate(-1);
                          }}
                        />
                      </div>
                      <div id="4" className="form-group m-2"></div>
                      {successful && (
                        <div className="alert alert-success">
                          Update Successful!
                        </div>
                      )}
                    </center>
                  </div>
                  <div className="col"></div>
                </div>
              </form>
            </div>
          ) : (
            <h2 className="display-6">No Reservation Found with that ID</h2>
          )}
        </>
      )}
    </React.Fragment>
  );
} // End export default funtion DemoForm

/*

                    <Input
                      register={register}
                      fieldName="description"
                      label="Reason for Demo:"
                      errors={errors}
                      type="textarea"
                    />
*/
/*                    
                    <label className="text-muted" htmlFor="description">
                      <small>{"Reason for Demo?"}</small>
                    </label>
                    <textarea
                      {...register("description")}
                      className="form-control"
                    />
                    {errors.description && (
                      <small>
                        <small>
                          <div className="alert alert-danger">
                            {errors.description?.message}
                          </div>
                        </small>
                      </small>
                    )}
*/
