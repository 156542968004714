import React, { Component } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { removeSignup } from "../../services/signupService";
import Table from "../common/table";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";

class NewUserRequestsTable extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      currentPage: 1, 
      pageSize: 6, 
      sortColumn: { path: "fullName", order: "asc"}, 
      signups: [],
    }
  }
  static getDerivedStateFromProps(props, state) {
    if(props.signups !== state.signups){
      console.log("updated state.signups");
      return{signups: props.signups};
    }
    return null;
  }
 

  handleDelete = async (signup) => {
    console.log("Delete Clicked");
    const deleted = await removeSignup(signup.id);
    console.log(deleted);
    if (deleted.status === 200) {
      const fedbks = this.state.signups.filter((s) => s.id !== signup.id);
      this.setState({ signups: fedbks });
    }
  };

  columns = [
    {
      path: "fullName",
      label: "Full Name",
      content: (signup) => (
        <Link to={`/signup/${signup.id}`}>{signup.fullName}</Link>
      ),
    },
    {
      path: "email",
      label: "Email",
      content: (signup) => (
        <a href={`mailto:${signup.email}`} className="text-decoration-none">
          {signup.email}
        </a>
      ),
    },
    { path: "workPhone", label: "Work Phone" },
    { path: "companyName", label: "Company Name" },
    { path: "createdAt", label: "Requested Date",
      content: (signup) => (format(new Date(signup.createdAt), "Pp"))
    },
    {
      key: "delete",
      content: (signup) => (
        <button
          onClick={() => this.props.delete(signup)}
          className="btn btn-danger btn-sm"
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </button>
      ),
    },
    /*
     */
  ];

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  render() {
    const { caption } = this.props;

    if (this.state.signups?.length === 0) {
      return;
    } else {
      return (
        <Table
          columns={this.columns}
          data={this.state.signups}
          sortColumn={this.state.sortColumn}
          onSort={this.handleSort}
          caption={caption}
        />
      );
    }
  }
}

export default NewUserRequestsTable;

 /*
  state = {
    signups: [],
    currentPage: 1,
    pageSize: 6,
    sortColumn: { path: "fullName", order: "asc" },
  };

  componentDidMount() {
    const { signups } = this.props;
    this.setState({ signups });
    console.log("props.signups in cDM()");
    console.log(signups);
  }
 */

/*
  formatDate = (reservation) => {
    const formattedDate = correctDate(reservation.startTime);
    reservation.startTime = formattedDate;
    return reservation;
  };


  {
      key: "delete",
      content: (signup) => (
        <button
          onClick={() => this.handleDelete(signup)}
          className="btn btn-danger btn-sm"
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </button>
      ),
    },


*/
