// given and array and an element return array of indices
// of elements that match element


export function arrayFindAll(array, element) {
  const indices = [];

  let idx = array.indexOf(element);
  while (idx !== -1) {
    indices.push(idx);
    idx = array.indexOf(element, idx + 1);
  }

  return indices;
}
