import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

import BackButton from "../common/backButtion";
import HeroBlock from "../common/heroBlock";
import { getCenters } from "../../services/centerService";
import { isDebug, logData } from "../common/helpers";
import authService from "../../services/auth.service";
import rbacService from "../../services/rbacService";

export default function Centers() {
  const [centers, setCenters] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const currentUser = authService.getCurrentUser();
  const rbac = new rbacService(currentUser);

  // Run once after initial render
  useEffect(() => {
    setIsLoading(true);
    const getCenterList = async () => {
      const localTT = await getCenters();
      const sortedCenters = _.orderBy(localTT, ["name"], ["asc"]);
      setCenters(sortedCenters);
      if (isDebug) logData(sortedCenters);
    };
    getCenterList().catch(console.error);
    setIsLoading(false);
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      ) : (
        centers && (
          <div className="container">
            <div className="mt-2 ms-4">
              <BackButton />
               {rbac.isAdmin() ? (
                  <Link to={`/centerForm/new`}>
                    <button className="btn btn-sm btn-primary me-2 ms-2"> New Center </button>
                  </Link>
                ) : null}
            </div>
            <center>

            <h2 className="display-4 mt-2">Solution Centers</h2>

            <center>
           <p className="lead mt-2"> 
             <strong>
              <button className="btn btn-primary">

              <a className="link-light"
                href="https://outlook.office365.com/book/SolutionCenterTour@tdworldwide.onmicrosoft.com/"
                target="_blank"
                rel="noreferrer"
                >
              Click Here to Schedule a Solution Center Tour
              </a>
              </button>
             </strong>
              </p>
          </center>

             { centers.map((center) => 
                <div className="row">
                  <div className="col">
                    {HeroBlock(
                       center.name, 
                       center.description, 
                       center.facilities, 
                       `/centerDetail/${center.id}`
                     )}
                  </div>
                </div>
               ) 
             }
             
            </center>
          </div>
        )
      )}
    </>
  );
}
/*
                href="https://outlook.office365.com/book/ResellerEnabledSecurityAssessments@tdworldwide.onmicrosoft.com/"
              <CenterTable centers={centers} />
*/

/*
 <div className="row">
                <div className="col">
                  {HeroBlock(
                    centers[0].name,
                    centers[0].description,
                    centers[0].facilities,
                    "/centerDetail/1"
                  )}
                </div>
                <div className="col">
                  {HeroBlock(
                    centers[1].name,
                    centers[1].description,
                    centers[1].facilities,
                    "/centerDetail/2"
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {HeroBlock(
                    centers[2].name,
                    centers[2].description,
                    centers[2].facilities,
                    "/centerDetail/3"
                  )}
                </div>
                <div className="col">
                  {HeroBlock(
                    centers[3].name,
                    centers[3].description,
                    centers[3].facilities,
                    "/centerDetail/4"
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {HeroBlock(
                    centers[4].name,
                    centers[4].description,
                    centers[4].facilities,
                    "/centerDetail/5"
                  )}
                </div>
                <div className="col">
                  {HeroBlock(
                    centers[5].name,
                    centers[5].description,
                    centers[5].facilities,
                    "/centerDetail/6"
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {HeroBlock(
                    centers[6].name,
                    centers[6].description,
                    centers[6].facilities,
                    "/centerDetail/7"
                  )}
                </div>
              </div>
*/
