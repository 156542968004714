import React, { Component } from "react";
import { Link } from "react-router-dom";
import { logData, isDebug } from "../common/helpers";
import Table from "../common/table";

class DeliveredServicesTable extends Component {
  state = {
    deliveredServices: [],
    currentPage: 1,
    pageSize: 6,
    sortColumn: { path: "name", order: "asc" },
  };

  componentDidMount() {
    const { deliveredServices } = this.props;
    this.setState({ deliveredServices });
  }

  columns = [
    {
      path: "title",
      label: "Title",
    },
    { path: "description", label: "Description" },
    { path: "type", label: "Service Type" },
    /*
     */
  ];

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  render() {
    const { deliveredServices, caption } = this.props;

    if (deliveredServices.length === 0) {
      return;
    } else {
      return (
        <Table
          columns={this.columns}
          data={deliveredServices}
          sortColumn={this.state.sortColumn}
          onSort={this.handleSort}
          caption={caption}
        />
      );
    }
  }
}

export default DeliveredServicesTable;
