import React, { useState, useEffect } from "react";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-solid-svg-icons";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { isDebug, logData } from "../common/helpers";
import BackButton from "../common/backButtion";
import { Link } from "react-router-dom";
import ResellerReservationsTable from "../resellers/resellerReservationsTable";
import UserUserActivitiesTable from "./userUserActivitiesTable";
import authService from "../../services/auth.service";
import http from "../../services/httpService";
import config from "../../config.json";
import { getUserInfo } from "../../services/userService";
import { getInstructorbyUserID } from "../../services/instructorService";
import { getResellerInfo } from "../../services/resellerService";
import rbacService from "../../services/rbacService";


export default function UserDetail() {
  const [redirect, setRedirect] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [instructorInfo, setInstructorInfo] = useState(null);
  const [resellerInfo, setResellerInfo] = useState(null);
  const [rbac, setRBAC] = useState(null);
  const [userActivitiesID, setUserActivitiesID] = useState(null);

  let navigate = useNavigate();

  let { userId } = useParams();
  const changePassword = () => {
    navigate("/cp");
  };

  useEffect(() => {
    const loggedInUser = authService.getCurrentUser();
    let fetchedUser = {};
    let fetchedInstructor = {};

    if (!loggedInUser) {
      setRedirect("/login");
    } else {
      const cu = authService.getCurrentUser();
      const lrbac = new rbacService(cu);
      setRBAC(lrbac);
    }

    const getInstructorDetails = async () => {
      if (userId) {
        fetchedInstructor = await getInstructorbyUserID(userId);
      } else {
        fetchedInstructor = await getInstructorbyUserID(loggedInUser.id);
      }
      if (isDebug) {
        console.log("Fetched Instructor: ");
        console.log(fetchedInstructor);
      }
      setInstructorInfo(fetchedInstructor);
    };

    const pullResellerInfo = async () => {
      const reseller = await getResellerInfo(fetchedUser.resellerId);
      setResellerInfo(reseller);
      console.log(reseller);
    };

    const getUsersActivities = async () => {
      let activities = [];
      if(userId) {
        //activities = await getUserActivitybyUserID(userId);
        setUserActivitiesID(userId);
        console.log("User Id for pulling activities: " + userId);
      } else {
        //activities = await getInstructorDetails(loggedInUser.id);
        setUserActivitiesID(loggedInUser.id);
        console.log("User Id for pulling activities: " + loggedInUser.id);
      }
      //setUserActivities(activities);
    };
    getUsersActivities().catch((error) => http.handleError(error));

    const getUserDetails = async () => {
      if (userId) {
        fetchedUser = await getUserInfo(userId);
      } else {
        fetchedUser = await getUserInfo(loggedInUser.id);
      }


      if (isDebug) {
        console.log("From getUserDetails in UserDetail: fetchedUser: ");
        console.log(fetchedUser);
      }

      setUserInfo(fetchedUser);

      // rbac is for the current logged in user.  If we are fetching
      // another user, we need to use the fetched user's roleId to 
      // determine if we need to fetch instructor details.
      //if (rbac.isAdminOrInstructor()) {
      if (fetchedUser.roleId === 1 || fetchedUser.roleId === 2) {
        getInstructorDetails().catch((error) => http.handleError(error));
      }

      pullResellerInfo().catch((error) => http.handleError(error));
    };
    getUserDetails().catch((error) => http.handleError(error));
  }, []);

  const handleUpdate = () => {
    const link = "/user/" + userInfo.id;
    setRedirect(link);
  };

  return (
    <>
      {redirect ? <Navigate to={redirect} /> : null}
      {userInfo ? (
        <>
          <div className="container shadow border border-1 border-light rounded-3 pt-2 mt-2">
            <div className="row justify-content-lg-center">
              <div className="col-2 mt-2">
                <BackButton />
              </div>
              <div className="col-10">
                <h2 className="display-6">User Info</h2>
              </div>
            </div>
            <div className="row justify-content-lg-center">
              <div className="col-md">
                <p className="h6 mt-1">Full Name:</p>
                {userInfo.fullName}
                <hr />
                <p className="h6 mt-2">First Name: </p>
                {userInfo.firstName}
                <hr />
                <p className="h6 mt-2">Last Name: </p>
                {userInfo.lastName}
                <hr />
              </div>

              <div className="col-md">
                <p className="h6 mt-1">Username:</p>
                {userInfo.userName}
                <hr />
                <p className="h6 mt-2">Email: </p>
                {userInfo.email}
                <hr />
                <p className="h6 mt-2">Work Phone: </p>
                {userInfo.workPhone}
                <hr />
              </div>

              <div className="col-md">
                <p className="h6 mt-1">Reseller:</p>
                <Link to={`/resellerDetail/${userInfo.reseller.id}`}>
                  {userInfo.reseller?.name}
                </Link>
                <hr />
                <p className="h6 mt-1">Reseller Acct#:</p>
                {userInfo.reseller?.acctNum}
                <hr />
                <p className="h6 mt-2">Current Role: </p>
                {userInfo.role.name}
                <hr />
              </div>

              {instructorInfo && (
                <>
                <div className="row justify-content-lg-center">
                  <div className="col-4 text-center">
                    {instructorInfo?.imageUrl ? (

                    <img
                      src={config.imgBaseURL + instructorInfo.imageUrl}
                      width="200"
                      height="200"
                      alt={instructorInfo.imageUrl}
                    />
                    ) : (
                      <img
                          src={config.imgBaseURL + "/headshots/unknown.jpg"}
                          width="200px"
                          height="200px"
                          alt="Instructor  headshot"
                        />
                    )}
                  </div>
                  <div className="col-8">
                    <div className="d-flex flex-column">
                      <div className="p-2">
                        <p className="h6 mt-1">Instructor Bio:</p>
                        <Markdown 
                        remarkPlugins={[remarkGfm]} 
                        components={{
                          a(props) {
                           const { node, ...rest} = props
                          return <a target="_blank" rel="noopener noreferrer" {...rest} />
                          } 
                        }}
                      >
                        {instructorInfo?.description}
                      </Markdown>  

                      </div>
                      <div className="p-2">

                        <div className="row justify-content-lg-center">
                          <div className="col-4 text-center p-2">
                            {instructorInfo?.twitterURL ? (
                              <a target="_blank" rel="noopener noreferrer" href={instructorInfo.twitterURL}>
                                Twitter
                              </a>
                            ): null}
                          </div>
                          <div className="col-4 text-center p-2">
                            {instructorInfo?.linkedInURL ? (
                              <a target="_blank" rel="noopener noreferrer" href={instructorInfo.twitterURL}>
                                LinkedIn
                              </a>
                            ): null}
                          </div>
                          <div className="col-4 text-center p-2">
                            {instructorInfo?.githubURL ? (
                              <a target="_blank" rel="noopener noreferrer" href={instructorInfo.twitterURL}>
                                Github
                              </a>
                            ): null}
                          </div>
                        </div>

                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
                </>
              )}

              <div className="row justify-content-lg-center">
                <div className="col-md">
                  <center>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>
                            {" "}
                            <center>Favorite Vendors</center>{" "}
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {userInfo.vendors &&
                          userInfo.vendors.map((vendor, i) => (
                            <>
                              <tr>
                                <td key={i}>
                                  <small>{vendor.name}</small>
                                </td>
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </table>
                  </center>
                </div>

                <div className="col-md">
                  <center>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>
                            {" "}
                            <center>Favorite Topics</center>{" "}
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {userInfo.categories &&
                          userInfo.categories.map((category, i) => (
                            <>
                              <tr>
                                <td key={i}>
                                  <small>{category.title}</small>
                                </td>
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </table>
                  </center>
                </div>
              </div>
              <center className="mt-3">
                <button onClick={handleUpdate} className="btn-sm btn-primary">
                  Edit Info
                </button>
                <button
                  onClick={changePassword}
                  className="btn-sm btn-primary ms-2 mb-2"
                >
                  Change Password
                </button>
              </center>
            </div>
          </div>
          <div className="container shadow border border-1 border-light rounded-3 pb-2 pt-2 mb-3 mt-2">
            {resellerInfo?.reservations.length > 0 ? (
              <ResellerReservationsTable
                reservations={resellerInfo.reservations}
                caption="Your Company's Reservations"
              />
            ) : (
              <div className="text-center text-muted">
                Your Company Has No Current Reservations
              </div>
            )}
          </div>
          {(rbac && rbac.hasElevatedPrivs()) ? (
              <div className="container shadow border border-1 border-light rounded-3 pb-2 pt-2 mb-3 mt-2">
                <UserUserActivitiesTable user={userInfo} />
              </div>
            ) : ( null )
           }
        </>
      ) : null}
    </>
  );
}

/*


                <p className="h6 mt-2">Favorite vendors: </p>
                {userInfo.vendors.map((vendor) => vendor.name)}

*/
