import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import * as yup from "yup";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";

import { arrayFindAll } from "../../utils/utils";
import { getVendors } from "../../services/vendorService";
import { getCategories } from "../../services/categoryService";
import authService from "../../services/auth.service";
import http from "../../services/httpService";

import {
  createCenter,
  getCenterbyID,
  updateCenter,
} from "../../services/centerService";
import Input from "../common/input";
import { isDebug, logData } from "../common/helpers";

export default function CenterForm() {
  const [fetchedCenter, setFetchedCenter] = useState(null);
  const [siteVendors, setSiteVendors] = useState(null);
  const [siteTopics, setSiteTopics] = useState(null);
  const [allVendors, setAllVendors] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [showVendorModal, setShowVendorModal] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [errMsg, setErrMsg] = useState();

  const { centerId } = useParams();
  const navigate = useNavigate();
  const currentUser = authService.getCurrentUser();


  const isNewCenter = centerId.toString() === "new" ? true : false;
  const submitButtonLabel = isNewCenter ? "Create" : "Update";

  if (isDebug) {
    console.log("currentUser");
    console.log(currentUser);
    console.log(`isNewCenter = ${isNewCenter}`);
  } 
  /*
   */
  const schema = yup.object().shape({
    description: yup.string().required().min(4),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const handleShowCategories = () => setShowCategoryModal(true);
  const handleCloseCategories = () => setShowCategoryModal(false);

  const handleShowVendors = () => setShowVendorModal(true);
  const handleCloseVendors = () => setShowVendorModal(false);

  const handleVendorUpdate = () => {
    setShowVendorModal(false);
    const hookVendors = getValues("vendors");
    const newVendorsIndexes = arrayFindAll(hookVendors, true);
    console.log(newVendorsIndexes);
    const newVendors = allVendors.filter((i) =>
      newVendorsIndexes.includes(i.id)
    );
    //console.log(allVendors);
    console.log(newVendors);
    setSiteVendors(newVendors);
    if (typeof fetchedCenter === "object" && fetchedCenter !== null) {
      fetchedCenter.vendors = newVendors;
      setFetchedCenter(fetchedCenter);
    }
    setValue("vendors", newVendors);
  };

  const handleCategoryUpdate = () => {
    setShowCategoryModal(false);
    const hookCategories = getValues("categories");
    if(isDebug) {
      console.log("hookCategories");
      console.log(hookCategories);
    } 
    const newCategoriesIndexes = arrayFindAll(hookCategories, true);
    const newCategories = allCategories.filter((i) =>
      newCategoriesIndexes.includes(i.id)
    );
    //console.log(allCategories);
    if(isDebug) {
      console.log("newCategories");
      console.log(newCategories);
    } 
    setSiteTopics(newCategories);
    if (typeof fetchedCenter === "object" && fetchedCenter !== null) {
      fetchedCenter.categories = newCategories;
      setFetchedCenter(fetchedCenter);
    }
    setValue("categories", newCategories);
  };

  const handleUpdate = (data) => {
    data.userId = currentUser.id;

    /* We map array of objects, arrays of related vendors and topics,
       to array of true/false for the checkbox display. This sets the 
       value of the vendors/topics in the form data to the array of
       true/false items, not the array of objects. 
       If we never update the vendors or topics, the array of objects
       is never reset to the form data.
    */
    data.vendors = siteVendors;
    data.categories = siteTopics;

    if (isDebug) {
      console.log("Handling Create / Update ....");
      console.log( data);
    }

    if (data.externalUrl === "" ) data.externalUrl = null;

    if (isNewCenter) {
      // Creating new center entry
      const doCreate = async () => {
        const createResponse = await createCenter(data);
        if (isDebug) {
          console.log(createResponse);
          console.log(createResponse.status);
        }
        if (createResponse.status === 201) {
          // HTTP POST seem to return 201 on success
          setSuccessful(true);
        } else {
          setHasError(true);
          setErrMsg("Returned error code: " + createResponse.status);
        }
      };
      //doCreate().catch(console.error);
      //doCreate().catch((e) => alert(e));
      //doCreate().catch((e) => console.log(e.response.status));
      doCreate().catch((e) => http.handleError(e));
    } else {
      // updating an existing item
      data.id = centerId;
      const pushUpdate = async () => {
        const updateResponse = await updateCenter(centerId, data);
        console.log(updateResponse);
        console.log(updateResponse.status);
        if (updateResponse.status === 201) {
          // HTTP PUT return 201 on success
          setSuccessful(true);
        }
      };
      //pushUpdate().catch((e) => alert(e));
      pushUpdate().catch((e) => http.handleError(e));
    }
  };

  // Runs after first render (when component mounts, sorta)
  useEffect(() => {
    setIsLoading(true);

    const getCenterInfo = async () => {
      const center = await getCenterbyID(centerId);
      setFetchedCenter(center);
    };

    const pullVendors = async () => {
      const fetchedvendors = await getVendors();
      setAllVendors(fetchedvendors);
    };

    const pullCategories = async () => {
      const fetchedcategories = await getCategories();
      setAllCategories(fetchedcategories);
    };

    pullVendors().catch(console.error);
    pullCategories().catch(console.error);

    if (!isNewCenter) {
      getCenterInfo().catch(console.error);
    }

    setIsLoading(false);
  }, []);

  /* this useEffect is called only when we have 
  fetched a user from the backend */
  useEffect(() => {
    if(fetchedCenter != null) {

      if (isDebug) {
         console.log("Fetched Center: ");
         console.log(fetchedCenter);
      } 

      setValue("description", fetchedCenter.description, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("facilities", fetchedCenter.facilities, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("contactEmail", fetchedCenter.contactEmail, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("capabilities", fetchedCenter.capabilities, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("externalUrl", fetchedCenter.externalUrl, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("name", fetchedCenter.name, {
        shouldValidate: true,
        shouldDirty: true,
      });

      if (fetchedCenter.vendors) {
        fetchedCenter.vendors?.map((vendor) => {
          const vendorId = vendor.id.toString();
          setValue(`vendors[${vendorId}]`, true);
          if(isDebug) {
            console.log("vendor checkbox values");
            console.log(getValues("vendors"));
          } 
          setSiteVendors(fetchedCenter.vendors);
        });
      }
      if (fetchedCenter.categories) {
        fetchedCenter.categories?.map((category) => {
          const categoryId = category.id.toString();
          setValue(`categories[${categoryId}]`, true);
          if(isDebug) {
            console.log("category checkbox values");
            console.log(getValues("categories"));
          } 
          setSiteTopics(fetchedCenter.categories);
        });
      }
    }
  }, [fetchedCenter]);

  /* This useEffect is only when successful submission */
  useEffect(() => {
    if (successful) {
      //navigate("/users");
      navigate(-1);
    }
  }, [successful]);

  return (
    <React.Fragment>
      {hasError ? (
        alert(errMsg)
      ) : isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      ) : (
        <div className="container shadow border border-2 border-light rounded-3 mt-4">
          <form onSubmit={handleSubmit(handleUpdate)} className="p-2">
            <div className="row justify-content-lg-center">
              <div className="col">
                <Input
                  register={register}
                  fieldName="name"
                  label="Site Name"
                  errors={errors}
                />
              </div>
              <div className="col">
                <Input
                  register={register}
                  fieldName="contactEmail"
                  label="Contact Email"
                  errors={errors}
                />
              </div>
            </div>

            <div className="row justify-content-lg-center">
              <div className="col">
                <Input
                  register={register}
                  fieldName="capabilities"
                  label="Access Capabilities"
                  errors={errors}
                />
              </div>
              <div className="col">
                <Input
                  register={register}
                  fieldName="externalUrl"
                  label="URL for Overview Video"
                  errors={errors}
                />
              </div>
            </div>

            <div className="row justify-content-lg-center mt-3">
              <div className="col-md-8">
                <label htmlFor="description">Description</label>
                <textarea
                  {...register("description")}
                  className="form-control"
                  rows="5"
                />
              </div>
            </div>

            <div className="row justify-content-lg-center mt-3">
              <div className="col-md-8">
                <label htmlFor="description">
                  Specific Facilities Included
                </label>
                <textarea
                  {...register("facilities")}
                  className="form-control"
                  rows="5"
                />
              </div>
            </div>

            <div className="row justify-content-lg-center">
              <div className="col-md">
                <center>
                  <div className="form-group m-2">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>
                            {" "}
                            <center>
                              Vendors in this Solution Center
                            </center>{" "}
                          </th>
                          <th>
                            <Button
                              variant="primary"
                              onClick={handleShowVendors}
                            >
                              <FontAwesomeIcon icon={faPenToSquare} />
                            </Button>
                            <Modal
                              show={showVendorModal}
                              onHide={handleCloseVendors}
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>Select Vendor(s)</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                Vendor List
                                <div className="col">
                                  {allVendors &&
                                    allVendors.map((vendor) => (
                                      <>
                                        <div className="form-check ">
                                          <label className="form-check-label p-2">
                                            <input
                                              className="form-check-input"
                                              {...register(
                                                `vendors[${vendor.id}]`
                                              )}
                                              type="checkbox"
                                              key={vendor.id}
                                            />
                                            {vendor.name}
                                          </label>
                                        </div>
                                      </>
                                    ))}
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleCloseVendors}
                                >
                                  Close
                                </Button>
                                <Button
                                  variant="primary"
                                  onClick={handleVendorUpdate}
                                >
                                  Submit
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {siteVendors &&
                          siteVendors.map((vendor, i) => (
                            <>
                              <tr>
                                <td>
                                  <small>{vendor.name}</small>
                                </td>
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </center>
              </div>

              <div className="col-md">
                <center>
                  <div className="form-group m-2">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>
                            {" "}
                            <center>Topics in this Solution Center</center>{" "}
                          </th>
                          <th>
                            <Button
                              variant="primary"
                              onClick={handleShowCategories}
                            >
                              <FontAwesomeIcon icon={faPenToSquare} />
                            </Button>
                            <Modal
                              show={showCategoryModal}
                              onHide={handleCloseCategories}
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>Pick Topic(s)</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                Topic List
                                <div className="col">
                                  {allCategories &&
                                    allCategories.map((category) => (
                                      <>
                                        <div className="form-check ">
                                          <label className="form-check-label p-2">
                                            <input
                                              className="form-check-input"
                                              {...register(
                                                `categories[${category.id}]`
                                              )}
                                              type="checkbox"
                                              key={category.id}
                                            />
                                            {category.title}
                                          </label>
                                        </div>
                                      </>
                                    ))}
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleCloseCategories}
                                >
                                  Close
                                </Button>
                                <Button
                                  variant="primary"
                                  onClick={handleCategoryUpdate}
                                >
                                  Submit
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {siteTopics &&
                          siteTopics.map((category, i) => (
                            <>
                              <tr>
                                <td>
                                  <small>{category.title}</small>
                                </td>
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </center>
              </div>
            </div>

            <div className="row justify-content-lg-center">
              <div className="col"></div>
              <div className="col">
                <center>
                  <div id="4" className="form-group m-2">
                    <input
                      className="btn btn-sm btn-primary me-2"
                      type="submit"
                      value={submitButtonLabel}
                    />
                    <input
                      className="btn btn-sm btn-danger"
                      type="button"
                      value="Cancel"
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                  </div>
                  {successful && (
                    <div className="alert alert-success">
                      Update Successful!
                    </div>
                  )}
                </center>
              </div>
              <div className="col"></div>
            </div>
          </form>
        </div>
      )}
    </React.Fragment>
  );
}
