import axios from "axios";
import config from "../config.json";

class AuthService {
  login(userName, password) {
    return axios
      .post(config.authApiEndpoint + "signin", {
        userName,
        password,
      })
      .then((res) => {
        if (res.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(res.data));
        }
        return res.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default new AuthService();

/*
  register(userName, firstName, lastName, email, password, roles) {
    return axios.post(config.authApiEndpoint + "signup", {
      userName,
      firstName,
      lastName,
      email,
      password,
      roles,
    });
  }
*/
