import React, { useState } from "react";
import ScrollToTop from "react-scroll-to-top";
import { HashLink } from "react-router-hash-link";
import HashLinkObserver from "react-hash-link";
import config from "../../config.json";
import { serviceSolv_categories } from "../../utils/const";
import { Link } from "react-router-dom";
import PreSalesEngineeringFeature from "./presalesEngineeringFeature";
import EducationFeature from "./educationFeature";
import FieldServicesFeature from "./fieldServicesFeature";
import RenewSolvFeature from "./renewSolvFeature";
import SupportServicesFeature from "./supportServicesFeature";
import IntegrationFeature from "./integrationFeature";

export default function ServiceSolv() {
  //
  //
  //
  function RenderQuickLink(
    title,
    hlink = "#top",
    color = "#266a7d",
    bgcolor = "white"
  ) {
    let divStyleObj = {
      color: color,
      backgroundColor: bgcolor,
    };
    return (
      <div className="col-sm" style={divStyleObj}>
        <HashLink
          smooth
          to={hlink}
          className="text-decoration-none text-secondary"
        >
          <small>
            <small>{title}</small>
          </small>
        </HashLink>
      </div>
    );
  }

  function RenderServiceSolvCard(
    title,
    description,
    color = "white",
    bgcolor = "#266a7d",
    hlink = "#top"
  ) {
    let divStyleObj = {
      color: color,
      backgroundColor: bgcolor,
    };
    return (
      <div className="col-sm servicesolv-card m-2" style={divStyleObj}>
        <center>
          <p className="lead">{title}</p>
          <p>
            {" "}
            <small>
              {" "}
              <small> {description} </small>{" "}
            </small>{" "}
          </p>
          <p className="pt-2">
            <button className="btn btn-light">
              <HashLink smooth to={hlink} className="text-decoration-none">
                Learn More
              </HashLink>
            </button>
          </p>
        </center>
      </div>
    );
  }

  function RenderHeroBlock(title, subtitle, detail = "") {
    return (
      <div className="col p-4 pe-lg-0 pt-lg-5 align-items center rounded-3 border shadow-lg m-3">
        <h2 className="ss-featurette-heading fw-normal lh-1 servicesolv-title">
          {title}
        </h2>
        <strong>{subtitle}</strong>
        <br />
        <small className="text-muted">{detail}</small>
      </div>
    );
  }

  return (
    <>
      <div className="container">
        <center>
          <HashLinkObserver />
          <nav className="quicklinks">
            <div className="row">
              <div className="col">
                <Link
                  to="/centers"
                  className="text-decoration-none text-secondary"
                >
                  <small>
                    <small>Solution Centers</small>
                  </small>
                </Link>
              </div>
              <div className="col">
                {RenderQuickLink("Engineering", "#PreSalesEngineering")}
              </div>
              <div className="col">
                {RenderQuickLink("Education", "#Education")}
              </div>
              <div className="col">
                {RenderQuickLink("Professional Services", "#FieldServices")}
              </div>
              <div className="col">
                {RenderQuickLink("RenewSolv", "#RenewSolv")}
              </div>
              <div className="col">
                {RenderQuickLink("Support Services", "#SupportServices")}
              </div>
              <div className="col">
                {RenderQuickLink("BTO & Integration", "#Integration")}
              </div>
            </div>
          </nav>
          <h2 className="display-2 mt-3 mb-3 servicesolv-title">
            Service<i>Solv</i>
          </h2>

          <div className="row">
            {RenderHeroBlock(
              "4000+",
              "Certifications within our pre-sales engineering & enablement group"
            )}
            {RenderHeroBlock(
              "200+",
              "Countries for Importer of Record Services"
            )}
            {RenderHeroBlock("100+", "OEM training classes available today")}
          </div>
          <div className="row">
            {RenderHeroBlock(
              "1st Call Support",
              "For 10 OEM partners within GoldSeal",
              "(UCC & Security)"
            )}
            {RenderHeroBlock(
              "100+",
              "SME engineers across all verticals and geographies"
            )}
            {RenderHeroBlock(
              "9 Integration Facilities",
              "Across North America"
            )}
          </div>
          <div className="row">
            {RenderHeroBlock("7 Solution Centers", "Across North America")}
            {RenderHeroBlock(
              "40 Vendors",
              "Loaded and Ready in our RenewSolv Platform Today"
            )}
          </div>
        </center>
        <hr className="featurette-divider" />
        <div className="row justify-content-around">
          {RenderServiceSolvCard(
            //Pre-Sales Engineering
            serviceSolv_categories[0].title,
            serviceSolv_categories[0].description,
            "white",
            "#266A7D",
            "#PreSalesEngineering"
          )}
          {RenderServiceSolvCard(
            // Education
            serviceSolv_categories[1].title,
            serviceSolv_categories[1].description,
            "white",
            "darkcyan",
            "#Education"
          )}
          {RenderServiceSolvCard(
            // Field Services
            serviceSolv_categories[2].title,
            serviceSolv_categories[2].description,
            "white",
            "darkblue",
            "#FieldServices"
          )}
        </div>
        <div className="row justify-content-around">
          {RenderServiceSolvCard(
            // RenewSolv
            serviceSolv_categories[3].title,
            serviceSolv_categories[3].description,
            "white",
            "darkgreen",
            "#RenewSolv"
          )}
          {RenderServiceSolvCard(
            // Support services
            serviceSolv_categories[4].title,
            serviceSolv_categories[4].description,
            "white",
            "darkgoldenrod",
            "#SupportServices"
          )}
          {RenderServiceSolvCard(
            // Integration
            serviceSolv_categories[5].title,
            serviceSolv_categories[5].description,
            "white",
            "darkolivegreen",
            "#Integration"
          )}
        </div>
        <div id="PreSalesEngineering">
          <PreSalesEngineeringFeature />
        </div>
        <div id="Education">
          <EducationFeature />
        </div>
        <div id="FieldServices">
          <FieldServicesFeature />
        </div>
        <div id="RenewSolv">
          <RenewSolvFeature />
        </div>
        <div id="SupportServices">
          <SupportServicesFeature />
        </div>
        <div id="Integration">
          <IntegrationFeature />
        </div>
        <hr className="featurette-divider" />
        <ScrollToTop smooth color="#266a7d" />
      </div>
    </>
  );
}

/*
   <img src={config.imgBaseURL + "/IMG/Engineering.svg"} alt="" />
*/
