import http from "./httpService";
import authHeader from "./auth-header";

export async function getDemos() {
  const { data: demos } = await http.get("/api/v1/demos", {
    headers: authHeader(),
  });
  return demos;
}

export async function getDemoInfo(demoId) {
  const { data: demo } = await http.get(`/api/v1/demos/${demoId}`, {
    headers: authHeader(),
  });
  //console.log(demo);
  return demo;
}

export async function updateRating(demoId, ratingInfo) {
  const { data: demo } = await http.put(`/api/v1/demos/updaterating/${demoId}`,ratingInfo, {
    headers: authHeader(),
  });
  //console.log(demo);
  return demo;
}

export async function incrementView(demoId) {
  const { data: demo } = await http.put(`/api/v1/demos/incrementview/${demoId}`,{}, {
    headers: authHeader(),
  });
  //console.log(demo);
  return demo;
}

export async function updateDemo(demoId, demo) {
  const response = await http.put(`/api/v1/demos/${demoId}`, demo, {
    headers: authHeader(),
  });
  return response;
}

export async function createDemo(demo) {
  const response = await http.post(`/api/v1/demos/`, demo, {
    headers: authHeader(),
  });
  return response;
}

export async function createDemoWithProgressBar(demo, onUploadProgress) {
  let config = {
    headers: authHeader(),
    onUploadProgress: function (progressEvent) {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      if (percentCompleted < 100)
        console.log("Percent Complete: " + percentCompleted);
    },
  };

  const response = await http.post(`/api/v1/demos/`, demo, config);
  return response;
}

export async function removeDemo(demoId) {
  const response = await http.delete(`/api/v1/demos/${demoId}`, {
    headers: authHeader(),
  });
  return response;
}
