import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import authService from "../../services/auth.service";
import BackButton from "../common/backButtion";
import rbacService from "../../services/rbacService";
import { getDTCRequestInfo } from "../../services/dtcRequestService";

export default function DTCRequest() {
  const [isLoading, setIsLoading] = useState(false);
  const [dtcRequest, setDTCRequest] = useState(null);
  const [rbac, setRBAC] = useState(null);
 // const [cu, setCU] = useState(null);

  let { dtcRequestId } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    if(dtcRequest === null){
      setIsLoading(true);
      const loggedInUser = authService.getCurrentUser();
      const rbac = new rbacService(loggedInUser);
     // setCU(loggedInUser);
      setRBAC(rbac);

      const getDTCAccessRequest = async () => {
        const dtcRq = await getDTCRequestInfo(dtcRequestId);
        console.log(dtcRq);
        setDTCRequest(dtcRq);
      }
      getDTCAccessRequest().catch((error) => console.log(error));

      /* */
      setIsLoading(false);
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      ) : dtcRequest ? (
        <div className="container shadow border border-1 border-light rounded-3 pt-2 mt-2 pb-3">
          <div className="row justify-content-lg-center">
            <div className="col-2 mt-2">
              <div className="d-flex flex-column mb-2 align-items-center">
                <div className="p-2">
                  <BackButton />
                </div>
              </div>
            </div>
            <div className="col-10 text-align-center">
              <div className="d-flex flex-row justify-content-start">
                <p className="display-6">{dtcRequest.productName}</p>
                <div className="col"></div>
                <div className="col"></div>
              </div>
              <div className="d-flex flex-row justify-content-start mt-2">
                  <div className="col-4 fs-6 fst-italic">Partner</div>
                  <div className="col-8 fs-6 ">{dtcRequest.reseller.name}</div>
              </div>
              <hr />
              <div className="d-flex flex-row justify-content-start mt-2">
                  <div className="col-4 fs-6 fst-italic">User</div>
                  <div className="col-8 fs-6 ">{dtcRequest.user.fullName}</div>
              </div>
              <hr />
              <div className="d-flex flex-row justify-content-start mt-2">
                  <div className="col-4 fs-6 fst-italic">End User</div>
                  <div className="col-8 fs-6 ">{dtcRequest.endUserName}</div>
              </div>
              <hr />
              <div className="d-flex flex-row justify-content-start mt-2">
                  <div className="col-4 fs-6 fst-italic">Activity Type</div>
                  <div className="col-8 fs-6 ">{dtcRequest.activityType}</div>
              </div>
              <hr />
              <div className="d-flex flex-row justify-content-start mt-2">
                  <div className="col-4 fs-6 fst-italic">Potential Revenue</div>
                  <div className="col-8 fs-6 ">{dtcRequest.potentialRevenue}</div>
              </div>
              <hr />
              <div className="d-flex flex-row justify-content-start mt-2">
                  <div className="col-4 fs-6 fst-italic"> Deal Reg: </div>
                  <div className="col-8 fs-6 ">{dtcRequest.dealReg}</div>
              </div>
              <hr />
              <div className="d-flex flex-row justify-content-start mt-2">
                  <div className="col-4 fs-6 fst-italic">Demo Instructor</div>
                  <div className="col-8 fs-6 ">{dtcRequest.demo.instructors[0].user.fullName}</div>
              </div>
              <hr />
              <div className="d-flex flex-row justify-content-start mt-2">
                  <div className="col-4 fs-6 fst-italic">Comments</div>
                  <div className="col-8 fs-6 ">{dtcRequest.comments}</div>
              </div>
            </div>
          </div>

        </div>
      ) : (
        <h3> dtcRequest Not Found</h3>
      )}
    </>
  );
}

/*
                  <BackButton />
                  { rbac.isAdminOrInstructor() ? (
                      <Link to={`/dtcRequestForm/${dtcRequest.id}`}>
                        <button className="btn btn-primary btn-sm ms-2">Edit</button>
                      </Link>
                  ) : null}



              <div className="form-group ">
                <label htmlFor="nextdtcRequest" className="ms-2 mb-2" >Choose A Different dtcRequest</label>
                <div style={{ maxWidth: "250px"}} >

                <select
                  id="nextdtcRequest"
                  className="form-select ms-2"
                  onChange={choosedtcRequest}
                >
                  <option>{"All dtcRequests"}</option>
                  {dtcRequests && dtcRequests.map((nextdtcRequest, i) => (
                    <option key={i} value={nextdtcRequest.id}>
                      {nextdtcRequest.title}
                    </option>
                  ))}
                </select>
                </div>
              </div>


           <div className="row justify-content-lg-center p-2">
            <dtcRequestDemosTable 
               demos={dtcRequest.demos}
               caption={`Demos about ${dtcRequest.title}`}
            />
          </div>

  const choosedtcRequest = (e) => {
    //if(isDebug) console.log(e.target.value);
    if(e.target.value === "All dtcRequests"){
      navigate("/categories");
    }
    if(dtcRequests) {
      const tid = parseInt(e.target.value);
      //if(isDebug) console.log("tid = " + tid);
      //setdtcRequest(dtcRequests[tid]);
      //const newdtcRequest = dtcRequests.filter((t) => t.id === tid);
      const newdtcRequest = dtcRequests.find((t) => t.id === tid);
      setdtcRequest(newdtcRequest);
      //if(isDebug) console.log(newdtcRequest);
    }
  }

*/
