import http from "./httpService";
import authHeader from "./auth-header";

export async function getEnum() {
  const { data: pocKitStatuses } = await http.get("/api/v1/pocKits/getEnum", {
    headers: authHeader(),
  });
  return pocKitStatuses;
}

export async function getPocKits() {
  const { data: pkits } = await http.get("/api/v1/pocKits/", {
    headers: authHeader(),
  });
  return pkits;
}

export async function getPocKitbyID(pocKitId) {
  const { data: pkInfo } = await http.get(
    `/api/v1/pocKits/${pocKitId}`,
    {
      headers: authHeader(),
    }
  );
  return pkInfo;
}

export async function updatePocKit(pocKitId, pocKit) {
  const response = await http.put(`/api/v1/pocKits/${pocKitId}`, pocKit, {
    headers: authHeader(),
  });
  return response;
}

export async function createPocKit(pocKit) {
  const response = await http.post("/api/v1/pocKits/", pocKit, {
    headers: authHeader(),
  });
  return response;
}

export async function removePocKit(pocKitId) {
  const response = await http.delete(`/api/v1/pocKits/${pocKitId}`, {
    headers: authHeader(),
  });
  return response;
}
