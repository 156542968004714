import http from "./httpService";
import authHeader from "./auth-header";
import { getUserInfo } from "./userService";
import { isDebug } from "../components/common/helpers";

export async function getInstructors() {
  const { data: instructors } = await http.get("/api/v1/instructors", {
    headers: authHeader(),
  });
  return instructors;
}

export async function getInstructorbyID(instructorId) {
  const { data: instructorInfo } = await http.get(
    `/api/v1/instructors/${instructorId}`,
    {
      headers: authHeader(),
    }
  );
  return instructorInfo;
}

export async function getInstructorbyUserID(userId) {
  const { data: instructorInfo } = await http.get(
    `/api/v1/instructors/getByFKey/${userId}`,
    {
      headers: authHeader(),
    }
  );

  return instructorInfo;
}

export async function updateInstructor(instructorId, instructor) {
  const response = await http.put(
    `/api/v1/instructors/${instructorId}`,
    instructor,
    {
      headers: authHeader(),
    }
  );
  return response;
}
