import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import authService from "../../services/auth.service";
import eventBus from "./eventBus";
import  rbacService  from "../../services/rbacService";
import { isDebug } from "./helpers";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: undefined,
      isAdmin: false,
      isInstructor: false,
      isLoanerManager: false,
      isUser: false,
      isLoggedIn: false,
    };
  }


  logOut() {
    //eventBus.dispatch("logout");
    authService.logout();
    this.setState({
      isLoggedIn: false,
      isAdmin: false,
      isUser: false,
      isInstructor: false,
      isLoanerManager: false,
      currentUser: undefined,
    });
  
  }

  login() {
    const user = authService.getCurrentUser();
    const rbac = new rbacService(user);
    this.setState({
      currentUser: user,
      isAdmin: rbac.isAdmin(),
      isUser: rbac.isUser(),
      isInstructor: rbac.isInstructor(),
      isLoanerManager: rbac.isLoanerManager(),
      isLoggedIn: true,
    });
  }

  componentDidMount() {
    const user = authService.getCurrentUser();
    //if(isDebug) {
    //  console.log("Navbar -> componentDidMount Current User:");
    //  console.log(user);
    //}
    if(user) {
      const rbac = new rbacService(user);
      this.setState({
        currentUser: user,
        isAdmin: rbac.isAdmin(), //s.includes("ROLE_ADMIN"),
        isInstructor: rbac.isInstructor(), //s.includes("ROLE_INSTRUCTOR"),
        isLoanerManager: rbac.isLoanerManager(), //s.includes("ROLE_INSTRUCTOR"),
        isUser: rbac.isUser(), 
        isLoggedIn: true,
      });
    } else {
      this.setState({
        currentUser: user,
        isAdmin: false,
        isInstructor: false,
        isLoanerManager: false,
        isUser: false,
        isLoggedIn: false,
      });

    }

    eventBus.on("logout", () => {
      //this.props.logOut();
      this.logOut();
    });

    eventBus.on("login", () => {
      this.login();
    });
  }

  componentWillUnmount() {
    eventBus.remove("logout");
    eventBus.remove("login");
  }

  render() {
    //const activeColor = "#2D9F88"; // Green
    const activeColor = "#C70039"; // Navy
    const regularColor = "black";
    const { currentUser, isAdmin, isInstructor, isLoggedIn, isLoanerManager, isUser } = this.state;

    return (
      <nav className="tbnavbar navbar-expand-lg justify-content-center align-content-center ">
        <div className="row m-0 h-100">
          <div className="col align-self-center">
            <div className="d-inline-flex flex-row align-items-center align-content-center align-self-center w-50">
              <div>
                <NavLink to="/">
                  <img
                    className="img-fluid p-1 ms-3"
                    src="/TD_SYNNEX_Logo_Black.png"
                    alt="TD SYNNEX Solution Center Portal"
                    style={{
                      maxWidth: "191px",
                      height: "auto",
                      verticalAlign: "middle",
                    }}
                  />
                </NavLink>
              </div>
            </div>
          </div>
          <div className="col align-self-center">
            <div className="d-flex justify-content-end">
              <div className="navbar-nav container-fluid text-end justify-content-end align-items-center">
                {isLoggedIn && ( // Leave Feedback button
                  <NavLink
                    className="nav-item nav-link"
                    style={({ isActive }) => ({
                      color: isActive ? activeColor : regularColor,
                    })}
                    to="/feedbacks/new"
                  >
                    <button className="btn btn-primary ">
                      <small>Leave Feedback</small>
                    </button>
                  </NavLink>
                )}
                {isUser ? (
                    <>
                    <NavLink
                      className="nav-item nav-link"
                      style={({ isActive }) => ({
                        color: isActive ? activeColor : regularColor,
                      })}
                      to="/userDashboard"
                    >
                      Home
                    </NavLink>
                   
                    </>
                  ) : null
                }

                {(isLoanerManager) && (
                    <>
                      <NavLink
                        className="nav-item nav-link"
                        style={({ isActive }) => ({
                          color: isActive ? activeColor : regularColor,
                        })}
                        to="/lmdashboard"
                      >
                        Dashboard
                      </NavLink>
                    </>
                )}

                {isAdmin  && (
                      <NavLink
                        className="nav-item nav-link"
                        style={({ isActive }) => ({
                          color: isActive ? activeColor : regularColor,
                        })}
                        to="/admin"
                      >
                        Admin
                      </NavLink>
                )}

                {(isAdmin || isLoanerManager) && (
                    <>
                      <NavLink
                        className="nav-item nav-link"
                        style={({ isActive }) => ({
                          color: isActive ? activeColor : regularColor,
                        })}
                        to="/users"
                      >
                        Users
                      </NavLink>
                    </>
                )}

                {(isInstructor || isAdmin) && (
                    <>
                      <NavLink
                        className="nav-item nav-link"
                        style={({ isActive }) => ({
                          color: isActive ? activeColor : regularColor,
                        })}
                        to="/instructorWorkspace"
                      >
                        Workspace
                      </NavLink>
                    </>
                )}

                {isLoggedIn && (
                    <>
                      <NavLink
                        className="nav-item nav-link"
                        style={({ isActive }) => ({
                          color: isActive ? activeColor : regularColor,
                        })}
                        to="/demos"
                      >
                        Demos
                      </NavLink>

                      <NavLink
                        className="nav-item nav-link"
                        style={({ isActive }) => ({
                          color: isActive ? activeColor : regularColor,
                        })}
                        to="/loaner"
                      >
                        PoC
                      </NavLink>

                      <NavLink
                        className="nav-item nav-link"
                        style={({ isActive }) => ({
                          color: isActive ? activeColor : regularColor,
                        })}
                        to="/centers"
                      >
                        Centers
                      </NavLink>
                    </>
                )}


                {currentUser ? (
                    <>
                      <NavLink
                        className="nav-item nav-link"
                        style={({ isActive }) => ({
                          color: isActive ? activeColor : regularColor,
                        })}
                        to="/profile"
                      >
                        Profile
                      </NavLink>
                      <a
                        href="/"
                        className="nav-item nav-link"
                        onClick={this.logOut}
                        style={{ color: regularColor }}
                      >
                          Logout
                      </a>
                    </>
                  ) : (
                  <>
                      <NavLink
                        className="nav-item nav-link"
                        style={({ isActive }) => ({
                          color: isActive ? activeColor : regularColor,
                        })}
                        to="/login"
                      >
                          Login
                      </NavLink>{" "}
                      <NavLink
                        className="nav-item nav-link"
                        style={({ isActive }) => ({
                          color: isActive ? activeColor : regularColor,
                        })}
                        to="/register"
                      >
                          Register
                      </NavLink>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;


/*
                        <small>Demos</small>

                    <NavLink
                      className="nav-item nav-link"
                      style={({ isActive }) => ({
                        color: isActive ? activeColor : regularColor,
                      })}
                      to="/reservations"
                    >
                      <small>My Reservations</small>
                    </NavLink>

                      <NavLink
                        className="nav-item nav-link"
                        style={({ isActive }) => ({
                          color: isActive ? activeColor : regularColor,
                        })}
                        to="/deliveredServices"
                      >
                        <small>Services</small>
                      </NavLink>
*/
/*

            <div className="d-inline-flex flex-row justify-content-center align-content-center align-self-center  h-100 ">
              <div className="col align-self-center">
                {isLoggedIn ? (
                  <NavLink
                    className="nav-item nav-link"
                    style={({ isActive }) => ({
                      color: isActive ? {activeColor} : {regularColor},
                    })}
                    to="/feedbacks/new"
                  >
                    <small>Leave Feedback</small>
                  </NavLink>
                ) : (
                  <div className="ms-2 text-muted text-small">
                    {" "}
                    Please Login{" "}
                  </div>
                )}
              </div>
            </div>


                  <div className="col align-self-center">
                    <div className="flex-column lh-1">
                      <div className="text-center text-light smfont">
                        <NavLink className="text-white" to="/">
                          <small>Solution Center Portal</small>
                        </NavLink>
                      </div>
                    </div>
                  </div>

                      src="/TDSynnex51.png"

 <img
                    className="img-fluid p-0"
                    src="/TDSSCP-logo.png"
                    alt="TD SYNNEX Solution Center Portal"
                    style={{
                      maxHeight: "100%",
                      maxWidth: "100%",
                      display: "block",
                    }}
                  />

<div className="logo">
                <center>
                  <img
                    className="img-fluid p-0"
                    src="/TDSynnex_logo_222X71.png"
                    alt="TD SYNNEX Solution Center Portal"
                  />
                  <span className="text-light text-wrap">
                    <small>
                      <small>Solution Center Portal</small>
                    </small>
                  </span>
                </center>
              </div>

 <NavLink className="navbar-brand " style={{ color: {regularColor} }} to="/">
            <img
              className="img-fluid p-0"
              src="/TDSynnex_logo_222X71.png"
              alt="TD SYNNEX Solution Center Portal"
              style={{ maxHeight: "2rem" }}
            />
            <br />
            <small>
              <small>
                <small>Solution Center Portal</small>
              </small>
            </small>
          </NavLink>

              src="/TDSynnex_logo_222X71.png"
              src="/TDSSCP-logo.png"



if (isNewReseller) {
      // Creating new reseller
      const doCreate = async () => {
        const createResponse = await createReseller(data);
        if (isDebug) {
          logData(createResponse);
          logData(createResponse.status);
        }
        if (createResponse.status === 201) {
          setSuccessful(true);
        }
      };
      doCreate().catch(console.error);
*/
