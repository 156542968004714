import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../common/table";
import authService from "../../services/auth.service";
import rbacService from "../../services/rbacService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";

class VendorsTable extends Component {
  state = {
    currentUser: {},
    vendors: [],
    showAddButton: false,
  };

  async componentDidMount() {
    const loggedInUser = authService.getCurrentUser();
    this.setState({ currentUser: loggedInUser });
    const rbac = new rbacService(loggedInUser);
    //if (loggedInUser.roleId <= 2) {
    if (rbac.hasElevatedPrivs()) {
      // logged in user is either admin or instructor
      this.columns.push(...this.adminColumns);
      this.setState({ showAddButton: true });
    }
  }

  columns = [
    {
      path: "name",
      label: "Name",
      content: (vendor) => (
        <Link to={`/vendor/${vendor.id}`}>{vendor.name}</Link>
      ),
    },
    { path: "description", label: "Description" },
    /*
    {
      key: "like",
      content: (vendor) => (
        <Like liked={vendor.liked} onClick={() => this.props.onLike(vendor)} />
      ),
    },
    */
  ];

  //These additional columns are only present when user
  //is an instructor or admin
  adminColumns = [
    {
      key: "edit",
      content: (demo) => (
        <Link to={`/vendorForm/${demo.id}`}>
          <FontAwesomeIcon icon={faPenToSquare} />
        </Link>
      ),
    },
    {
      key: "delete",
      content: (demo) => (
        <button
          onClick={() => this.props.onDelete(demo)}
          className="btn btn-danger btn-sm"
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </button>
      ),
    },
  ];

  render() {
    const { vendors, onSort, onDelete, sortColumn, caption } = this.props;

    return (
      <>
        <Table
          columns={this.columns}
          data={vendors}
          sortColumn={sortColumn}
          onDelete={onDelete}
          onSort={onSort}
          caption={caption}
        />
      </>
    );
  }
}

export default VendorsTable;
