import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useNavigate } from "react-router-dom";
import * as yup from "yup";

import authService from "../../services/auth.service";
import {
  getInstructorbyID,
  updateInstructor,
} from "../../services/instructorService";
import Input from "../common/input";
import { isDebug, logData } from "../common/helpers";
import config from "../../config.json";

export default function InstructorForm() {
  const [fetchedInstructor, setFetchedInstructor] = useState(null);
  const [currentHeadshot, setCurrentHeadshot] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const { instructorId } = useParams();
  const navigate = useNavigate();

  const isNewInstructor = instructorId.toString() === "new" ? true : false;
  console.log(`isNewInstructor = ${isNewInstructor}`);
  const currentUser = authService.getCurrentUser();
  const pageTitle = isNewInstructor
    ? "Create New Instructor"
    : "Update Instructor Information";
  /*
   */
  const schema = yup.object().shape({
    description: yup.string().required().min(4).max(3000),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const handleUpdate = (data) => {
    console.log("Handling Update...");
    const formData = new FormData();

    if (data.headshot?.length > 0) {
      formData.append("headshot", data.headshot[0]);
    }

    formData.append("description", data.description);
    formData.append("id", instructorId);

    data.id = instructorId;

    console.log("Original data: ");
    console.log(data);
    console.log("formData: ");
    console.log(formData);

    const pushUpdate = async () => {
      //const updateResponse = await updateInstructor(instructorId, data);
      const updateResponse = await updateInstructor(instructorId, formData);
      console.log(updateResponse);
      console.log(updateResponse.status);
      if (updateResponse.status === 200) {
        setSuccessful(true);
      }
    };
    // // Commenting this out for testing only
    pushUpdate().catch(console.error);
  };

  // Runs after first render (when component mounts, sorta)
  useEffect(() => {
    setIsLoading(true);

    if (!isNewInstructor) {
      const getInstructorInfo = async () => {
        const instructor = await getInstructorbyID(instructorId);
        setFetchedInstructor(instructor);
      };
      getInstructorInfo().catch(console.error);
    } else {
      // only Admins can use this form to create a new instructor
      if (!currentUser.roles.id !== 1) {
        setHasError(true);
        setErrMsg("Only Admins can create new users!");
      }
    }
    setIsLoading(false);
  }, []);

  /* this useEffect is called only when we have 
  fetched a user from the backend */
  useEffect(() => {
    if (typeof fetchedInstructor === "object" && fetchedInstructor !== null) {
      logData("Fetched User ID: " + fetchedInstructor.id);
    } else {
      return;
    }
    console.log("Fetched Instructor: ");
    console.log(fetchedInstructor);

    setValue("description", fetchedInstructor.description, {
      shouldValidate: true,
      shouldDirty: true,
    });
    if (fetchedInstructor.imageUrl !== null) {
      setCurrentHeadshot(config.imgBaseURL + fetchedInstructor.imageUrl);
    }
  }, [fetchedInstructor]);

  /* This useEffect is only when successful submission */
  useEffect(() => {
    if (successful) {
      navigate(-1);
    }
  }, [successful]);

  return (
    <React.Fragment>
      {hasError ? (
        alert(errMsg)
      ) : isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      ) : (
        <>
          <center>
            <h2 className="display-6">{pageTitle}</h2>
          </center>
          <div className="container shadow border border-2 border-light rounded-3 mt-4">
            <p className="fs-4">
              Update Instructor Details for {fetchedInstructor?.user.fullName}
            </p>
            <form onSubmit={handleSubmit(handleUpdate)} className="p-2">
              <div className="row justify-content-lg-center">
                <div className="col-4">
                  <center>
                    {currentHeadshot ? (
                      <div>
                        <img
                          src={currentHeadshot}
                          width="200"
                          height="200"
                          alt={fetchedInstructor.imageUrl}
                        />
                      </div>
                    ) : (
                      <img
                        src={config.imgBaseURL + "/headshots/unknown.jpg"}
                        width="200"
                        height="200"
                        alt="Instructor  headshot"
                      />
                    )}
                    <Input
                      fieldName="headshot"
                      type="file"
                      label="Replace Instructor Picture?"
                      className="form-control form-control-sm m-2"
                      register={register}
                      errors={errors}
                    />
                  </center>
                </div>
                <div className="col-8">
                  <label htmlFor="description">Instructor Bio</label>
                  <textarea
                    {...register("description")}
                    className="form-control"
                    rows="5"
                  />
                  {errors["description"] && (
                    <div className="alert alert-danger m-2">
                      {errors["description"].message}
                    </div>
                  )}
                </div>
              </div>
              <div className="row justify-content-lg-center">
                <div className="col"></div>
                <div className="col">
                  <center>
                    <div id="4" className="form-group m-2">
                      <input
                        className="btn btn-sm btn-primary me-2"
                        type="submit"
                        value="Update"
                      />
                      <input
                        className="btn btn-sm btn-danger"
                        type="button"
                        value="Cancel"
                        onClick={() => {
                          navigate(-1);
                        }}
                      />
                    </div>
                    {successful && (
                      <div className="alert alert-success">
                        Update Successful!
                      </div>
                    )}
                  </center>
                </div>
                <div className="col"></div>
              </div>
            </form>
          </div>
        </>
      )}
    </React.Fragment>
  );
}
