import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import * as yup from "yup";
import _ from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";

import { arrayFindAll } from "../../utils/utils";
import Input from "../common/input";
import authService from "../../services/auth.service";
import http from "../../services/httpService";
import { getResellers } from "../../services/resellerService";
import { getRoles } from "../../services/roleService.js";
import { getVendorByName, getVendors } from "../../services/vendorService";
import {
  getCategories,
  getCategoryByTitle,
} from "../../services/categoryService";
import { getInstructorbyUserID } from "../../services/instructorService";
import {
  createUser,
  getUserInfo,
  updateUser,
} from "../../services/userService";
import { isDebug } from "../common/helpers";
import config from "../../config.json";
import rbacService from "../../services/rbacService";

export default function UserForm() {
  const [fetchedUser, setFetchedUser] = useState(null);
  const [fetchedInstructor, setfetchedInstructor] = useState(null);
  const [currentHeadshot, setCurrentHeadshot] = useState(null);
  const [uploadBtnLabel, setUploadBtnLabel] = useState("Change Photo?");

  const [userVendors, setUserVendors] = useState(null);
  const [userTopics, setUserTopics] = useState(null);
  const [resellers, setResellers] = useState([]);
  const [roles, setRoles] = useState([]);

  const [allVendors, setAllVendors] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [showVendorModal, setShowVendorModal] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showInstructorBio, setShowInstructorBio] = useState(false);
  const [showFileUpload, setShowFileUpload] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [errMsg, setErrMsg] = useState();

  const { userId } = useParams();
  const navigate = useNavigate();

  const currentUser = authService.getCurrentUser();
  const rb = new rbacService(currentUser);

  //const isAdminUser = currentUser.roleId === 1; //s.includes("ROLE_ADMIN");
  const isAdminUser = rb.isAdmin();

  const isNewUser = userId.toString() === "new" ? true : false;
  const submitButtonLabel = isNewUser ? "Create" : "Update";
  const pageTitle = isNewUser ? "Create New User" : "Update User Information";

  let location = useLocation();
  let newuserVendors = [];
  let newuserTopics = [];

  const schema = yup.object().shape({
    email: yup.string().required().email(),
    firstName: yup.string().required().max(20),
    lastName: yup.string().required().min(2).max(20),
    resellerId: yup.number().required().integer().positive(),
    roleId: yup.number().required().integer().positive(),
    password: yup.string(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Password must match"),
  });
  //workPhone: yup.string().min(8).max(17).nullable(),
  //userName: yup.string().min(4).max(20),

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const watchUser = watch("roleId");

  async function getVByName(name) {
    if (name !== "" && name !== null) {
      const fetchedVendor = await getVendorByName(name);
      console.log(fetchedVendor);
      newuserVendors.push(fetchedVendor);
    }
  }

  async function getTByName(title) {
    if (title !== "" && title !== null) {
      const fetchedTopic = await getCategoryByTitle(title);
      console.log(fetchedTopic);
      newuserTopics.push(fetchedTopic);
    }
  }

  // This is where we clean up the form data
  // and PUSH it to the API
  const handleUpdate = (data) => {
    const formData = new FormData();

    //console.log(data);

    if (data.headshot?.length > 0) {
      formData.append("headshot", data.headshot[0]);
    }

    if (data.userName === "") {
      const nuserName = data.email.split("@");
      data.userName = nuserName[0];
      if (isDebug) console.log("Handle Update: Set username to: " + data.userName);
    }
    //formData.append("userName", data.userName);

    //Correcting issue with no instructor data causing no instructor
    // record to be created
    if(data.instructorBio === "") {
      data.instructorBio = "Please update your bio";
    }

    if (!data.fullName) {
      data.fullName = data.firstName + " " + data.lastName;
      if (isDebug) console.log("Handle Update: Set fullName to: " + data.fullName);
    }
    //formData.append("fullName", data.fullName);

    if (isDebug && fetchedUser?.vendors) {
      console.log("HandleUpdate: Fetched User Vendors: ");
      console.log(fetchedUser.vendors);
    }
    /* map vendor boolean array to array of vendor names */
    /*
      Currently if this is a new user, then the vendors for that user
      are in the data.vendors object array
      If it's an existing user being updated, the vendors for that user
      are in the fetchedUser.vendors object array
    */
    let userVendorsNames = [];
    if (isDebug) {
      console.log("Handle Update: initial data.vendors attribute: ");
      console.log(data.vendors);
      console.log("Handle Update: userVendors attribute: ");
      console.log(userVendors);
    }
    if (userVendors) {
      userVendors.map((value, i) => {
        userVendorsNames.push(userVendors[i].name);
        formData.append("vendors", userVendors[i].name);
      });
    }

    data.vendors = userVendorsNames;
    // this is smashing the array into a string so put it
    // in the loop above
    //formData.append("vendors", userVendorsNames);

    if (isDebug) {
      console.log("Handle Update: updated vendors attribute: ");
      console.log(data.vendors);
    }

    /* map category boolean array to array of category titles */
    let userCategoriesNames = [];
    if (isDebug) {
      console.log("Handle Update: initial data.categories attribute: ");
      console.log(data.categories);
      console.log("Handle Update: userTopics attribute: ");
      console.log(userTopics);
    }
    if (userTopics) {
      userTopics.map((value, i) => {
        userCategoriesNames.push(userTopics[i].title);
        formData.append("categories", userTopics[i].title);
      });
    }

    data.categories = userCategoriesNames;
    // same issue as vendors above
    //formData.append("categories", userCategoriesNames);

    if (isDebug) {
      console.log("Handle Update: updated topics attribute: ");
      console.log(data.categories);
    }

    for (const field in data) {
      if (
        field !== "headshot" &&
        field !== "vendors" &&
        field !== "categories"
      ) {
        formData.append(`${field}`, data[field]);
      }
    }

    /*
     */

    formData.append("requestorId", currentUser.id);

    if (isNewUser) {
      // Create a new user
      if (isDebug) {
        console.log("Handle Update: New User Creation. Data Object: ");
        console.log(data);
      }

      const pushNew = async () => {
        const createResponse = await createUser(formData);
        if (isDebug) {
          console.log(createResponse);
          console.log(createResponse.status);
        }
        if (createResponse.status >= 200 && createResponse.status < 300) {
          // should be 201
          setSuccessful(true);
        }
      };
      /* */
      pushNew().catch((error) => http.handleError(error));
      // END OF CREATE NEW USER
    } else {
      // Update an existing user
      if (data.password === "") {
        formData.delete("password");
        formData.delete("confirmPassword");
        delete data.password;
        delete data.confirmPassword;
      }

      formData.append("id", userId);

      const pushUpdate = async () => {
        //const updateResponse = await updateUser(userId, newData);
        const updateResponse = await updateUser(userId, formData);
        if (isDebug) {
          console.log(updateResponse);
          console.log(updateResponse.status);
        }
        if (updateResponse.status >= 200 && updateResponse.status < 300) {
          setSuccessful(true);
        }
      };
      pushUpdate().catch((error) => http.handleError(error));
      // END OF UPDATE EXISTING USER
    }
  };

  const handleShowCategories = () => setShowCategoryModal(true);
  const handleCloseCategories = () => setShowCategoryModal(false);

  const handleShowVendors = () => setShowVendorModal(true);
  const handleCloseVendors = () => setShowVendorModal(false);

  const handleShowHideFileUpload = () => {
    showFileUpload
      ? setUploadBtnLabel("Change Photo?")
      : setUploadBtnLabel("Cancel");
    setShowFileUpload(!showFileUpload);
  };

  const handleVendorUpdate = () => {
    setShowVendorModal(false);
    const hookVendors = getValues("vendors");
    const newVendorsIndexes = arrayFindAll(hookVendors, true);
    const newVendors = allVendors.filter((i) =>
      newVendorsIndexes.includes(i.id)
    );
    console.log(hookVendors);
    console.log(newVendorsIndexes);
    console.log(allVendors);
    console.log(newVendors);
    setUserVendors(newVendors);
    if (typeof fetchedUser === "object" && fetchedUser !== null) {
      fetchedUser.vendors = newVendors;
      setFetchedUser(fetchedUser);
    } else {
      setValue("vendors", newVendors);
    }
  };

  const handleCategoryUpdate = () => {
    setShowCategoryModal(false);
    const hookCategories = getValues("categories");
    const newCategoriesIndexes = arrayFindAll(hookCategories, true);
    const newCategories = allCategories.filter((i) =>
      newCategoriesIndexes.includes(i.id)
    );
    console.log(newCategoriesIndexes);
    console.log(allCategories);
    console.log(newCategories);
    setUserTopics(newCategories);
    if (typeof fetchedUser === "object" && fetchedUser !== null) {
      fetchedUser.categories = newCategories;
      setFetchedUser(fetchedUser);
    } else {
      setValue("categories", newCategories);
    }
  };

  // watching the role dropdown to show / hide instructor info
  useEffect(() => {
    const currentRole = getValues("roleId");
    let intRole = null;

    if (currentRole !== "") {
      intRole = parseInt(currentRole);
      if (intRole <= 2) {
        setShowInstructorBio(true);
      } else {
        setShowInstructorBio(false);
      }
    }
  }, [watchUser]);

  // Runs after first render (when component mounts, sorta)
  useEffect(() => {
    setIsLoading(true);

    const getRoleList = async () => {
      const currentRoles = await getRoles();
      setRoles(currentRoles);
    };

    const getResellerList = async () => {
      const resellerList = await getResellers();
      const sortedResellers = _.orderBy(resellerList, ["name"], "asc");
      setResellers(sortedResellers);
    };

    const getVendorList = async () => {
      const fetchedvendors = await getVendors();
      const sortedVendors = _.orderBy(fetchedvendors, ["name"], "asc");
      setAllVendors(sortedVendors);
    };

    const getCategoryList = async () => {
      const fetchedcategories = await getCategories();
      const sortedTopics = _.orderBy(fetchedcategories, ["title"], "asc");
      setAllCategories(sortedTopics);
    };

    //if (currentUser.roleId === 1) {
    if (rb.isAdmin()) {
      getRoleList().catch(console.error);
      getResellerList().catch(console.error);
    }

    getVendorList().catch(console.error);
    getCategoryList().catch(console.error);

    if (!isNewUser) {
      let localuser = null;
      let localinstructor = null;
      // The user can only update thier own user unless he/she is admin or loaner manager
      //if (userId === currentUser.id.toString() || currentUser.roleId === 1 || currentUser.roleId === 4) {
      if (userId === currentUser.id.toString() || rb.isAdmin() || rb.isLoanerManager()) {
        const getInstructorDetails = async () => {
          localinstructor = await getInstructorbyUserID(userId);
          setfetchedInstructor(localinstructor);
          console.log(localinstructor);
        };

        const getUserDetails = async () => {
          localuser = await getUserInfo(userId);
          setFetchedUser(localuser);
          console.log(localuser);
          //if (localuser.roleId === 1 || localuser.roleId === 2) {
          if (rb.isAdminOrInstructor()) {
            getInstructorDetails().catch(console.error);
          }
        };
        getUserDetails().catch((error) => http.handleError(error));
      } else {
        setHasError(true);
        setErrMsg("Access Denied!");
      }
    } else {
      //userId == 'new'
      // only Admins can use this form to create a new user
      //if (currentUser.roleId !== 1) {
      if (! rb.isAdmin()) {
        setHasError(true);
        setErrMsg("Only Admins can create new users!");
      }
      console.log("Location.state ...");
      console.log(location.state);
      if (location.state) {
        // Creating User from Sign Up Request
        let newUser = {};
        const roleId = 3;

        // we need to pull the vendors and topics
        // and convert them to id's
        if (location.state.vendors !== "") {
          const nuVendors = location.state.vendors
            .split("-")
            .map((element) => element.trim());
          console.log(nuVendors);

          nuVendors.forEach(getVByName);
        }

        if (location.state.topics !== "") {
          const nuTopics = location.state.topics
            .split("-")
            .map((e) => e.trim());
          console.log(nuTopics);

          nuTopics.forEach(getTByName);
        }

        console.log("newuserTopics: ");
        console.log(newuserTopics);
        console.log("newuserVendors: ");
        console.log(newuserVendors);

        const nuserName = location.state.email.split("@");
        newUser.firstName = location.state.firstName;
        newUser.lastName = location.state.lastName;
        newUser.fullName = location.state.fullName;
        newUser.email = location.state.email;
        newUser.userName = nuserName[0];
        newUser.workPhone = location.state.workPhone;
        newUser.roleId = roleId;
        newUser.vendors = newuserVendors;
        newUser.categories = newuserTopics;
        console.log(newUser);
        setFetchedUser(newUser);
        //setUserVendors(newuserVendors);
        //setUserTopics(newuserTopics);
      }
    }
    //A hack to try and prevent errors showing up
    //before data has been entered
    clearErrors();

    setIsLoading(false);
  }, []);

  /* this useEffect is called only when we have 
  fetched a user from the backend */
  useEffect(() => {
    // Making sure fetchedUser actually has a value
    if (typeof fetchedUser === "object" && fetchedUser !== null) {
      console.log("Fetched User ID: " + fetchedUser.id);
    } else {
      return;
    }

    setValue("firstName", fetchedUser.firstName, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue("lastName", fetchedUser.lastName, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue("fullName", fetchedUser.fullName, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue("userName", fetchedUser.userName, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue("email", fetchedUser.email, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue("workPhone", fetchedUser.workPhone, {
      shouldValidate: true,
      shouldDirty: true,
    });
    //if (currentUser.roleId <= 2) {
    if (rb.isAdminOrInstructor()) {
      const filtered = fetchedUser.resellerId
        ? resellers.filter((r) => r.id === fetchedUser.resellerId)
        : resellers;
      setResellers(filtered);
      if (isDebug) console.log(resellers);
    }
    // This isn't setting the default value correctly
    if (isDebug) console.log("Fetched User Reseller ID: " + fetchedUser.resellerId);
    setValue("resellerId", fetchedUser.resellerId, {
      shouldValidate: true,
      shouldDirty: true,
    });
    if (isDebug) console.log("getValue(resellerId): " + getValues("resellerId"));

    setValue("roleId", fetchedUser.roleId, {
      shouldValidate: true,
      shouldDirty: true,
    });
    console.log(fetchedUser.vendors);
    console.log(fetchedUser.categories);
    setUserVendors(fetchedUser.vendors);
    setUserTopics(fetchedUser.categories);
    // now we need to set the correct checkboxes in react-hook-form
    if(fetchedUser.vendors) {
      fetchedUser.vendors?.map((vendor) => {
        const vendorId = vendor.id.toString();
        setValue(`vendors[${vendorId}]`, true);
      })
    }
    if(fetchedUser.categories) {
      fetchedUser.categories?.map((category) => {
        const topicId = category.id.toString();
        setValue(`categories[${topicId}]`, true);
      })
    }
  }, [fetchedUser]);

  // called when we fetch an instructor record
  useEffect(() => {
    if (typeof fetchedInstructor === "object" && fetchedInstructor !== null) {
      console.log(fetchedInstructor);
      setValue("instructorBio", fetchedInstructor.description, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("twitterURL", fetchedInstructor.twitterURL, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("linkedInURL", fetchedInstructor.linkedInURL, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("githubURL", fetchedInstructor.githubURL, {
        shouldValidate: true,
        shouldDirty: true,
      });
      if (fetchedInstructor.imageUrl !== null) {
        console.log("fetched Instructor imageUrl:");
        console.log(fetchedInstructor.imageUrl);
        setCurrentHeadshot(config.imgBaseURL + fetchedInstructor.imageUrl);
      } else {
        console.log("fetched Instructor imageUrl:");
        console.log(fetchedInstructor.imageUrl);

      }
    }
  }, [fetchedInstructor]);

  /* This useEffect is only when successful submission */
  useEffect(() => {
    //if(isDebug) console.log("successful: " + successful);
    if (successful) {
      //navigate("/users");
      navigate(-1);
    }
  }, [successful]);

  return (
    <React.Fragment key={2345}>
      {hasError ? (
        alert(errMsg)
      ) : isLoading ? (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      ) : (
        <>
          <center>
            <h2 className="display-6">{pageTitle}</h2>
          </center>
          <div className="container shadow border border-2 border-light rounded-3 pt-2 mt-2">
            <form onSubmit={handleSubmit(handleUpdate)} className="p-2">
              <div className="row justify-content-lg-center">
                <div className="col-md">
                  <Input
                    register={register}
                    fieldName="firstName"
                    label="First Name"
                    errors={errors}
                  />
                  <Input
                    register={register}
                    fieldName="lastName"
                    label="Last Name"
                    errors={errors}
                  />
                  <Input
                    register={register}
                    fieldName="fullName"
                    label="Full Name"
                    errors={errors}
                  />
                </div>

                <div className="col-md">
                  <Input
                    register={register}
                    fieldName="userName"
                    label="User Name"
                    errors={errors}
                    autoComplete="off"
                  />
                  <Input
                    register={register}
                    fieldName="email"
                    label="Email"
                    errors={errors}
                  />
                  <Input
                    register={register}
                    fieldName="workPhone"
                    label="Work Phone"
                    errors={errors}
                  />
                </div>

                {isAdminUser ? (
                  <>
                    <div className="col-md">
                      <Link
                        className="ms-2"
                        to="/resellerForm/new"
                        state={location.state}
                      >
                        Add Reseller...
                      </Link>{" "}
                      {" if reseller not found in list"}
                      <div className="form-floating">
                        <select
                          {...register("resellerId", {
                            onChange: (e) => {
                              setValue("resellerId", e.target.value);
                            },
                          })}
                          id="resellerId"
                          className="form-select m-2"
                        >
                          <option key="0" value="">
                            {" "}
                          </option>
                          {resellers.map((reseller) => (
                            <option key={reseller.id} value={reseller.id}>
                              {reseller.name}
                            </option>
                          ))}
                        </select>
                        <label htmlFor="resellerId">
                          {"Select your reseller..."}
                        </label>
                        {errors["resellerId"] && (
                          <div className="alert alert-danger">
                            {errors["resellerId"].message}
                          </div>
                        )}
                        <center>
                          <Input
                            register={register}
                            fieldName="password"
                            label="New Password:"
                            errors={errors}
                            type="password"
                            autoComplete="new-password"
                          />
                          <Input
                            register={register}
                            fieldName="confirmPassword"
                            label="Confirm New Password"
                            errors={errors}
                            type="password"
                            autoComplete="new-password"
                          />
                        </center>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>

              <div className="row justify-content-lg-center">
                <div className="col-4">
                  <div className="form-floating">
                    {isAdminUser ? (
                      <>
                        <select
                          {...register("roleId", {
                            onChange: (e) => {
                              setValue("roleId", e.target.value);
                            },
                          })}
                          id="roleId"
                          className="form-select m-2"
                        >
                          <option key="0" value="">
                            {" "}
                          </option>
                          {roles.map((role) => (
                            <option key={role.id} value={role.id}>
                              {role.name}
                            </option>
                          ))}
                        </select>
                        <label htmlFor="roleId">{"Select role..."}</label>
                        {errors["roleId"] && (
                          <div className="alert alert-danger">
                            {errors["roleId"].message}
                          </div>
                        )}
                      </>
                    ) : null}
                  </div>
                </div>
                <div className="col-8"></div>
              </div>

              {showInstructorBio && (
                <>
                <div className="row justify-content-lg-center">
                  <div className="col-4 ">
                    <center>
                      {currentHeadshot ? (
                        <img
                          src={currentHeadshot}
                          className="mb-2"
                          width="200"
                          height="200"
                          alt={fetchedInstructor.imageUrl}
                        />
                      ) : (
                        <img
                          src={config.imgBaseURL + "/headshots/unknown.jpg"}
                          width="200px"
                          height="200px"
                          alt="Instructor  headshot"
                        />
                      )}
                      <div className="w-100"> </div>
                      <Button
                        size="sm"
                        variant="primary"
                        onClick={handleShowHideFileUpload}
                      >
                        {uploadBtnLabel}
                      </Button>
                      <div className="w-100"></div>
                      {showFileUpload ? (
                        <>
                          <Input
                            fieldName="headshot"
                            type="file"
                            label="Replace Instructor Picture?"
                            className="form-control form-control-sm m-2"
                            register={register}
                            errors={errors}
                          />
                          <p className="text-danger">
                            <small>Photo should be square!</small>
                          </p>
                        </>
                      ) : null}
                      <div className="w-100"> </div>
                    </center>
                  </div>
                  <div className="col-8">
                    <>
                      <label htmlFor="instructorBio">Instructor Bio</label>
                      <textarea
                        {...register("instructorBio")}
                        id="instructorBio"
                        className="form-control m-2"
                        rows="5"
                      />
                      {errors["instructorBio"] && (
                        <div className="alert alert-danger m-2">
                          {errors["instructorBio"].message}
                        </div>
                      )}
                    </>
                  </div>
                </div>
                <div className="row justify-content-lg-center">
                  <div className="col-4 ">
                    <Input
                      register={register}
                      fieldName="twitterURL"
                      label="Twitter URL"
                      errors={errors}
                    />
                  </div>
                  <div className="col-4 ">
                    <Input
                      register={register}
                      fieldName="linkedInURL"
                      label="LinkedIn URL"
                      errors={errors}
                    />
                  </div>
                  <div className="col-4 ">
                    <Input
                      register={register}
                      fieldName="githubURL"
                      label="Github URL"
                      errors={errors}
                    />
                  </div>
                </div>
                </>
              )}

              <div className="row justify-content-lg-center">
                <div className="col-md">
                  <center>
                    <div className="form-group m-2">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>
                              {" "}
                              <center>Favorite Vendors</center>{" "}
                            </th>
                            <th>
                              <Button
                                variant="primary"
                                onClick={handleShowVendors}
                              >
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </Button>
                              <Modal
                                show={showVendorModal}
                                onHide={handleCloseVendors}
                                scrollable={true}
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>Select Vendor(s)</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  Vendor List
                                  <div className="col">
                                    {allVendors &&
                                      allVendors.map((vendor) => (
                                        <>
                                          <div className="form-check ">
                                            <label className="form-check-label p-2">
                                              <input
                                                className="form-check-input"
                                                {...register(
                                                  `vendors[${vendor.id}]`
                                                )}
                                                type="checkbox"
                                                key={vendor.id}
                                              />
                                              {vendor.name}
                                            </label>
                                          </div>
                                        </>
                                      ))}
                                  </div>
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button
                                    variant="secondary"
                                    onClick={handleCloseVendors}
                                  >
                                    Close
                                  </Button>
                                  <Button
                                    variant="primary"
                                    onClick={handleVendorUpdate}
                                  >
                                    Submit
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {userVendors &&
                            userVendors.map((vendor, i) => (
                              <>
                                <tr>
                                  <td key={i}>
                                    <small>{vendor.name}</small>
                                  </td>
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </center>
                </div>
                <div className="col-md">
                  <center>
                    <div className="form-group m-2">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>
                              {" "}
                              <center>Favorite Categories</center>{" "}
                            </th>
                            <th>
                              <Button
                                variant="primary"
                                onClick={handleShowCategories}
                              >
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </Button>
                              <Modal
                                show={showCategoryModal}
                                onHide={handleCloseCategories}
                                scrollable={true}
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>Pick Category(s)</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  Category List
                                  <div className="col">
                                    {allCategories &&
                                      allCategories.map((category) => (
                                        <>
                                          <div className="form-check ">
                                            <label className="form-check-label p-2">
                                              <input
                                                className="form-check-input"
                                                {...register(
                                                  `categories[${category.id}]`
                                                )}
                                                type="checkbox"
                                                key={category.id}
                                              />
                                              {category.title}
                                            </label>
                                          </div>
                                        </>
                                      ))}
                                  </div>
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button
                                    variant="secondary"
                                    onClick={handleCloseCategories}
                                  >
                                    Close
                                  </Button>
                                  <Button
                                    variant="primary"
                                    onClick={handleCategoryUpdate}
                                  >
                                    Submit
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {userTopics &&
                            userTopics.map((category, i) => (
                              <>
                                <tr>
                                  <td key={i}>
                                    <small>{category.title}</small>
                                  </td>
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </center>
                </div>
              </div>

              <div className="row justify-content-lg-center">
                <div className="col"></div>
                <div className="col">
                  <center>
                    <div id="4" className="form-group m-2">
                      <input
                        className="btn btn-sm btn-primary me-2"
                        type="submit"
                        value={submitButtonLabel}
                      />
                      <input
                        className="btn btn-sm btn-danger"
                        type="button"
                        value="Cancel"
                        onClick={() => {
                          navigate(-1);
                        }}
                      />
                    </div>
                    {successful && (
                      <div className="alert alert-success">
                        Update Successful!
                      </div>
                    )}
                  </center>
                </div>
                <div className="col"></div>
              </div>
            </form>
          </div>
        </>
      )}
    </React.Fragment>
  );
}

/*

                  {roles &&
                    roles.map((role) => (
                      <>
                        <div className="form-check form-switch">
                          <label className="form-check-label p-2">
                            <input
                              className="form-check-input"
                              role="switch"
                              {...register(`role[${role.id}]`)}
                              type="checkbox"
                              key={role.id}
                            />
                            {role.description}
                          </label>
                        </div>
                      </>
                    ))}
*/

/*

                    <img
                     src={config.imgBaseURL + instructor.imageUrl}
                     src={currentHeadshot}
                     width="200"
                     height="200"
                     alt="Instructor Image"
                    />
*/

/*

                      <Input
                        register={register}
                        fieldName="instructorBio"
                        label="Instructor Bio"
                        errors={errors}
                        type="textarea"
                      />
*/
